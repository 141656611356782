import axios from "axios";
import { useContext, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BACKEND_DOMAIN, REFRESH_TOKEN_URL } from "../constants/GlobalUrls";
import { LOGIN_AUTH_ACCESS_TOKEN } from "../constants/GlobalVariables";
import AuthContextAPI from "../context/AuthContextAPI";

const baseURL = BACKEND_DOMAIN;

const useAxios = () => {

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const { authTokens, setAuthTokens, logoutUser, userInformation } =
    useContext(AuthContextAPI);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (
        Object.keys(userInformation).length > 0 &&
        !("permissions" in userInformation)
      ) {
        logoutUser();
      }
    }

    return () => {
      isMoulded = false;
    };
  }, [userInformation]);

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
    data: {},
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = authTokens?.refresh;

        return new Promise(function (resolve, reject) {
          axios
            .post(REFRESH_TOKEN_URL, { refresh: refreshToken })
            .then(({ data }) => {
              AsyncStorage.setItem(
                LOGIN_AUTH_ACCESS_TOKEN,
                JSON.stringify(data)
              );
              setAuthTokens(data)
              axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
              originalRequest.headers['Authorization'] = 'Bearer ' + data.access;

              processQueue(null, data.access);
              resolve(axiosInstance(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              logoutUser();
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      return Promise.reject(error);
    }
  )

  return axiosInstance;
};

export default useAxios;
