import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import AdminAppDrawer from "../AdminAppDrawer/AdminAppDrawer";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";
import adminWebNavigationString from "./adminWebNavigationString";

const Stack = createStackNavigator();

const AdminAppStack = () => {
  return (
    <Stack.Navigator
      initialRouteName={adminWebNavigationString.ADMIN.name}
      screenOptions={{
        headerTitleAlign: "center",
        headerBackTitle: null,
        headerBackTitleVisible: false,
        header: () => {
          return <CommonHeader />;
        },
      }}
    >
      <Stack.Screen
        name={adminWebNavigationString.ADMIN.name}
        component={AdminAppDrawer}
        options={{
          title: adminWebNavigationString.ADMIN.title,
        }}
      />
    </Stack.Navigator>
  );
};

export default AdminAppStack;
