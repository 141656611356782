export const chartData = [
  { x: 1, y: 2 },
  { x: 2, y: 3 },
  { x: 3, y: 5 },
  { x: 4, y: 4 },
  { x: 5, y: 6 },
];

export const statsChartsData = [
  {
    title: "YouTube",
    subtitle: "Video Views",
    count: "2 877 834",
    chartData: chartData,
    statsData: [
      {
        count: "346K",
        title: "Comments",
        stat: null,
        line1: "Your YouTube",
        line2: "Comments",
      },
      {
        count: "47",
        title: "Videos",
        stat: null,
        line1: "Your YouTube",
        line2: "Videos",
      },
    ],
  },
  {
    title: "YouTube",
    subtitle: "Subscribers",
    count: "35 837",
    chartData: chartData,
    statsData: [
      {
        count: "56.4K",
        title: "Likes",
        stat: null,
        line1: "Your YouTube",
        line2: "Likes",
      },
      {
        count: "47",
        title: "Dislikes",
        stat: null,
        line1: "Your YouTube",
        line2: "Dislikes",
      },
    ],
  },
];

export const socialMediaData = [
  {
    title: "Instagram",
    subtitle: "Followers",
    count: "23 374",
    chartData: chartData,
    statsData: [
      {
        count: "3.4M",
        title: "Instagram Likes",
        stat: "3.6K (8.23%)",
        line1: "Average Instagram ",
        line2: "Post Likes",
      },
      {
        count: "445.1K",
        title: "Comments",
        stat: "13.6K (12.23%)",
        line1: "Average Daily New",
        line2: "IG Followers",
      },
    ],
  },
  {
    title: "Facebook",
    subtitle: "Post Likes",
    count: "3593",
    chartData: chartData,
    statsData: [
      {
        count: "3.4M",
        title: "Followers",
        stat: "13.6K (12.23%)",
        line1: "New Facebook",
        line2: "Followers",
      },
      {
        count: "445.1K",
        title: "Comments",
        stat: "13.6K (12.23%)",
        line1: "New Facebook",
        line2: "Comments",
      },
    ],
  },
];
