import { StyleSheet, Text, View } from "react-native";
import React, { useContext } from "react";
import {
  CustomButton,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import InvoicesDataContextAPI from "./InvoicesDataContextAPI";
import { STATISTICS_DATA, config } from "./invoice.config";
import { customStyle } from "../../../components/CustomText/customStyle";
import {
  BUTTON_TEXTS,
  BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
} from "../../../constants/GlobalVariables";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTheme } from "react-native-paper";
import MultiSelectFilter from "../Reports/CustomReportFilter/MultiSelectFilter";
import GetDataFromFilters from "./GetDataFromFilters";
import InvoiceTable from "./InvoiceTable";
import { LOADER, RESET_FILTERS, UPDATE_MULTIPLE_DATA } from "./reducer/actions";
import { DOWNLOAD_INVOICE_URL } from "../../../constants/GlobalUrls";
import useAxios from "../../../hooks/useAxios";
import AuthContextAPI from "../../../context/AuthContextAPI";
import axios from "axios";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";
let uuid = require("uuid");

const LandingPage = () => {
  // api hook
  let api = useAxios();

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const { logoutUser } = useContext(AuthContextAPI);

  let {
    state: {
      loading,
      client_id,
      clientOptions,
      service_id,
      serviceOptions,
      report_version_id,
      reportVersionOptions,
      tableData,
    },
    dispatch,
  } = useContext(InvoicesDataContextAPI);

  let theme = useTheme();

  const onChangeMultiSelectFilter = (value) => {
    let tempValue = { ...value, tableData: [] };
    dispatch({
      type: UPDATE_MULTIPLE_DATA,
      payload: tempValue,
    });
  };

  const onClearAllFilter = () => {
    dispatch({
      type: RESET_FILTERS,
      payload: {},
    });
  };

  const onClickDownloadCSV = async () => {
    dispatch({ type: LOADER, payload: true });
    await api
      .get(DOWNLOAD_INVOICE_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `invoice_${uuid.v4()}.csv`);
        tempLink.click();
        dispatch({ type: LOADER, payload: false });
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          dispatch({ type: LOADER, payload: false });
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const extraHeaderComponent = (
    <View style={commonStatisticButtonStyle(theme).statisticContainer}>
      <Statistics label={STATISTICS_DATA.TOTAL} value={tableData.length || 0} />
      <Statistics
        label={STATISTICS_DATA.PAID}
        value={
          tableData.filter((invoice) => invoice.status === STATISTICS_DATA.PAID)
            .length
        }
      />
      <Statistics
        label={STATISTICS_DATA.UNPAID}
        value={
          tableData.filter((invoice) => invoice.status !== STATISTICS_DATA.PAID)
            .length
        }
      />
      <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
        <CustomButton
          text={BUTTON_TEXTS.DOWNLOAD_CSV}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={onClickDownloadCSV}
        />
      </View>
    </View>
  );
  return (
    <WebContentContainerWrapper loading={loading} title={config.TITLE}>
      <View style={styles.headerContainer}>
        <Text style={customStyle(theme).headerText20}>
          Make your selection to generate a comparison
        </Text>
        <CustomButton
          buttontype={BUTTON_TYPE.SECONDARY}
          onPress={onClearAllFilter}
          text={
            <View style={styles.clearBtnTextContainer}>
              <MaterialCommunityIcons
                name="restart"
                size={20}
                color={theme.colors.black}
              />
              <Text>Restart</Text>
            </View>
          }
        />
      </View>
      <View style={styles.filtersContainer}>
        <MultiSelectFilter
          showEraser
          optionName="client_name"
          optionValue="client_id"
          multipleSelect={false}
          name={config.CLIENT_KEY}
          title={config.CLIENT_NAME}
          placeholdertext={config.CLIENTS_TITLE}
          value={client_id}
          options={clientOptions}
          onChange={onChangeMultiSelectFilter}
        />

        <MultiSelectFilter
          showEraser
          optionName="name"
          optionValue="id"
          multipleSelect={false}
          name={config.SERVICE_KEY}
          title={config.SERVICE_NAME}
          placeholdertext={config.SERVICE_TITLE}
          value={service_id}
          options={serviceOptions}
          onChange={onChangeMultiSelectFilter}
          disableOptions={["YouTube"]}
        />
        <MultiSelectFilter
          showEraser
          optionName="name"
          optionValue="id"
          multipleSelect={false}
          name={config.REPORT_VERSION_KEY}
          title={config.REPORT_VERSION_NAME}
          placeholdertext={config.REPORT_VERSION_TITLE}
          value={report_version_id}
          options={reportVersionOptions}
          onChange={onChangeMultiSelectFilter}
        />
      </View>
      <GetDataFromFilters />
      <View style={styles.statisticContainer}>{extraHeaderComponent}</View>
      <InvoiceTable />
    </WebContentContainerWrapper>
  );
};

export default LandingPage;

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: "row",
    gap: 10,
    flex: 1,
    justifyContent: "space-between",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearBtnTextContainer: { flexDirection: "row", gap: 5, alignItems: "center" },
  statisticContainer: { marginTop: 15 },
});
