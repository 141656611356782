import React from "react";
import { InvoiceDataProvider } from "./InvoicesDataContextAPI";
import InvoicesDataConsumer from "./InvoicesDataConsumer";
import { PaymentProvider } from "../Payments/Reducer/PaymentsContextAPI";
import { config } from "./invoice.config";

const InvoicesData = () => {
  return (
    <InvoiceDataProvider>
      <PaymentProvider pageName={config.TITLE}>
        <InvoicesDataConsumer />
      </PaymentProvider>
    </InvoiceDataProvider>
  );
};

export default InvoicesData;
