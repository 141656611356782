import React from "react";
import { Card, withTheme } from "react-native-paper";
import { customCardsStyle } from "./customCardsStyle";

const TitleSubtitleCard = ({
  theme,
  title,
  subtitle,
  leaveMarginRight = false,
}) => {
  return (
    <Card
      elevation={0}
      theme={theme}
      style={customCardsStyle(theme, leaveMarginRight).container}
    >
      <Card.Content style={customCardsStyle(theme).content}>
        <Card.Title
          titleStyle={customCardsStyle(theme).titleCard}
          subtitleStyle={customCardsStyle(theme).subtitleCard}
          title={title}
          subtitle={subtitle}
        />
      </Card.Content>
    </Card>
  );
};

export default withTheme(TitleSubtitleCard);
