import { View } from "react-native";
import React, { useState } from "react";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import { config } from "./adjustment.config";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { Searchbar, useTheme } from "react-native-paper";

const AdjustmentTable = ({ selectActiveTab }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "invoice_name",
      title: "Invoice Name",
      dataIndex: "invoice_name",
      sort: true,
      search: true,
    },
    {
      key: "client_id",
      title: "Client ID",
      dataIndex: "client_id",
      sort: true,
      filter: true,
    },
    {
      key: "Client_Name",
      title: "Client Name",
      dataIndex: "Client_Name",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "Period_from",
      title: "Period From",
      dataIndex: "Period_from",
      sort: true,
      filter: true,
    },
    {
      key: "Period_to",
      title: "Period To",
      dataIndex: "Period_to",
      sort: true,
      filter: true,
    },
    {
      key: "Report Version",
      title: "Report Version",
      dataIndex: "Report Version",
      sort: true,
      filter: true,
    },
    {
      key: "Legacy",
      title: "Legacy",
      dataIndex: "Legacy",
      sort: true,
    },
    {
      key: "Generated",
      title: "Generated",
      dataIndex: "Generated",
      sort: true,
    },
    {
      key: "Difference",
      title: "Difference",
      dataIndex: "Difference",
      sort: true,
    },
    {
      key: "Currency",
      title: "Currency",
      dataIndex: "Currency",
      sort: true,
      filter: true,
    },
  ];
  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.ADJUSTMENT_TABLE_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        tableMaxHeight={600}
        columns={columns}
        data={[]}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default AdjustmentTable;
