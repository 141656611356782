import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { withTheme } from "react-native-paper";
import { MESSAGE_TYPE } from "../constants/GlobalVariables";

const MessageValidation = ({
  show,
  type = MESSAGE_TYPE.success,
  message,
  containerStyle = {},
  textStyle = {},
  theme,
  fieldValidation = false,
}) => {
  const { colors, customFonts } = theme;
  let textColor = colors.success;
  if (type === MESSAGE_TYPE.error || fieldValidation) {
    textColor = colors.error;
  }
  return (
    (!!show || !!fieldValidation) && (
      <View style={[styles(fieldValidation).container, containerStyle]}>
        <Text
          style={{
            color: textColor,
            fontFamily: customFonts.regular,
            textAlign: !!!fieldValidation && "center",
            marginTop: fieldValidation ? 10 : 40,
            ...textStyle,
          }}
        >
          {message}
        </Text>
      </View>
    )
  );
};

export default withTheme(MessageValidation);

const styles = (fieldValidation) =>
  StyleSheet.create({
    container: { alignItems: !!!fieldValidation && "center" },
    text: {
      fontSize: 14,
    },
  });
