import { StyleSheet } from "react-native";

export const formLabelStyle = (theme, bold = false) =>
  StyleSheet.create({
    labelText: {
      fontFamily: bold ? theme.customFonts.bold : theme.customFonts.regular,
      fontSize: 14,
      marginBottom: 4,
      color: theme.colors.text,
    },
    labelTextError: {
      fontFamily: bold ? theme.customFonts.bold : theme.customFonts.regular,
      fontSize: 14,
      marginBottom: 4,
      color: theme.colors.error,
    },
    labelRequired: {
      fontSize: 14,
      color: theme.colors.error,
      marginLeft: 4,
    },
  });

export const formLabelCustomStyle = { container: { flexDirection: "row" } };
