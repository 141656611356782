import {
  View,
  FlatList,
  ScrollView,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import React, { useContext } from "react";
import { productList } from "../products.config";
import BoldText14 from "../../../components/CustomText/BoldText14";
import { InformationText } from "../../../components";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { imageUrl } from "../../../utils/imageUrl";
const screen = Dimensions.get("window");
const { width } = screen;
const imageDimension = width / 2 - 30;

const ProductListing = ({ updateProductDetailObj }) => {
  const {
    userInformation: { profilepicturepath },
  } = useContext(AuthContextAPI);
  const profileImageUrl = imageUrl(profilepicturepath);

  const ItemRender = ({ item }) => (
    <TouchableOpacity
      onPress={() => {
        updateProductDetailObj(item);
      }}
    >
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: profileImageUrl }}
      />
      <BoldText14 text={item.title} />
      <InformationText text={item.subtitle} />
    </TouchableOpacity>
  );

  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.scrollContainer}
      showsVerticalScrollIndicator={false}
    >
      <FlatList
        showsVerticalScrollIndicator={false}
        data={productList}
        numColumns={2}
        renderItem={({ item, index }) => (
          <View
            style={{
              marginRight: (index + 1) % 2 === 0 ? 0 : 20,
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <ItemRender item={item} />
          </View>
        )}
        keyExtractor={(item) => item.id}
      />
    </ScrollView>
  );
};

export default ProductListing;

const styles = StyleSheet.create({
  scrollContainer: { flex: 2, marginBottom: -20 },
  separaor: {
    height: 20,
  },
  image: {
    width: imageDimension,
    height: imageDimension,
    borderRadius: 10,
    marginBottom: 20,
  },
});
