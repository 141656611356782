import React, { createContext, useState } from "react";

const ContractFormContextAPI = createContext();

export const ContractFormContext = ({ children }) => {
  const [formData, setFormData] = useState({
    contracttypeid: "",
    contractnumber: "",
    rawContractDate: "",
    contractdate: "",
    duration: 0,
    renewal: 0,
    autorenewal: false,
    deals_data: [],
    advance_data: [],
    uploaded_pdf: [],
  });
  const [original, setOriginal] = useState({
    contracttypeid: "",
    contractnumber: "",
    rawContractDate: "",
    contractdate: "",
    duration: 0,
    renewal: 0,
    autorenewal: false,
    deals_data: [],
    advance_data: [],
    uploaded_pdf: [],
  });

  let contextData = {
    formData,
    setFormData,
    original,
    setOriginal,
  };
  return (
    <ContractFormContextAPI.Provider value={contextData}>
      {children}
    </ContractFormContextAPI.Provider>
  );
};

export default ContractFormContextAPI;
