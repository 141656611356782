export const commonFormStyle = (theme) => {
  return {
    formContainer: { width: 620, marginTop: 16 },
    actionBtnContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 40,
      alignItem: "center",
      marginBottom: 4,
    },
    actionBtnContainerMarginTop20: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 10,
      alignItem: "center",
      marginBottom: 4,
    },
    actionBtnHeaderContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItem: "center",
      alignSelf: "center",
    },
    yesBtn: { width: "49%", marginRight: 20 },
    submitBtn: { width: "49%" },
    backBtn: {
      width: "49%",
      // paddingVertical: 12.5,
      backgroundColor: theme.colors.avatarBackground,
      borderColor: theme.colors.avatarBackground,
    },
    modalFormContainer: {
      marginHorizontal: 50,
      width: 620,
      marginTop: 16,
      alignItem: "center",
    },
    inLineFormContainer: {
      flexDirection: "row",
      alignItems: "flex-end",
    },
    inLineFormSelectInputContainer: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
    inLineFormBtn: {
      marginLeft: 16,
      // height: 56,
      // justifyContent: "center",
      // paddingHorizontal: 50,
    },
    inLineFormField: {
      marginLeft: 16,
      justifyContent: "center",
      width: 200,
    },
    inLineFormFieldBtnContainer: {
      flexDirection: "row",
      // alignItems: "center",
      // justifyContent: "center",
      marginTop: 5,
    },
    inlineFormFieldAddBtn: {
      // marginRight: 8,
      marginLeft: 8,
    },
    inlineFormFieldRemoveBtn: {
      height: 21,
      width: 24,
    },
    tableFormFieldContainer: { marginTop: 32 },
    uploadMsgText: {
      textAlign: "center",
      fontFamily: theme.customFonts.regular,
      fontSize: 16,
      marginBottom: 8,
    },
  };
};
