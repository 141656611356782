import React, { useContext } from "react";
import ReportsContextAPI from "./ReportsContextAPI";
import ReportsLandingPage from "./ReportsLandingPage";
import IndividualReportPreview from "./IndividualReportPreview";

const ReportsPage = () => {
  let {
    state: { showLandingPage },
  } = useContext(ReportsContextAPI);

  return showLandingPage ? <ReportsLandingPage /> : <IndividualReportPreview />;
};

export default ReportsPage;
