import {
  FlatList,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
} from "react-native";
import React, { useCallback, useState } from "react";
import { Menu, Searchbar, useTheme } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import {
  formSelectCustomStyle,
  formSelectStyle,
} from "../../../../components/FormControl/FormSelect/formSelectStyle";
import NoData from "../../../../components/NoData";

const SingleSelectFilter = ({
  disabled = false,
  options = [],
  placeholdertext = "placeholdertext",
  name = "",
  value = "",
  onChange = () => { },
}) => {
  let theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [widthOfTheDropdown, setWidthOfTheDropdown] = useState(0);

  const openMenu = () => setVisible(true);

  const closeMenu = () => {
    setVisible(false);
    if (searchTerm) {
      setSearchTerm("");
    }
  };

  const onItemSelection = (option) => {
    onChange(name, option);
    closeMenu();
  };

  const getOptions = useCallback(() => {
    let filteredData = [...options];
    if (searchTerm !== "") {
      filteredData = [...options].filter((element) =>
        element.toLocaleLowerCase().includes(searchTerm)
      );
    }
    return filteredData.sort();
  }, [searchTerm, options]);

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setWidthOfTheDropdown(width);
  };

  return (
    <View style={formSelectCustomStyle.filterContainer}>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        style={formSelectStyle(theme, value, disabled).customDropdownContainer}
        anchor={
          <TouchableHighlight
            onLayout={onLayout}
            disabled={disabled}
            underlayColor={theme.colors.selectedTableBackgroundColor}
            style={
              commonSelectFilterstyles(theme, value, disabled).btnContainer
            }
            onPress={openMenu}
          >
            <View style={commonSelectFilterstyles(theme).btnContentContainer}>
              <View
                style={commonSelectFilterstyles(theme).buttonTxtPlaceholder}
              >
                <Text
                  numberOfLines={1}
                  style={
                    commonSelectFilterstyles(theme, value).customDropdownBtnText
                  }
                >
                  {placeholdertext}
                  <Text
                    style={commonSelectFilterstyles(theme).selectedValueText}
                  >
                    {value && `: ${value}`}
                  </Text>
                </Text>
              </View>
              <View
                style={commonSelectFilterstyles(theme).btnActionIconsContainer}
              >
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color={disabled ? theme.colors.disabled : theme.colors.text}
                />
              </View>
            </View>
          </TouchableHighlight>
        }
      >
        <View style={commonSelectFilterstyles(theme).searchCheckBoxContainer}>
          <Searchbar
            style={{ width: widthOfTheDropdown - 20 }}
            onChangeText={(query) => {
              setSearchTerm(query.toLocaleLowerCase());
            }}
            value={searchTerm}
          />
        </View>
        <FlatList
          ListEmptyComponent={<NoData />}
          data={getOptions()}
          keyExtractor={(item, index) => index}
          renderItem={({ item }) => (
            <Menu.Item
              onPress={() => {
                onItemSelection(item);
              }}
              style={
                commonSelectFilterstyles(
                  theme,
                  value === item,
                  disabled,
                  widthOfTheDropdown
                ).menuItemStyle
              }
              title={item}
              titleStyle={
                commonSelectFilterstyles(
                  theme,
                  value === item,
                  disabled,
                  widthOfTheDropdown - 20
                ).titleStyle
              }
            />
          )}
          style={{
            height: options.length > 5 ? 250 : null,
            width: widthOfTheDropdown,
          }}
        />
      </Menu>
    </View>
  );
};

export default SingleSelectFilter;

export const commonSelectFilterstyles = (
  theme,
  isSelected = false,
  disabled = false,
  widthOfTheDropdown,
  isForm = false,
  optionDisabled = false
) =>
  StyleSheet.create({
    btnContainer: {
      backgroundColor: disabled
        ? theme.colors.secondaryBackground
        : isSelected
          ? theme.colors.selectedTableBackgroundColor
          : theme.colors.background,
      borderColor:
        isSelected || isForm
          ? theme.colors.inputborderColor
          : theme.colors.divider,
      borderWidth: 1,
      borderRadius: 10,
      padding: 10,
      height: isForm ? 56 : 46,
      justifyContent: "center",
    },
    buttonTxtPlaceholder: { flex: 1, alignItems: "flex-start" },
    btnContentContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    btnActionIconsContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    customDropdownBtnText: {
      letterSpacing: 0,
      fontFamily: isSelected
        ? theme.customFonts.semiBold
        : theme.customFonts.regular,
      fontSize: 16,
      color: "#0000008a",
      textAlign: "center",
    },
    selectedValueText: {
      color: theme.colors.text,
    },
    menuItemStyle: {
      minWidth: widthOfTheDropdown,
    },
    searchCheckBoxContainer: {
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
      paddingHorizontal: 10,
      marginBottom: 5,
    },
    titleStyle: {
      color: isSelected
        ? theme.colors.primary : optionDisabled
          ? theme.colors.borderColor : theme.colors.text,
      fontFamily: isSelected
        ? theme.customFonts.semiBold
        : theme.customFonts.regular,
      minWidth: widthOfTheDropdown,
    },
  });
