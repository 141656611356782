import React from "react";
import { useWindowDimensions } from "react-native";
import { VictoryContainer, VictoryPie } from "victory-native";

const CustomVictoryPiechart = ({ data, x = "x", y = "y", theme }) => {
  let { colors } = theme;
  let scale = useWindowDimensions().scale;
  let innerRadius = 30;
  if (scale === 1) {
    innerRadius = 800;
  }

  let customStyle = { labels: { display: "none" } };

  return (
    <VictoryPie
      x={x}
      y={y}
      width={60}
      height={60}
      padding={5}
      containerComponent={<VictoryContainer responsive />}
      padAngle={10}
      innerRadius={innerRadius}
      colorScale={[colors.cardPrimaryText, colors.secondary]}
      cornerRadius={({ datum }) => datum.y * 30}
      data={data}
      style={customStyle}
    />
  );
};

export default CustomVictoryPiechart;
