import { StyleSheet, Platform } from "react-native";

export const commonLayoutWrapperStyle = (marginTop, marginBottom) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 20,
      alignItems: Platform.OS === "web" ? "center" : "stretch",
    },
    scrollContainer: { marginTop: marginTop, marginBottom: marginBottom },
  });
