import React from "react";
import { CatalogueProvider } from "./Reducer/CatalogueContextAPI";
import Catalogue from "./Catalogue";

const CatalogueConsumer = () => {
  return (
    <CatalogueProvider>
      <Catalogue />
    </CatalogueProvider>
  );
};

export default CatalogueConsumer;
