export const TABS = {
  RELEASES: "Release",
  TRACK: "Track",
};
export const config = {
  TITLE: "Catalogue",
  RELEASE_SEARCH_PLACEHOLDER: "Search by ID/Artist Name/Release Title/Label/UPC",
  TRACK_SEARCH_PLACEHOLDER: "Search by ID/Artist Name/Release Title/Track Title/Label/UPC/ISRC ",
};

export const STATISTICS_DATA = {
  TOTAL_RELEASES: "Total Releases",
  AVAILABLE_UPCS: "Available UPCs",
  TOTAL_TRACKS: "Total Tracks",
  AVAILABLE_ISRCS: "Available ISRCs",
};

export const BUTTON_RELEASE_TEXT = "Create Release";
