import { View, Text } from "react-native";
import React from "react";
import { commonFormStyle } from "../CommonStyles/commonFormStyle";
import { withTheme, ProgressBar } from "react-native-paper";

const CustomProgressBar = ({ progress, theme }) => {
  return (
    <View style={commonFormStyle(theme).tableFormFieldContainer}>
      <ProgressBar
        progress={progress / 100}
        style={{ backgroundColor: theme.colors.avatarBackground }}
      />
      <Text style={{ marginTop: 4 }}>{progress}%</Text>
    </View>
  );
};

export default withTheme(CustomProgressBar);
