import React from "react";
import { View } from "react-native";
import MessageValidation from "../../MessageValidation";
import FormLabel from "../FormLabel/FormLabel";
import { formControlInputStyle } from "./formRadioGroupStyle";
import { RadioButton } from "react-native-paper";
import { FORM_REQUIRED_FIELD_MSG } from "../../../constants/GlobalVariables";

const FormRadioGroup = ({
  label,
  name,
  onChange,
  value,
  required = false,
  isValidated = false,
  validationMessage = FORM_REQUIRED_FIELD_MSG,
  children,
  inlineLabel = false,
}) => {
  return (
    <View
      style={
        inlineLabel
          ? formControlInputStyle.containerInline
          : formControlInputStyle.container
      }
    >
      <FormLabel
        text={label}
        required={required}
        isValidated={isValidated}
        customStyle={inlineLabel ? { marginTop: 4 } : {}}
      />
      <RadioButton.Group
        onValueChange={(newValue) => onChange(name, newValue)}
        value={value}
      >
        {children}
      </RadioButton.Group>
      <MessageValidation
        fieldValidation={isValidated}
        message={validationMessage}
      />
    </View>
  );
};

export default FormRadioGroup;
