import { Text } from "react-native";
import React from "react";
import { withTheme } from "react-native-paper";
import { customStyle } from "./customStyle";

const SecondaryHeaderTextSemiBold = ({ text, theme }) => {
  return (
    <Text style={customStyle(theme).secondaryHeaderTextSemiBold}>{text}</Text>
  );
};

export default withTheme(SecondaryHeaderTextSemiBold);
