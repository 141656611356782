import React from "react";
import { VictoryAxis, VictoryChart, VictoryLine } from "victory-native";
import { withTheme } from "react-native-paper";

const CustomVictoryLineChart = ({ data, theme }) => {
  let { colors } = theme;

  const customAxisStyle = {
    grid: { stroke: colors.gridLine, strokeWidth: 0.5 },
    axis: { stroke: "transparent" },
    tickLabels: {
      fill: colors.infoText,
      fontSize: 12,
      fontFamily: theme.customFonts.regular,
    },
  };
  const customLineStyle = {
    data: { stroke: colors.cardPrimaryText, strokeWidth: 3 },
  };
  return (
    <VictoryChart
      height={200}
      padding={{ top: 10, bottom: 10, right: 70, left: 35 }}
    >
      <VictoryAxis
        dependentAxis
        tickFormat={(t) => `${t} M`}
        style={customAxisStyle}
      />
      <VictoryLine
        interpolation="natural"
        data={data}
        style={customLineStyle}
      />
    </VictoryChart>
  );
};

export default withTheme(CustomVictoryLineChart);
