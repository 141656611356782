import { DEAL_TYPES } from "../ContractManager/ContractManagerDealForm/ContractManagerDealForm.config";

export const config = {
  Title: "Missing DSP Deals",
  STATISITICS_ARR: [{ label: "Total", value: "total" }],
  LABEL_SEARCH_PLACEHOLDER: "Search by ID/Label Name",
  RELEASE_SEARCH_PLACEHOLDER: "Search by ID/Label Name/Artist Name/Release Title/UPC ",
  TRACKS_ON_UPC_SEARCH_PLACEHOLDER:
    "Search by ID/Label Name/Artist Name/Release Title/UPC/Track Title/ISRC",
  CREATE_NEW_CONTRACT_BTN_TEXT: "Create New Contract",
  ADD_TO_EXISTING_CONTRACT_BTN_TEXT: "Add To Existing Contract",
};

export const configContract = {
  Title: "Select Contract",
  SEARCH_PLACEHOLDER: "Search by Contract ID/Client Name/Contract Number",
};

export const CONTRACT_TYPE_YOUTUBE = "YouTube";

export const TABS = {
  LABEL: "labelTableData",
  RELEASE: "releaseTableData",
  TRACKS_ON_UPC: "trackOnUpcTableData"
}

export const TABLENAMES = {
  labelTableData: "Label",
  releaseTableData: "Release",
  trackOnUpcTableData: "Track On UPC"
}

export const DEAL_TYPE_BASED_ON_TAB = {
  labelTableData: DEAL_TYPES.LABEL,
  releaseTableData: DEAL_TYPES.RELEASE,
  trackOnUpcTableData: DEAL_TYPES.TRACK_ON_UPC
}