import {
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  Text,
  Dimensions,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import CommonAvatar from "../CommonAvatar/CommonAvatar";
import { TextInput, withTheme } from "react-native-paper";
import RBSheet from "react-native-raw-bottom-sheet";
import { customStyle } from "../CustomText/customStyle";
import { imageUrl } from "../../utils/imageUrl";

const screen = Dimensions.get("window");
const { height } = screen;

const SearchFilter = ({ data, options, onChange, theme }) => {
  const modalRef = useRef(null);
  const [listData, setListData] = useState(options);
  const onOpen = () => {
    modalRef.current?.open();
  };
  const [searchValue, setSearchValue] = useState("");

  const onClose = () => {
    modalRef.current?.close();
  };

  const onItemSelection = (value) => {
    onChange(value);
    onClose();
  };
  useEffect(() => {
    setListData(options);
    return () => {};
  }, [options.length]);

  const filteredList = (searchTerm) => {
    setSearchValue(searchTerm);
    let filteredData = options.filter((item) =>
      item?.trackid__title
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase())
    );
    setListData(filteredData);
  };

  const ItemDivider = () => {
    return (
      <View
        style={{
          height: 1,
          backgroundColor: theme.colors.divider,
          marginHorizontal: 15,
        }}
      />
    );
  };

  const selectItem = (item, index, searchable = false, active = false) => (
    <TouchableOpacity
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: active
          ? theme.colors.actionIconActive
          : theme.colors.customCardColor,
        padding: 15,
        borderRadius: 10,
      }}
      key={item.key}
      onPress={() => {
        onItemSelection(item);
      }}
    >
      <>
        {item?.trackid === 0 ? (
          <MaterialCommunityIcons
            name="checkbox-multiple-marked-outline"
            size={80}
            color={
              active || !theme.dark ? theme.colors.black : theme.colors.white
            }
            style={{ height: 80, width: 80 }}
          />
        ) : (
          <CommonAvatar uri src={imageUrl(item?.release_imagepath)} size={80} />
        )}
        <View style={{ flex: 1, marginLeft: 15 }}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                ...customStyle(theme).secondaryHeaderTextSemiBold,
                color:
                  active || !theme.dark
                    ? theme.colors.black
                    : theme.colors.white,
              }}
            >
              {item?.trackid__title}
            </Text>
            {searchable && (
              <AntDesign
                name="search1"
                size={24}
                color={theme.colors.secondaryText}
                onPress={onOpen}
              />
            )}
          </View>
          <View>
            <Text
              style={{
                ...customStyle(theme).informationText,
                color: active ? theme.colors.black : theme.colors.infoText,
              }}
            >
              {item?.trackid__displayartist}
            </Text>
            <Text
              style={{
                ...customStyle(theme).informationText,
                color: active ? theme.colors.black : theme.colors.infoText,
              }}
            >
              {item?.release_date
                ? `Released ${item?.release_date}`
                : item?.released}
            </Text>
          </View>
        </View>
      </>
    </TouchableOpacity>
  );

  return (
    <View>
      {selectItem(data, -1, true)}
      <RBSheet
        ref={modalRef}
        openDuration={250}
        height={height - 150}
        closeOnDragDown
        customStyles={{
          container: {
            backgroundColor: theme.colors.customCardColor,
            borderRadius: 15,
          },
        }}
      >
        <FlatList
          initialNumToRender={10}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item?.trackid}
          data={listData}
          stickyHeaderIndices={[0]}
          ListHeaderComponentStyle={styles(theme).stickyHeader}
          ListHeaderComponent={
            <View style={styles(theme).modalHeaderTitle}>
              <TextInput
                onChangeText={(val) => {
                  filteredList(val);
                }}
                placeholder="Search a track..."
                mode="outlined"
                right={
                  <TextInput.Icon
                    name="magnify"
                    color={theme.colors.secondaryText}
                    size={32}
                  />
                }
                value={searchValue}
                style={{ backgroundColor: theme.colors.searchBackgroundColor }}
                outlineColor={theme.colors.searchBackgroundColor}
                // activeOutlineColor={theme.colors.searchBackgroundColor}
              />
            </View>
          }
          ItemSeparatorComponent={ItemDivider}
          renderItem={({ item, index }) => (
            <View
              style={{
                flex: 1,
                backgroundColor: theme.colors.customCardColor,
              }}
            >
              {selectItem(item, index, false, data.trackid === item.trackid)}
            </View>
          )}
        />
      </RBSheet>
    </View>
  );
};

export default withTheme(SearchFilter);

const styles = (theme) =>
  StyleSheet.create({
    stickyHeader: {
      backgroundColor: theme.colors.customCardColor,
    },
    modalHeaderTitle: {
      margin: 10,
      flex: 1,
    },
    titleHeaderStyle: {
      // textAlign: "center",
      color: theme.colors.secondaryText,
      fontFamily: theme.customFonts.semiBold,
      fontSize: 14,
      flex: 1,
    },
  });
