export const config = {
  CLIENT_TITLE: "Clients",
  ARTIST_TITLE: "Artists",
  LABEL_TITLE: "Labels",
  CLIENT_TABLE_TITLE: "Client",
  ARTIST_TABLE_TITLE: "Artist",
  LABEL_TABLE_TITLE: "Label",
  CLIENT_SEARCH_PLACEHOLDER:
    "Search by Client ID/Individual/Company Name, Personal/Tax Identification Number or Email",
  ARTIST_SEARCH_PLACEHOLDER:
    "Search by  Artist ID/Artist Name/CM Artist/CM Artist Name/Historical Data",
  LABEL_SEARCH_PLACEHOLDER: "Search by Label ID/Label Name",
  CREATE_BTN_TEXT: "Create New Account",
  CREATE_NEW_ACCOUNT_FORM_TITLE: "New Account",
  ARTIST_ACCOUNT_TYPE: "Artist",
};
