import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { commonFormStyle, CustomButton } from "..";
import { BUTTON_TEXTS } from "../../constants/GlobalVariables";
import { FormControlInput } from "../FormControl";

const index = ({
  message,
  handleYesOnClick,
  handleNoOnClick,
  isDelete = false,
  showButtons = true,
  customMessage,
}) => {
  let DELETE = "delete";
  let theme = useTheme();
  const [deleteText, setDeleteText] = useState("");
  const onChange = (name, value) => {
    setDeleteText(value);
  };

  return (
    <View style={confirmationStyle(theme).container}>
      {message && (
        <Text style={confirmationStyle(theme).messageContainer}>{message}</Text>
      )}
      {customMessage}
      {!!isDelete && (
        <View style={confirmationStyle(theme).deleteContainer}>
          <FormControlInput
            name={"delete"}
            placeholder={"delete"}
            value={deleteText}
            onChange={onChange}
          />
        </View>
      )}
      {showButtons && (
        <View style={confirmationStyle(theme).buttonContainer}>
          <CustomButton
            onPress={handleYesOnClick}
            text={BUTTON_TEXTS.YES}
            cutomButtonStyle={commonFormStyle(theme).yesBtn}
            disabled={!!isDelete && deleteText !== DELETE}
          />
          <CustomButton
            text={BUTTON_TEXTS.NO}
            onPress={handleNoOnClick}
            cutomButtonStyle={commonFormStyle(theme).backBtn}
          />
        </View>
      )}
    </View>
  );
};

export default index;

export const confirmationStyle = (theme) =>
  StyleSheet.create({
    container: { alignItems: "center", marginVertical: 100, width: 800 },
    messageContainer: {
      width: 700,
      fontSize: 30,
      fontFamily: theme.customFonts.regular,
      textAlign: "center",
      color: theme.colors.text,
    },
    deleteContainer: { justifyContent: "center", width: 300 },
    buttonContainer: {
      width: "80%",
      flexDirection: "row",
      marginTop: 100,
    },
  });
