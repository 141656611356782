export const config = {
  TITLE_TEXT: "Clients",
  STATISITICS_ARR: [
    { label: "Total", value: "total" },
    { label: "Individuals", value: "total_individual_data" },
    { label: "Companies", value: "total_company_data" },
  ],
  CREATE_BTN_TEXT: "Create New Client",
  SEARCH_PLACEHOLDER:
    "Search by ID/Individual/Company Name, Personal/Tax Identification Number or Email",
};
