import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { withTheme } from "react-native-paper";
import CustomVictoryBarChart from "../components/charts/CustomVictoryBarChart";
import CustomVictoryLineChart from "../components/charts/CustomVictoryLineChart";
import CustomVictoryPiechart from "../components/charts/CustomVictoryPiechart";

const VictoryChartsExamples = ({ theme }) => {
  const data = [
    { x: 1, y: 2 },
    { x: 2, y: 3 },
    { x: 3, y: 5 },
    { x: 4, y: 4 },
    { x: 5, y: 6 },
  ];
  return (
    <ScrollView stickyHeaderHiddenOnScroll>
      <View style={styles.container}>
        <CustomVictoryLineChart data={data} />
        <CustomVictoryBarChart data={data} theme={theme} />
        <CustomVictoryPiechart data={data.slice(0, 2)} theme={theme} />
      </View>
    </ScrollView>
  );
};

export default withTheme(VictoryChartsExamples);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  wrap: { display: "flex", flexWrap: "wrap" },
});
