import { StyleSheet, View } from "react-native";
import React from "react";
import { menuOptions, MENU_KEYS } from "./settings.config";
import TopMenu from "../../components/TopMenu/TopMenu";
import { CommonLayoutWrapper } from "../../components";
import SettingsContent from "./SettingsContent";
import nonAdminNavigationStrings from "../../navigation/AppStack/NonAdminAppStack/nonAdminNavigationStrings";

const Settings = ({ navigation }) => {
  const handleBackBtn = () =>
    navigation.navigate(nonAdminNavigationStrings.HOME.name);

  return (
    <View style={styles.container}>
      <TopMenu
        title={"Settings"}
        menuOptions={menuOptions}
        activeMenu={MENU_KEYS.SETTINGS}
        singleOption
        handleBackBtnClick={handleBackBtn}
      />
      <CommonLayoutWrapper marginTop={20}>
        <SettingsContent />
      </CommonLayoutWrapper>
    </View>
  );
};

export default Settings;

const styles = StyleSheet.create({
  container: { flex: 1 },
});
