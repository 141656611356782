export const updateSelectedRow = (currentData, id, key = "") => {
  let tempData = [...currentData];
  let finalData = tempData.map((el) => {
    if (el[key] === id) {
      return {
        ...el,
        existing: el.hasOwnProperty("existing") ? !el?.existing : true,
      };
    }
    return el;
  });
  return finalData;
};
