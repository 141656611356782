import { Platform, StyleSheet } from "react-native";

export const formSelectStyle = (theme, disabled, isValidated) =>
  StyleSheet.create({
    customDropdownContainer: {
      marginTop: theme.inputText.height,
    },
    customDropdownMenuItem: {
      minWidth: 620,
    },
    customDropdownBtn: {
      color: disabled ? "#0000008a" : theme.colors.text,
      backgroundColor: disabled
        ? theme.colors.secondaryBackground
        : theme.colors.background,
      height: theme.inputText.height,
      borderColor: isValidated
        ? theme.colors.error
        : theme.inputText.outlineColor,
      justifyContent: "center",
      borderWidth: 1,
      borderRadius: 10,
      padding: 15,
    },
    customDropdownBtnContainer: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      height: 54
    },
    customDropdownFixWidthBtnContainer: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      width: 590,
      height: 54
    },
    customDropdownBtnText: {
      letterSpacing: 0,
      fontFamily:
        Platform.OS === "web"
          ? theme.customFonts.regular
          : theme.customFonts.medium,
      fontSize: 16,
      color: disabled ? "#0000008a" : theme.colors.text,
      textAlign: "center",
    },
  });

export const formSelectCustomStyle = {
  customDropdownMenuLabel: { marginBottom: 4 },
  container: { marginTop: 20 },
  filterContainer: { marginTop: 20, flex: 1 },
};
