import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Searchbar, withTheme } from "react-native-paper";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  Statistics,
  WebContentContainerWrapper,
} from "../../../../components";
import { config as parentConfig } from "../ContractManager.config";
import {
  config,
  config as selfConfig,
} from "./ContractManagerListingPage.config";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../../constants/GlobalVariables";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import {
  CONTRACTS_URL,
  EXPORT_CONTRACT_URL,
} from "../../../../constants/GlobalUrls";
import { AntDesign, Entypo } from "@expo/vector-icons";
let uuid = require("uuid");
import dayjs from "dayjs";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const ContractManagerListingPage = ({
  changeShowPage,
  getRecordData,
  theme,
}) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getAllContracts();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const onChangeSearch = (query) => setSearchQuery(query);

  const goToContractForm = () => {
    getRecordData({});
    changeShowPage(parentConfig.pages.CONTRACT_FORM);
  };
  const onEditBtnClick = (record) => {
    getRecordData(record);
    changeShowPage(parentConfig.pages.CONTRACT_FORM);
  };

  const getAllContracts = async () => {
    const handleSuccessResponse = (res) => {
      setTableData(res);
    };

    await api
      .get(CONTRACTS_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);

    let id = showDeleteConfirmation?.record?.id;
    let url = `${CONTRACTS_URL}${id}/`;
    closeDeleteConfirmationWindow();

    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      getAllContracts();
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
      }
    };

    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorResponse(error);
      });
  };

  const onClickDownloadCSV = async () => {
    setIsLoading(true);
    await api
      .get(EXPORT_CONTRACT_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `contract_${uuid.v4()}.csv`);
        tempLink.click();
        setIsLoading(false);
      })
      .catch((error) => {
        checkNetworkReachable();
        setIsLoading(false);
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 0.15,
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "contracttypename",
      title: "Contract Type",
      dataIndex: "contracttypename",
      sort: true,
      width: 0.6,
      filter: true,
    },
    {
      key: "contractnumber",
      title: "Contract Number",
      dataIndex: "contractnumber",
      sort: true,
      search: true,
      width: 0.45,
      filter: true,
    },
    {
      key: "contract_date",
      title: "Contract Date",
      dataIndex: "contract_date",
      sort: true,
      width: 0.37,
      isDate: true,
      filter: true,
      customToolTip: (date) => {
        return dayjs(date).format("DD/MM/YYYY");
      },
      render: (date) => {
        return dayjs(date).format("DD/MM/YYYY");
      },
    },
    {
      key: "duration",
      title: "Duration (Months)",
      dataIndex: "duration",
      sort: true,
      width: 0.4,
      filter: true,
    },
    {
      key: "renewal",
      title: "Renewal (Months)",
      dataIndex: "renewal",
      sort: true,
      width: 0.36,
      filter: true,
    },
    {
      key: "autorenewal",
      title: "Auto-Renewal",
      dataIndex: "autorenewal",
      width: 0.4,
      sort: true,
      center: true,
      filter: true,
      customToolTip: (autorenewal) => {
        return autorenewal ? "Yes" : "No";
      },
      render: (autorenewal) => {
        return autorenewal ? (
          <AntDesign name="check" size={16} color={theme.colors.primary} />
        ) : (
          <Entypo name="cross" size={16} color={theme.colors.error} />
        );
      },
    },
    {
      key: "remaining_days",
      title: "Remaining (Days)",
      dataIndex: "remaining_days",
      sort: true,
      width: 0.4,
      filter: true,
    },
    {
      key: "total_deal",
      title: "Number of Deals",
      dataIndex: "total_deal",
      sort: true,
      width: 0.45,
      filter: true,
    },
    {
      key: "total_works",
      title: "Total Works",
      dataIndex: "total_works",
      sort: true,
      width: 0.38,
      filter: true,
    },
    {
      key: "advance_value",
      title: "Advance",
      dataIndex: "advance_value",
      sort: true,
      width: 0.26,
      center: true,
      filter: true,
    },
    {
      key: "pdf",
      title: "PDF",
      dataIndex: "pdf",
      sort: true,
      width: 0.17,
      center: true,
      filter: true,
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "id",
      width: 0.1633,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              onEditBtnClick(record);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.DELETE,
      title: "Delete",
      dataIndex: "id",
      width: 0.1633,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <WebContentContainerWrapper title="Contracts" loading={isLoading}>
      <View style={commonStatisticButtonStyle(theme).statisticContainer}>
        {config.STATISITICS_ARR.map((item) => {
          return (
            <Statistics
              key={item.value}
              label={item.label}
              value={tableData.length || 0}
            />
          );
        })}
        <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
          <CustomButton
            text={selfConfig.CREATE_BTN_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={goToContractForm}
          />
          <CustomButton
            text={DOWNLOAD_CSV_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={onClickDownloadCSV}
          />
        </View>
      </View>
      <Searchbar
        placeholder={config.SEARCH_PLACEHOLDER}
        onChangeText={onChangeSearch}
        value={searchQuery}
        style={commonStatisticButtonStyle(theme).searchBar}
        inputStyle={{ fontSize: 16 }}
      />
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.contracttypename} contract with ID ${showDeleteConfirmation?.record?.id}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default withTheme(ContractManagerListingPage);
