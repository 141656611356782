import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { DASHBOARD_CARD_URL, STORES } from "../../constants/GlobalUrls";
import { DEFAULT_ERROR_MSG } from "../../constants/GlobalVariables";
import AuthContextAPI from "../../context/AuthContextAPI";
import useAxios from "../../hooks/useAxios";

const LandingPageContextAPI = createContext();

export default LandingPageContextAPI;

export const LandingPageProvider = ({ children }) => {
  const { authTokens } = useContext(AuthContextAPI);
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  // Endpoints
  let DASHBOARD_CARD_REQUEST = api.get(DASHBOARD_CARD_URL, {
    cancelToken: cancelTokenSource.token,
  });
  let STORES_REQUEST = api.get(STORES, {
    cancelToken: cancelTokenSource.token,
  });

  // Endpoints Array
  let endpoints = [DASHBOARD_CARD_REQUEST, STORES_REQUEST];

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    dashboardCardData: {},
    audienceData: [],
  });

  const getData = async () => {
    setLoading(true);

    await Promise.all(endpoints)
      .then(
        axios.spread(({ data: dashboardCardData }, { data: audienceData }) => {
          setData({ dashboardCardData, audienceData });
        })
      )
      .catch((err) => {
        if (err?.status === 500) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && authTokens) {
      getData();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [authTokens]);

  let contextData = {
    loading,
    data,
    setData,
  };
  return (
    <LandingPageContextAPI.Provider value={contextData}>
      {children}
    </LandingPageContextAPI.Provider>
  );
};
