import { View, Image, StyleSheet, Dimensions } from "react-native";
import React, { useContext } from "react";
import { HeaderText, InformationText } from "../../components";
import StatisticsCardsItems from "../../components/StatisticsCards/StatisticsCardsItems";
import AuthContextAPI from "../../context/AuthContextAPI";
import { imageUrl } from "../../utils/imageUrl";
const screen = Dimensions.get("window");
const { width } = screen;
const imageDimension = width - 40;

const ProductDetail = ({ selectedProduct }) => {
  const {
    userInformation: { profilepicturepath },
  } = useContext(AuthContextAPI);
  const profileImageUrl = imageUrl(profilepicturepath);

  return (
    <View>
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: profileImageUrl }}
      />
      <View style={styles.contentContainer}>
        <HeaderText
          text={`${selectedProduct.title} - ${selectedProduct.subtitle}`}
        />
        <InformationText text={`Released ${selectedProduct.date}`} />
      </View>

      <StatisticsCardsItems title={"Release Revenue"} />

      {/* <TopCards /> */}
      <View style={styles.lastContainerWarpper}>
        <StatisticsCardsItems title={"Release Forecast"} />
      </View>
    </View>
  );
};

export default ProductDetail;

const styles = StyleSheet.create({
  image: {
    flex: 1,
    width: imageDimension,
    height: imageDimension,
    borderRadius: 10,
    marginBottom: 20,
  },
  contentContainer: {
    marginHorizontal: 20,
  },
  lastContainerWarpper: {
    marginBottom: -15,
  },
});
