import { StyleSheet, Text, View, Pressable } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { config, MAKE_THESE_STATUS_RED_COLOR } from "./invoice.config";
import { CustomButton, WebContentContainerWrapper } from "../../../components";
import InvoicesDataContextAPI from "./InvoicesDataContextAPI";
import {
  LOADER,
  RETURN_TO_LANDING_PAGE,
  SELECTED_ROW,
} from "./reducer/actions";
import BoldText14 from "../../../components/CustomText/BoldText14";
import { FormSelect } from "../../../components/FormControl";
import { useTheme } from "react-native-paper";
import { downloadPDF } from "../Reports/downloadFileUsingURL";
import NoData from "../../../components/NoData";
import { UPDATE_INVOICE_URL } from "../../../constants/GlobalUrls";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";
import axios from "axios";
import useAxios from "../../../hooks/useAxios";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import InvoicePaymentDetailsTable from "./InvoicePaymentDetailsTable";
import { config as paymentConfig } from "../Payments/payment.config";
import PaymentContextAPI from "../Payments/Reducer/PaymentsContextAPI";
import { formatCurrency } from "../../../utils/formatCurrency";

const InvoiceDetailPage = () => {
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const { logoutUser } = useContext(AuthContextAPI);

  let theme = useTheme();
  let {
    state: { selectedRowData, loading, invoiceTypeOptions },
    pageName,
    dispatch: invoiceDispatch,
  } = useContext(InvoicesDataContextAPI);

  let { state: paymentState, dispatch: paymentDispatch } =
    useContext(PaymentContextAPI);

  const [formData, setFormData] = useState({
    invoicetypeid: null,
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      setFormData({ invoicetypeid: selectedRowData?.invoicetypeid });
    }

    return () => {
      isMoulded = false;
    };
  }, [selectedRowData]);

  const gotToLandingPage = () => {
    invoiceDispatch({ type: RETURN_TO_LANDING_PAGE });
  };

  const goToPaymentDetailPage = () => {
    invoiceDispatch({ type: SELECTED_ROW, payload: {} });
  };

  const goToPaymentLandingPage = () => {
    invoiceDispatch({ type: SELECTED_ROW, payload: {} });
    paymentDispatch({ type: RETURN_TO_LANDING_PAGE });
  };

  const invoiceBreadCrumbData = [
    {
      title: config.TITLE,
      onClickPress: gotToLandingPage,
    },
    {
      title: `Invoice Details #${selectedRowData?.id}`,
      active: true,
    },
  ];

  const paymentBreadCrumbData = [
    {
      title: paymentConfig.TITLE,
      onClickPress: goToPaymentLandingPage,
    },
    {
      title: `Payment Details #${paymentState?.selectedRowData?.id}`,
      onClickPress: goToPaymentDetailPage,
    },
    {
      title: `Invoice Details #${selectedRowData?.id}`,
      active: true,
    },
  ];

  const onChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getLabelValue = (label, value = "") => {
    const displayValue = value ? value.toString().toUpperCase() : ""; // Handle null/undefined and convert to uppercase
    const color = MAKE_THESE_STATUS_RED_COLOR.includes(displayValue)
      ? theme.colors.error
      : theme.colors.text;
    return (
      <View style={styles(theme).labelValueContainer}>
        <BoldText14 text={`${label}: `} />
        <Text
          style={{
            color: color,
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  const handleSuccessResponse = (res) => {
    if (res?.msg) {
      alert(res.msg);
    }
    // gotToLandingPage();
  };

  const handleErrorResponse = (error) => {
    checkNetworkReachable();
    if (error?.response?.status === 401) {
      logoutUser();
    } else if (!axios.isCancel(error) && error?.response) {
      if (error?.response?.data?.msg) {
        alert(error?.response?.data?.msg);
      } else {
        alert(DEFAULT_ERROR_MSG);
      }
    }
    invoiceDispatch({ type: LOADER, payload: false });
  };

  const onSubmit = async () => {
    invoiceDispatch({ type: LOADER, payload: true });
    let URL = UPDATE_INVOICE_URL + selectedRowData?.id;
    await api
      .put(URL, formData, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        invoiceDispatch({ type: LOADER, payload: false });
      });
  };

  return (
    <WebContentContainerWrapper
      loading={loading}
      breadCrumbData={
        pageName === config.TITLE
          ? invoiceBreadCrumbData
          : paymentBreadCrumbData
      }
    >
      <View style={styles(theme).contentWrapper}>
        <View style={styles(theme).formContainer}>
          <View style={styles(theme).gapContainer}>
            <View>
              {getLabelValue("Client", selectedRowData?.client_name)}
              {getLabelValue("Service", selectedRowData?.service)}
            </View>
            <View>
              {getLabelValue("From", selectedRowData?.from_field)}
              {getLabelValue("To", selectedRowData?.to)}
            </View>
            <View>
              {getLabelValue("Gross Revenue", formatCurrency(selectedRowData?.gross))}
              {getLabelValue("Net Revenue", formatCurrency(selectedRowData?.net))}
            </View>
            <View>
              {getLabelValue("Currency", selectedRowData?.currency)}
              {getLabelValue("Report Version", selectedRowData?.report_version)}
            </View>
            <View>
              {getLabelValue(
                "Status",
                selectedRowData?.status || selectedRowData?.paymentstatus
              )}
            </View>
          </View>
        </View>
        <View style={styles(theme).pdfPreviewContainer}>
          {selectedRowData?.pdfurl && !loading ? (
            <iframe
              itemType="pdf"
              src={selectedRowData?.pdfurl}
              style={{
                width: 170,
                height: "inherit",
                border: "none",
              }}
            />
          ) : (
            <View style={styles(theme).emptyPDFContainer}>
              <NoData text="No PDF" />
            </View>
          )}
          <Pressable
            disabled={!selectedRowData?.pdfurl}
            onPress={() => {
              downloadPDF(selectedRowData?.pdfurl);
            }}
          >
            <Text style={styles(theme, selectedRowData?.pdfurl).downloadBtn}>
              Download Invoice PDF
            </Text>
          </Pressable>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <View>
          <BoldText14 text={`Invoice Type: `} />
          <View style={styles(theme).dropdownMargin}>
            <FormSelect
              name={"invoicetypeid"}
              value={formData.invoicetypeid}
              optionName="name"
              optionValue="id"
              options={invoiceTypeOptions}
              placeholdertext="Informational / Legacy / Generated / Adjustment"
              onChange={onChange}
            />
          </View>
        </View>
        <View style={{ justifyContent: "flex-end", marginLeft: 30 }}>
          <CustomButton
            cutomButtonStyle={{ width: 300 }}
            text={"Update Invoice Type"}
            onPress={onSubmit}
          />
        </View>
      </View>
      <InvoicePaymentDetailsTable pageName={pageName} />
    </WebContentContainerWrapper>
  );
};

export default InvoiceDetailPage;

const styles = (theme, pdfPresent = false) =>
  StyleSheet.create({
    formContainer: { width: 620 },
    gapContainer: { gap: 25 },
    labelValueContainer: { flexDirection: "row" },
    dropdownMargin: { marginTop: -6 },
    marginTopContainer16: { marginTop: 16 },
    marginTopContainer20: { marginTop: 20, opacity: 0.3 },
    marginBottomContainer10: { marginBottom: 10 },
    dropdownTextInputContainer: {
      flexDirection: "row",
      gap: 10,
      marginTop: -21,
    },
    dropdownWithInputContainerWrapper: { width: 200, marginTop: 4 },
    inputWithDropdownContainerWrapper: { flex: 1 },
    pdf: {
      flex: 1,
      width: 200,
      height: 200,
    },
    contentWrapper: { flexDirection: "row" },
    pdfPreviewContainer: {
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      height: 265, //500
      width: 300, //175
      marginLeft: 30,
    },
    emptyPDFContainer: {
      justifyContent: "center",
      alignSelf: "center",
      flex: 1,
      borderWidth: 1,
      width: 170,
    },
    downloadBtn: {
      textDecorationLine: "underline",
      color: theme.colors.linkColor,
      fontFamily: theme.customFonts.bold,
      fontSize: 14,
      opacity: pdfPresent ? 1 : 0.5,
    },
  });
