import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Searchbar, withTheme } from "react-native-paper";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  Statistics,
  WebContentContainerWrapper,
} from "../../../../components";
import {
  DEAL_DASHBOARD_URL,
  EXPORT_DEAL_URL,
} from "../../../../constants/GlobalUrls";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../../constants/GlobalVariables";
import useAxios from "../../../../hooks/useAxios";
import { config } from "../Deals.config";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
let uuid = require("uuid");

const DealListingPage = ({ getRecordData, theme }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });
  const onChangeSearch = (query) => setSearchQuery(query);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getAllDeals();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [refreshTable]);

  const getAllDeals = async () => {
    const handleSuccessResponse = (res) => {
      setTableData(res);
    };

    await api
      .get(DEAL_DASHBOARD_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setIsLoading(false);
  };
  const onEditBtnClick = async (record) => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let data = res && res[0];
      getRecordData(data);
    };

    let url = DEAL_DASHBOARD_URL + record?.dealid + "/";
    await api
      .get(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);

    let id = showDeleteConfirmation?.record?.dealid;
    let url = `${DEAL_DASHBOARD_URL}${id}/`;
    closeDeleteConfirmationWindow();

    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      setRefreshTable(!refreshTable);
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
      }
    };

    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
        setIsLoading(false);
      });
  };

  const onClickDownloadCSV = async () => {
    setIsLoading(true);
    await api
      .get(EXPORT_DEAL_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        let csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `deal_${uuid.v4()}.csv`);
        tempLink.click();
        setIsLoading(false);
      })
      .catch((error) => {
        checkNetworkReachable();
        setIsLoading(false);
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  let columns = [
    {
      key: "dealid",
      title: "ID",
      dataIndex: "dealid",
      width: 0.25,
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "dealid__contractid",
      title: "Contract ID",
      dataIndex: "dealid__contractid",
      width: 0.5,
      sort: true,
      filter: true,
      // bold: true,
    },
    {
      key: "category",
      title: "Deal Category",
      dataIndex: "category",
      width: 0.6,
      sort: true,
      filter: true,
      // bold: true,
    },
    {
      key: "dealsubcategory",
      title: "Deal Subcategory",
      dataIndex: "dealsubcategory",
      width: 0.7,
      sort: true,
      filter: true,
      // bold: true,
    },
    {
      key: "label_upc_upcisrc",
      title: "Label ID/UPC/UPCISRC/Asset ID/Channel ID",
      dataIndex: "label_upc_upcisrc",
      sort: true,
      width: 1.3,
      search: true,
      filter: true,
      // bold: true,
    },
    {
      key: "label_title_name",
      title: "Label Name/Release Title/Track Title/Asset Title/Channel Name",
      dataIndex: "label_title_name",
      sort: true,
      width: 1.9,
      search: true,
      filter: true,
      // bold: true,
    },
    {
      key: "client_name",
      title: "Client Individual/Company Name",
      dataIndex: "client_name",
      sort: true,
      width: 1.2,
      search: true,
      filter: true,
      // bold: true,
    },
    {
      key: "revenueshare",
      title: "Split",
      dataIndex: "revenueshare",
      width: 0.35,
      sort: true,
      filter: true,
    },
    {
      key: "advance",
      title: "Advance",
      dataIndex: "advance",
      width: 0.4,
      center: true,
      sort: true,
      filter: true,
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "dealid",
      width: 0.175,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              onEditBtnClick(record);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.DELETE,
      title: "Delete",
      dataIndex: "dealid",
      width: 0.23,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(record);
            }}
          />
        );
      },
    },
  ];
  return (
    <WebContentContainerWrapper title={config.Title} loading={isLoading}>
      <View style={commonStatisticButtonStyle(theme).statisticContainer}>
        {config.STATISITICS_ARR.map((item) => {
          return (
            <Statistics
              key={item.value}
              label={item.label}
              value={tableData.length || 0}
            />
          );
        })}
        <CustomButton
          text={DOWNLOAD_CSV_TEXT}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={onClickDownloadCSV}
        />
      </View>
      <Searchbar
        placeholder={config.SEARCH_PLACEHOLDER}
        onChangeText={onChangeSearch}
        value={searchQuery}
        style={commonStatisticButtonStyle(theme, 1285).searchBar}
        inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
      />
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.category} deal with ID ${showDeleteConfirmation?.record?.dealid}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default withTheme(DealListingPage);
