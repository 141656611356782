import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
} from "victory-native";
import { withTheme } from "react-native-paper";

const CustomVictoryBarChart = ({ data, x = "x", y = "y", theme }) => {
  let { colors } = theme;

  let customStyle = {
    axis: { stroke: "transparent" },
    ticks: { stroke: "transparent" },
    tickLabels: {
      fill: colors.text,
      fontSize: 12,
      fontFamily: theme.customFonts.regular,
    },
  };

  return (
    <VictoryChart
      width={190}
      height={85}
      padding={{ top: 5, bottom: 12, right: 50, left: 50 }}
    >
      <VictoryBar
        x={x}
        y={y}
        data={data}
        horizontal
        // cornerRadius={{ top: 2, bottom: 2 }}
        style={{
          data: { fill: colors.ternaryColor },
        }}
      />
      <VictoryAxis
        tickLabelComponent={
          <VictoryLabel verticalAnchor="middle" textAnchor="start" x={10} />
        }
        dependentAxis={false}
        style={customStyle}
      />
    </VictoryChart>
  );
};

export default withTheme(CustomVictoryBarChart);
