import React from "react";
import { View } from "react-native";
import { FormLabel } from "..";
import CustomDatePickerInput from "../../CustomDatePickerInput/CustomDatePickerInput";
import {
  formDatePickerCustomStyle,
  formDatePickerStyle,
} from "./formDatePickerStyle";
import { FORM_REQUIRED_FIELD_MSG } from "../../../constants/GlobalVariables";
import MessageValidation from "../../MessageValidation";

const FormDatePicker = ({
  label,
  name,
  value,
  onChange,
  disabled = false,
  required=false,
  isValidated = false,
  validationMessage = FORM_REQUIRED_FIELD_MSG,
}) => {
  return (
    <View style={formDatePickerStyle.container}>
      <FormLabel
        text={label}
        customStyle={formDatePickerCustomStyle.customLabel}
        required={required}
        isValidated={isValidated}
      />
      <CustomDatePickerInput
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        isValidated={isValidated}
      />
      <MessageValidation
        fieldValidation={isValidated}
        message={validationMessage}
      />
    </View>
  );
};

export default FormDatePicker;
