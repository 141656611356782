import { StyleSheet, View, Text } from "react-native";
import React, { useContext, useState } from "react";
import {
  ConfirmationWindow,
  CustomButton,
  CustomModal,
} from "../../../../components";
import ReportsContextAPI from "../ReportsContextAPI";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import { REPORTS_URL } from "../../../../constants/GlobalUrls";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import { REFRESH_TABLE, RESET_FILTERS, TABLE_LOADER } from "../Reducer/actions";
import { dealFormForLabelStyle } from "../../ContractManager/ContractManagerDealForm/dealFormForLabelStyle";
import { useTheme } from "react-native-paper";
import ShowAllSelectFilters from "../ShowAllSelectFilters";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const GenerateReportFromFilters = () => {
  let theme = useTheme();
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  let {
    state: {
      start_month,
      end_month,
      client_ids,
      contract_ids,
      label_ids,
      release_ids,
      track_ids,
      client_names,
      contract_names,
      label_names,
      release_names,
      track_names,
    },
    dispatch,
  } = useContext(ReportsContextAPI);

  const onPressGenerateReportBtn = async () => {
    closeShowWarningMessage();
    dispatch({ type: TABLE_LOADER, payload: true });

    let payload = {
      start_month,
      end_month,
      client_ids,
      contract_ids,
      label_ids,
      release_ids,
      track_ids,
    };
    const handleSuccessResponse = (res) => {
      if (res?.message) {
        alert(res.message);
      }
      dispatch({ type: REFRESH_TABLE, payload: false });
      dispatch({ type: RESET_FILTERS, payload: false });
    };
    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        if (error?.response?.data?.message) {
          alert(error?.response?.data?.message);
        } else {
          alert(DEFAULT_ERROR_MSG);
        }
      }
    };
    await api
      .post(REPORTS_URL, payload, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch({ type: TABLE_LOADER, payload: false });
      });
  };

  const closeShowWarningMessage = () => {
    setShowWarningMessage(false);
  };

  return (
    <View style={styles.btnContainer}>
      <CustomButton
        disabled={
          !(
            start_month &&
            end_month &&
            (client_ids.length > 0 ||
              contract_ids.length > 0 ||
              label_ids.length > 0 ||
              release_ids.length > 0 ||
              track_ids.length > 0)
          )
        }
        text={"Generate Report"}
        onPress={() => {
          setShowWarningMessage(true);
        }}
      />
      <CustomModal
        visible={showWarningMessage}
        hideModal={closeShowWarningMessage}
      >
        <ConfirmationWindow
          customMessage={
            <>
              <Text style={dealFormForLabelStyle(theme).messageContainer}>
                Are you certain about proceeding with the report generation
                using the specified filters?
              </Text>
              <View style={styles.containerForFilter}>
                <ShowAllSelectFilters
                  onlyFilters
                  data={{
                    start_month,
                    end_month,
                    client_names,
                    contract_names,
                    label_names,
                    release_names,
                    track_names,
                  }}
                />
              </View>
            </>
          }
          handleNoOnClick={closeShowWarningMessage}
          handleYesOnClick={onPressGenerateReportBtn}
        />
      </CustomModal>
    </View>
  );
};

export default GenerateReportFromFilters;

const styles = StyleSheet.create({
  btnContainer: { marginTop: 20 },
  containerForFilter: {
    width: 700,
    marginTop: 20,
  },
});
