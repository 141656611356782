import React, { useContext, useEffect, useState } from "react";
import { View, Keyboard } from "react-native";
import { withTheme } from "react-native-paper";
import {
  FormControlInput,
  FormDatePicker,
  FormSelect,
} from "../../../../components/FormControl";
import axios from "axios";
import useAxios from "../../../../hooks/useAxios";
import {
  BUTTON_TEXTS,
  CONDFIRM_EDIT_MESSAGE,
  DEFAULT_ERROR_MSG,
  DEFAULT_REQUIRED_VALIDATION_MESSAGE,
  FORM_REQUIRED_FIELD_MSG,
  INVALID_EMAIL_VALIDATION_MESSAGE,
  MESSAGE_TYPE,
} from "../../../../constants/GlobalVariables";
import MessageValidation from "../../../../components/MessageValidation";
import {
  CONTRACTS_REGISTRATION,
  GET_REPORT_VERSIONS_URL,
} from "../../../../constants/GlobalUrls";
import dayjs from "dayjs";
import {
  clientTypeObj,
  clientTypeOptions,
  config,
} from "./CreateNewClient.config";
import {
  commonFormStyle,
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  WebContentContainerWrapper,
} from "../../../../components";
import AdvancesTable from "../../ContractManager/ContractManagerDealForm/AdvancesTable";
import { checkEmailValidation } from "../../../../utils/checkEmailValidation";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const CreateNewClient = ({
  isEditMode,
  hideShowListinPage,
  recordData,
  theme,
}) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [original, setOriginal] = useState({});
  const [formData, setFormData] = useState({
    clientType: "",
    companyname: "",
    taxidentificationnumber: "",
    registrationnumber: "",
    individualName: "",
    personalidentificationnumber: "",
    identificationcardnumber: "",
    address: "",
    birthdate: null,
    rawBirthdate: "",
    contactperson: "",
    email: "",
    payeeid: "",
    advance_data: [],
    reportversionid: "",
  });

  const [isValidated, setIsValidated] = useState({
    email: false,
  });
  const [reportVersionOptions, setReportVersionOptions] = useState([]);

  const {
    clientType,
    companyname,
    taxidentificationnumber,
    registrationnumber,
    individualName,
    personalidentificationnumber,
    identificationcardnumber,
    address,
    birthdate,
    rawBirthdate,
    contactperson,
    email,
    payeeid,
    advance_data,
    reportversionid,
  } = formData;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (isEditMode) {
        getIndividualClient();
      }
      getReportVersionOptions();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [recordData]);

  const configText = config(isEditMode);

  const getIndividualClient = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let data = res && res[0];
      setOriginal({ ...data });
      if (recordData.clientType === clientTypeObj.INDIVIDUAL) {
        if (data.birthdate) {
          let d = data.birthdate && data.birthdate.split("/");
          let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
          data = {
            ...data,
            rawBirthdate: dat,
          };
        }
      }
      setFormData({ ...data });
    };
    let url = CONTRACTS_REGISTRATION + recordData?.clientid + "/";
    await api
      .get(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setIsLoading(false);
  };

  const getReportVersionOptions = async () => {
    const handleSuccessResponse = (res) => {
      setReportVersionOptions(res);
    };
    await api
      .get(GET_REPORT_VERSIONS_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value) => {
    if (message.show) {
      resetMessageObj();
    }
    if (name === "clientType") {
      resetMessageObj();
      setFormData({ ...formData, [name]: value });
    } else if (name === "rawBirthdate") {
      setFormData({
        ...formData,
        [name]: value,
        birthdate: value ? dayjs(value).format("DD/MM/YYYY") : value,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getAdvanceData = (advancesData) => {
    onChange("advance_data", advancesData);
  };

  const closeShowEditConfirmationWindow = () => {
    setShowEditConfirmation(false);
  };
  const openShowEditConfirmationWindow = () => {
    setShowEditConfirmation(true);
  };

  const handleCompanySubmit = () => {
    return (
      clientType !== "" &&
      companyname !== "" &&
      email !== "" &&
      address !== "" &&
      contactperson !== "" &&
      reportversionid !== ""
    );
  };

  const handleIndividualSubmit = () => {
    return (
      clientType !== "" &&
      individualName !== "" &&
      email !== "" &&
      reportversionid !== ""
    );
  };

  const getFormdataForsubmit = () => {
    if (clientType === clientTypeOptions[0].value) {
      return {
        individualName,
        identificationcardnumber,
        personalidentificationnumber,
        birthdate,
        address,
        email,
        payeeid,
        clientType,
        advance_data,
        reportversionid,
      };
    } else {
      return {
        companyname,
        taxidentificationnumber,
        registrationnumber,
        contactperson,
        email,
        payeeid,
        address,
        clientType,
        advance_data,
        reportversionid,
      };
    }
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    let isEmpty = false;

    if (clientType === clientTypeOptions[0].value) {
      isEmpty = handleIndividualSubmit();
    } else {
      isEmpty = handleCompanySubmit();
    }

    if (isEmpty) {
      let passValidation = checkEmailValidation(email);
      setIsValidated({ ...isValidated, email: !!!passValidation });
      isEmpty = !!passValidation;
    }

    if (isEmpty) {
      let submitFormData = getFormdataForsubmit();
      setIsLoading(true);
      const handleSuccessResponse = (res) => {
        if (res?.msg) {
          alert(res.msg);
        }
        hideShowListinPage();
      };

      const handleEditErrorResponse = (error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (error?.response?.status === 409) {
          openShowEditConfirmationWindow();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
        setIsLoading(false);
      };

      const handleErrorResponse = (error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
        setIsLoading(false);
      };
      if (isEditMode) {
        let requestData = {
          original,
          revised: submitFormData,
          compare_data: true,
        };
        if (showEditConfirmation) {
          requestData.compare_data = false;
          closeShowEditConfirmationWindow();
        }
        let url = `${CONTRACTS_REGISTRATION}${formData.clientid}/`;
        await api
          .put(url, requestData, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            handleSuccessResponse(res.data);
          })
          .catch((error) => {
            handleEditErrorResponse(error);
          });
      } else {
        await api
          .post(CONTRACTS_REGISTRATION, submitFormData, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            handleSuccessResponse(res.data);
          })
          .catch((error) => {
            handleErrorResponse(error);
          });
      }

      setIsLoading(false);
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_REQUIRED_VALIDATION_MESSAGE,
      });
    }
  };

  const breadCrumbData = [
    {
      title: "Clients",
      onClickPress: hideShowListinPage,
    },
    {
      title: isEditMode ? "Edit Client" : "New Client",
      active: true,
    },
  ];

  return (
    <WebContentContainerWrapper
      breadCrumbData={breadCrumbData}
      loading={isLoading}
    >
      <View style={commonFormStyle(theme).formContainer}>
        <FormSelect
          name="clientType"
          label={"Select Client Type"}
          options={clientTypeOptions}
          onChange={onChange}
          value={clientType}
          disabled={isEditMode}
          required
          isValidated={message.show && !clientType}
        />
        {!!clientType &&
          (clientType === clientTypeOptions[1].value ? (
            <>
              <FormControlInput
                label="Company Name"
                name="companyname"
                onChange={onChange}
                value={companyname}
                required
                isValidated={message.show && !companyname}
              />
              <FormControlInput
                label="Tax Identification Number"
                name="taxidentificationnumber"
                onChange={onChange}
                value={taxidentificationnumber}
              />
              <FormControlInput
                label="Registration Number"
                name="registrationnumber"
                onChange={onChange}
                value={registrationnumber}
              />
              <FormControlInput
                label="Address"
                name="address"
                onChange={onChange}
                value={address}
                required
                isValidated={message.show && !address}
              />
              <FormControlInput
                label="Contact Person"
                name="contactperson"
                onChange={onChange}
                value={contactperson}
                required
                isValidated={message.show && !contactperson}
              />
            </>
          ) : (
            <>
              <FormControlInput
                label="Individual Name"
                name="individualName"
                onChange={onChange}
                value={individualName}
                required
                isValidated={message.show && !individualName}
              />
              <FormControlInput
                label="Personal Identification Number"
                name="personalidentificationnumber"
                onChange={onChange}
                value={personalidentificationnumber}
              />
              <FormControlInput
                label="Identification Card Number"
                name="identificationcardnumber"
                onChange={onChange}
                value={identificationcardnumber}
              />
              <FormDatePicker
                label="Birth Date"
                name={"rawBirthdate"}
                value={rawBirthdate}
                onChange={onChange}
              />
              <FormControlInput
                label="Address"
                name="address"
                onChange={onChange}
                value={address}
              />
            </>
          ))}
        {!!clientType && (
          <>
            <FormControlInput
              label="Email"
              name="email"
              onChange={onChange}
              value={email}
              required
              isValidated={isValidated.email || (message.show && !email)}
              validationMessage={
                isValidated.email
                  ? INVALID_EMAIL_VALIDATION_MESSAGE
                  : FORM_REQUIRED_FIELD_MSG
              }
            />
            <FormControlInput
              label="Payee ID"
              name="payeeid"
              onChange={onChange}
              value={payeeid}
            />
          </>
        )}
        {!!clientType && (
          <FormSelect
            optionName="name"
            optionValue="id"
            name="reportversionid"
            label={"Select Report Version"}
            options={reportVersionOptions}
            onChange={onChange}
            value={reportversionid}
            required
            isValidated={message.show && !reportversionid}
          />
        )}
        <MessageValidation {...message} />
      </View>
      {!!clientType && (
        <View style={commonFormStyle(theme).tableFormFieldContainer}>
          <AdvancesTable
            getAdvanceData={getAdvanceData}
            advancesData={formData.advance_data}
          />
        </View>
      )}
      <View style={commonFormStyle(theme).formContainer}>
        {!!clientType && (
          <View style={commonFormStyle(theme).actionBtnContainer}>
            <CustomButton
              onPress={onSubmit}
              text={configText.SUBMIT_BTN_TEXT}
              cutomButtonStyle={commonFormStyle(theme).submitBtn}
            />
            <CustomButton
              text={BUTTON_TEXTS.BACK}
              onPress={hideShowListinPage}
              cutomButtonStyle={commonFormStyle(theme).backBtn}
            />
          </View>
        )}
      </View>
      <CustomModal
        title={`Confirm`}
        visible={showEditConfirmation}
        hideModal={closeShowEditConfirmationWindow}
      >
        <ConfirmationWindow
          message={`${CONDFIRM_EDIT_MESSAGE}`}
          handleNoOnClick={closeShowEditConfirmationWindow}
          handleYesOnClick={onSubmit}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default withTheme(CreateNewClient);
