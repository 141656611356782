import { Text } from "react-native";
import React from "react";
import { withTheme } from "react-native-paper";
import { customStyle } from "./customStyle";

const InformationText = ({ text, theme }) => {
  return <Text style={customStyle(theme).informationText}>{text}</Text>;
};

export default withTheme(InformationText);
