export const config = {
  TITLE: "Report Generator",

  START_MONTH: "Start Period",
  START_MONTH_KEY: "start_month",

  END_MONTH: "End Period",
  END_MONTH_KEY: "end_month",

  CLIENTS_TITLE: "Client",
  CLIENT_KEY: "client_ids",
  CLIENT_NAME: "client_names",

  // DEALS_TITLE: "Deal",
  // DEAL_KEY: "deal_ids",
  // DEAL_NAME: "deal_names",

  CONTRACT_TITLE: "Contract",
  CONTRACT_KEY: "contract_ids",
  CONTRACT_NAME: "contract_names",

  LABELS_TITLE: "Label",
  LABEL_KEY: "label_ids",
  LABEL_NAME: "label_names",

  RELEASE_TITLE: "Release",
  RELEASE_KEY: "release_ids",
  RELEASE_NAME: "release_names",

  TRACKS_TITLE: "Track",
  TRACK_KEY: "track_ids",
  TRACK_NAME: "track_names",

  SEARCH_PLACEHOLDER: "Search",
  GENERATE_REPORT_SEARCH_PLACHOLDER: "Search by ID/Type/Selection/File Name/Created By/Start Period/End Period",
};

export const REPORT_STATUS = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  FAILED: 'failed'
}

export const groupByDefaultValue = "no_group";

export const groupByTableOptions = [
  {
    label: "No Group",
    value: "no_group",
  },
  {
    label: "Label",
    value: "label",
  },
  {
    label: "Release",
    value: "release",
  },
  {
    label: "Track",
    value: "track",
  },
  {
    label: "Reporting Period",
    value: "reporting_period",
  },
  {
    label: "Country",
    value: "country",
  },
  {
    label: "Platform",
    value: "platform",
  },
  {
    label: "Currency",
    value: "currency",
  },
];
