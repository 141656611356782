import { View } from "react-native";
import React, { useState } from "react";
import { Checkbox, Searchbar, useTheme } from "react-native-paper";
import { config } from "./account.config";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../../components";
import { commonstyle } from "./commonstyle";
import { updateSelectedRow } from "./updateSelectedRow";

const ArtistTable = ({
  data,
  selectActiveTab,
  updateTable,
  canSelect = true,
}) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  const onPressUpdateSelectedRow = (id) => {
    let finalData = updateSelectedRow(data, id, "artist_id");
    updateTable(finalData);
  };

  let commonColumns = [
    {
      key: "artist_id",
      title: "ID",
      dataIndex: "artist_id",
      width: 0.3,
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "artist_name",
      title: "Artist Name",
      dataIndex: "artist_name",
      sort: true,
      search: true,
      width: 0.4,
    },
    {
      key: "cm_artist",
      title: "CM Artist",
      dataIndex: "cm_artist",
      sort: true,
      search: true,
      width: 0.4,
    },
    {
      key: "cm_artistname",
      title: "CM Artist Name",
      dataIndex: "cm_artistname",
      sort: true,
      search: true,
      width: 0.4,
    },
    {
      key: "historicaldata",
      title: "Historical Data",
      dataIndex: "historicaldata",
      sort: true,
      search: true,
      width: 0.4,
    },
  ];

  let selectColumns = [
    {
      key: "existing",
      title: "",
      dataIndex: "existing",
      width: 0.1,
      hideToolTip: true,
      render: (existing, record) => {
        return (
          <Checkbox
            status={existing ? "checked" : "unchecked"}
            onPress={() => {
              onPressUpdateSelectedRow(record?.artist_id);
            }}
          />
        );
      },
    },
  ];
  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <View>
          <Searchbar
            placeholder={config.ARTIST_SEARCH_PLACEHOLDER}
            onChangeText={onChangeSearch}
            value={searchQuery}
            style={commonStatisticButtonStyle(theme, 600).searchBar}
            inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
          />
        </View>
        {selectActiveTab && <View>{selectActiveTab}</View>}
      </View>
      <CommonDataTable
        columns={
          canSelect ? [...selectColumns, ...commonColumns] : commonColumns
        }
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default React.memo(ArtistTable);
