import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import React from "react";
import MainNav from "./src/navigation/MainNav";
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from "react-native-safe-area-context";
import { AuthProvider } from "./src/context/AuthContextAPI";

export default function App() {
  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <AuthProvider>
        <MainNav />
      </AuthProvider>
      <StatusBar style="auto" />
    </SafeAreaProvider>
  );
}
