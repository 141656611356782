import { Text } from "react-native";
import React from "react";
import { customStyle } from "./customStyle";
import { withTheme } from "react-native-paper";

const PrimaryColorText = ({ text, theme }) => {
  return <Text style={customStyle(theme).primaryColorText}>{text}</Text>;
};

export default withTheme(PrimaryColorText);
