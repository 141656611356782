import { StyleSheet } from "react-native";

export const statisticsStyle = (theme, color=theme.colors.text) =>
  StyleSheet.create({
    label: {
      fontFamily: theme.customFonts.bold,
      fontSize: 16,
      marginBottom: 8,
      color: theme.colors.text,
    },
    value: {
      fontFamily: theme.customFonts.bold,
      fontSize: 28,
      color: color || theme.colors.text,
    },
    valueSemiBold: {
      fontFamily: theme.customFonts.semiBold,
      fontSize: 28,
      color: theme.colors.text,
    },
    valueRegular: {
      fontFamily: theme.customFonts.regular,
      fontSize: 28,
      color: theme.colors.text,
    },
  });
