import React, { useEffect, useState } from "react";
import { ContractFormContext } from "../../../context/ContractFormContextAPI";
import ContractManagerDealForm from "./ContractManagerDealForm/ContractManagerDealForm";
import ContractManagerForm from "./ContractManagerForm/ContractManagerForm";
import ContractManagerListingPage from "./ContractManagerListingPage/ContractManagerListingPage";
import { useRoute } from "@react-navigation/native";
import { config } from "./ContractManager.config";

const ContractManager = () => {
  let route = useRoute();
  const [showPage, setShowPage] = useState({
    landing: true,
    contractForm: false,
    dealForm: false,
  });
  const [recordData, setRecordData] = useState({});
  const [dealRecordData, setDealRecordData] = useState({});
  const [dealFormPrefillData, setDealFormPrefillData] = useState({});

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (route.params?.dealFormPrefillData && route.params?.recordData) {
        setRecordData(route.params?.recordData);
        setDealFormPrefillData(route.params?.dealFormPrefillData);
        changeShowPage(config.pages.CONTRACT_FORM);
      }
    }
    return () => {
      isMoulded = false;
    };
  }, [route]);

  const changeShowPage = (pageName) => {
    setShowPage({
      landing: false,
      contractForm: false,
      dealForm: false,
      [pageName]: true,
    });
  };

  const getRecordData = (record) => {
    setRecordData({ ...record });
  };

  const getDealRecordData = (record) => {
    setDealRecordData({ ...record });
  };

  const checkObjectHasEmptyKeysForContract = () => {
    return Object.keys(recordData).length !== 0;
  };

  const checkObjectHasEmptyKeysForDeals = () => {
    return Object.keys(dealRecordData).length !== 0;
  };

  const checkObjectHasEmptyKeysForDealsFormPrefillData = () => {
    return Object.keys(dealFormPrefillData).length !== 0;
  };

  return (
    <>
      {!!showPage.landing && (
        <ContractManagerListingPage
          changeShowPage={changeShowPage}
          getRecordData={getRecordData}
        />
      )}
      {(!!showPage.contractForm || !!showPage.dealForm) && (
        <ContractFormContext>
          {!!showPage.contractForm ? (
            <ContractManagerForm
              isEditMode={checkObjectHasEmptyKeysForContract()}
              changeShowPage={changeShowPage}
              recordData={recordData}
              getDealRecordData={getDealRecordData}
              hasDealFormPrefillData={checkObjectHasEmptyKeysForDealsFormPrefillData()}
            />
          ) : (
            <ContractManagerDealForm
              isExistingContractForm={checkObjectHasEmptyKeysForContract()}
              isEditMode={checkObjectHasEmptyKeysForDeals()}
              changeShowPage={changeShowPage}
              recordData={dealRecordData}
              dealFormPrefillData={dealFormPrefillData}
              setDealFormPrefillData={setDealFormPrefillData}
              hasDealFormPrefillData={checkObjectHasEmptyKeysForDealsFormPrefillData()}
            />
          )}
        </ContractFormContext>
      )}
    </>
  );
};

export default ContractManager;
