import { Dimensions, StyleSheet } from "react-native";
const screen = Dimensions.get("window");

export const styles = (theme, style) =>
  StyleSheet.create({
    statsHead: {
      paddingTop: 10,
      paddingHorizontal: 12,
    },
    container: {
      backgroundColor:
        style === "stats"
          ? theme.colors.background
          : theme.colors.customCardColor,
      borderRadius: 10,
      justifyContent: "space-evenly",
      marginBottom: 10,
      flex: 2,
    },
    scrollView: {
      flexDirection: "row",
      overflow: "hidden",
      justifyContent: "space-evenly",
    },
    slide: {
      flexBasis: "100%",
      flex: 1,
      maxWidth: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.customCardColor,
      borderWidth: 0,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      borderRadius: 0,
    },
    audienceSlide: {
      flexWrap: "wrap",
      flex: 1,
      maxWidth: screen.width,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
    },
  });

export default styles;
