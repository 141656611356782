import React, { useContext, useState } from "react";
import { View } from "react-native";
import {
  ActionsButtonIcons,
  CommonDataTable,
  CustomModal,
  ConfirmationWindow,
} from "../../../../components";
import { FormLabel } from "../../../../components/FormControl";
import { ACTION_BUTTON_TYPE } from "../../../../constants/GlobalVariables";
import ContractFormContextAPI from "../../../../context/ContractFormContextAPI";
import { findAllByKey } from "../../../../utils/findAllByKey";
import {
  DEAL_TYPES,
  TABLE_ACTION,
} from "../ContractManagerDealForm/ContractManagerDealForm.config";
import { deleteTableRecord } from "../../../../utils/deleteTableRecord";

const ContractManagerDealTable = ({
  requiredField,
  gotToDealPage,
  getRecordData,
}) => {
  const { formData, setFormData } = useContext(ContractFormContextAPI);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };
  const handleDelete = () => {
    let id = showDeleteConfirmation.record.id;
    let tempData = { ...formData };
    if (id === "New") {
      tempData.deals_data = deleteTableRecord(
        tempData.deals_data,
        showDeleteConfirmation.record
      );
    } else {
      let obj = tempData.deals_data.find((item) => item.id === id);
      obj.action = TABLE_ACTION.delete;
    }
    setFormData(tempData);
    closeDeleteConfirmationWindow();
  };
  const createDeal = () => {
    if (requiredField) {
      getRecordData({});
      gotToDealPage();
    } else {
      openDeleteConfirmationWindow({});
    }
  };

  let getCategoryText = (record) => {
    let tempData = "";
    if (record?.labelid && record?.labelid !== "") {
      tempData = DEAL_TYPES.LABEL_NAME;
    } else if (record?.releaseid && record?.releaseid !== 0) {
      tempData = DEAL_TYPES.RELEASE_NAME;
    } else if (record?.assetid && record?.assetid !== "") {
      tempData = DEAL_TYPES.ASSET_NAME;
    } else if (record?.channelid && record?.channelid !== "") {
      tempData = DEAL_TYPES.CHANNEL_NAME;
    } else {
      tempData = DEAL_TYPES.TRACK_ON_UPC_NAME;
    }
    return tempData;
  };
  let columns = [
    {
      key: "id",
      title: "Deal ID",
      dataIndex: "id",
      width: 0.7,
      // bold: true,
    },
    {
      key: "dealType",
      title: "Deal Category",
      dataIndex: "dealType",
      // bold: true,
      width: 1.2,
      customToolTip: (labelid, record) => getCategoryText(record),
      render: (labelid, record) => getCategoryText(record),
    },
    {
      key: "labelid",
      title: "Label ID/UPC/UPCISRC/Asset ID/Channel ID",
      dataIndex: "labelid",
      // bold: true,
      width: 3.5,
      render: (labelid, record) => {
        return (
          labelid ||
          record.releaseid ||
          record.release_trackid ||
          record.assetid ||
          record.channelid
        );
      },
    },
    {
      key: "label_title_name",
      title: "Label Name/Release Title/Track Title/Asset Title/Channel Name",
      dataIndex: "label_title_name",
      // bold: true,
      width: 5,
    },
    {
      key: "labelReleaseTrackName",
      title: "Individual/Company Name",
      dataIndex: "labelReleaseTrackName",
      // bold: true,
      width: 4,
      customToolTip: (label, record) => {
        const revenueShareValues = findAllByKey(
          record?.revenue_data,
          "client_name"
        );
        return revenueShareValues.join("/");
      },
      render: (label, record) => {
        const revenueShareValues = findAllByKey(
          record?.revenue_data,
          "client_name"
        );
        return revenueShareValues.join("/");
      },
    },
    {
      key: "revenueshare",
      title: "Split",
      dataIndex: "revenueshare",
      width: 1.2,
      customToolTip: (label, record) => {
        const revenueShareValues = findAllByKey(
          record?.revenue_data,
          "revenueshare"
        );
        return revenueShareValues.join("/");
      },
      render: (label, record) => {
        const revenueShareValues = findAllByKey(
          record?.revenue_data,
          "revenueshare"
        );
        return revenueShareValues.join("/");
      },
    },
    {
      key: "advance_data",
      title: "Advance",
      dataIndex: "advance_data",
      width: 0.7,
      customToolTip: (advance_data) => {
        let text = "No";
        if (advance_data.length !== 0) {
          text = "Yes";
        }
        return text;
      },
      render: (advance_data) => {
        let text = "No";
        if (advance_data.length !== 0) {
          text = "Yes";
        }
        return text;
      },
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "id",
      width: 0.7,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              gotToDealPage();
              getRecordData(record);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.REMOVE,
      title: "Remove",
      dataIndex: "id",
      width: 0.7,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.REMOVE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <FormLabel bold text={"Deals"} />
      <CommonDataTable
        columns={columns}
        data={formData.deals_data}
        showAddButton
        handleAddBtnClick={createDeal}
        showPagination={false}
      />
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        {requiredField ? (
          <ConfirmationWindow
            isDelete
            message={`Are you sure you want to delete ${getCategoryText(
              showDeleteConfirmation?.record
            )} deal with ID ${
              showDeleteConfirmation?.record?.id
            }? If yes, type in delete below`}
            handleNoOnClick={closeDeleteConfirmationWindow}
            handleYesOnClick={handleDelete}
          />
        ) : (
          <ConfirmationWindow
            message={"Please select a Contract Type before creating a Deal."}
            showButtons={false}
          />
        )}
      </CustomModal>
    </View>
  );
};

export default React.memo(ContractManagerDealTable);
