import { StyleSheet, Image, View, Dimensions } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  CustomButton,
  HeaderText,
  InformationText,
  Loader,
} from "../../components";
import AuthContextAPI from "../../context/AuthContextAPI";
import { imageUrl } from "../../utils/imageUrl";
import {
  BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
  INFORMATION_TEXT,
} from "../../constants/GlobalVariables";
import { withTheme, Switch, Divider } from "react-native-paper";
import { commonStyle } from "../../components/CommonStyles/commonStyle";
import { useNavigation } from "@react-navigation/native";
import nonAdminNavigationStrings from "../../navigation/AppStack/NonAdminAppStack/nonAdminNavigationStrings";
import * as ImagePicker from "expo-image-picker";
import { UIImagePickerPresentationStyle } from "expo-image-picker/build/ImagePicker.types";
import useAxios from "../../hooks/useAxios";
import axios from "axios";
import { UPLOAD_PROFILE_PICTURE } from "../../constants/GlobalUrls";

const screen = Dimensions.get("window");
const { width } = screen;
const imageDimension = width - 40;

const SettingsContent = ({ theme }) => {
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const {
    updateTheme,
    logoutUser,
    userInformation: { profilepicturepath, darkmode },
    updateProfilePicturePath,
  } = useContext(AuthContextAPI);

  const profileImageUrl = imageUrl(profilepicturepath);

  let navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      presentationStyle: UIImagePickerPresentationStyle.FullScreen,
    });

    if (!result.cancelled) {
      setIsLoading(true);
      let filename = result.uri.split("/").pop();
      let match = /\.(\w+)$/.exec(filename);
      let type = match ? `image/${match[1]}` : `image`;
      let fileObj = {
        name: filename,
        type: type,
        uri: result.uri,
      };
      let formData = new FormData();
      // Assume "photo" is the name of the form field the server expects
      formData.append("file", fileObj);
      const handleSuccessResponse = (res) => {
        updateProfilePicturePath(res.url);
        alert(res.msg);
      };

      const handleErrorResponse = (err) => {
        alert(DEFAULT_ERROR_MSG);
      };

      await api
        .post(UPLOAD_PROFILE_PICTURE, formData, {
          "content-type": `multipart/form-data`,
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          handleSuccessResponse(res.data);
        })
        .catch((error) => {
          handleErrorResponse(error.response);
        });
      setIsLoading(false);
    }
  };

  const goToChangePassword = () => {
    navigation.navigate(nonAdminNavigationStrings.CHANGE_PASSWORD.name);
  };

  return (
    <View>
      <Loader loading={isLoading} />
      <View style={commonStyle.commonMarginBottonContainer}>
        <InformationText text={INFORMATION_TEXT.settingPageTile} />
      </View>
      <View style={styles(theme).imageContainer}>
        <Image
          style={styles(theme).image}
          resizeMode="cover"
          source={{ uri: profileImageUrl }}
        />
      </View>

      <View style={commonStyle.commonMarginContainer}>
        <CustomButton
          onPress={pickImage}
          text="Change Profile Picture"
          buttontype={BUTTON_TYPE.PRIMARY}
        />
      </View>
      <View style={commonStyle.commonMarginContainer}>
        <CustomButton
          onPress={goToChangePassword}
          text="Change Password"
          buttontype={BUTTON_TYPE.PRIMARY}
        />
      </View>
      <View style={styles(theme).switchesContainer}>
        <View style={styles(theme).textSwitchContainer}>
          <View>
            <HeaderText text={"Notifications"} />
            <InformationText text={"Receive notifications"} />
          </View>
          <Switch
            value={true}
            color={theme.colors.primary}
            style={styles(theme).switch}
          />
        </View>
        <Divider style={styles(theme).switchDivider} />
        <View style={styles(theme).textSwitchContainer}>
          <View>
            <HeaderText text={"Dark mode"} />
            <InformationText text={"Dark mode or light mode"} />
          </View>
          <Switch
            value={darkmode}
            color={theme.colors.primary}
            style={styles(theme).switch}
            onValueChange={updateTheme}
          />
        </View>
      </View>
      <View style={commonStyle.commonMarginTopContainer}>
        <CustomButton
          text="Sign Out"
          buttontype={BUTTON_TYPE.PRIMARY}
          onPress={logoutUser}
        />
      </View>
    </View>
  );
};

export default withTheme(SettingsContent);

const styles = (theme) =>
  StyleSheet.create({
    image: {
      width: imageDimension,
      height: imageDimension,
      borderRadius: 10,
      flex: 1,
    },
    switchesContainer: {
      marginVertical: 30,
    },
    textSwitchContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switchDivider: {
      backgroundColor: theme.colors.divider,
      marginVertical: 20,
      height: 1,
    },
    switch: { height: 24 },
    imageContainer: {
      marginTop: 10,
      marginBottom: 30,
    },
  });
