export default {
  LOGIN: {
    name: "login",
    title: "Login",
  },
  FORGOT_PASSWORD: {
    name: "forgot-password",
    title: "Forgot Password",
  },
  PASSWORD_RESET: {
    name: "password_reset",
    title: "New Password",
  },
  SIGN_UP: {
    name: "sign-up",
    title: "Sign Up",
  },
  CONTACT_SUPPORT: {
    name: "contact-support",
    title: "Contact Support",
  },
};
