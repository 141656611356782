import React from "react";
import { Platform, Text, TouchableOpacity } from "react-native";
import { withTheme } from "react-native-paper";
import { BUTTON_TYPE } from "../../constants/GlobalVariables";

const CustomButton = ({
  text,
  buttontype = BUTTON_TYPE.PRIMARY,
  onPress,
  cutomButtonStyle = {},
  textStyle = {},
  disabled = false,
  theme,
}) => {
  // button type = primary, secondary, link
  const { customFonts, colors } = theme;
  let height = Platform.OS === "web" ? 56 : 44;

  let buttonColor =
    buttontype === BUTTON_TYPE.PRIMARY ? colors.primary : colors.background;

  let borderColor =
    buttontype === BUTTON_TYPE.LINK ? colors.contrastText : colors.primary;

  let textColor =
    buttontype === BUTTON_TYPE.SECONDARY ? colors.white : colors.black;

  return (
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderColor: borderColor,
        alignItems: "center",
        justifyContent: "center",
        height: height,
        backgroundColor: buttonColor,
        borderRadius: 10,
        paddingHorizontal: 50,
        ...cutomButtonStyle,
        opacity: disabled? 0.5: 1
      }}
      onPress={onPress}
      disabled={disabled}
    >
      <Text
        style={
          buttontype === BUTTON_TYPE.LINK
            ? {
                textDecorationLine: "underline",
                fontFamily: customFonts.semiBold,
                fontSize: 16,
                color: theme.dark ? colors.primary : colors.black,
                ...textStyle,
              }
            : {
                fontFamily: customFonts.semiBold,
                fontSize: 16,
                color: theme.dark ? textColor : colors.black,
                ...textStyle,
              }
        }
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default withTheme(CustomButton);
