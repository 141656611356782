export const config = {
  CURRENT_BALANCE_TEXT: "Current Balance",
  CARD_HOLDER_TEXT: "Card Holder",
  UPDATED_TEXT: "Updated",
};

export const dummyCardDetails = {
  CURRENT_BALANCE_VALUE: "$ 154,344.20",
  CARD_HOLDER_VALUE: "Devito Anonymo",
  UPDATED_VALUE: "9-10-22",
};
