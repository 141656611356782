import React, { useContext, useEffect, useState } from "react";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import {
  BUTTON_TYPE,
  CHANGE_PASSWORD_DONT_MATCH_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INFORMATION_TEXT,
  MESSAGE_TYPE,
} from "../constants/GlobalVariables";
import { withTheme } from "react-native-paper";
import { checkObjectIsEmpty } from "../utils/checkObjectIsEmpty";
import useAxios from "../hooks/useAxios";
import AuthContextAPI from "../context/AuthContextAPI";
import { CHANGE_PASSWORD } from "../constants/GlobalUrls";
import axios from "axios";
import CustomTextInput from "../components/CustomTextInput";
import MessageValidation from "../components/MessageValidation";
import { CommonLayoutWrapper, CustomButton } from "../components";

const ChangePassword = ({ navigation, theme }) => {
  let initialState = {
    form: {
      old_password: "",
      new_password: "",
      password_confirmation: "",
    },
    message: {
      show: false,
      type: "",
      message: "",
    },
  };
  const { colors } = theme;
  // api hook
  let api = useAxios();

  //deconstruct logout user from context
  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(initialState.form);
  const [message, setMessage] = useState(initialState.message);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      setFormData(initialState.form);
      setMessage(initialState.message);
    });
  }, [navigation]);

  useEffect(() => {
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const { old_password, new_password, password_confirmation } = formData;

  const onChangeText = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    let isEmpty = checkObjectIsEmpty(formData);
    if (isEmpty) {
      if (formData.new_password !== formData.password_confirmation) {
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: CHANGE_PASSWORD_DONT_MATCH_MSG,
        });
      } else {
        setIsLoading(true);
        const handleSuccessResponse = (res) => {
          alert(res.message);
          logoutUser();
        };

        const handleErrorResponse = (err) => {
          if (err?.status === 400 && err?.data?.old_password) {
            setMessage({
              show: true,
              type: MESSAGE_TYPE.error,
              message: err?.data?.old_password[0],
            });
          } else if (err?.status === 500 && err?.data?.detail) {
            setMessage({
              show: true,
              type: MESSAGE_TYPE.error,
              message: err?.data?.detail,
            });
          }
        };
        await api
          .put(CHANGE_PASSWORD, formData, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            handleSuccessResponse(res.data);
          })
          .catch((error) => {
            handleErrorResponse(error.response);
          });
        setIsLoading(false);
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <CommonLayoutWrapper loading={isLoading} marginTop={20}>
      <View style={styles.titleContainer}>
        <Text style={{ color: colors.infoText }}>
          {INFORMATION_TEXT.changePassword}
        </Text>
      </View>
      <CustomTextInput
        secureTextEntry
        name="old_password"
        placeholder="Old Password"
        customStyle={styles.inputField}
        onChangeText={onChangeText}
        value={old_password}
      />
      <CustomTextInput
        secureTextEntry
        name="new_password"
        placeholder="New Password"
        customStyle={styles.inputField}
        onChangeText={onChangeText}
        value={new_password}
      />
      <CustomTextInput
        secureTextEntry
        name="password_confirmation"
        placeholder="Confirm New Password"
        onChangeText={onChangeText}
        value={password_confirmation}
      />
      <View style={styles.bottomButton}>
        <CustomButton
          text="Change Password"
          buttontype={BUTTON_TYPE.PRIMARY}
          onPress={onSubmit}
        />
      </View>
      <MessageValidation {...message} />
    </CommonLayoutWrapper>
  );
};

export default withTheme(ChangePassword);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  titleContainer: {
    marginBottom: 40,
  },
  inputField: {
    marginBottom: 10,
  },
  bottomButton: {
    marginTop: 30,
  },
});
