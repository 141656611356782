import { StyleSheet } from "react-native";

export const dealFormForLabelStyle = (theme) =>
  StyleSheet.create({
    messageContainer: {
      width: 700,
      fontSize: 30,
      fontFamily: theme.customFonts.regular,
      textAlign: "center",
    },
    messageBold: {
        fontSize: 30,
        fontFamily: theme.customFonts.bold,
        textAlign: "center",
      },
  });
