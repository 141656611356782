import { View } from "react-native";
import React, { useState } from "react";
import HeaderText from "../CustomText/HeaderText";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import {
  channelOptions,
  monthFilterList,
} from "../../screens/Trends/trends.config";
import InformationText from "../CustomText/InformationText";
import { trendingStyle } from "../TrendingCharts/trendingStyle";

const HeaderWithFilter = () => {
  const [filters, setFilters] = useState(monthFilterList[0]);
  const [selectChannel, setSelectChannel] = useState({});
  const getFilter = (value) => {
    setFilters(value);
  };
  const getSelectedChannel = (value) => {
    setSelectChannel(value);
  };

  return (
    <View>
      <View style={trendingStyle.textFilterContainer}>
        <HeaderText text={"Radio Channels"} />
        <FilterDropdown
          options={monthFilterList}
          activeFilter={filters}
          onChange={getFilter}
          filterHeaderLabel={"Choose period"}
        />
      </View>
      <View style={trendingStyle.textFilterContainer}>
        <InformationText text={"Choose period"} />
        <FilterDropdown
          options={channelOptions}
          activeFilter={selectChannel}
          onChange={getSelectedChannel}
          filterHeaderLabel={"Choose Channel"}
          placeholder="All Channels"
        />
      </View>
    </View>
  );
};

export default HeaderWithFilter;
