import { View } from "react-native";
import React, { useState, useEffect, useContext } from "react";
import * as DocumentPicker from "expo-document-picker";
import { withTheme, List, Text } from "react-native-paper";
import {
  ActionsButtonIcons,
  commonFormStyle,
  commonStatisButtonCustomStyle,
  CustomButton,
  CustomProgressBar,
  Loader,
} from "../../../../components";
import {
  ACTION_BUTTON_TYPE,
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
} from "../../../../constants/GlobalVariables";
import { FormLabel, FormSelect } from "../../../../components/FormControl";
import MessageValidation from "../../../../components/MessageValidation";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import {
  PDF_TYPES_URL,
  UPLOAD_PDF_CONTRACT_URL,
} from "../../../../constants/GlobalUrls";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const UploadPDFForm = ({ theme, cancelButton, successAddPDF }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [disableForm, setDisableForm] = useState(false);
  const [pdfTypeOptions, setPdfTypeOptions] = useState([]);
  const [uploadFormData, setUploadFormData] = useState({
    fileTypeid: "",
    fileType: "",
    file: [],
  });

  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getPDFTypesDropdown();
    }
    return () => {
      isMoulded = false;
    };
  }, []);

  const getPDFTypesDropdown = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      setPdfTypeOptions(res);
    };
    await api
      .get(PDF_TYPES_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setIsLoading(false);
  };

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value) => {
    if (message.show) {
      resetMessageObj();
    } else {
      setUploadFormData({ ...uploadFormData, [name]: value });
    }
  };

  const _pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      multiple: true,
      type: ".pdf",
    });
    let tempFile = [...uploadFormData.file];
    let outputFile = result.output;
    if (tempFile.length > 0) {
      outputFile = [...tempFile, ...result.output];
    }
    onChange("file", outputFile);
  };

  const onSubmit = async () => {
    let allFiles = [...uploadFormData.file];

    if (allFiles.length > 0) {
      setDisableForm(true);

      let data = new FormData();
      data.append("fileType", uploadFormData.fileType);
      data.append("fileTypeid", uploadFormData.fileTypeid);
      allFiles.forEach((file) => {
        data.append("file", file);
      });
      const config = {
        "content-type": `multipart/form-data`,
        cancelToken: cancelTokenSource.token,
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted === 100) {
            setIsLoading(true);
          }
          setFileUploadProgress(percentCompleted);
        },
      };
      const handleSuccessResponse = (res) => {
        if (res) {
          alert("File uploaded successfully");
        }
        successAddPDF(res);
      };

      const handleErrorResponse = (error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      };

      await api
        .post(UPLOAD_PDF_CONTRACT_URL, data, config)
        .then((res) => {
          handleSuccessResponse(res.data);
        })
        .catch((error) => {
          handleErrorResponse(error);
          setIsLoading(false);
        });
    } else {
      alert("Please select at least a file");
    }
  };

  const deleteFile = (index) => {
    var allFiles = [...uploadFormData.file];
    allFiles.splice(index, 1);
    onChange("file", allFiles);
  };

  const setFileTypesData = (selectname, fileTypeid, fileType) => {
    setUploadFormData({
      ...uploadFormData,
      fileType: fileType,
      fileTypeid: fileTypeid,
    });
  };

  const getFileList = Array.from(uploadFormData.file).map((item, i) => (
    <List.Item
      key={i}
      title={item.name}
      right={(props) =>
        !!!disableForm && (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              deleteFile(i);
            }}
          />
        )
      }
    />
  ));

  return (
    <View style={commonFormStyle(theme).modalFormContainer}>
      {fileUploadProgress === 100 && (
        <Text style={commonFormStyle(theme).uploadMsgText}>
          Uploading to S3 bucket
        </Text>
      )}
      {!!!disableForm ? (
        <View>
          <Loader loading={isLoading} />
          <FormSelect
            required
            label={"Select File Type"}
            options={pdfTypeOptions}
            onChange={setFileTypesData}
            value={uploadFormData.fileTypeid}
            name={"fileType"}
            optionName="pdftypename"
            optionValue="id"
          />
          {!!uploadFormData.fileType && (
            <>
              <View style={commonFormStyle(theme).tableFormFieldContainer}>
                <FormLabel text={"Upload File(s)"} required />
                <CustomButton
                  text={"Select File(s)"}
                  cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
                  textStyle={commonStatisButtonCustomStyle(theme).text}
                  onPress={_pickDocument}
                />
              </View>
              {Array.from(uploadFormData.file).length > 0 && (
                <View style={commonFormStyle(theme).tableFormFieldContainer}>
                  {getFileList}
                </View>
              )}
              <MessageValidation {...message} />

              <View style={commonFormStyle(theme).actionBtnContainer}>
                <CustomButton
                  onPress={onSubmit}
                  text={BUTTON_TEXTS.UPLOAD}
                  cutomButtonStyle={commonFormStyle(theme).submitBtn}
                />
                <CustomButton
                  text={BUTTON_TEXTS.CANCEL}
                  onPress={cancelButton}
                  cutomButtonStyle={commonFormStyle(theme).backBtn}
                />
              </View>
            </>
          )}
        </View>
      ) : (
        <View>
          <Loader loading={isLoading} />

          {getFileList}
          <CustomProgressBar progress={fileUploadProgress} />
        </View>
      )}
    </View>
  );
};

export default withTheme(UploadPDFForm);
