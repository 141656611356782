import React from "react";
import { View, Text } from "react-native";
import { withTheme } from "react-native-paper";
import { breadCrumbStyle } from "./breadCrumbStyle";

const BreadCrumb = ({ theme, breadCrumbData = [] }) => {
  return (
    <View style={breadCrumbStyle(theme).titleTextContainer}>
      {breadCrumbData.length > 0 &&
        breadCrumbData.map((item, i) => (
          <Text
            key={i}
            style={
              item?.active
                ? breadCrumbStyle(theme).titleLink
                : breadCrumbStyle(theme).titleText
            }
            onPress={item?.onClickPress}
          >
            <Text style={breadCrumbStyle(theme).titleText}>{`${
              i > 0 ? " > " : ""
            }`}</Text>
            {item.title}
          </Text>
        ))}
    </View>
  );
};

export default withTheme(BreadCrumb);
