import { View } from "react-native";
import React from "react";
import { withTheme } from "react-native-paper";

const HorzontalLine = ({ color, thickness = 1, theme }) => {
  return (
    <View
      style={{
        borderBottomColor: color ? color : theme.colors.secondaryText,
        borderBottomWidth: thickness,
      }}
    />
  );
};

export default withTheme(HorzontalLine);
