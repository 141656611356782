import { View } from "react-native";
import React, { useState } from "react";
import { Checkbox, Searchbar, useTheme } from "react-native-paper";
import { config } from "./account.config";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../../components";
import { commonstyle } from "./commonstyle";
import { updateSelectedRow } from "./updateSelectedRow";

const LabelTable = ({
  data,
  selectActiveTab,
  updateTable,
  canSelect = true,
}) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  const onPressUpdateSelectedRow = (id) => {
    let finalData = updateSelectedRow(data, id, "label_id");
    updateTable(finalData);
  };

  let commonColumns = [
    {
      key: "label_id",
      title: "ID",
      dataIndex: "label_id",
      width: 0.4,
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "label_name",
      title: "Label Name",
      dataIndex: "label_name",
      sort: true,
      search: true,
    },
  ];

  let selectColumns = [
    {
      key: "existing",
      title: "",
      dataIndex: "existing",
      width: 0.1,
      hideToolTip: true,
      render: (existing, record) => {
        return (
          <Checkbox
            status={existing ? "checked" : "unchecked"}
            onPress={() => {
              onPressUpdateSelectedRow(record?.label_id);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <View>
          <Searchbar
            placeholder={config.LABEL_SEARCH_PLACEHOLDER}
            onChangeText={onChangeSearch}
            value={searchQuery}
            style={commonStatisticButtonStyle(theme, 522).searchBar}
            inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
          />
        </View>
        {selectActiveTab && <View>{selectActiveTab}</View>}
      </View>

      <CommonDataTable
        columns={
          canSelect ? [...selectColumns, ...commonColumns] : commonColumns
        }
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default LabelTable;
