import { StyleSheet, View } from "react-native";
import React, { useState, useEffect } from "react";
import CommonCardHeaders from "../CommonCardHeaders/CommonCardHeaders";
import { withTheme } from "react-native-paper";
import CustomVictoryLineChart from "../charts/CustomVictoryLineChart";
import { CommonDataTable } from "..";

const ChartTableCard = ({ theme, data }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      setTableData(data.tableData);
    }
    return () => {
      isMoulded = false;
    };
  }, [data]);

  return (
    <View style={styles(theme).wrapper}>
      <CommonCardHeaders
        size={16}
        title={data?.title}
        subtitle={data?.subtitle}
        src={require("../../../assets/images/TVChannels/IDJTV.jpg")}
      />
      <View style={styles(theme).bodyContainer}>
        <CustomVictoryLineChart theme={theme} data={data?.chartData} />
        <CommonDataTable
          mobileView
          data={tableData}
          columns={data?.columns}
          showPagination={false}
        />
      </View>
    </View>
  );
};

export default withTheme(ChartTableCard);

const styles = (theme) =>
  StyleSheet.create({
    wrapper: {
      backgroundColor: theme.colors.customCardColor,
      paddingBottom: 0,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      paddingTop: 15,
    },
    bodyContainer: {
      paddingHorizontal: 15,
    },
  });
