import { View } from "react-native";
import React from "react";
import { Badge, withTheme } from "react-native-paper";
import { commonBadgePaginationStyle } from "./commonBadgePaginationStyle";

const CommonBadgePagination = ({
  activePage,
  numberOfPage,
  theme,
  setPage,
}) => {
  let show = numberOfPage > 1;
  return (
    <View style={commonBadgePaginationStyle(theme, null, show).container}>
      {numberOfPage > 1 &&
        Array(numberOfPage)
          .fill(0)
          .map((e, n) => (
            <Badge
              key={n}
              size={10}
              // onPress={() => {
              //   setPage(n + 1);
              // }}
              style={
                commonBadgePaginationStyle(theme, activePage === n).badgeColor
              }
            />
          ))}
    </View>
  );
};

export default withTheme(CommonBadgePagination);
