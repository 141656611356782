import { View, StyleSheet } from "react-native";
import React, { useState, useEffect } from "react";
import CommonAvatar from "../CommonAvatar/CommonAvatar";
import SecondaryHeaderText from "../CustomText/SecondaryHeaderText";
import InformationText from "../CustomText/InformationText";
import Carousel from "../Carousel/Carousel";
import { AntDesign } from "@expo/vector-icons";
import { withTheme } from "react-native-paper";
import { commonStyle } from "../CommonStyles/commonStyle";

const ChartTable = ({ theme }) => {
  let columns = [
    {
      key: "chartname",
      title: "Chart Name",
      dataIndex: "chartname",
      width: 2,
      render: (store, record) => {
        return (
          <View style={commonStyle.tableColumnImageTextContainer}>
            <View style={commonStyle.TableImageContainer}>
              <CommonAvatar
                src={require("../../../assets/images/spotify.jpg")}
              />
            </View>

            <View style={commonStyle.TableTextContainer}>
              <SecondaryHeaderText text={store} />
              <InformationText text={record.date} />
            </View>
          </View>
        );
      },
      // bold: true,
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
      center: true,
      width: 0.7,
      render: () => {
        return (
          <View>
            <CommonAvatar
              src={require("../../../assets/images/057-india.png")}
              size={24}
              circleShape
            />
          </View>
        );
      },
    },
    {
      key: "rank",
      title: "Rank",
      dataIndex: "rank",
      width: 0.7,
      center: true,
      render: (rank) => {
        let positive = rank < 4;
        return (
          <View style={styles.descriptionContainer}>
            <View style={styles.iconContainer}>
              <AntDesign
                name={positive ? "caretup" : "caretdown"}
                size={16}
                color={
                  positive
                    ? theme.colors.actionIconActive
                    : theme.colors.downArrow
                }
                style={styles.icon}
              />
            </View>
            <SecondaryHeaderText text={`${rank}`} />
          </View>
        );
      },
    },
    {
      key: "peak",
      title: "Peak",
      dataIndex: "peak",
      width: 0.5,
      center: true,
      render: (peak) => {
        return (
          <View>
            <SecondaryHeaderText text={`${peak}`} />
          </View>
        );
      },
    },
  ];

  const data = [
    {
      title: "Spotify Charts",
      columns: columns,
      showHeader: true,
      data: [
        {
          id: 1,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 1,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 4,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 12,
          peak: 1,
        },
      ],
    },
    {
      title: "YouTube Charts",
      columns: columns,
      showHeader: true,

      data: [
        {
          id: 1,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
      ],
    },
    {
      title: "Deezer Charts",
      columns: columns,
      showHeader: true,
      data: [
        {
          id: 1,
          chartname: "Chart 1",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 2,
          chartname: "Chart 2",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 3,
          chartname: "Chart 3",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 4,
          chartname: "Chart 4",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
        {
          id: 5,
          chartname: "Chart 5",
          date: "18/06/2020",
          country: "India",
          rank: 3,
          peak: 1,
        },
      ],
    },
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let isMoulded = true;

    if (isMoulded) {
      setTableData(data);
    }
    return () => {
      isMoulded = false;
    };
  }, []);

  return <Carousel style="slide" items={tableData} />;
};

export default withTheme(ChartTable);

const styles = StyleSheet.create({
  iconContainer: {
    marginRight: 8,
    justifyContent: "flex-end",
  },
  icon: { height: 16, width: 16 },
  descriptionContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
