import { StyleSheet, Text, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  ActionsButtonIcons,
  CommonDataTable,
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../../components";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import {
  ACCOUNT_URL,
  DOWNLOAD_USER_ACCOUNT_DATA,
  GET_ALL_USERS_LIST_URL,
  GET_USERS_ALL_ARTIST_URL,
  GET_USERS_ALL_LABELS_URL,
  GET_USERS_CLIENT_URL,
} from "../../../../constants/GlobalUrls";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../../constants/GlobalVariables";
import { Fontisto, AntDesign, Entypo, FontAwesome } from "@expo/vector-icons";
import { Searchbar, useTheme } from "react-native-paper";
import { customStyle } from "../../../../components/CustomText/customStyle";
import { TABLE_IDS, config } from "./AccountsListingPage.config";
import { pageConfig } from "../Accounts.config";
import CreateAccountForm from "./CreateAccountForm";
import ClientsTable from "../AccountEditPage/ClientsTable";
import ArtistTable from "../AccountEditPage/ArtistTable";
import LabelTable from "../AccountEditPage/LabelTable";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
let uuid = require("uuid");

const AccountsListingPage = ({ changeShowPage, getRecordData }) => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  let theme = useTheme();

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showNewAccountForm, setshowNewAccountForm] = useState({
    show: false,
    record: {},
  });
  const [showDetailInformationTable, setShowDetailInformationTable] = useState({
    data: [],
    show: false,
    tableTitle: "",
    tableID: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getAllUserList();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [refreshTable]);

  const getAllUserList = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      setTableData(res);
      setIsLoading(false);
    };

    await api
      .get(GET_ALL_USERS_LIST_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
        setIsLoading(false);
      });
  };

  const getUserMoreDetails = async (tableID, record) => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      setShowDetailInformationTable({
        tableTitle: `${record?.id} • ${record?.username} > ${tableID} (${res.length})`,
        show: true,
        data: res,
        tableID: tableID,
      });
    };
    let endpoint = GET_USERS_CLIENT_URL;
    if (TABLE_IDS.ARTIST_TABLE_ID === tableID) {
      endpoint = GET_USERS_ALL_ARTIST_URL;
    } else if (TABLE_IDS.LABEL_TABLE_ID === tableID) {
      endpoint = GET_USERS_ALL_LABELS_URL;
    }
    await api
      .get(`${endpoint}${record?.id}/`, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setIsLoading(false);
  };

  const onLinkBtnClick = (record) => {
    getRecordData(record);
    changeShowPage(pageConfig.pages.ACCOUNT_LINK_PAGE);
  };

  const onClickDownloadCSV = async () => {
    setIsLoading(true);
    await api
      .get(DOWNLOAD_USER_ACCOUNT_DATA, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `useraccount_${uuid.v4()}.csv`);
        tempLink.click();
        setIsLoading(false);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          setIsLoading(false);
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const onChangeSearch = (query) => setSearchQuery(query);

  const closeShowNewAccountForm = () => {
    setshowNewAccountForm({ show: false, record: {} });
    setRefreshTable(!refreshTable);
  };

  const openShowNewAccountForm = (record) => {
    setshowNewAccountForm({ show: true, record: record });
  };

  const triggerTableRefresh = () => {
    setRefreshTable(!refreshTable);
  };

  const closeShowDetailInformationTable = () => {
    setShowDetailInformationTable({ data: [], show: false, table: "" });
  };

  const openShowDetailInformationTable = (tableID, record) => {
    getUserMoreDetails(tableID, record);
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ show: false, record: {} });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    closeDeleteConfirmationWindow();

    let id = showDeleteConfirmation?.record?.id;
    let url = `${ACCOUNT_URL}${id}/`;

    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      triggerTableRefresh();
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
        setIsLoading(false);
      }
    };
    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 0.2,
      sort: true,
      search: true,
    },
    {
      key: "username",
      title: "Username",
      dataIndex: "username",
      sort: true,
      search: true,
      width: 0.4,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      sort: true,
      search: true,
      width: 1,
    },
    {
      key: "gender",
      title: "Gender",
      dataIndex: "gender",
      width: 0.5,
      center: true,
      filter: true,
      render: (gender) => {
        return <Fontisto name={gender} size={16} />;
      },
    },
    {
      key: "accounttype",
      title: "Account Type",
      dataIndex: "accounttype",
      sort: true,
      search: true,
      width: 0.4,
      filter: true,
    },
    {
      key: "subscribed",
      title: "Subscribed",
      dataIndex: "subscribed",
      width: 0.35,
      center: true,
      filter: true,
      customToolTip: (subscribed) => {
        return subscribed ? "Yes" : "No";
      },
      render: (subscribed) => {
        return subscribed ? (
          <AntDesign name="check" size={16} color={theme.colors.primary} />
        ) : (
          <Entypo name="cross" size={16} color={theme.colors.error} />
        );
      },
    },
    {
      key: "active",
      title: "Active",
      dataIndex: "active",
      width: 0.3,
      center: true,
      filter: true,
      customToolTip: (active) => {
        return active ? "Active" : "Inactive";
      },
      render: (active) => {
        return (
          <FontAwesome
            name="circle"
            size={14}
            color={active ? theme.colors.primary : theme.colors.infoText}
          />
        );
      },
    },
    {
      key: "num_of_clients",
      title: "No. of Clients",
      dataIndex: "num_of_clients",
      sort: true,
      width: 0.5,
      filter: true,
      render: (num_of_clients, record) => {
        return (
          <Text
            onPress={() => {
              openShowDetailInformationTable(TABLE_IDS.CLIENT_TABLE_ID, record);
            }}
            style={
              num_of_clients === 0
                ? customStyle(theme).textSemiBold14Black
                : customStyle(theme).textRegular14Blue
            }
          >
            {num_of_clients}
          </Text>
        );
      },
    },
    {
      key: "num_of_artists",
      title: "No. of Artists",
      dataIndex: "num_of_artists",
      sort: true,
      width: 0.5,
      filter: true,
      render: (num_of_artists, record) => {
        return (
          <Text
            onPress={() => {
              openShowDetailInformationTable(TABLE_IDS.ARTIST_TABLE_ID, record);
            }}
            style={
              num_of_artists === 0
                ? customStyle(theme).textSemiBold14Black
                : customStyle(theme).textRegular14Blue
            }
          >
            {num_of_artists}
          </Text>
        );
      },
    },
    {
      key: "num_of_labels",
      title: "No. of Labels",
      dataIndex: "num_of_labels",
      sort: true,
      width: 0.4,
      filter: true,
      render: (num_of_labels, record) => {
        return (
          <Text
            onPress={() => {
              openShowDetailInformationTable(TABLE_IDS.LABEL_TABLE_ID, record);
            }}
            style={
              num_of_labels === 0
                ? customStyle(theme).textSemiBold14Black
                : customStyle(theme).textRegular14Blue
            }
          >
            {num_of_labels}
          </Text>
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.LINK,
      title: "Link",
      dataIndex: "id",
      width: 0.2,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.LINK}
            handleOnPress={() => {
              onLinkBtnClick(record);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "key",
      width: 0.2,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              openShowNewAccountForm(row);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.DELETE,
      title: "Delete",
      dataIndex: "id",
      width: 0.2,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(record);
            }}
          />
        );
      },
    },
  ];

  const breadCrumbData = [
    {
      title: config.Title,
      onClickPress: closeShowNewAccountForm,
    },
    {
      title: `${
        Object.keys(showNewAccountForm.record).length === 0 ? "New" : "Edit"
      } Account`,
      active: true,
    },
  ];

  return showNewAccountForm.show ? (
    <WebContentContainerWrapper
      breadCrumbData={breadCrumbData}
      loading={isLoading}
    >
      <CreateAccountForm
        isEditMode={Object.keys(showNewAccountForm.record).length !== 0}
        record={showNewAccountForm.record}
        cancelButton={closeShowNewAccountForm}
        triggerTableRefresh={triggerTableRefresh}
      />
    </WebContentContainerWrapper>
  ) : (
    <WebContentContainerWrapper title={config.Title} loading={isLoading}>
      <View style={commonStatisticButtonStyle(theme).statisticContainer}>
        {config.STATISITICS_ARR.map((item) => {
          return (
            <Statistics
              key={item.value}
              label={item.label}
              value={tableData.length || 0}
            />
          );
        })}
        <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
          <CustomButton
            text={config.CREATE_BTN_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={() => {
              openShowNewAccountForm({});
            }}
          />
          <CustomButton
            text={DOWNLOAD_CSV_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={onClickDownloadCSV}
          />
        </View>
      </View>
      <Searchbar
        placeholder={config.SEARCH_PLACEHOLDER}
        onChangeText={onChangeSearch}
        value={searchQuery}
        style={commonStatisticButtonStyle(theme, 522).searchBar}
        inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
      />
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
      <CustomModal
        title={showDetailInformationTable.tableTitle}
        visible={showDetailInformationTable.show}
        hideModal={closeShowDetailInformationTable}
      >
        <View style={styles.modelContainer}>
          {TABLE_IDS.CLIENT_TABLE_ID === showDetailInformationTable.tableID && (
            <ClientsTable
              data={showDetailInformationTable.data}
              canSelect={false}
            />
          )}
          {TABLE_IDS.ARTIST_TABLE_ID === showDetailInformationTable.tableID && (
            <ArtistTable
              data={showDetailInformationTable.data}
              canSelect={false}
            />
          )}
          {TABLE_IDS.LABEL_TABLE_ID === showDetailInformationTable.tableID && (
            <LabelTable
              data={showDetailInformationTable.data}
              canSelect={false}
            />
          )}
        </View>
      </CustomModal>
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete this account with username ${showDeleteConfirmation?.record?.username}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default AccountsListingPage;

const styles = StyleSheet.create({
  modelContainer: { width: 1000 },
});
