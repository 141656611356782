export const TABS = {
  SOURCE: "Source",
  PLATFORM: "Platform",
  REPORTINGPERIOD: "Reporting Period",
};

export const config = {
  TITLE: "Royalties",
  SOURCE_SEARCH_PLACEHOLDER: "Search by Source",
  PLATFORM_SEARCH_PLACEHOLDER: "Search by Source/Platform/Reporting Period",
  REPORTING_PERIOD_PLACEHOLDER: "Search by Reporting Period",
};

export const statistics_labels = {
  TOTAL_EUR: "Total EUR",
  TOTAL_USD: "Total USD",
  TOTAL_GBP: "Total GBP",
};
