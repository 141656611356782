import axios from "axios";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { withTheme } from "react-native-paper";
import { commonFormStyle, CustomButton, Loader } from "../../../../components";
import { FormControlInput } from "../../../../components/FormControl";
import MessageValidation from "../../../../components/MessageValidation";
import { LABELS_URL } from "../../../../constants/GlobalUrls";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  MESSAGE_TYPE,
} from "../../../../constants/GlobalVariables";
import useAxios from "../../../../hooks/useAxios";
import { checkObjectIsEmpty } from "../../../../utils/checkObjectIsEmpty";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const LabelForm = ({ theme, cancelButton, submitSuccess }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [formData, setFormData] = useState({ labelname: "" });
  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let isEmpty = checkObjectIsEmpty(formData);
    if (isEmpty) {
      const handleSuccessResponse = (res) => {
        if (res?.msg) {
          alert(res.msg);
        }
        submitSuccess();
      };

      const handleErrorResponse = (error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      };
      await api
        .post(LABELS_URL, formData, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          handleSuccessResponse(res.data);
        })
        .catch((error) => {
          setIsLoading(false);
          handleErrorResponse(error);
        });
    } else {
      setIsLoading(false);
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <View style={commonFormStyle(theme).modalFormContainer}>
      <Loader loading={isLoading} />
      <FormControlInput
        required
        label="Label Name"
        name="labelname"
        onChange={onChange}
        value={formData.labelname}
        isValidated={message.show && !formData.labelname}
      />
      <MessageValidation {...message} />

      <View style={commonFormStyle(theme).actionBtnContainer}>
        <CustomButton
          onPress={onSubmit}
          text={BUTTON_TEXTS.SUBMIT}
          cutomButtonStyle={commonFormStyle(theme).submitBtn}
        />
        <CustomButton
          text={BUTTON_TEXTS.CANCEL}
          onPress={cancelButton}
          cutomButtonStyle={commonFormStyle(theme).backBtn}
        />
      </View>
    </View>
  );
};

export default withTheme(LabelForm);
