import React, { useState } from "react";
import AccountsListingPage from "./AccountsListingPage/AccountsListingPage";
import AccountEditPage from "./AccountEditPage/AccountEditPage";

const Accounts = () => {
  const [showPage, setShowPage] = useState({
    landing: true,
    accountLinkPage: false,
  });
  const [recordData, setRecordData] = useState({});

  const changeShowPage = (pageName) => {
    setShowPage({
      landing: false,
      accountLinkPage: false,
      [pageName]: true,
    });
  };

  const getRecordData = (record) => {
    setRecordData({ ...record });
  };

  return (
    <>
      {showPage.landing ? (
        <AccountsListingPage
          changeShowPage={changeShowPage}
          getRecordData={getRecordData}
        />
      ) : (
        <AccountEditPage
          recordData={recordData}
          changeShowPage={changeShowPage}
        />
      )}
    </>
  );
};

export default Accounts;
