import { StyleSheet, Text, View } from "react-native";
import React, { useContext, useState } from "react";
import { useTheme } from "react-native-paper";
import { getLegendTextColor } from "./getLegendTextColorCode";
import AdjustmentContextAPI from "./AdjustmentContextAPI";
import { formatCurrency } from "../../../utils/formatCurrency";

const ComparisonMatrix = ({ selectActiveTab }) => {
  let theme = useTheme();

  let {
    state: { compareMatrixData },
  } = useContext(AdjustmentContextAPI);

  const [heightOfTheDropdown, setHeightOfTheDropdown] = useState({
    width: 0,
    height: 0,
  });

  const onLayout = (event) => {
    const { height, width } = event.nativeEvent.layout;
    setHeightOfTheDropdown({ width, height });
  };

  const getTextColor = (index, text) => {
    if (index === 0) {
      return theme.colors.legacyInvoiceText;
    } else if (index === 1) {
      return theme.colors.black;
    } else {
      return getLegendTextColor(text, theme);
    }
  };

  const getLegend = (backgroundColor, text) => (
    <View style={styles(theme).legendContainer}>
      <View style={styles(theme, backgroundColor).legendColorBox} />
      <View>
        <Text selectable>{text}</Text>
      </View>
    </View>
  );

  const getRow = (index, currency, valuesArr) => (
    <View style={styles(theme).rowContainer}>
      {index === 0 ? (
        <View
          style={
            styles(theme, null, heightOfTheDropdown.width).currencyFirstRow
          }
        >
          <Text
            selectable
            numberOfLines={1}
            style={styles(theme).currencyFirstRowText}
          >
            {currency}
          </Text>
        </View>
      ) : (
        <View style={styles(theme).valueContainerRow} onLayout={onLayout}>
          {valuesArr.map((el, index) => {
            return (
              <Text
                selectable
                key={index}
                numberOfLines={1}
                style={
                  styles(theme, null, null, getTextColor(index, el))
                    .commonRowText
                }
              >
                {`${
                  typeof el === "string" && el.charAt(0) === "+" ? "+" : ""
                }${formatCurrency(el)}` || " "}
              </Text>
            );
          })}
        </View>
      )}
    </View>
  );

  return (
    <>
      <View style={styles(theme).radioGroupContainer}>{selectActiveTab}</View>
      <View style={styles(theme).matrixTopContainer}>
        <View style={styles(theme).matrixTableContainer}>
          {compareMatrixData.length > 0 &&
            compareMatrixData.map((item, index) => {
              return (
                <View key={index} style={styles(theme).matrixRowContainer}>
                  <View style={styles(theme).matrixFirstHeaderContainer}>
                    <View>
                      <Text
                        numberOfLines={1}
                        style={styles(theme).matrixHeaderRow}
                      >
                        {item?.reportingPeriod}
                      </Text>
                    </View>
                  </View>
                  {getRow(index, "EUR", item?.EUR)}
                  {getRow(index, "USD", item?.USD)}
                  {getRow(index, "GBP", item?.GBP)}
                </View>
              );
            })}
        </View>
        <View style={styles(theme).legendWrapperForSpacing}>
          {getLegend(theme.colors.legacyInvoiceText, "Legacy invoices")}
          {getLegend(theme.colors.black, "Generated invoices")}
          {getLegend(theme.colors.paidLessText, "Paid less")}
          {getLegend(theme.colors.error, "Paid more")}
          {getLegend(theme.colors.primary, "No difference")}
        </View>
      </View>
    </>
  );
};

export default ComparisonMatrix;

const styles = (theme, backgroundColor, rowWidth, color) =>
  StyleSheet.create({
    legendContainer: { flexDirection: "row", alignItems: "center", gap: 5 },
    legendColorBox: { width: 12, height: 12, backgroundColor: backgroundColor },
    rowContainer: {
      flexDirection: "row",
      borderBottomWidth: 2,
      paddingVertical: 10,
      borderBottomColor: theme.colors.divider,
    },
    currencyFirstRow: {
      flex: 1,
      height: 51,
      width: rowWidth,
      justifyContent: "center",
      alignItems: "center",
    },
    currencyFirstRowText: {
      fontSize: 14,
      color: theme.colors.datatableTitleText,
      fontFamily: theme.customFonts.bold,
    },
    commonRowText: {
      fontSize: 14,
      color: color,
    },
    radioGroupContainer: { flex: 1, alignSelf: "flex-end", marginTop: 14 },
    matrixTopContainer: { marginTop: 20 },
    matrixTableContainer: { flexDirection: "row" },
    matrixRowContainer: {
      flex: 1,
      width: "auto",
    },
    matrixFirstHeaderContainer: {
      flexDirection: "row",
      borderBottomWidth: 2,
      paddingVertical: 10,
      borderBottomColor: theme.colors.divider,
    },
    matrixHeaderRow: {
      fontSize: 14,
      color: theme.colors.datatableTitleText,
      fontFamily: theme.customFonts.bold,
    },
    legendWrapperForSpacing: { gap: 10, marginTop: 15 },
    valueContainerRow: {
      height: 51,
      justifyContent: "center",
    },
  });
