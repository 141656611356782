import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import TopMenu from "../../components/TopMenu/TopMenu";
import {
  MENU_KEYS,
  productDetailsMenuOptions,
  productMenuOptions,
} from "./products.config";
import { CommonLayoutWrapper } from "../../components";
import ProductListing from "./ProductListing/ProductListing";
import ProductDetail from "./ProductDetail";

const Products = () => {
  const [listingPageActive, setListingPageActive] = useState({
    show: true,
    obj: {},
  });

  const updateProductDetailObj = (obj = {}) => {
    setListingPageActive({
      show: Object.keys(obj).length === 0,
      obj: obj,
    });
  };

  return (
    <View style={styles.container}>
      <TopMenu
        title={listingPageActive.show ? "Products" : "Product Details"}
        menuOptions={
          listingPageActive.show
            ? productMenuOptions
            : productDetailsMenuOptions
        }
        activeMenu={
          listingPageActive.show
            ? MENU_KEYS.PRODUCTS
            : MENU_KEYS.PRODUCT_DETAILS
        }
        singleOption
        handleBackBtnClick={
          listingPageActive.show
            ? false
            : () => {
                updateProductDetailObj();
              }
        }
      />
      <CommonLayoutWrapper marginTop={20}>
        {!!listingPageActive.show ? (
          <ProductListing updateProductDetailObj={updateProductDetailObj} />
        ) : (
          <ProductDetail selectedProduct={listingPageActive.obj} />
        )}
      </CommonLayoutWrapper>
    </View>
  );
};

export default Products;

const styles = StyleSheet.create({
  container: { flex: 1 },
});
