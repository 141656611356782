import { SORTDIRECTION } from "../components/CommonDataTable/commonDataTable.config";

export const sortDynamic = (key, order = SORTDIRECTION.ascending) => {
  const sortOrder = order === SORTDIRECTION.ascending ? 1 : -1;
  return (a, b) => {
    const A = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const B = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    if (A < B) {
      return sortOrder * -1;
    } else if (A > B) {
      return sortOrder * 1;
    } else {
      return 0;
    }
  };
};
