import { View } from "react-native";
import React, { useState } from "react";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import { Searchbar, useTheme } from "react-native-paper";
import { config } from "./royalties.config";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { formatCurrency } from "../../../utils/formatCurrency";

const PlatformTable = ({ data, selectActiveTab }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  let columns = [
    {
      key: "source_name",
      title: "Source",
      dataIndex: "source_name",
      sort: true,
      filter: true,
    },
    {
      key: "store_name",
      title: "Platform",
      dataIndex: "store_name",
      sort: true,
      filter: true,
    },
    {
      key: "reporting_period_name",
      title: "Reporting Period",
      dataIndex: "reporting_period_name",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "AccountingPeriod",
      title: "Accounting Period",
      dataIndex: "AccountingPeriod",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "total_eur",
      title: "Gross EUR",
      dataIndex: "total_eur",
      sort: true,
      total: true,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
    {
      key: "total_usd",
      title: "Gross USD",
      dataIndex: "total_usd",
      sort: true,
      total: true,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
    {
      key: "total_gbp",
      title: "Gross GBP",
      dataIndex: "total_gbp",
      sort: true,
      total: true,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.PLATFORM_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        showFooterTotal
        tableMaxHeight={420}
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default PlatformTable;
