import { StyleSheet } from "react-native";

export const customCardsStyle = (theme, leaveMarginRight) =>
  StyleSheet.create({
    buttonWrapper: {
      flex: 1,
      marginRight: leaveMarginRight ? 20 : 0,
      marginBottom: 10,
      backgroundColor: theme.colors.customCardColor,
      borderRadius: 10,
    },
    container: {
      backgroundColor: theme.colors.customCardColor,
      marginRight: leaveMarginRight ? 20 : 0,
      flex: 1,
    },
    content: {
      height: 75,
      justifyContent: "center",
      paddingHorizontal: 0,
      alignItems: "flex-start",
    },
    iconTitleCard: { fontFamily: theme.customFonts.semiBold, fontSize: 16 },
    titleCard: { fontFamily: theme.customFonts.bold, fontSize: 18 },
    subtitleCard: { fontFamily: theme.customFonts.regular, fontSize: 14 },
  });
