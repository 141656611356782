import React, { useContext } from "react";
import LandingPage from "./LandingPage";
import EmployeeAccountContextAPI from "./Reducer/EmployeeContextAPI";
import EmployeeAccountForm from "./EmployeeAccountForm";

const EmployeeAccountConsumer = () => {
  let {
    state: { isLandingPage },
  } = useContext(EmployeeAccountContextAPI);

  return <>{isLandingPage ? <LandingPage /> : <EmployeeAccountForm />}</>;
};

export default EmployeeAccountConsumer;
