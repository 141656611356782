import React, { useState } from "react";
import { ContractFormContext } from "../../../context/ContractFormContextAPI";
import ContractManagerDealForm from "../ContractManager/ContractManagerDealForm/ContractManagerDealForm";
import DealListingPage from "./DealListingPage/DealListingPage";

const Deals = () => {
  const [showListingPage, setShowListingPage] = useState(true);
  const [recordData, setRecordData] = useState({});
  const hideShowListinPage = () => {
    setShowListingPage(!showListingPage);
  };

  const getRecordData = (record) => {
    setRecordData(record);
    hideShowListinPage();
  };

  const checkObjectHasEmptyKeys = () => {
    return Object.keys(recordData).length !== 0;
  };

  return !!showListingPage ? (
    <DealListingPage getRecordData={getRecordData} />
  ) : (
    <ContractFormContext>
      <ContractManagerDealForm
        isEditMode={checkObjectHasEmptyKeys()}
        changeShowPage={hideShowListinPage}
        recordData={recordData}
        dealDashboard={true}
      />
    </ContractFormContext>
  );
};

export default Deals;
