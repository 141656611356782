import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Feather } from "@expo/vector-icons";
import { useTheme } from "react-native-paper";

const NoData = ({ text = "No Data" }) => {
  let theme = useTheme();
  return (
    <View style={styles.contentWrapper}>
      <View style={styles.iconWrapper}>
        <Feather name="box" size={24} color={theme.colors.black} />
      </View>

      <Text>{text}</Text>
    </View>
  );
};

export default NoData;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    padding: 20,
    alignItems: "center",
  },
  iconWrapper: { alignSelf: "center" },
});
