import { StyleSheet, Text, View } from "react-native";
import React, { Fragment, useState } from "react";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { Searchbar, useTheme } from "react-native-paper";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import { config } from "./overlappingDspDeal.config";

const LabelTable = ({ data, selectActiveTab, getRecordData }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
    },
    {
      key: "label_name",
      title: "Label Name",
      dataIndex: "label_name",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "data",
      title: "Deal IDs",
      dataIndex: "id",
      customToolTip: (id, record) => {
        return record?.data.length > 0
          ? record.data.map((el, index) => {
              return (
                <Fragment key={index}>
                  {index === 0 && "["}
                  <Text
                    onPress={() => {
                      getRecordData({ id: el.contract_id });
                    }}
                  >
                    {el.deal_id}
                  </Text>
                  {index === record?.data.length - 1 ? "]" : ", "}
                </Fragment>
              );
            })
          : "";
      },
      render: (id, record) =>
        record?.data.length > 0
          ? record.data.map((el, index) => {
              return (
                <Fragment key={index}>
                  {index === 0 && "["}
                  <Text
                    style={commonTableStyles(theme).linkText}
                    onPress={() => {
                      getRecordData({ id: el.contract_id });
                    }}
                  >
                    {el.deal_id}
                  </Text>
                  {index === record?.data.length - 1 ? "]" : ", "}
                </Fragment>
              );
            })
          : "",
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.LABEL_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default LabelTable;

export const commonTableStyles = (theme) =>
  StyleSheet.create({
    linkText: { color: theme.colors.linkColor },
  });
