import React, { useContext, useState } from 'react';
import { StyleSheet, View, Text, Dimensions } from 'react-native';
import { useTheme } from 'react-native-paper';
import PaymentContextAPI from './Reducer/PaymentsContextAPI';
import { ACTION_BUTTON_TYPE } from '../../../constants/GlobalVariables';
import { breadCrumbStyle } from '../../../components/BreadCrumb/breadCrumbStyle';
import { ActionsButtonIcons, CommonDataTable, commonStatisButtonCustomStyle, CustomButton, CustomModal } from '../../../components';
import AddInvoiceToPaymentPopUp from './AddInvoiceToPaymentPopUp';
import InvoicesDataContextAPI from '../InvoicesData/InvoicesDataContextAPI';
import { config } from './payment.config';
import { formatCurrency } from '../../../utils/formatCurrency';

// import adminNavigationString from "../../../navigation/AppStack/AdminAppDrawer/adminNavigationString";
const screen = Dimensions.get("window");
const { width } = screen;

const InvoiceDetailForPaymentTable = ({ pageName }) => {
    let theme = useTheme();
    let {
        state: { paymentInvoiceDetailTable, selectedRowData, existingInvoiceList },
        getInvoiceDetailsTableData,
    } = useContext(PaymentContextAPI);

    let {
        getInvoiceByID,
    } = useContext(InvoicesDataContextAPI);

    const [showInvoices, setShowInvoices] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const onClickCloseShowInvoice = () => {
        setShowInvoices(false)
        setSelectedRows([])
        setTableData([])
    }

    const onSubmitSuccessful = () => {
        getInvoiceDetailsTableData();
        onClickCloseShowInvoice();
    }

    let columns = [
        {
            key: "invoiceid",
            title: "ID",
            dataIndex: "invoiceid",
            sort: true,
            search: true,
            width: 1.5,
            // bold: true,
        },
        {
            key: "clientname",
            title: "Client Name",
            dataIndex: "clientname",
            sort: true,
            search: true,
        },
        {
            key: "service",
            title: "Service",
            dataIndex: "service",
            sort: true,
        },

        {
            key: "fromfield",
            title: "From",
            dataIndex: "fromfield",
            sort: true,
            filter: true,
        },
        {
            key: "to",
            title: "To",
            dataIndex: "to",
            sort: true,
            filter: true,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            filter: true,
        },
        {
            key: "gross",
            title: "Gross",
            dataIndex: "gross",
            sort: true,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "net",
            title: "Net",
            dataIndex: "net",
            sort: true,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            sort: true,
            filter: true,
        },
        {
            key: "reportversion",
            title: "Report Version",
            dataIndex: "reportversion",
            sort: true,
            filter: true,
        }
    ];

    let paymentColumns = [...columns, {
        key: "invoiceid",
        title: "View",
        dataIndex: "invoiceid",
        center: true,
        // hideToolTip: true,
        width: 1,
        customToolTip: () => {
            return "View invoice detail";
        },
        render: (id, record) => {
            return (
                <ActionsButtonIcons
                    buttonType={ACTION_BUTTON_TYPE.FILE_SEARCH}
                    handleOnPress={() => {
                        getInvoiceByID(id);
                    }}
                />
            );
        },
    },]

    return (
        <View style={{ marginVertical: 36, gap: 15 }}>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Text style={breadCrumbStyle(theme).titleLink}>{`Related Invoices (${paymentInvoiceDetailTable.length})`}</Text>
                <CustomButton
                    text={"Add Invoice To Payment"}
                    cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
                    textStyle={commonStatisButtonCustomStyle(theme).text}
                    onPress={() => { setShowInvoices(true) }}
                />
            </View>
            <CommonDataTable
                canFilterColumns
                columns={pageName === config.TITLE ? paymentColumns : columns}
                data={paymentInvoiceDetailTable}
                tableMaxHeight={530}
            />
            <CustomModal
                title={`Add Invoice to Payment (${selectedRows.length} - ${tableData.length})`}
                visible={showInvoices}
                hideModal={onClickCloseShowInvoice}
            >
                <View style={styles(theme).modelContainer}>
                    <AddInvoiceToPaymentPopUp
                        selectedRowData={selectedRowData}
                        existingInvoiceList={existingInvoiceList}
                        onSubmitSuccessful={onSubmitSuccessful}
                        onClickCloseShowInvoice={onClickCloseShowInvoice}
                        tableData={tableData}
                        selectedRows={selectedRows}
                        setTableData={setTableData}
                        setSelectedRows={setSelectedRows}
                    />
                </View>
            </CustomModal>

        </View>
    );
}

const styles = (theme) =>
    StyleSheet.create({
        modelContainer: { width: width - 150 },
    });


export default InvoiceDetailForPaymentTable;
