import { View } from "react-native";
import React, { useContext } from "react";
import {
  CustomButton,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import {
  BUTTON_RELEASE_TEXT,
  STATISTICS_DATA,
  TABS,
  config,
} from "./catalogue.config";
import FormRadioGroup from "../../../components/FormControl/FormRadio/FormRadioGroup";
import CatalogueContextAPI from "./Reducer/CatalogueContextAPI";
import FormRadioButton from "../../../components/FormControl/FormRadio/FormRadioButton";
import { CHANGE_ACTIVE_TAB, LOADER } from "./Reducer/actions";
import { accountEditTableStyle } from "../Accounts/AccountEditPage/AccountEditTables";
import {
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import ReleaseTable from "./ReleaseTable";
import TrackTable from "./TrackTable";
import {
  DOWNLOAD_RELEASE_URL,
  DOWNLOAD_TRACK_URL,
} from "../../../constants/GlobalUrls";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";
import AuthContextAPI from "../../../context/AuthContextAPI";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";

let uuid = require("uuid");

const Catalogue = () => {
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  let theme = useTheme();

  let {
    state: {
      activeTab,
      isLoading,
      releaseTableData,
      trackTableData,
      available_upcs,
      available_isrcs,
    },
    dispatch,
  } = useContext(CatalogueContextAPI);

  const { logoutUser } = useContext(AuthContextAPI);

  const onTabChange = (tab) => {
    dispatch({ type: CHANGE_ACTIVE_TAB, payload: tab });
  };

  const onClickDownloadCSV = async () => {
    dispatch({ type: LOADER, payload: true });
    let URL =
      activeTab === TABS.RELEASES ? DOWNLOAD_RELEASE_URL : DOWNLOAD_TRACK_URL;
    await api
      .get(URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `${activeTab}_${uuid.v4()}.csv`);
        tempLink.click();
        dispatch({ type: LOADER, payload: false });
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const extraHeaderComponent = (
    <View style={commonStatisticButtonStyle(theme).statisticContainer}>
      {activeTab === TABS.RELEASES && (
        <>
          <Statistics
            label={STATISTICS_DATA.TOTAL_RELEASES}
            value={releaseTableData.length || 0}
          />
          <Statistics
            label={STATISTICS_DATA.AVAILABLE_UPCS}
            value={available_upcs}
          />
        </>
      )}
      {activeTab === TABS.TRACK && (
        <>
          <Statistics
            label={STATISTICS_DATA.TOTAL_TRACKS}
            value={trackTableData.length || 0}
          />
          <Statistics
            label={STATISTICS_DATA.AVAILABLE_ISRCS}
            value={available_isrcs}
          />
        </>
      )}
      <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
        {activeTab === TABS.RELEASES && (
          <CustomButton
            disabled
            text={BUTTON_RELEASE_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            // onPress={() => {
            //   openShowNewAccountForm({});
            // }}
          />
        )}
        <CustomButton
          text={DOWNLOAD_CSV_TEXT}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={() => {
            onClickDownloadCSV();
          }}
        />
      </View>
    </View>
  );

  let selectActiveTab = (
    <FormRadioGroup
      inlineLabel
      label={"Table:"}
      value={activeTab}
      onChange={(name, value) => {
        onTabChange(value);
      }}
    >
      <View style={accountEditTableStyle.radioButtonWrapper}>
        <FormRadioButton label={TABS.RELEASES} value={TABS.RELEASES} />
        <FormRadioButton label={TABS.TRACK} value={TABS.TRACK} />
      </View>
    </FormRadioGroup>
  );
  return (
    <WebContentContainerWrapper
      loading={isLoading}
      title={config.TITLE}
      extraHeaderComponent={extraHeaderComponent}
    >
      {activeTab === TABS.RELEASES ? (
        <ReleaseTable
          data={releaseTableData}
          selectActiveTab={selectActiveTab}
        />
      ) : (
        <></>
      )}
      {activeTab === TABS.TRACK ? (
        <TrackTable data={trackTableData} selectActiveTab={selectActiveTab} />
      ) : (
        <></>
      )}
    </WebContentContainerWrapper>
  );
};

export default Catalogue;
