import { View, StyleSheet, Image } from "react-native";
import React from "react";
import { LinearGradient } from "expo-linear-gradient";
import { withTheme } from "react-native-paper";

const DetailCard = ({ children, theme }) => {
  return (
    <View style={styles.container}>
      <Image
        source={
          theme.dark
            ? require("../../assets/images/card_top_mask_dark.png")
            : require("../../assets/images/card_top_mask_light.png")
        }
        style={styles.topImage}
      />

      <LinearGradient
        // Linear Gradient
        colors={["#02ffa6", "#02ffdd", "#14d0ff", "#ceec33"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        // locations={[0.1, 0.3, 0.1, 0.5]}
        style={styles.reactangleBody}
      >
        {children}
      </LinearGradient>
    </View>
  );
};

export default withTheme(DetailCard);

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    width: "100%",
    borderRadius: 20,
    flex: 1,
  },
  topImage: {
    width: 150,
    height: 13,
    position: "absolute",
    zIndex: 1,
    alignSelf: "center",
  },
  reactangleBody: {
    width: "100%",
    borderRadius: 20,
  },
});
