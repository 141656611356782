import React, { useContext, useState } from "react";
import OverlappingDspDealContextAPI from "./Reducer/OverlappingDspDealContextAPI";
import LandingPage from "./LandingPage";
import { CHANGE_SHOW_PAGE } from "./Reducer/actions";
import ContractManagerForm from "../ContractManager/ContractManagerForm/ContractManagerForm";
import ContractManagerDealForm from "../ContractManager/ContractManagerDealForm/ContractManagerDealForm";
import { ContractFormContext } from "../../../context/ContractFormContextAPI";
import { config as contractmanagerConfig } from "../ContractManager/ContractManager.config";
import { config } from "./overlappingDspDeal.config";

const OverlappingDspDealComsumer = () => {
  let {
    state: { landing, contractForm, dealForm },
    dispatch,
  } = useContext(OverlappingDspDealContextAPI);

  const [recordData, setRecordData] = useState({});
  const [dealRecordData, setDealRecordData] = useState({});

  const changeShowPage = (pageName) => {
    dispatch({ type: CHANGE_SHOW_PAGE, payload: pageName });
  };

  const getDealRecordData = (record) => {
    setDealRecordData({ ...record });
  };

  const getRecordData = (record) => {
    setRecordData({ ...record });
    dispatch({ type: CHANGE_SHOW_PAGE, payload: contractmanagerConfig.pages.CONTRACT_FORM });
  };

  const checkObjectHasEmptyKeysForContract = () => {
    return Object.keys(recordData).length !== 0;
  };

  const checkObjectHasEmptyKeysForDeals = () => {
    return Object.keys(dealRecordData).length !== 0;
  };

  return (
    <>
      {landing && <LandingPage getRecordData={getRecordData} />}
      {(contractForm || dealForm) && (
        <ContractFormContext>
          {contractForm ? (
            <ContractManagerForm
              isEditMode={checkObjectHasEmptyKeysForContract()}
              changeShowPage={changeShowPage}
              recordData={recordData}
              getDealRecordData={getDealRecordData}
              hasDealFormPrefillData={false}
              landingPageTitle={config.TITLE}
            />
          ) : (
            <ContractManagerDealForm
              isExistingContractForm={checkObjectHasEmptyKeysForContract()}
              isEditMode={checkObjectHasEmptyKeysForDeals()}
              changeShowPage={changeShowPage}
              recordData={dealRecordData}
              dealFormPrefillData={[]}
              setDealFormPrefillData={() => {}}
              hasDealFormPrefillData={false}
              landingPageTitle={config.TITLE}
            />
          )}
        </ContractFormContext>
      )}
    </>
  );
};

export default OverlappingDspDealComsumer;
