import React from "react";
import { View } from "react-native";
import { ActivityIndicator, withTheme } from "react-native-paper";
import { loaderStyle } from "./loaderStyle";

const Loader = ({ loading, theme, backgroundColor, transparent=false }) => {
  const { colors } = theme;
  return (
    !!loading && (
      <View style={loaderStyle(theme, backgroundColor, transparent)}>
        <ActivityIndicator
          animating={loading}
          color={colors.actionIconActive}
          size="large"
        />
      </View>
    )
  );
};

export default withTheme(Loader);
