import React from "react";
import { View } from "react-native";
import { withTheme } from "react-native-paper";
import { ACTION_BUTTON_TYPE } from "../../constants/GlobalVariables";
import {
  MaterialIcons,
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
  AntDesign,
  Feather,
} from "@expo/vector-icons";

const ActionsButtonIcons = ({
  buttonType,
  theme,
  handleOnPress = () => {},
  active = true,
  customStyle = {},
  size = 16,
  borderRadius = 5,
}) => {
  const getBackgroudColor = () => {
    // if (!active) {
    //   return theme.colors.disableBtnIcon;
    // }
    switch (buttonType) {
      case ACTION_BUTTON_TYPE.DELETE:
      case ACTION_BUTTON_TYPE.REMOVE:
        return theme.colors.deleteBtnIcon;
      case ACTION_BUTTON_TYPE.EDIT:
      case ACTION_BUTTON_TYPE.FILE_SEARCH:
      case ACTION_BUTTON_TYPE.VIEW:
        return theme.colors.editBtnIcon;
      case ACTION_BUTTON_TYPE.ADD:
        return theme.colors.activeBtnIcon;
      case ACTION_BUTTON_TYPE.LINK:
        return theme.colors.linkBtnIcon;
      case ACTION_BUTTON_TYPE.CLOUD_DOWNLOAD:
      case ACTION_BUTTON_TYPE.DOWNLOAD_PDF:
        return theme.colors.cloudDownloadIcon;
      case ACTION_BUTTON_TYPE.DOWNLOAD_EXCEL:
        return theme.colors.excelSheetIcon;
      case ACTION_BUTTON_TYPE.VIEW_MORE:
        return theme.colors.avatarBackground;
      default:
        return theme.colors.activeBtnIcon;
    }
  };
  const getIcon = () => {
    switch (buttonType) {
      case ACTION_BUTTON_TYPE.ADD:
      case ACTION_BUTTON_TYPE.REMOVE:
        return (
          <FontAwesome
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.textIconBtn}
          />
        );
      case ACTION_BUTTON_TYPE.CLOUD_DOWNLOAD:
        return (
          <FontAwesome5
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.textIconBtn}
          />
        );
      case ACTION_BUTTON_TYPE.DOWNLOAD_PDF:
        return (
          <FontAwesome5
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.textIconBtn}
          />
        );
      case ACTION_BUTTON_TYPE.DOWNLOAD_EXCEL:
      case ACTION_BUTTON_TYPE.FILE_SEARCH:
        return (
          <MaterialCommunityIcons
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.textIconBtn}
          />
        );
      case ACTION_BUTTON_TYPE.VIEW:
        return (
          <AntDesign
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.textIconBtn}
          />
        );
      case ACTION_BUTTON_TYPE.VIEW_MORE:
        return (
          <Feather
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.actionIconInActive}
          />
        );
      default:
        return (
          <MaterialIcons
            disabled={!active}
            onPress={active && handleOnPress}
            name={buttonType}
            size={size}
            color={theme.colors.textIconBtn}
          />
        );
    }
  };

  return (
    <View
      style={
        size === 16
          ? {
              opacity: active ? 1 : 0.4,
              backgroundColor: getBackgroudColor(),
              borderRadius: borderRadius,
              // paddingHorizontal: 5,
              // paddingVertical: 5,
              height: 24,
              width: 24,
              justifyContent: "center",
              alignItems: "center",
              ...customStyle,
            }
          : {
              opacity: active ? 1 : 0.4,
              backgroundColor: getBackgroudColor(),
              borderRadius: borderRadius,
              paddingHorizontal: 5,
              paddingVertical: 5,
              // height: size,
              // width: size,
              justifyContent: "center",
              alignItems: "center",
              ...customStyle,
            }
      }
    >
      {getIcon()}
    </View>
  );
};

export default withTheme(ActionsButtonIcons);
