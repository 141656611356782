import { Text } from "react-native";
import React from "react";
import { customStyle } from "./customStyle";
import { withTheme } from "react-native-paper";

const BoldText14 = ({ text, theme }) => {
  return <Text style={customStyle(theme).boldText14}>{text}</Text>;
};

export default withTheme(BoldText14);
