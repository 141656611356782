import { View } from "react-native";
import React, { useState } from "react";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { updateSelectedRow } from "../Accounts/AccountEditPage/updateSelectedRow";
import { Checkbox, Searchbar, useTheme } from "react-native-paper";
import { config } from "./missingDspDeals.config";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";

const LabelTable = ({ data, selectActiveTab, onChangeSeletedRow }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  const onPressUpdateSelectedRow = (id) => {
    let finalData = updateSelectedRow(data, id, "id");
    onChangeSeletedRow(finalData);
  };

  let columns = [
    {
      key: "existing",
      title: "",
      dataIndex: "existing",
      width: 0.3,
      hideToolTip: true,
      render: (existing, record) => {
        return (
          <Checkbox
            status={existing ? "checked" : "unchecked"}
            onPress={() => {
              onPressUpdateSelectedRow(record?.id);
            }}
          />
        );
      },
    },
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "labelname",
      title: "Label Name",
      dataIndex: "labelname",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "start_reporting_period",
      title: "MIN From",
      dataIndex: "start_reporting_period",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "end_reporting_period",
      title: "MAX To",
      dataIndex: "end_reporting_period",
      sort: true,
      search: true,
      filter: true,
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.LABEL_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default LabelTable;
