import { View, Text } from "react-native";
import React from "react";
import ChartsDetailsCardList from "../TrendingCharts/SongCharts/ChartsDetailsCardList";

const TrendsTVStatsCard = () => {
  const DATA = [
    {
      headerTitle: "166.3K",
      title: "Spotify Charts",
      stat: "12.23%",
      line1: "1 total tracks",
      line2: "Peak position: 1",
    },
    {
      headerTitle: "231.4K",
      title: "Deezer Charts",
      stat: "12.23%",
      line1: "1 total tracks",
      line2: "Peak position: 1",
    },
  ];

  return (
    <View>
      <ChartsDetailsCardList data={DATA} />
    </View>
  );
};

export default TrendsTVStatsCard;
