import { StyleSheet } from "react-native";

export const commonHeaderStyle = (theme, size) =>
  StyleSheet.create({
    container: { marginVertical: 10, marginHorizontal: 15 },
    titleSubtitleImage: { flexDirection: "row", alignItems: "center" },
    imageContainer: { marginRight: 10 },
    // subtitleContainer: { marginTop: 5 },
    titleIconContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    icon: { width: 24, height: 24 },
    titleText: {
      color: theme.colors.text,
      fontSize: size,
      fontFamily: theme.customFonts.semiBold,
    },
  });
