import dayjs from "dayjs";

let currentMonth = dayjs().month(dayjs().get("month")).format("MMMM");

let previousMonth = dayjs()
  .month(dayjs().get("month") - 1)
  .format("MMMM");

let previous2Month = dayjs()
  .month(dayjs().get("month") - 2)
  .format("MMMM");

export const MENU_KEYS = {
  CHARTS: "charts",
  STATS: "stats",
  TV: "tv",
  RADIO: "radio",
};

export const menuOptions = [
  {
    key: MENU_KEYS.CHARTS,
    title: "Charts",
  },
  {
    key: MENU_KEYS.STATS,
    title: "Stats",
  },
  {
    key: MENU_KEYS.TV,
    title: "TV",
  },
  {
    key: MENU_KEYS.RADIO,
    title: "Radio",
  },
];

export const monthFilterList = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 Days",
    value: "last7Days",
  },
  {
    label: "Last 30 Days",
    value: "last30Days",
  },
  {
    label: "Last 90 Days",
    value: "last90Days",
  },
  {
    label: "Last 180 Days",
    value: "last180Days",
  },
  {
    label: currentMonth,
    value: currentMonth,
  },
  {
    label: previousMonth,
    value: previousMonth,
  },
  {
    label: previous2Month,
    value: previous2Month,
  },
];

export const selectAllItemData = [
  {
    trackid: 0,
    image: null,
    trackid__title: "All Tracks",
    trackid__displayartist: "Display information for all",
    released: "charted tracks in your catalog",
  },
];

export const channelOptions = [
  {
    label: "All Channels",
    value: "all",
  },
  {
    label: "TDI Radio",
    value: "tdi1",
  },
  {
    label: "Radio Impuls",
    value: "impuls1",
  },
  {
    label: "Antena Radio",
    value: "antenna1",
  },
  {
    label: "Radio Impuls",
    value: "impuls2",
  },
  {
    label: "Antena Radio",
    value: "antenna2",
  },
];
