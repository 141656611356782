import React from "react";
import { View, Text, ScrollView } from "react-native";
import { webContentContainerWrapperStyle } from "./webContentContainerWrapperStyle";
import { withTheme } from "react-native-paper";
import { BreadCrumb, Loader } from "..";

const WebContentContainerWrapper = ({
  title = "",
  breadCrumbData = [],
  children,
  theme,
  loading = false,
  actionButtons,
  extraHeaderComponent,
  loaderTransparent = false,
}) => {
  return (
    <View style={webContentContainerWrapperStyle(theme).container}>
      <Loader loading={loading} transparent={loaderTransparent} />
      {!!title && (
        <Text style={webContentContainerWrapperStyle(theme).titleText}>
          {title}
        </Text>
      )}
      {breadCrumbData.length > 0 && (
        <View
          style={
            webContentContainerWrapperStyle(theme).actionButtonHeaderWrapper
          }
        >
          <BreadCrumb breadCrumbData={breadCrumbData} />
          {actionButtons && actionButtons}
        </View>
      )}
      {extraHeaderComponent && <View>{extraHeaderComponent}</View>}
    <ScrollView>{children}</ScrollView>
    </View>
  );
};

export default withTheme(WebContentContainerWrapper);
