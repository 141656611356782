import { View } from "react-native";
import React, { useCallback, useContext } from "react";
import RoyaltiesContextAPI from "./Reducer/RoyaltiesContextAPI";
import {
  CustomButton,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import { TABS, config, statistics_labels } from "./royalties.config";
import FormRadioGroup from "../../../components/FormControl/FormRadio/FormRadioGroup";
import SourceTable from "./SourceTable";
import PlatformTable from "./PlatformTable";
import ReportingPeriodTable from "./ReportingPeriodTable";
import { accountEditTableStyle } from "../Accounts/AccountEditPage/AccountEditTables";
import { CHANGE_ACTIVE_TAB } from "./Reducer/actions";
import FormRadioButton from "../../../components/FormControl/FormRadio/FormRadioButton";
import { useTheme } from "react-native-paper";
import { DOWNLOAD_CSV_TEXT } from "../../../constants/GlobalVariables";
import { formatCurrency } from "../../../utils/formatCurrency";

const Royalties = () => {
  let {
    state: {
      activeTab,
      isLoading,
      sourceTableData,
      platformTableData,
      reportingPeriodTableData,
    },
    dispatch,
  } = useContext(RoyaltiesContextAPI);

  let theme = useTheme();

  const onTabChange = (tab) => {
    dispatch({ type: CHANGE_ACTIVE_TAB, payload: tab });
  };

  const getTotal = useCallback(
    (currency) => {
      let total = 0;
      if (activeTab === TABS.SOURCE) {
        total = sourceTableData.reduce(
          (acc, o) => acc + parseFloat(o[currency]),
          0
        );
      } else if (activeTab === TABS.PLATFORM) {
        total = platformTableData.reduce(
          (acc, o) => acc + parseFloat(o[currency]),
          0
        );
      } else {
        total = reportingPeriodTableData.reduce(
          (acc, o) => acc + parseFloat(o[currency]),
          0
        );
      }
      return formatCurrency(total);
    },
    [sourceTableData, activeTab]
  );

  const extraHeaderComponent = (
    <View style={commonStatisticButtonStyle(theme).statisticContainer}>
      {activeTab === TABS.SOURCE && (
        <Statistics label={TABS.SOURCE} value={sourceTableData.length || 0} />
      )}
      {activeTab === TABS.PLATFORM && (
        <Statistics
          label={TABS.PLATFORM}
          value={platformTableData.length || 0}
        />
      )}
      {activeTab === TABS.REPORTINGPERIOD && (
        <Statistics
          label={TABS.REPORTINGPERIOD}
          value={reportingPeriodTableData.length || 0}
        />
      )}
      <Statistics
        label={statistics_labels.TOTAL_EUR}
        value={getTotal("total_eur") || 0}
      />
      <Statistics
        label={statistics_labels.TOTAL_USD}
        value={getTotal("total_usd") || 0}
      />
      <Statistics
        label={statistics_labels.TOTAL_GBP}
        value={getTotal("total_gbp") || 0}
      />
      <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
        <CustomButton
          text={DOWNLOAD_CSV_TEXT}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          //   onPress={onClickDownloadCSV}
        />
      </View>
    </View>
  );

  let selectActiveTab = (
    <FormRadioGroup
      inlineLabel
      label={"Group by:"}
      value={activeTab}
      onChange={(name, value) => {
        onTabChange(value);
      }}
    >
      <View style={accountEditTableStyle.radioButtonWrapper}>
        <FormRadioButton label={TABS.SOURCE} value={TABS.SOURCE} />
        <FormRadioButton label={TABS.PLATFORM} value={TABS.PLATFORM} />
        <FormRadioButton
          label={TABS.REPORTINGPERIOD}
          value={TABS.REPORTINGPERIOD}
        />
      </View>
    </FormRadioGroup>
  );
  return (
    <WebContentContainerWrapper
      title={config.TITLE}
      loading={isLoading}
      extraHeaderComponent={extraHeaderComponent}
    >
      {activeTab === TABS.SOURCE ? (
        <SourceTable data={sourceTableData} selectActiveTab={selectActiveTab} />
      ) : (
        <></>
      )}
      {activeTab === TABS.PLATFORM ? (
        <PlatformTable
          data={platformTableData}
          selectActiveTab={selectActiveTab}
        />
      ) : (
        <></>
      )}
      {activeTab === TABS.REPORTINGPERIOD ? (
        <ReportingPeriodTable
          data={reportingPeriodTableData}
          selectActiveTab={selectActiveTab}
        />
      ) : (
        <></>
      )}
    </WebContentContainerWrapper>
  );
};

export default Royalties;
