import { StyleSheet, Dimensions } from "react-native";
const screen = Dimensions.get("window");
const { width } = screen;

export const landingPageCardInformationStyle = (theme) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      height: "fit-content",
    },
    imageContainer: {
      width: "100%",
      height: "100%",
      resizeMode: "contain",
      justifyContent: "center",
    },
    currentBalanceContainer: { alignItems: "center" },
    bottomDetailsContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 15,
    },
    titleText: {
      textTransform: "uppercase",
      fontSize: 12,
      fontFamily: theme.customFonts.regular,
    },
    amountText: {
      fontSize: 32,
      fontFamily: theme.customFonts.bold,
    },
    cardDetailsContainer: {
      flexDirection: "row",
      marginTop: 16,
      overflow: "hidden",
    },
    cardDetailsSubText: {
      fontSize: 20,
      fontFamily: theme.customFonts.semiBold,
    },
    cardDetailLeftContainer: {
      alignItems: "flex-end",
    },
  });
