import { StyleSheet } from "react-native";

export const webContentContainerWrapperStyle = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      padding: 16,
    },
    titleTextContainer: {
      marginVertical: 16,
    },
    titleText: {
      fontFamily: theme.customFonts.bold,
      fontSize: 30,
      color: theme.colors.text,
      marginVertical: 16,
    },
    actionButtonHeaderWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
