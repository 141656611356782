import { FlatList, StyleSheet, Text, View } from "react-native";
import React from "react";
import { customStyle } from "../CustomText/customStyle";

const GlobalHeatMapContent = ({ data, theme }) => {
  return (
    <View style={styles.container}>
      <FlatList
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled
        data={data}
        numColumns={2}
        renderItem={({ item, index }) => {
          return (
            <View
              style={{
                ...styles.itemContainer,
                marginRight: (index + 1) % 2 === 0 ? 0 : 30,
              }}
            >
              <View style={styles.countryTextValue}>
                <View style={{ flex: 0.7 }}>
                  <Text
                    style={customStyle(theme).chartLabelText}
                    ellipsizeMode="tail"
                    numberOfLines={1}
                  >
                    {item?.countryid__name}
                  </Text>
                </View>
                <View style={{ flex: 0.3, alignItems: "flex-end" }}>
                  <Text
                    style={customStyle(theme).heatMapValueText}
                    ellipsizeMode="tail"
                    numberOfLines={1}
                  >
                    {item?.total_listeners_value}
                  </Text>
                </View>
              </View>
            </View>
          );
        }}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
};

export default GlobalHeatMapContent;

const styles = StyleSheet.create({
  container: { flex: 2, height: 180 },
  itemContainer: {
    flex: 0.5,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  countryTextValue: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
});
