import { StyleSheet, Text, View } from "react-native";
import React, { useState, useEffect } from "react";
import CommonAvatar from "../../CommonAvatar/CommonAvatar";
import { customStyle } from "../../CustomText/customStyle";
import { withTheme } from "react-native-paper";
import SecondaryHeaderTextSemiBold from "../../CustomText/SecondaryHeaderTextSemiBold";
import InformationText from "../../CustomText/InformationText";
import { AntDesign } from "@expo/vector-icons";

let defaultData = {
  count: 45,
  title: "Spotify Charts",
  stat: "12.23%",
  line1: "1 total tracks",
  line2: "Peak position: 1",
};
const SongsChartsItemData = ({
  theme,
  index = null,
  samecategory = false,
  onlyBox = false,
  rightborder = false,
  data,
}) => {
  const [cardData, setCardData] = useState({});
  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && data) {
      setCardData(data);
    } else {
      setCardData(defaultData);
    }
    return () => {
      isMoulded = false;
    };
  }, [data]);

  return (
    Object.keys(cardData).length > 0 && (
      <View
        style={
          onlyBox
            ? styles(theme, rightborder, index).onlyBoxContainer
            : samecategory
            ? styles(theme, rightborder).belowTablecontainer
            : styles(theme).separateCategoryContianer
        }
      >
        <View style={styles(theme).headerContainer}>
          {!!!cardData?.headerTitle && (
            <CommonAvatar
              src={require("../../../../assets/images/spotify.jpg")}
              size={32}
              circleShape
            />
          )}
          <Text style={customStyle(theme).statisticTextSemiBold}>
            {cardData?.headerTitle || cardData?.count}
          </Text>
        </View>
        <View style={styles(theme).headerTextcontainer}>
          <SecondaryHeaderTextSemiBold text={cardData?.title} />
        </View>
        {cardData?.stat && (
          <View style={styles(theme).descriptionContainer}>
            <View style={styles(theme).iconContainer}>
              <AntDesign
                name="caretup"
                size={14}
                color={theme.colors.actionIconActive}
                style={styles(theme).icon}
              />
            </View>
            <InformationText text={cardData.stat} />
          </View>
        )}
        {cardData?.line1 && <InformationText text={cardData.line1} />}
        {cardData?.line2 && <InformationText text={cardData.line2} />}
      </View>
    )
  );
};

export default withTheme(SongsChartsItemData);

const styles = (theme, rightborder, index) =>
  StyleSheet.create({
    separateCategoryContianer: {
      flex: 1,
      backgroundColor: theme.colors.customCardColor,
      paddingVertical: 15,
      paddingHorizontal: 15,
      borderRadius: 10,
      justifyContent: "center",
    },
    belowTablecontainer: {
      flex: 1,
      backgroundColor: theme.colors.customCardColor,
      paddingVertical: 15,
      paddingHorizontal: 15,
      borderRightWidth: rightborder ? 1 : 0,
      borderColor: theme.colors.divider,
      paddingRight: rightborder ? 15 : 0,
      paddingLeft: rightborder ? 0 : 15,
    },
    onlyBoxContainer: {
      flex: 1,
      backgroundColor: theme.colors.customCardColor,
      paddingBottom: index === 0 || index === 1 ? 15 : 0,
      paddingTop: index === 2 || index === 3 ? 15 : 0,
      borderRightWidth: rightborder ? 1 : 0,
      borderColor: theme.colors.divider,
      paddingRight: rightborder ? 15 : 0,
      paddingLeft: rightborder ? 0 : 15,
    },
    headerContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerTextcontainer: {
      marginTop: 15,
      marginBottom: 5,
    },
    iconContainer: {
      marginRight: 8,
      justifyContent: "flex-end",
    },
    icon: { height: 14, width: 14 },
    descriptionContainer: {
      flexDirection: "row",
      // alignItems: "flex-end",
      textAlignVertical: "bottom",
    },
  });
