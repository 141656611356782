import { View } from "react-native";
import React, { useState, useContext } from "react";
import { FormLabel } from "../../../../components/FormControl";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonFormStyle,
  ConfirmationWindow,
  CustomModal,
  CustomProgressBar,
} from "../../../../components";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
} from "../../../../constants/GlobalVariables";
import UploadPDFForm from "./UploadPDFForm";
import ContractFormContextAPI from "../../../../context/ContractFormContextAPI";
import { deleteTableRecord } from "../../../../utils/deleteTableRecord";
import { TABLE_ACTION } from "../ContractManagerDealForm/ContractManagerDealForm.config";
import { DOWNLOAD_PDF_CONTRACT_URL } from "../../../../constants/GlobalUrls";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import { withTheme } from "react-native-paper";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const PDFTable = ({ theme }) => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const { formData, setFormData } = useContext(ContractFormContextAPI);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });
  const [showPDFForm, setShowPDFForm] = useState(false);
  const [fileDownloadProgress, setFileDownloadProgress] = useState({
    show: false,
    progress: 0,
  });

  const openShowPDFForm = () => {
    setShowPDFForm(true);
  };
  const closeShowPDFForm = () => {
    setShowPDFForm(false);
  };

  const showPdfDownloadProgress = () => {
    setFileDownloadProgress({ show: true, progress: 0 });
  };
  const hidePdfDownloadProgress = () => {
    setFileDownloadProgress({ show: false, progress: 0 });
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  const successAddPDF = (addPDF) => {
    setFormData({
      ...formData,
      uploaded_pdf: [...formData.uploaded_pdf, ...addPDF],
    });
    closeShowPDFForm();
  };

  const handleDelete = () => {
    let id = showDeleteConfirmation.record.id;
    let tempData = [...formData.uploaded_pdf];
    if (id === "New") {
      tempData = deleteTableRecord(
        formData.uploaded_pdf,
        showDeleteConfirmation.record
      );
    } else {
      let obj = tempData.find((item) => item.id === id);
      obj.action = TABLE_ACTION.delete;
    }
    setFormData({
      ...formData,
      uploaded_pdf: tempData,
    });
    closeDeleteConfirmationWindow();
  };

  const downloadPDF = async (record) => {
    let url = `${DOWNLOAD_PDF_CONTRACT_URL}${record.id}/`;

    const config = {
      "Content-Type": "application/pdf",
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted === 100) {
          hidePdfDownloadProgress();
        } else {
          setFileDownloadProgress({
            show: true,
            progress: percentCompleted,
          });
        }
      },
    };
    showPdfDownloadProgress();

    await api
      .get(url, config)
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const filename = formData.uploaded_pdf.find(
          ({ id }) => id === record.id
        )?.pdfpath;
        var pdfURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = pdfURL;
        tempLink.setAttribute("download", `${filename}`);
        tempLink.click();
      })
      .catch((error) => {
        checkNetworkReachable();
        hidePdfDownloadProgress();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  let columns = [
    {
      key: "id",
      title: "File ID",
      dataIndex: "id",
      width: 0.5,
    },
    {
      key: "pdfpath",
      title: "File Name",
      dataIndex: "pdfpath",
      render: (pdfpath, row) => {
        if (row.id === "New") {
          return row.name;
        }
        return pdfpath;
      },
    },
    {
      key: "pdftype",
      title: "File Type",
      dataIndex: "pdftype",
      width: 1,
    },
    {
      key: "pdfsize",
      title: "Size",
      dataIndex: "pdfsize",
      width: 0.6,
    },
    {
      key: ACTION_BUTTON_TYPE.DOWNLOAD,
      title: "DL",
      dataIndex: "id",
      width: 0.3,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        let active = true;
        if (id === "New") {
          active = false;
        }
        return (
          <ActionsButtonIcons
            active={active}
            buttonType={ACTION_BUTTON_TYPE.DOWNLOAD}
            handleOnPress={() => {
              downloadPDF(row);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.REMOVE,
      title: "Remove",
      dataIndex: ACTION_BUTTON_TYPE.REMOVE,
      width: 0.2,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.REMOVE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(row);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <FormLabel bold text={"PDF"} />
      <CommonDataTable
        columns={columns}
        data={formData.uploaded_pdf}
        showUploadButton
        handleAddBtnClick={openShowPDFForm}
        showPagination={false}
      />
      <CustomModal
        title="Upload PDF"
        visible={showPDFForm}
        hideModal={closeShowPDFForm}
      >
        <UploadPDFForm
          cancelButton={closeShowPDFForm}
          successAddPDF={successAddPDF}
        />
      </CustomModal>
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.pdfpath} PDF of ${showDeleteConfirmation?.record?.pdfsize}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
      <CustomModal
        title="Downloading PDF"
        visible={fileDownloadProgress.show}
        hideModal={hidePdfDownloadProgress}
      >
        <View style={commonFormStyle(theme).modalFormContainer}>
          <CustomProgressBar progress={fileDownloadProgress.progress} />
        </View>
      </CustomModal>
    </View>
  );
};

export default withTheme(PDFTable);
