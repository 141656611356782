import { View } from "react-native";
import React from "react";
import TopCards from "../TopCards/TopCards";
import { commonStyle } from "../CommonStyles/commonStyle";

const TopCountriesTable = () => {
  let data = [
    {
      title: "Top Countries",
      subtitle: "Last Month",
      data: [
        {
          id: 1,
          stores: "Spotify",
          date: "September 2021",
          amount: "$2,084,00",
        },
        {
          id: 2,
          stores: "Spotify",
          date: "September 2021",
          amount: "$2,084,00",
        },
        {
          id: 2,
          stores: "Spotify",
          date: "September 2021",
          amount: "$784,00",
        },
        {
          id: 2,
          stores: "Spotify",
          date: "September 2021",
          amount: "$84,00",
        },
        {
          id: 2,
          stores: "Spotify",
          date: "September 2021",
        },
      ],
    },
  ];
  return (
    <View
      style={{ ...commonStyle.commonMarginTopContainer, marginBottom: -10 }}
    >
      <TopCards customData={data} />
    </View>
  );
};

export default TopCountriesTable;
