import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { statisticsStyle } from "../../../../components/Statistics/statisticsStyle";
import { useTheme } from "react-native-paper";

const SelectedChipContainer = ({ label, activeTab, children }) => {
  let theme = useTheme();
  return (
    <View style={styles.container}>
      <Text
        style={
          activeTab
            ? statisticsStyle(theme).valueSemiBold
            : statisticsStyle(theme).valueRegular
        }
      >
        {label}
      </Text>
      <View style={styles.chipContainer}>{children}</View>
    </View>
  );
};

export default SelectedChipContainer;

const styles = StyleSheet.create({
  container: { flex: 0.5 },
  chipContainer: {
    flexDirection: "row",
    gap: 4,
    flexWrap: "wrap",
    marginTop: 5,
  },
});
