import { View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import CommonBadgePagination from "../CommonBadgePagination/CommonBadgePagination";
import TitleSubtitleCard from "../CustomCards/TitleSubtitleCard";
import { Card, withTheme } from "react-native-paper";
import Tableslide from "./Slide/Tableslide";
import Audience from "../Audience/Audience";
import StatsChartItems from "../TrendsStats/StatsChart/StatsChartItems";
import ChartTableCard from "../TrendsTV/ChartTableCard";

const Carousel = ({ theme, items, style, itemsPerInterval = 1 }) => {
  const [inputData, setInputData] = useState([]);
  const [interval, setInterval] = React.useState(1);
  const [intervals, setIntervals] = React.useState(1);
  const [width, setWidth] = React.useState(0);

  const init = (width) => {
    // initialise width
    setWidth(width);
    // initialise total intervals
    const totalItems = inputData.length;
    setIntervals(Math.ceil(totalItems / itemsPerInterval));
  };

  const getInterval = (offset) => {
    for (let i = 1; i <= intervals; i++) {
      if (offset + 1 < (width / intervals) * i) {
        return i;
      }
      if (i == intervals) {
        return i;
      }
    }
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      init();
      setInputData(items);
    }
    return () => {
      isMoulded = false;
    };
  }, [items]);

  return (
    <View style={styles(theme, style).container}>
      <ScrollView
        horizontal={true}
        contentContainerStyle={{
          ...styles.scrollView,
          width: `${100 * intervals}%`,
        }}
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={(w) => init(w)}
        onScroll={(data) => {
          setWidth(data.nativeEvent.contentSize.width);
          setInterval(getInterval(data.nativeEvent.contentOffset.x));
        }}
        // scrollEventThrottle={200}
        pagingEnabled
        decelerationRate="fast"
      >
        {inputData.map((item, index) => {
          switch (style) {
            case "stats":
              return (
                <TitleSubtitleCard
                  leaveMarginRight={(index + 1) % 2 !== 0}
                  key={index}
                  title={item.label}
                  subtitle={item.value}
                />
              );
            case "heatMap":
              return (
                <Card
                  elevation={0}
                  key={index}
                  style={styles(theme).audienceSlide}
                >
                  <Audience
                    data={item}
                    activeTab={interval}
                    itemIndex={index + 1}
                  />
                </Card>
              );
            case "trendStats":
              return (
                <Card elevation={0} key={index} style={styles(theme).slide}>
                  <StatsChartItems data={item} />
                </Card>
              );
            case "chartTable":
              return (
                <Card elevation={0} key={index} style={styles(theme).slide}>
                  <ChartTableCard data={item} />
                </Card>
              );
            case "slide":
            default:
              return <Tableslide key={index} data={item} />;
          }
        })}
      </ScrollView>
      <View
        style={{
          marginBottom:
            style === "slide" ||
            style === "heatMap" ||
            style === "trendStats" ||
            style === "chartTable"
              ? 15
              : 0,
          marginTop: style === "heatMap" || style === "trendStats" ? 15 : 0,
        }}
      >
        <CommonBadgePagination
          activePage={interval - 1}
          numberOfPage={intervals}
        />
      </View>
    </View>
  );
};

export default withTheme(Carousel);
