export const config = {
    TITLE: "Payments",
    CLIENTS_TITLE: "Client",
    CLIENT_KEY: "client_id",
    CLIENT_NAME: "client_name",
  
    YEAR_TITLE: "Year",
    YEAR_KEY: "reporting_year",
    YEAR_NAME: "reporting_year",
  
    CURRENCY_TITLE: "Currency",
    CURRENCY_KEY: "currency_id",
    CURRENCY_NAME: "currency_code",
  
    TABLE_SEARCH_PLACEHOLDER: "Search by ID/Name/Client ID/Client Name"
  };
  
  export const STATISTICS_DATA = {
    TOTAL: "Total",
    PAID: "Paid",
    REQUESTED: "Requested",
  };
  