export const config = {
  TITLE: "Invoices / Adjustments",
  ADJUSTMENT_TABLE_SEARCH_PLACEHOLDER: "Search by ID/Invoice Name/Client Name",

  CLIENTS_TITLE: "Client",
  CLIENT_KEY: "client_id",
  CLIENT_NAME: "client_name",

  SERVICE_TITLE: "Service",
  SERVICE_KEY: "service_id",
  SERVICE_NAME: "service_name",

  YEAR_TITLE: "Year",
  YEAR_KEY: "reporting_year",
  YEAR_NAME: "reporting_year",
};

export const TABS = {
  MATRIX_VIEW: "Matrix",
  TABLE_VIEW: "Table",
};

export const STATISTICS_DATA = {
  REPORT_VERISION_TITLE: "Report Version",
  EUR_TOTAL_DIFF: "EUR Total Diff",
  USD_TOTAL_DIFF: "USD Total Diff",
  GBP_TOTAL_DIFF: "GBP Total Diff",
};

export const BUTTON_TEXTS = {
  ADD_TO_NEXT_INVOICE_BTN: "Add to Invoice",
  UPGRADE_TO_V2: "Upgrade",
  DOWNLOAD_CSV: "Download CSV",
};
