import { StyleSheet } from "react-native";

export const formControlInputStyle = StyleSheet.create({
  container: { marginTop: 20, width: "100%" },
  containerInline: {
    marginTop: 20,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
});
