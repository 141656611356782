import { createContext, useContext, useEffect, useReducer } from "react";
import { initialState, reportReducer } from "./Reducer/reportReducer";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import {
  LOADER,
  TABLE_LOADER,
  UPDATE_MULTIPLE_DATA,
  UPDATE_TABLE_DATA,
} from "./Reducer/actions";
import {
  GET_CLIENTS_FILTER_URL,
  GET_CONTRACTS_FILTER_URL,
  GET_LABELS_FILTER_URL,
  GET_RELEASES_FILTER_URL,
  GET_REPORTING_PERIOD_URL,
  GET_TRACKS_FILTER_URL,
  REPORTS_URL,
} from "../../../constants/GlobalUrls";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const ReportsContextAPI = createContext();

export default ReportsContextAPI;

export const ReportProvider = ({ children }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const cancelTokenFiltersSource = axios.CancelToken.source();

  const [state, dispatch] = useReducer(reportReducer, initialState);

  const {
    refreshTable,
    start_month,
    end_month,
    client_ids,
    contract_ids,
    label_ids,
    release_ids,
    track_ids,
  } = state;

  const FILTERS_ENDPOINTS = {
    CLIENT_FILTER_REQUEST: {
      url: GET_CLIENTS_FILTER_URL,
      payload: { start_month, end_month },
    },
    CONTRACT_FILTER_REQUEST: {
      url: GET_CONTRACTS_FILTER_URL,
      payload: { start_month, end_month, client_ids },
    },
    LABEL_FILTER_REQUEST: {
      url: GET_LABELS_FILTER_URL,
      payload: { start_month, end_month, client_ids, contract_ids },
    },
    RELEASE_FILTER_REQUEST: {
      url: GET_RELEASES_FILTER_URL,
      payload: {
        start_month,
        end_month,
        client_ids,
        contract_ids,
        label_ids,
      },
    },
    TRACK_FILTER_REQUEST: {
      url: GET_TRACKS_FILTER_URL,
      payload: {
        start_month,
        end_month,
        client_ids,
        contract_ids,
        label_ids,
        release_ids,
      },
    },
  };

  const {
    CLIENT_FILTER_REQUEST,
    CONTRACT_FILTER_REQUEST,
    LABEL_FILTER_REQUEST,
    RELEASE_FILTER_REQUEST,
    TRACK_FILTER_REQUEST,
  } = FILTERS_ENDPOINTS;

  const filter_cases = {
    CASE_ONE: {
      endpoints: [
        CLIENT_FILTER_REQUEST,
        CONTRACT_FILTER_REQUEST,
        LABEL_FILTER_REQUEST,
        RELEASE_FILTER_REQUEST,
        TRACK_FILTER_REQUEST,
      ],
      responseSpread: axios.spread(
        (
          { data: clientOptions },
          { data: contractOptions },
          { data: labelOptions },
          { data: releaseOptions },
          { data: trackOptions }
        ) => {
          dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: {
              clientOptions,
              contractOptions,
              labelOptions,
              releaseOptions,
              trackOptions,
              loading: false,
            },
          });
        }
      ),
    },
    CASE_TWO: {
      endpoints: [
        CONTRACT_FILTER_REQUEST,
        LABEL_FILTER_REQUEST,
        RELEASE_FILTER_REQUEST,
        TRACK_FILTER_REQUEST,
      ],
      responseSpread: axios.spread(
        (
          { data: contractOptions },
          { data: labelOptions },
          { data: releaseOptions },
          { data: trackOptions }
        ) => {
          dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: {
              contractOptions,
              labelOptions,
              releaseOptions,
              trackOptions,
              loading: false,
            },
          });
        }
      ),
    },
    CASE_THREE: {
      endpoints: [
        LABEL_FILTER_REQUEST,
        RELEASE_FILTER_REQUEST,
        TRACK_FILTER_REQUEST,
      ],
      responseSpread: axios.spread(
        (
          { data: labelOptions },
          { data: releaseOptions },
          { data: trackOptions }
        ) => {
          dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: {
              labelOptions,
              releaseOptions,
              trackOptions,
              loading: false,
            },
          });
        }
      ),
    },
    CASE_FOUR: {
      endpoints: [RELEASE_FILTER_REQUEST, TRACK_FILTER_REQUEST],
      responseSpread: axios.spread(
        ({ data: releaseOptions }, { data: trackOptions }) => {
          dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: {
              releaseOptions,
              trackOptions,
              loading: false,
            },
          });
        }
      ),
    },
    CASE_FIVE: {
      endpoints: [TRACK_FILTER_REQUEST],
      responseSpread: axios.spread(({ data: trackOptions }) => {
        dispatch({
          type: UPDATE_MULTIPLE_DATA,
          payload: {
            trackOptions,
            loading: false,
          },
        });
      }),
    },
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getAllReports();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [refreshTable]);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getStartMonthOptions();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && start_month) {
      getEndMonthOptions();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [start_month]);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && start_month && end_month) {
      getAllOptions();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [end_month, client_ids, contract_ids, label_ids, release_ids]);

  const getEndMonthOptions = async () => {
    dispatch({ type: LOADER, payload: true });
    const handleSuccessResponse = (res) => {
      dispatch({
        type: UPDATE_MULTIPLE_DATA,
        payload: { endMonthOptions: res, end_month: "", loading: false },
      });
    };
    await api
      .post(
        GET_REPORTING_PERIOD_URL,
        { start_month: start_month },
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const getStartMonthOptions = async () => {
    dispatch({ type: LOADER, payload: true });
    const handleSuccessResponse = (res) => {
      dispatch({
        type: UPDATE_MULTIPLE_DATA,
        payload: { startMonthOptions: res, loading: false },
      });
    };
    await api
      .post(
        GET_REPORTING_PERIOD_URL,
        { start_month: null },
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const getEndpoints = () => {
    if (
      client_ids.length === 0 &&
      contract_ids.length === 0 &&
      label_ids.length === 0 &&
      release_ids.length === 0 &&
      track_ids.length === 0
    ) {
      return filter_cases.CASE_ONE;
    } else if (
      client_ids.length > 0 &&
      contract_ids.length === 0 &&
      label_ids.length === 0 &&
      release_ids.length === 0 &&
      track_ids.length === 0
    ) {
      return filter_cases.CASE_TWO;
    } else if (
      contract_ids.length > 0 &&
      label_ids.length === 0 &&
      release_ids.length === 0 &&
      track_ids.length === 0
    ) {
      return filter_cases.CASE_THREE;
    } else if (
      label_ids.length > 0 &&
      release_ids.length === 0 &&
      track_ids.length === 0
    ) {
      return filter_cases.CASE_FOUR;
    } else if (release_ids.length > 0 && track_ids.length === 0) {
      return filter_cases.CASE_FIVE;
    } else {
      return null;
    }
  };

  const getAllOptions = async () => {
    let endpointObj = getEndpoints();
    if (endpointObj) {
      dispatch({ type: LOADER, payload: true });
      await axios
        .all(
          endpointObj.endpoints.map(({ url, payload }) =>
            api.post(url, payload, {
              cancelToken: cancelTokenFiltersSource.token,
            })
          )
        )
        .then(endpointObj.responseSpread)
        .catch((error) => {
          checkNetworkReachable();
          dispatch({ type: LOADER, payload: false });
          if (error?.response?.status === 401) {
            logoutUser();
          } else if (!axios.isCancel(error) && error?.response) {
            alert(DEFAULT_ERROR_MSG);
          }
        });
    }
  };

  const getAllReports = async () => {
    dispatch({ type: TABLE_LOADER, payload: true });
    const handleSuccessResponse = (res) => {
      dispatch({ type: UPDATE_TABLE_DATA, payload: res });
    };

    await api
      .get(REPORTS_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: TABLE_LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  let contextData = {
    state,
    dispatch,
    initialState,
  };
  return (
    <ReportsContextAPI.Provider value={contextData}>
      {children}
    </ReportsContextAPI.Provider>
  );
};
