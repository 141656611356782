import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import {
  DEFAULT_ERROR_MSG,
  INFORMATION_TEXT,
  LOGIN_AUTH_ACCESS_TOKEN,
  MESSAGE_TYPE,
  USER_INFORMATION,
} from "../constants/GlobalVariables";
import {
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  VALIDATE_TOKEN_NEW_PASSWORD,
  ACCESS_TOKEN_URL,
  CONTACT_SUPPORT_URL,
  SIGN_UP_URL,
  LOGOUT_URL,
} from "../constants/GlobalUrls";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getData } from "../utils/getItemFromStorage";
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
} from "@expo-google-fonts/inter";
import { Loader } from "../components";
import { Provider as PaperProvider } from "react-native-paper";
import { getPaperTheme } from "../themes/getTheme";

const AuthContextAPI = createContext();

export default AuthContextAPI;

export const AuthProvider = ({ children }) => {
  let [fontsLoaded] = useFonts({
    Inter_400Regular: Inter_400Regular,
    Inter_500Medium: Inter_500Medium,
    Inter_600SemiBold: Inter_600SemiBold,
    Inter_700Bold: Inter_700Bold,
  });

  let [loading, setLoading] = useState(true);
  let [authTokens, setAuthTokens] = useState(null);
  let [user, setUser] = useState(null);
  const [userInformation, setUserInformation] = useState({});

  const updateTheme = () => {
    setUserInformation({
      ...userInformation,
      darkmode: !!!userInformation.darkmode,
    });
  };

  const updateProfilePicturePath = (newProfilePicturePath) => {
    setUserInformation({
      ...userInformation,
      profilepicturepath: newProfilePicturePath,
    });
  };

  let loginUser = async (formData) => {
    setLoading(true);
    let url = ACCESS_TOKEN_URL;
    let res = await axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          let {
            accounttype,
            darkmode,
            email,
            profilepicturepath,
            username,
            permissions,
          } = response.data;

          setAuthTokens(response.data);
          setUser(jwt_decode(response.data.access));
          setUserInformation({
            accounttype,
            darkmode,
            email,
            profilepicturepath,
            username,
            permissions,
          });
          AsyncStorage.setItem(
            LOGIN_AUTH_ACCESS_TOKEN,
            JSON.stringify(response.data)
          );
          AsyncStorage.setItem(
            USER_INFORMATION,
            JSON.stringify({
              accounttype,
              darkmode,
              email,
              profilepicturepath,
              username,
              permissions,
            })
          );
        } else {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: DEFAULT_ERROR_MSG,
          };
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: error?.response?.data?.detail,
          };
        } else {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: DEFAULT_ERROR_MSG,
          };
        }
      });
    setLoading(false);
    return res;
  };

  let forgotPassword = async (formData) => {
    setLoading(true);
    let url = FORGOT_PASSWORD;
    let res = await axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          let message = INFORMATION_TEXT.passwordResetSuccessMsg;
          return {
            show: true,
            type: MESSAGE_TYPE.success,
            message: message,
          };
        } else {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: DEFAULT_ERROR_MSG,
          };
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.email) {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: error?.response?.data?.email,
          };
        } else {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: DEFAULT_ERROR_MSG,
          };
        }
      });
    setLoading(false);
    return res;
  };

  let contactSupport = async (formData) => {
    setLoading(true);
    let url = CONTACT_SUPPORT_URL;
    let res = await axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          return {
            show: true,
            type: MESSAGE_TYPE.success,
            message: response.data.msg,
          };
        } else {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: DEFAULT_ERROR_MSG,
          };
        }
      })
      .catch((error) => {
        return {
          show: true,
          type: MESSAGE_TYPE.error,
          message: DEFAULT_ERROR_MSG,
        };
      });
    setLoading(false);
    return res;
  };

  let signUp = async (formData) => {
    setLoading(true);
    let url = SIGN_UP_URL;
    let res = await axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          return {
            show: true,
            type: MESSAGE_TYPE.success,
            message: response.data.msg,
          };
        } else {
          return {
            show: true,
            type: MESSAGE_TYPE.error,
            message: DEFAULT_ERROR_MSG,
          };
        }
      })
      .catch((error) => {
        return {
          show: true,
          type: MESSAGE_TYPE.error,
          message: DEFAULT_ERROR_MSG,
        };
      });
    setLoading(false);
    return res;
  };

  let newPasswordTokenValidate = async (data) => {
    let url = VALIDATE_TOKEN_NEW_PASSWORD;
    return axios({
      method: "post",
      url: url,
      data: data,
    });
  };

  let newPasswordConfirm = async (data) => {
    let url = NEW_PASSWORD;
    return axios({
      method: "post",
      url: url,
      data: data,
    });
  };

  let logoutUser = async () => {
    setLoading(true);

    await axios({
      method: "post",
      url: LOGOUT_URL,
      data: {
        refresh_token: authTokens?.refresh
      },
    })
      .then((response) => {
        if (response?.status === 200) {
          alert(response?.data?.message)
        } else {
          alert(DEFAULT_ERROR_MSG)
        }
      })
      .catch((error) => {
        console.log("Logout API failed...")
      })
      .finally(() => {
        AsyncStorage.clear();
        setAuthTokens(null);
        setUser(null);
        setUserInformation({});
        window.location.href = 'login';
        setLoading(false);
      }
      );
  };

  let contextData = {
    user: user,
    authTokens: authTokens,
    setAuthTokens: setAuthTokens,
    setUser: setUser,
    loginUser: loginUser,
    logoutUser: logoutUser,
    forgotPassword: forgotPassword,
    newPasswordTokenValidate: newPasswordTokenValidate,
    newPasswordConfirm: newPasswordConfirm,
    loading: loading,
    setLoading: setLoading,
    userInformation,
    updateTheme,
    contactSupport,
    signUp,
    updateProfilePicturePath,
  };

  const getStorage = async () => {
    let login_auth_access_token = await getData(LOGIN_AUTH_ACCESS_TOKEN);
    let getUserDetails = await getData(USER_INFORMATION);
    if (login_auth_access_token) {
      setAuthTokens(JSON.parse(login_auth_access_token));
      setUser(jwt_decode(login_auth_access_token));
    }
    if (getUserDetails) {
      let parsUserData = JSON.parse(getUserDetails);
      if (!("permissions" in parsUserData)) {
        logoutUser();
        return;
      }
      setUserInformation(parsUserData);
    }
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    } else {
      getStorage();
    }
    setLoading(false);
  }, [authTokens]);

  return (
    <PaperProvider theme={getPaperTheme(userInformation?.darkmode)}>
      <AuthContextAPI.Provider value={contextData}>
        {fontsLoaded && children}
        <Loader loading={loading || !fontsLoaded} />
      </AuthContextAPI.Provider>
    </PaperProvider>
  );
};
