import { Pressable, StyleSheet, View } from "react-native";
import React, { useEffect } from "react";
import MessageValidation from "../../MessageValidation";
import { FORM_REQUIRED_FIELD_MSG } from "../../../constants/GlobalVariables";
import { formControlInputStyle } from "../FormInput/formControlInputStyle";
import FormLabel from "../FormLabel/FormLabel";
import { TextInput, useTheme } from "react-native-paper";
import { Feather, Ionicons } from "@expo/vector-icons";
import * as Clipboard from "expo-clipboard";

const GeneratePassword = ({
  label,
  name,
  value,
  onChange = () => {},
  required = false,
  isValidated = false,
  validationMessage = FORM_REQUIRED_FIELD_MSG,
  showHelpText = true,
}) => {
  let theme = useTheme();
  const { inputText, colors, customFonts } = theme;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      generatePassword();
    }
    return () => {
      isMoulded = false;
    };
  }, []);

  const copyToClipboard = async () => {
    Clipboard.setString(value);
  };

  const passwordGenerator = (length) => {
    let s = "";
    Array.from({ length }).some(() => {
      s += Math.random().toString(36).slice(2);
      return s.length >= length;
    });
    return s.slice(0, length);
  };

  const generatePassword = async () => {
    onChange(name, passwordGenerator(12));
  };

  return (
    <View style={formControlInputStyle.container}>
      <FormLabel text={label} required={required} isValidated={isValidated} />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <View style={{ flex: 1 }}>
          <TextInput
            disabled
            right={
              <TextInput.Icon
                name={() => (
                  <Ionicons
                    selectable={false}
                    onPress={copyToClipboard}
                    name="copy"
                    size={24}
                    color="black"
                    style={{ width: 24, height: 24 }}
                  />
                )}
              />
            }
            style={{
              borderWidth: 1,
              paddingVertical: 0,
              borderColor: colors.inputborderColor,
              fontSize: 16,
              fontFamily: customFonts.regular,
              color: colors.text,
              justifyContent: "flex-start",
              backgroundColor: colors.secondaryBackground,
              outline: 0,
              height: inputText.height,
              borderRadius: 10,
              overflow: "hidden",
              textAlignVertical: "top",
            }}
            // secureTextEntry={true}
            multiline={false}
            numberOfLines={1}
            value={value}
            editable={false}
            outlineColor={colors.secondaryBackground}
            underlineColorAndroid={colors.secondaryBackground}
            underlineColor={colors.secondaryBackground}
          />
        </View>
        <View>
          <Pressable
            style={styles(theme).refreshBtnContainer}
            onPress={() => {
              generatePassword();
            }}
          >
            <Feather
              name="refresh-cw"
              size={24}
              color={theme.colors.black}
              style={{ width: 24, height: 24 }}
            />
          </Pressable>
        </View>
      </View>

      {showHelpText && (
        <MessageValidation
          fieldValidation={isValidated}
          message={validationMessage}
        />
      )}
    </View>
  );
};

export default GeneratePassword;

const styles = (theme) =>
  StyleSheet.create({
    refreshBtnContainer: {
      alignItems: "center",
      justifyContent: "center",
      width: 56,
      borderRadius: 10,
      height: 56,
      //   marginTop: 6,
      backgroundColor: theme.colors.primary,
    },
  });
