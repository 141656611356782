import React from "react";
import { AdjustmentProvider } from "./AdjustmentContextAPI";
import AdjusmentConsumer from "./AdjusmentConsumer";

const Adjustments = () => {
  return (
    <AdjustmentProvider>
      <AdjusmentConsumer />
    </AdjustmentProvider>
  );
};

export default Adjustments;
