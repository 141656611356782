import React from "react";
import { View, ScrollView } from "react-native";
import { commonStyle } from "../CommonStyles/commonStyle";
import Loader from "../Loader/Loader";
import { commonLayoutWrapperStyle } from "./commonLayoutWrapperStyle";
import { withTheme } from "react-native-paper";

const CommonLayoutWrapper = ({
  theme,
  children,
  loading,
  marginTop = 40,
  marginBottom = 0,
}) => {
  return (
    <View style={commonLayoutWrapperStyle(null, null).container}>
      <Loader loading={loading} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        scrollIndicatorInsets
        indicatorStyle={theme.dark ? "white" : "default"}
        nestedScrollEnabled
        style={
          commonLayoutWrapperStyle(marginTop, marginBottom).scrollContainer
        }
      >
        <View style={commonStyle.lastComponentMarginBottom}>{children}</View>
      </ScrollView>
    </View>
  );
};

export default withTheme(CommonLayoutWrapper);
