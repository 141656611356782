import { StyleSheet, Text, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  ConfirmationWindow,
  CustomButton,
  CustomModal,
} from "../../../components";
import { dealFormForLabelStyle } from "../ContractManager/ContractManagerDealForm/dealFormForLabelStyle";
import { showAllSelectFilterStyles } from "../Reports/ShowAllSelectFilters";
import SecondaryHeaderTextSemiBold from "../../../components/CustomText/SecondaryHeaderTextSemiBold";
import SelectedFilterContainer from "../Reports/SelectedFilterContainer";
import { useTheme } from "react-native-paper";
import PaymentContextAPI from "./Reducer/PaymentsContextAPI";
import { config } from "./payment.config";

const GetDataFromFilters = () => {
  let theme = useTheme();
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  let {
    state: { client_name, reporting_year, currency_name, showLandingPage, tableData },
    getData,
  } = useContext(PaymentContextAPI);

  useEffect(() => {
    let isMoulded = true;
    if (showLandingPage && tableData.length > 0) {
      getData();
    }
    return () => {
      isMoulded = false;
    };
  }, [showLandingPage]);

  const onPressGenerateReportBtn = async () => {
    closeShowWarningMessage();
    getData();
  };

  const closeShowWarningMessage = () => {
    setShowWarningMessage(false);
  };

  return (
    <View style={styles.btnContainer}>
      <CustomButton
        text={"Get Data"}
        onPress={() => {
          setShowWarningMessage(true);
        }}
      />
      <CustomModal
        visible={showWarningMessage}
        hideModal={closeShowWarningMessage}
      >
        <ConfirmationWindow
          customMessage={
            <>
              <Text style={dealFormForLabelStyle(theme).messageContainer}>
                Are you certain about proceeding with the comparison generation
                using the specified filters?
              </Text>
              <View style={styles.containerForFilter}>
                <View style={showAllSelectFilterStyles(theme).container}>
                  <SecondaryHeaderTextSemiBold text={"Applied Filters"} />
                  <View
                    style={showAllSelectFilterStyles(theme).filterTextContainer}
                  >
                    <SelectedFilterContainer
                      title={config.CLIENTS_TITLE}
                      value={client_name && client_name[0]}
                    />
                    <SelectedFilterContainer
                      title={config.YEAR_TITLE}
                      value={reporting_year && reporting_year}
                    />
                    <SelectedFilterContainer
                      title={config.CURRENCY_TITLE}
                      value={currency_name && currency_name[0]}
                    />
                  </View>
                </View>
              </View>
            </>
          }
          handleNoOnClick={closeShowWarningMessage}
          handleYesOnClick={onPressGenerateReportBtn}
        />
      </CustomModal>
    </View>
  );
};

export default GetDataFromFilters;

const styles = StyleSheet.create({
  btnContainer: { marginTop: 15 },
  containerForFilter: {
    width: 700,
    marginTop: 20,
  },
});
