import {
  LOADER,
  REFRESH_TABLE,
  RESET_FILTERS,
  TABLE_LOADER,
  TABLE_PREVIEW_ROW,
  UPDATE_MULTIPLE_DATA,
  UPDATE_SHOW_LANDING_PAGE,
  UPDATE_TABLE_DATA,
} from "./actions";

export const initialState = {
  showLandingPage: true,
  refreshTable: true,

  loading: true,
  tableLoader: true,
  tableData: [],
  tablePreviewRow: {},

  startMonthOptions: [],
  endMonthOptions: [],
  clientOptions: [],
  contractOptions: [],
  labelOptions: [],
  releaseOptions: [],
  trackOptions: [],

  start_month: "",
  end_month: "",
  client_ids: [],
  contract_ids: [],
  label_ids: [],
  release_ids: [],
  track_ids: [],

  client_names: [],
  contract_names: [],
  label_names: [],
  release_names: [],
  track_names: [],
};

export const reportReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, loading: action.payload };
    case TABLE_LOADER:
      return { ...state, tableLoader: action.payload };
    case UPDATE_TABLE_DATA:
      return { ...state, tableData: action.payload, tableLoader: false };
    case UPDATE_SHOW_LANDING_PAGE:
      return {
        ...state,
        showLandingPage: true,
        refreshTable: !state.refreshTable,
      };
    case REFRESH_TABLE:
      return { ...state, refreshTable: !state.refreshTable };
    case TABLE_PREVIEW_ROW:
      return {
        ...state,
        tablePreviewRow: action.payload,
        showLandingPage: false,
      };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    case RESET_FILTERS:
      return {
        ...state,
        endMonthOptions: [],
        clientOptions: [],
        contractOptions: [],
        labelOptions: [],
        releaseOptions: [],
        trackOptions: [],

        start_month: "",
        end_month: "",
        client_ids: [],
        contract_ids: [],
        label_ids: [],
        release_ids: [],
        track_ids: [],

        client_names: [],
        contract_names: [],
        label_names: [],
        release_names: [],
        track_names: [],
      };
    default:
      return state;
  }
};
