export const loaderStyle = (theme, backgroundColor, transparent) => {
  return {
    backgroundColor: backgroundColor || theme.colors.background,
    flex: 1,
    zIndex: 1,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    opacity: transparent? 0.7: 1,
  };
};
