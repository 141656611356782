import { View, FlatList } from "react-native";
import React from "react";
import CommonAvatar from "../CommonAvatar/CommonAvatar";

const ChannelScroll = () => {
  const CHANNELS_NAMES = [
    {
      id: 1,
      name: require("../../../assets/images/TVChannels/Balkanika-TV.jpg"),
    },
    {
      id: 2,
      name: require("../../../assets/images/TVChannels/Grand-TV.jpg"),
    },
    {
      id: 3,
      name: require("../../../assets/images/TVChannels/Happy-TV.jpg"),
    },
    {
      id: 4,
      name: require("../../../assets/images/TVChannels/IDJTV.jpg"),
    },
    {
      id: 5,
      name: require("../../../assets/images/TVChannels/melos-tv.jpg"),
    },
    {
      id: 6,
      name: require("../../../assets/images/TVChannels/Imperia-TV.jpg"),
    },
    {
      id: 7,
      name: require("../../../assets/images/TVChannels/Red-TV.jpg"),
    },
  ];

  const ItemRender = ({ name }) => (
    <View>
      <CommonAvatar size={60} src={name} />
    </View>
  );
  const Separator = () => {
    return (
      <View
        style={{
          width: 5,
        }}
      />
    );
  };

  return (
      <FlatList
        data={CHANNELS_NAMES}
        renderItem={({ item }) => <ItemRender name={item.name} />}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={Separator}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      />
  );
};

export default ChannelScroll;
