import { createContext, useContext, useEffect, useReducer } from "react";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import axios from "axios";
import { initialState, paymentDataReducer } from "./paymentReducer";
import useAxios from "../../../../hooks/useAxios";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import { LOADER, SELECTED_ROW, UPDATE_MULTIPLE_DATA } from "./actions";
import { GET_ALL_CURRENCY_URL, GET_CLIENTS_FILTER_PAYMENTS_URL, GET_INVOICE_DETAILS_TABLE_FOR_PAYMENT_URL, GET_YEARS_FILTER_URL, PAYMENT_URL } from "../../../../constants/GlobalUrls";
import { config } from "../payment.config";

const PaymentContextAPI = createContext();

export default PaymentContextAPI;

export const PaymentProvider = ({ children, pageName = config.TITLE }) => {

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);

    // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
    const cancelTokenSource = axios.CancelToken.source();

    const [state, dispatch] = useReducer(paymentDataReducer, initialState);

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded && pageName === config.TITLE) {
            getAllOptions();
        }
        return () => {
            isMoulded = false;
            cancelTokenSource.cancel();
        };
    }, []);

    const getAllOptions = async () => {
        dispatch({ type: LOADER, payload: true });
        let endpoints = [
            { url: GET_CLIENTS_FILTER_PAYMENTS_URL, method: "get" },
            { url: GET_YEARS_FILTER_URL, method: "get" },
            { url: GET_ALL_CURRENCY_URL, method: "get" },
        ];
        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(
                    ({ data: clientOptions }, { data: yearOptions }, { data: currencyOptions }) => {
                        dispatch({
                            type: UPDATE_MULTIPLE_DATA,
                            payload: {
                                clientOptions,
                                yearOptions,
                                currencyOptions,
                                loading: false,
                            },
                        });
                    }
                )
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    };

    const getData = async () => {
        dispatch({ type: LOADER, payload: true });

        const handleSuccessResponse = (res) => {
            dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: { tableData: res, loading: false },
            });
        };

        await api
            .post(
                PAYMENT_URL,
                {
                    client_id: state?.client_id[0],
                    reporting_year: state?.reporting_year,
                    currency_id: state?.currency_id[0]
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                dispatch({ type: LOADER, payload: false });
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };

    const getInvoiceDetailsTableData = async () => {
        dispatch({ type: LOADER, payload: true });
        let URL = `${GET_INVOICE_DETAILS_TABLE_FOR_PAYMENT_URL}${state?.selectedRowData?.id}`

        let endpoints = [
            { url: URL, method: "post" }
        ];

        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(({ data: paymentInvoiceDetailTable }) => {
                    let existingInvoiceList = paymentInvoiceDetailTable.map(item => item.invoiceid)
                    dispatch({
                        type: UPDATE_MULTIPLE_DATA,
                        payload: {
                            paymentInvoiceDetailTable,
                            existingInvoiceList,
                            loading: false,
                        },
                    });
                })
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    }

    const getPaymentByID = async (id) => {
        dispatch({ type: LOADER, payload: true });
        const handleSuccessResponse = (res) => {
            let data = res.length > 0 ? res[0] : {}
            dispatch({ type: SELECTED_ROW, payload: data })
        };

        await api
            .post(`${PAYMENT_URL}${id}`, {
                cancelToken: cancelTokenSource.token,
            })
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    }

    let contextData = {
        state,
        dispatch,
        getData,
        getInvoiceDetailsTableData,
        pageName,
        getPaymentByID,
    };

    return (
        <PaymentContextAPI.Provider value={contextData}>
            {children}
        </PaymentContextAPI.Provider>
    );
}