import { StyleSheet, Text, View } from "react-native";
import React, { useState, useEffect } from "react";
import SecondaryHeaderText from "../../CustomText/SecondaryHeaderText";
import InformationText from "../../CustomText/InformationText";
import CommonAvatar from "../../CommonAvatar/CommonAvatar";
import { withTheme } from "react-native-paper";
import CustomVictoryLineChart from "../../../components/charts/CustomVictoryLineChart.js";
import SongsChartsItemData from "../../TrendingCharts/SongCharts/SongsChartsItemData";
import { commonStyle } from "../../CommonStyles/commonStyle";

const StatsChartItems = ({ theme, data }) => {
  const [statData, setStatData] = useState([]);
  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && data) {
      setStatData(data);
    }
    return () => {
      isMoulded = false;
    };
  }, [data]);

  return (
    <View style={styles(theme).wrapper}>
      <View style={styles(theme).container}>
        <View style={styles(theme).titleSubTitleContainer}>
          <View style={commonStyle.commonMarginContainer}>
            <CommonAvatar
              src={require("../../../../assets/images/youtube.png")}
            />
          </View>
          <View style={commonStyle.commonMarginLeftContent}>
            <SecondaryHeaderText text={statData?.title} />
            <InformationText text={statData?.subtitle} />
          </View>
        </View>
        <View>
          <Text style={styles(theme).statisticTextSemiBold}>
            {statData?.count}
          </Text>
        </View>
      </View>
      <CustomVictoryLineChart theme={theme} data={statData?.chartData} />
      <View style={styles(theme).informationContainer}>
        {statData?.statsData &&
          statData?.statsData.length > 0 &&
          statData.statsData.map((item, i) => {
            return (
              <View style={styles(theme).itemWrapper} key={i}>
                <SongsChartsItemData
                  samecategory
                  rightborder={i === 0}
                  data={item}
                />
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default withTheme(StatsChartItems);

const styles = (theme) =>
  StyleSheet.create({
    wrapper: {
      backgroundColor: theme.colors.customCardColor,
      padding: 15,
      paddingBottom: 0,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
    },
    container: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
    titleSubTitleContainer: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
    },
    statisticTextSemiBold: {
      color: theme.colors.text,
      fontSize: 24,
      fontFamily: theme.customFonts.semiBold,
    },
    informationContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 10,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
    },
    itemWrapper: { aspectRatio: 1, flex: 1 },
  });
