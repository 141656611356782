import { View, FlatList, ScrollView } from "react-native";
import React from "react";
import SongsChartsItemData from "./SongsChartsItemData";
import { withTheme } from "react-native-paper";

const ChartsDetailsCardList = ({ theme, data, samecategory = false }) => {
  const ItemDivider = () => {
    return (
      <View
        style={
          samecategory
            ? {
                height: 1,
                backgroundColor: theme.colors.divider,
                marginHorizontal: 10,
              }
            : {}
        }
      />
    );
  };

  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{ flex: 2 }}
    >
      <FlatList
        showsVerticalScrollIndicator={false}
        data={data}
        numColumns={2}
        ItemSeparatorComponent={ItemDivider}
        renderItem={({ item, index }) => {
          return (
            <View
              style={
                samecategory
                  ? {
                      flex: 1,
                      aspectRatio: 1,
                      flexDirection: "row",
                      borderColor: theme.colors.divider,
                      justifyContent: "space-between",
                    }
                  : {
                      flex: 1,
                      aspectRatio: 1,
                      flexDirection: "row",
                      borderColor: theme.colors.divider,
                      justifyContent: "space-between",
                      marginRight: (index + 1) % 2 === 0 ? 0 : 20,
                      marginVertical: 10,
                    }
              }
            >
              <SongsChartsItemData data={item} samecategory={samecategory} />
            </View>
          );
        }}
        keyExtractor={(item) => item.count}
      />
    </ScrollView>
  );
};

export default withTheme(ChartsDetailsCardList);
