import React from "react";
import ReportVersionLandingPage from "./ReportVersionLandingPage";
import { ReportVersionProvider } from "./ReportVersionConsumer";

const ReportVersions = () => {
  return (
    <ReportVersionProvider>
      <ReportVersionLandingPage />
    </ReportVersionProvider>
  );
};

export default ReportVersions;
