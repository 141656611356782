import { createContext, useContext, useEffect, useReducer } from "react";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import { initialState, royaltiesReducer } from "./royaltiesReducer";
import { LOADER, UPDATE_MULTIPLE_DATA } from "./actions";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import {
  GET_ROYALTIES_PLATFORM_URL,
  GET_ROYALTIES_REPORTING_PERIOD_URL,
  GET_ROYALTIES_SOURCE_URL,
} from "../../../../constants/GlobalUrls";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const RoyaltiesContextAPI = createContext();

export default RoyaltiesContextAPI;

export const RoyaltiesProvider = ({ children }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [state, dispatch] = useReducer(royaltiesReducer, initialState);
  let contextData = {
    state,
    dispatch,
    initialState,
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getTableData();
      // dispatch({ type: LOADER, payload: false });
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const getTableData = async () => {
    dispatch({ type: LOADER, payload: true });
    let endpoints = [
      GET_ROYALTIES_SOURCE_URL,
      GET_ROYALTIES_PLATFORM_URL,
      GET_ROYALTIES_REPORTING_PERIOD_URL,
    ];

    await Promise.all(
      endpoints.map((endpoint) =>
        api.get(endpoint, {
          cancelToken: cancelTokenSource.token,
        })
      )
    )
      .then(
        axios.spread(
          (
            { data: sourceTableData },
            { data: platformTableData },
            { data: reportingPeriodTableData }
          ) => {
            dispatch({
              type: UPDATE_MULTIPLE_DATA,
              payload: {
                sourceTableData,
                platformTableData,
                reportingPeriodTableData,
                isLoading: false,
              },
            });
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  return (
    <RoyaltiesContextAPI.Provider value={contextData}>
      {children}
    </RoyaltiesContextAPI.Provider>
  );
};
