import {
  LOADER,
  RESET_FILTERS,
  RETURN_TO_LANDING_PAGE,
  SELECTED_ROW,
  UPDATE_MULTIPLE_DATA,
} from "./actions";

export const initialState = {
  loading: false,
  tableData: [],
  showLandingPage: true,
  selectedRowData: {},
  invoiceTypeOptions: [],
  invoicePaymentDetailTable: [],

  clientOptions: [],
  serviceOptions: [],
  reportVersionOptions: [],

  client_name: [],
  service_name: [],
  report_version_name: [],

  client_id: [],
  service_id: [],
  report_version_id: [],
};

export const invoiceDataReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, loading: action.payload };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    case RETURN_TO_LANDING_PAGE:
      return { ...state, selectedRowData: {}, invoicePaymentDetailTable: [], showLandingPage: true };
    case SELECTED_ROW:
      return {
        ...state,
        selectedRowData: action.payload,
        showLandingPage: false,
      };
    case RESET_FILTERS:
      return {
        ...state,
        client_name: [],
        service_name: [],
        client_id: [],
        service_id: [],
        report_version_name: [],
        report_version_id: [],
        tableData: [],
      };
    default:
      return state;
  }
};
