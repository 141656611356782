import { StyleSheet } from "react-native";

export const topChartStyle = StyleSheet.create({
 imageTextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: { marginVertical: 15 },
  textContainer: { marginLeft: 10 }
});
