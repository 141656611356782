import { Platform } from "react-native";

export const customFonts = {
  regular: "Inter_400Regular",
  medium: "Inter_500Medium",
  semiBold: "Inter_600SemiBold",
  bold: "Inter_700Bold",
};

export const customDarkTheme = {
  primary: "#15FFAB",
  secondary: "#FF5CAA",
  text: "#FFFFFF",
  secondaryText: "#70707C",
  drawerActiveText: "#000000",
  background: "#000000",
  secondaryBackground: "#15151B",
  infoText: "#70707C",
  inputborderColor: "#5B5B65",
  datatableTitleText: "#656575",
  card: "#15151B",
  success: "#15FFAB",
  error: "#FF0000",
  gridLine: "#70707C",
  avatarBackground: "#C4C4C4",
  textIconBtn: "#FFFFFF",
  editBtnIcon: "#479CFF",
  deleteBtnIcon: "#FF0000",
  activeBtnIcon: "#15FFAB",
  disableBtnIcon: "#DFDFDF",
  black: "#000000",
  white: "#FFFFFF",
  actionIconActive: "#15FFAB",
  actionIconInActive: "#70707C",
  customCardColor: "#15151B",
  cardPrimaryText: "#15FFAB",
  borderColor: "#C4C4C4",
  divider: "#323237",
  contrastText: "#000000",
  downArrow: "#FF1515",
  containerBtnColor: "#15FFAB",
  ternaryColor: "#15FFAB",
  searchBackgroundColor: "#40404A",
  warning: "#FFD782",
  selectedTableBackgroundColor: "#15FFAB40",
  linkBtnIcon: "#b968ff",
  cloudDownloadIcon: "#FF9900",
  linkColor: "#1111F9",
  legacyInvoiceText: "#0077C0",
  paidLessText: "#FFA500",
  excelSheetIcon: "#1D6F42"
};

export const darkThemeCustomComponent = {
  customSecondaryButton: {
    borderColor: customDarkTheme.primary,
    borderWidth: 1,
    color: customDarkTheme.text,
  },
  inputText: {
    height: Platform.OS === "web" ? 56 : 42,
    outlineColor: customDarkTheme.inputborderColor,
    placeholderTextColor: customDarkTheme.inputborderColor,
    paddingHorizontal: 15,
  },
};

export const customLightTheme = {
  primary: "#15FFAB",
  secondary: "#FF5CAA",
  text: "#000000",
  secondaryText: "#70707C",
  drawerActiveText: "#000000",
  background: "#FFFFFF",
  secondaryBackground: "#E5E5E5",
  infoText: "#70707C",
  inputborderColor: "#5B5B65",
  datatableTitleText: "#656575",
  card: "#FFFFFF",
  success: "#15FFAB",
  error: "#FF0000",
  gridLine: "#70707C",
  avatarBackground: "#C4C4C4",
  textIconBtn: "#FFFFFF",
  editBtnIcon: "#479CFF",
  deleteBtnIcon: "#FF0000",
  activeBtnIcon: "#15FFAB",
  disableBtnIcon: "#DFDFDF",
  black: "#000000",
  white: "#FFFFFF",
  actionIconActive: "#07D2FF",
  actionIconInActive: "#15151B",
  customCardColor: "#F2F3F7",
  cardPrimaryText: "#07D2FF",
  borderColor: "#C4C4C4",
  divider: "#d5d6d9",
  contrastText: "#FFFFFF",
  downArrow: "#FF1515",
  containerBtnColor: "#07D2FF",
  ternaryColor: "#07D2FF",
  searchBackgroundColor: "#FFFFFF",
  warning: "#FFD782",
  selectedTableBackgroundColor: "#15FFAB40",
  linkBtnIcon: "#b968ff",
  cloudDownloadIcon: "#FF9900",
  linkColor: "#1111F9",
  legacyInvoiceText: "#0077C0",
  paidLessText: "#FFA500",
  excelSheetIcon: "#1D6F42"
};

export const lightThemeCustomComponent = {
  customSecondaryButton: {
    borderColor: customLightTheme.primary,
    borderWidth: 1,
    color: customLightTheme.text,
  },
  inputText: {
    height: Platform.OS === "web" ? 56 : 42,
    outlineColor: customLightTheme.inputborderColor,
    placeholderTextColor: customLightTheme.inputborderColor,
    paddingHorizontal: 15,
  },
};
