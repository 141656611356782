import { View } from "react-native";
import React, { useContext } from "react";
import {
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import OverlappingDspDealContextAPI from "./Reducer/OverlappingDspDealContextAPI";
import { TABLENAMES, TABS, config } from "./overlappingDspDeal.config";
import { useTheme } from "react-native-paper";
import FormRadioGroup from "../../../components/FormControl/FormRadio/FormRadioGroup";
import FormRadioButton from "../../../components/FormControl/FormRadio/FormRadioButton";
import { CHANGE_ACTIVE_TAB } from "./Reducer/actions";
import { accountEditTableStyle } from "../Accounts/AccountEditPage/AccountEditTables";
import LabelTable from "./LabelTable";
import ReleaseTable from "./ReleaseTable";
import TrackOnUpcTable from "./TrackOnUpcTable";

const LandingPage = ({ getRecordData }) => {
  let theme = useTheme();
  let {
    state: {
      isLoading,
      activeTab,
      labelTableData,
      releaseTableData,
      trackOnUpcTableData,
    },
    dispatch,
  } = useContext(OverlappingDspDealContextAPI);

  const onTabChange = (tab) => {
    dispatch({ type: CHANGE_ACTIVE_TAB, payload: tab });
  };

  const extraHeaderComponent = (
    <View style={commonStatisticButtonStyle(theme).statisticContainer}>
      <Statistics
        label={TABLENAMES.labelTableData}
        value={labelTableData.length || 0}
      />
      <Statistics
        label={TABLENAMES.releaseTableData}
        value={releaseTableData.length || 0}
      />
      <Statistics
        label={TABLENAMES.trackOnUpcTableData}
        value={trackOnUpcTableData.length || 0}
      />
      <View style={commonStatisButtonCustomStyle(theme).buttonContainer}></View>
    </View>
  );

  let selectActiveTab = (
    <FormRadioGroup
      inlineLabel
      label={"Table:"}
      value={activeTab}
      onChange={(name, value) => {
        onTabChange(value);
      }}
    >
      <View style={accountEditTableStyle.radioButtonWrapper}>
        <FormRadioButton
          label={`${TABLENAMES[TABS.LABEL]}`}
          value={TABS.LABEL}
        />
        <FormRadioButton
          label={`${TABLENAMES[TABS.RELEASE]}`}
          value={TABS.RELEASE}
        />
        <FormRadioButton
          label={`${TABLENAMES[TABS.TRACKS_ON_UPC]}`}
          value={TABS.TRACKS_ON_UPC}
        />
      </View>
    </FormRadioGroup>
  );

  return (
    <WebContentContainerWrapper
      title={config.TITLE}
      loading={isLoading}
      extraHeaderComponent={extraHeaderComponent}
    >
      {activeTab === TABS.LABEL ? (
        <LabelTable
          data={labelTableData}
          selectActiveTab={selectActiveTab}
          getRecordData={getRecordData}
        />
      ) : (
        <></>
      )}
      {activeTab === TABS.RELEASE ? (
        <ReleaseTable
          data={releaseTableData}
          selectActiveTab={selectActiveTab}
          getRecordData={getRecordData}
        />
      ) : (
        <></>
      )}
      {activeTab === TABS.TRACKS_ON_UPC ? (
        <TrackOnUpcTable
          data={trackOnUpcTableData}
          selectActiveTab={selectActiveTab}
          getRecordData={getRecordData}
        />
      ) : (
        <></>
      )}
    </WebContentContainerWrapper>
  );
};

export default LandingPage;
