import { createContext, useContext, useEffect, useReducer } from "react";
import { catalogueReducer, initialState } from "./catalogueReducer";
import axios from "axios";
import useAxios from "../../../../hooks/useAxios";
import {
  GET_CATALOGUE_OVERVIEW_URL,
  GET_CATALOGUE_RELEASE_URL,
  GET_CATALOGUE_TRACK_URL,
} from "../../../../constants/GlobalUrls";
import { LOADER, UPDATE_MULTIPLE_DATA } from "./actions";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import AuthContextAPI from "../../../../context/AuthContextAPI";

const CatalogueContextAPI = createContext();

export default CatalogueContextAPI;

export const CatalogueProvider = ({ children }) => {
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const { logoutUser } = useContext(AuthContextAPI);

  const [state, dispatch] = useReducer(catalogueReducer, initialState);

  let contextData = {
    state,
    dispatch,
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getTableData();
      // dispatch({ type: LOADER, payload: false });
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const getTableData = async () => {
    dispatch({ type: LOADER, payload: true });
    let endpoints = [
      GET_CATALOGUE_RELEASE_URL,
      GET_CATALOGUE_TRACK_URL,
      GET_CATALOGUE_OVERVIEW_URL,
    ];

    await Promise.all(
      endpoints.map((endpoint) =>
        api.get(endpoint, {
          cancelToken: cancelTokenSource.token,
        })
      )
    )
      .then(
        axios.spread(
          (
            { data: releaseTableData },
            { data: trackTableData },
            { data: overview }
          ) => {
            dispatch({
              type: UPDATE_MULTIPLE_DATA,
              payload: {
                ...overview,
                releaseTableData,
                trackTableData,
                isLoading: false,
              },
            });
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  return (
    <CatalogueContextAPI.Provider value={contextData}>
      {children}
    </CatalogueContextAPI.Provider>
  );
};
