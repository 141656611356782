import React from "react";
import { View } from "react-native";
import { ActionsButtonIcons, commonFormStyle } from "..";
import { withTheme } from "react-native-paper";
import { FormControlInput, FormSelect } from "../FormControl";
import { ACTION_BUTTON_TYPE } from "../../constants/GlobalVariables";
import { TABLE_ACTION } from "../../screens/WebDashboardPages/ContractManager/ContractManagerDealForm/ContractManagerDealForm.config";

const AddMoreField = ({
  index,
  label1,
  label2,
  options,
  dataObj,
  openCloseClientModal,
  handleAddMoreBtnClick,
  handleRemoveMoreBtnClick,
  theme,
  name1,
  name2,
  optionName = "display_name",
  optionValue = "clientid",
  getselectedValue,
  action = TABLE_ACTION.no_action,
  isValidated = false,
}) => {
  let { key, clientid, isOpen, revenueshare } = dataObj;

  const onChangeValue = (selectname, value, title, selectedOpt) => {
    getselectedValue(selectname, key, value, selectedOpt);
  };

  return (
    action !== TABLE_ACTION.delete && (
      <View
        key={key}
        style={commonFormStyle(theme).inLineFormSelectInputContainer}
      >
        <FormSelect
          required
          name={name1}
          label={label1}
          visible={isOpen}
          closeMenu={() => openCloseClientModal(key, false)}
          openMenu={() => openCloseClientModal(key, true)}
          options={options}
          onChange={onChangeValue}
          searchable
          value={clientid}
          optionName={optionName}
          optionValue={optionValue}
          // disabled={isEditMode}
          placeholdertext="Client Name • Client Type • Personal / Tax Identification Number"
          isValidated={isValidated && !clientid}
        />
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <View style={commonFormStyle(theme).inLineFormField}>
            <FormControlInput
              required
              label={label2}
              name={name2}
              onChange={onChangeValue}
              value={revenueshare}
              isValidated={isValidated && !revenueshare}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              marginTop: isValidated ? 15 : 40,
            }}
          >
            <ActionsButtonIcons
              buttonType={ACTION_BUTTON_TYPE.ADD}
              handleOnPress={handleAddMoreBtnClick}
              customStyle={commonFormStyle(theme).inlineFormFieldAddBtn}
            />
            {index !== 0 && (
              <ActionsButtonIcons
                buttonType={ACTION_BUTTON_TYPE.REMOVE}
                customStyle={{ marginLeft: 8 }}
                handleOnPress={() => handleRemoveMoreBtnClick(key)}
              />
            )}
          </View>
        </View>
      </View>
    )
  );
};

export default React.memo(withTheme(AddMoreField));
