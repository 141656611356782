import { useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { Avatar, Menu, withTheme } from "react-native-paper";
import AuthContextAPI from "../../context/AuthContextAPI";
import adminNavigationString from "../../navigation/AppStack/AdminAppDrawer/adminNavigationString";
import { AntDesign } from "@expo/vector-icons";
import { getNameInitials } from "../../utils/getNameInitials";
import { ENVIRONMENT } from "../../constants/GlobalVariables";
import Constants from "expo-constants";

const CommonHeader = ({ theme }) => {
  let navigation = useNavigation();
  const { dark, colors, customFonts } = theme;
  const {
    logoutUser,
    userInformation: { username },
  } = useContext(AuthContextAPI);

  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: 40,
        marginVertical: 16,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Image
          source={
            dark
              ? require("../../../assets/login-wallet-Icon.png")
              : require("../../../assets/icon_light3.png")
          }
          style={{ height: 32, width: 38 }}
        />
        <Text
          style={{
            fontFamily: customFonts.bold,
            fontSize: 24,
            color: colors.text,
            marginLeft: 12,
          }}
          onPress={() => {
            navigation.navigate(adminNavigationString.ADMIN_PORTAL.name);
          }}
        >
          IDJCore{" "}
          {Constants.manifest.extra?.environment === ENVIRONMENT.develop &&
            ENVIRONMENT.develop}
        </Text>
      </View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        style={{ marginTop: 42 }}
        anchor={
          <Pressable
            uppercase={false}
            onPress={openMenu}
            style={{
              color: colors.text,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 15,
              gap: 15,
              borderColor: theme.colors.text,
            }}
          >
            <Avatar.Text
              size={32}
              label={getNameInitials(username)}
              color={colors.text}
              style={{ backgroundColor: colors.avatarBackground }}
            />
            <Text
              numberOfLines={1}
              style={{
                fontFamily: customFonts.regular,
                fontSize: 14,
                color: colors.text,
                // marginHorizontal: 16,
              }}
            >
              {username}
            </Text>
            <AntDesign name="caretdown" size={16} color={colors.text} />
          </Pressable>
        }
      >
        <Menu.Item
          style={{ minWidth: 200 }}
          onPress={logoutUser}
          title="Sign Out"
        />
      </Menu>
    </View>
  );
};

export default withTheme(CommonHeader);
