import { StyleSheet, View, FlatList, ScrollView } from "react-native";
import React from "react";
import SongsChartsItemData from "./SongsChartsItemData";
import { withTheme } from "react-native-paper";

const StatsCardView = ({ theme, data }) => {
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles(theme).contentContainerStyle}
      style={styles(theme).listContainer}
    >
      <FlatList
        showsVerticalScrollIndicator={false}
        data={data}
        numColumns={2}
        renderItem={({ item, index }) => {
          return (
            <View
              key={index}
              style={{
                backgroundColor: theme.colors.customCardColor,
                borderColor: theme.colors.divider,
                flexDirection: "row",
                borderBottomWidth: index === 0 || index === 1 ? 1 : 0,
                flex: 1,
                aspectRatio: 1,
              }}
            >
              <SongsChartsItemData
                rightborder={index % 2 == 0}
                onlyBox
                index={index}
                data={item}
              />
            </View>
          );
        }}
        keyExtractor={(item) => item.count}
      />
    </ScrollView>
  );
};

export default withTheme(StatsCardView);

const styles = (theme) =>
  StyleSheet.create({
    contentContainerStyle: {
      flex: 2,
    },
    listContainer: {
      padding: 15,
      backgroundColor: theme.colors.customCardColor,
      borderRadius: 10,
    },
  });
