import React from "react";
import { Image } from "react-native";
import { commonAvatarStyle } from "./commonAvatarStyle";

const CommonAvatar = ({ size = 48, src, circleShape = false, uri = false }) => {
  return !!uri ? (
    <Image
      size={size}
      source={{ uri: src }}
      style={commonAvatarStyle(size, circleShape).container}
    />
  ) : (
    <Image
      size={size}
      source={src}
      style={commonAvatarStyle(size, circleShape).container}
    />
  );
};

export default CommonAvatar;
