import { View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { Searchbar, useTheme } from "react-native-paper";
import {
  ActionsButtonIcons,
  CommonDataTable,
  CustomModal,
  Loader,
  commonStatisticButtonStyle,
} from "../../../components";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import ReportVersionContextAPI from "./ReportVersionConsumer";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
} from "../../../constants/GlobalVariables";
import { config } from "./reportVersion.config";
import PlatformListPopUp from "./PlatformListPopUp";
import useAxios from "../../../hooks/useAxios";
import AuthContextAPI from "../../../context/AuthContextAPI";
import axios from "axios";
import { VERSION_LOADING } from "./reducer/actions";
import { GET_ALL_REPORT_VERSIONS_URL } from "../../../constants/GlobalUrls";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const ReportVersionTable = () => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  let {
    state: { tableData, versionLoading },
    dispatch,
  } = useContext(ReportVersionContextAPI);

  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  const [showReportVersionPlatforms, setshowReportVersionPlatforms] = useState({
    record: null,
    data: [],
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && versionLoading) {
      getPlatforms();
    }

    return () => {
      isMoulded = false;
    };
  }, [versionLoading]);

  const handleErrorResponse = (error) => {
    checkNetworkReachable();
    if (error?.response?.status === 401) {
      logoutUser();
    } else if (!axios.isCancel(error) && error?.response) {
      if (error?.response?.data?.msg) {
        alert(error?.response?.data?.msg);
      } else {
        alert(DEFAULT_ERROR_MSG);
      }
    }
  };

  const getPlatforms = async () => {
    let payload = {
      reportVersionId: showReportVersionPlatforms.record?.id,
    };

    const handleSuccessResponse = (response) => {
      setshowReportVersionPlatforms({
        ...showReportVersionPlatforms,
        data: response,
      });
    };

    await api
      .post(GET_ALL_REPORT_VERSIONS_URL, payload, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
    dispatch({ type: VERSION_LOADING, payload: false });
  };

  const onClickViewPlatform = (record) => {
    dispatch({ type: VERSION_LOADING, payload: true });
    setshowReportVersionPlatforms({
      ...showReportVersionPlatforms,
      record: record,
    });
  };

  const closeReportVersionPlatformsPopUp = () => {
    setshowReportVersionPlatforms({
      record: null,
      data: [],
    });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
      width: 1,
      // bold: true,
    },
    {
      key: "name",
      title: "Report Version",
      dataIndex: "name",
      sort: true,
      filter: true,
      width: 1,
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      search: true,
      sort: true,
    },
    {
      key: "Platform",
      title: "Platform",
      dataIndex: "Platform",
      center: true,
      hideToolTip: true,
      width: 0.5,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.VIEW}
            handleOnPress={() => {
              onClickViewPlatform(record);
            }}
          />
        );
      },
    },
  ];
  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.TABLE_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
      <CustomModal
        visible={showReportVersionPlatforms.record}
        hideModal={closeReportVersionPlatformsPopUp}
      >
        <Loader loading={versionLoading} />
        <PlatformListPopUp
          isLoading={versionLoading}
          record={showReportVersionPlatforms?.record}
          data={showReportVersionPlatforms?.data}
        />
      </CustomModal>
    </View>
  );
};

export default ReportVersionTable;
