import { StyleSheet, View } from "react-native";
import React from "react";
import { useTheme } from "react-native-paper";
import SecondaryHeaderTextSemiBold from "../../../components/CustomText/SecondaryHeaderTextSemiBold";
import SelectedFilterContainer from "./SelectedFilterContainer";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { REPORT_STATUS, config } from "./report.config";

const ShowAllSelectFilters = ({ onlyFilters = false, data }) => {
  let theme = useTheme();

  const {
    START_MONTH,
    END_MONTH,
    CLIENTS_TITLE,
    CONTRACT_TITLE,
    LABELS_TITLE,
    RELEASE_TITLE,
    TRACKS_TITLE,
  } = config;

  return (
    <View style={showAllSelectFilterStyles(theme).container}>
      <SecondaryHeaderTextSemiBold text={"Applied Filters"} />
      <View style={showAllSelectFilterStyles(theme).filterTextContainer}>
        {!onlyFilters && (
          <SelectedFilterContainer
            title={"Status"}
            value={
              data?.status ? (
                data?.status === REPORT_STATUS.COMPLETED ? (
                  <FontAwesome
                    name="circle"
                    size={14}
                    color={theme.colors.primary}
                  />
                ) : (
                  <Feather
                    name="loader"
                    size={16}
                    color={theme.colors.editBtnIcon}
                  />
                )
              ) : (
                ""
              )
            }
            custom={data?.status}
          />
        )}
        <SelectedFilterContainer
          title={START_MONTH}
          value={data?.start_month}
        />
        <SelectedFilterContainer title={END_MONTH} value={data?.end_month} />
        <SelectedFilterContainer
          title={CLIENTS_TITLE}
          value={data?.client_names && data?.client_names[0]}
        />
        <SelectedFilterContainer
          title={CONTRACT_TITLE}
          value={data?.contract_names && data?.contract_names[0]}
        />
        <SelectedFilterContainer
          title={LABELS_TITLE}
          value={data?.label_names && data?.label_names[0]}
        />
        <SelectedFilterContainer
          title={RELEASE_TITLE}
          value={data?.release_names && data?.release_names[0]}
        />
        <SelectedFilterContainer
          title={TRACKS_TITLE}
          value={data?.track_names && data?.track_names[0]}
        />
        {!onlyFilters && (
          <SelectedFilterContainer
            title={"Generated by"}
            value={data?.generated_by}
          />
        )}
      </View>
    </View>
  );
};

export default ShowAllSelectFilters;

export const showAllSelectFilterStyles = (theme) =>
  StyleSheet.create({
    container: {
      borderWidth: 1,
      borderRadius: 5,
      padding: 10,
      backgroundColor: theme.colors.selectedTableBackgroundColor,
      marginBottom: 15,
    },
    filterTextContainer: {
      flexDirection: "row",
      gap: 10,
      flexWrap: "wrap",
      marginTop: 5,
    },
  });
