import { Text } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import MissingDspDeals from "./MissingDspDeals";
import {
  DEAL_TYPE_BASED_ON_TAB,
  TABLENAMES,
  TABS,
} from "./missingDspDeals.config";
import {
  GET_ALL_MISSING_TRACK_ON_UPC_URL,
  GET_ALL_MISSING_LABELS_URL,
  GET_ALL_MISSING_RELEASES,
} from "../../../constants/GlobalUrls";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { ConfirmationWindow, CustomModal } from "../../../components";
import { dealFormForLabelStyle } from "../ContractManager/ContractManagerDealForm/dealFormForLabelStyle";
import { Entypo } from "@expo/vector-icons";
import { useTheme } from "react-native-paper";
import { ContractFormContext } from "../../../context/ContractFormContextAPI";
import ContractManagerDealForm from "../ContractManager/ContractManagerDealForm/ContractManagerDealForm";
import ContractManagerForm from "../ContractManager/ContractManagerForm/ContractManagerForm";
import { config as pageConfig } from "../ContractManager/ContractManager.config";
import SelectContractTypePopUp from "./SelectContractTypePopUp";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const MissingDspLandingPage = () => {
  let theme = useTheme();
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [activeTab, setActiveTab] = useState(TABS.LABEL);
  const [isLoading, setIsLoading] = useState(true);
  const [recordData, setRecordData] = useState({});
  const [dealRecordData, setDealRecordData] = useState({});
  const [dealFormPrefillData, setDealFormPrefillData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [preSelectedContracttypeid, setPreSelectedContracttypeid] =
    useState("");
  const [showSelectContractPopUp, setShowSelectContractPopUp] = useState(false);

  const [showPage, setShowPage] = useState({
    landing: true,
    contractForm: false,
    dealForm: false,
  });

  const [showWarningMessage, setShowWarningMessage] = useState({
    show: false,
    switchTab: "",
  });

  const [allTableData, setAllTableData] = useState({
    labelTableData: [],
    releaseTableData: [],
    trackOnUpcTableData: [],
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getTableData();
    }
    return () => {
      isMoulded = false;
    };
  }, [refreshTable]);

  const resetLandingPage = () => {
    setRefreshTable(!refreshTable);
    setDealFormPrefillData([]);
    setRecordData({});
    setDealRecordData({});
    setPreSelectedContracttypeid("");
    setActiveTab(TABS.LABEL);
  };

  const changeShowPage = (pageName, resetPage = false) => {
    setShowPage({
      landing: false,
      contractForm: false,
      dealForm: false,
      [pageName]: true,
    });
    if (resetPage) {
      resetLandingPage();
    }
  };

  const closeShowWarningMessage = () => {
    setShowWarningMessage({
      show: false,
      switchTab: "",
    });
  };

  const resetSelection = () => {
    let removeSelection = [...allTableData[activeTab]].map(
      ({ existing, ...rest }) => rest
    );
    getRecordSelectedData(removeSelection);
  };

  const handleYesOnClick = () => {
    resetSelection();
    setActiveTab(showWarningMessage.switchTab);
    closeShowWarningMessage();
  };

  const onTabChange = (tab) => {
    if (allTableData[activeTab].some((el) => el?.existing === true)) {
      setShowWarningMessage({
        show: true,
        switchTab: tab,
      });
    } else {
      setActiveTab(tab);
    }
  };

  const getRecordSelectedData = (data) => {
    setAllTableData({ ...allTableData, [activeTab]: data });
  };

  const getTableData = async () => {
    setIsLoading(true);
    let endpoints = [
      GET_ALL_MISSING_LABELS_URL,
      GET_ALL_MISSING_RELEASES,
      GET_ALL_MISSING_TRACK_ON_UPC_URL,
    ];

    await Promise.all(
      endpoints.map((endpoint) =>
        api.get(endpoint, {
          cancelToken: cancelTokenSource.token,
        })
      )
    )
      .then(
        axios.spread(
          (
            { data: labelTableData },
            { data: releaseTableData },
            { data: trackOnUpcTableData }
          ) => {
            setAllTableData({
              labelTableData,
              releaseTableData,
              trackOnUpcTableData,
            });
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setIsLoading(false);
  };

  const goToContractFormPage = () => {
    let filterSelectedDeals = allTableData[activeTab].filter(
      (el) => el.existing
    );
    setDealFormPrefillData(filterSelectedDeals);
    changeShowPage(pageConfig.pages.CONTRACT_FORM);
  };

  const getRecordData = (record) => {
    setRecordData({ ...record });
    goToContractFormPage();
  };

  const getDealRecordData = (record) => {
    setDealRecordData({ ...record });
  };

  const checkObjectHasEmptyKeysForContract = () => {
    return Object.keys(recordData).length !== 0;
  };

  const checkObjectHasEmptyKeysForDeals = () => {
    return Object.keys(dealRecordData).length !== 0;
  };

  const checkObjectHasEmptyKeysForDealsFormPrefillData = () => {
    return dealFormPrefillData.length !== 0;
  };

  const onClickCreateContractBtn = () => {
    setShowSelectContractPopUp(true);
  };

  const closeSelectContractPopUp = () => {
    setShowSelectContractPopUp(false);
    setPreSelectedContracttypeid("");
  };

  const onSubmitContractType = (id) => {
    setPreSelectedContracttypeid(id);
    setShowSelectContractPopUp(false);
    getRecordData({});
  };

  return (
    <>
      {showPage.landing && (
        <MissingDspDeals
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          activeTab={activeTab}
          onTabChange={onTabChange}
          getRecordSelectedData={getRecordSelectedData}
          allTableData={allTableData}
          getContractSelectedContract={getRecordData}
          onClickCreateContractBtn={onClickCreateContractBtn}
        />
      )}
      {(!!showPage.contractForm || !!showPage.dealForm) && (
        <ContractFormContext>
          {showPage.contractForm ? (
            <ContractManagerForm
              missingDspPage
              isEditMode={checkObjectHasEmptyKeysForContract()}
              changeShowPage={changeShowPage}
              recordData={recordData}
              getDealRecordData={getDealRecordData}
              hasDealFormPrefillData={checkObjectHasEmptyKeysForDealsFormPrefillData()}
              preSelectedContracttypeid={preSelectedContracttypeid}
            />
          ) : (
            <ContractManagerDealForm
              missingDspPage
              isExistingContractForm={checkObjectHasEmptyKeysForContract()}
              isEditMode={checkObjectHasEmptyKeysForDeals()}
              changeShowPage={changeShowPage}
              recordData={dealRecordData}
              dealFormPrefillData={dealFormPrefillData}
              setDealFormPrefillData={setDealFormPrefillData}
              hasDealFormPrefillData={checkObjectHasEmptyKeysForDealsFormPrefillData()}
              selectedDealType={DEAL_TYPE_BASED_ON_TAB[activeTab]}
            />
          )}
        </ContractFormContext>
      )}

      <CustomModal
        customTitle={
          <Entypo name="warning" size={24} color={theme.colors.warning} />
        }
        visible={showWarningMessage?.show}
        hideModal={closeShowWarningMessage}
      >
        <ConfirmationWindow
          customMessage={
            <Text style={dealFormForLabelStyle(theme).messageContainer}>
              Switching the table from{" "}
              <Text style={dealFormForLabelStyle(theme).messageBold}>
                {TABLENAMES[activeTab]}
              </Text>
              {" to "}
              <Text style={dealFormForLabelStyle(theme).messageBold}>
                {TABLENAMES[showWarningMessage?.switchTab]}
              </Text>{" "}
              with the selected rows will reset them. Are you sure you want to
              proceed with this action?
            </Text>
          }
          handleNoOnClick={closeShowWarningMessage}
          handleYesOnClick={handleYesOnClick}
        />
      </CustomModal>
      <CustomModal
        title="Select Contract Type"
        visible={showSelectContractPopUp}
        hideModal={closeSelectContractPopUp}
      >
        <SelectContractTypePopUp
          onSubmit={onSubmitContractType}
          cancelButton={closeSelectContractPopUp}
        />
      </CustomModal>
    </>
  );
};

export default MissingDspLandingPage;
