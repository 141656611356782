import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  ActionsButtonIcons,
  CommonDataTable,
  ConfirmationWindow,
  CustomModal,
} from "../../../../components";
import { FormLabel } from "../../../../components/FormControl";
import { ACTION_BUTTON_TYPE } from "../../../../constants/GlobalVariables";
import { addEditArrayOfObject } from "../../../../utils/addEditArrayOfObject";
import { deleteTableRecord } from "../../../../utils/deleteTableRecord";
import AdvancesForm from "./AdvancesForm";
import { TABLE_ACTION } from "./ContractManagerDealForm.config";

const AdvancesTable = ({ advanceFor, getAdvanceData, advancesData = [] }) => {
  const [showAdvancesForm, setShowAdvancesForm] = useState({
    showForm: false,
    record: {},
  });
  const [tableData, setTableData] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && advancesData.length > 0) {
      setTableData(advancesData);
    }
    return () => {
      isMoulded = false;
    };
  }, [advancesData]);

  const setAdvanceData = (rowData) => {
    let tempData = addEditArrayOfObject(tableData, rowData, record);
    setTableData(tempData);
    if (getAdvanceData) {
      getAdvanceData(tempData);
    }
    closeShowAdvancesForm();
  };

  const handleDelete = () => {
    let id = showDeleteConfirmation.record.id;
    let tempData = [...tableData];
    if (id === "New") {
      tempData = deleteTableRecord(tableData, showDeleteConfirmation.record);
    } else {
      let obj = tempData.find((item) => item.id === id);
      obj.action = TABLE_ACTION.delete;
    }
    setTableData(tempData);
    getAdvanceData(tempData);
    closeDeleteConfirmationWindow();
  };

  const { showForm, record } = showAdvancesForm;
  const openShowAdvancesForm = (rowdData = {}) => {
    setShowAdvancesForm({
      showForm: true,
      record: rowdData,
    });
  };
  const closeShowAdvancesForm = () => {
    setShowAdvancesForm({
      showForm: false,
      record: {},
    });
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  let columns = [
    {
      key: "id",
      title: "Advance ID",
      dataIndex: "id",
      width: 1,
      // bold: true,
    },
    {
      key: "type",
      title: "Advance Category",
      dataIndex: "type",
      // bold: true,
    },
    {
      key: "advancedate",
      title: "Advance Date",
      dataIndex: "advancedate",
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
    },
    {
      key: "currencycode",
      title: "Currency",
      dataIndex: "currencycode",
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "key",
      width: 0.6,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              openShowAdvancesForm(row);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.REMOVE,
      title: "Remove",
      dataIndex: "key",
      width: 0.6,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.REMOVE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(row);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <FormLabel bold text={"Advances"} />
      <CommonDataTable
        columns={columns}
        data={tableData}
        showAddButton
        handleAddBtnClick={() => {
          openShowAdvancesForm({});
        }}
        showPagination={false}
      />
      <CustomModal
        title={`${Object.keys(record).length > 0 ? "Edit" : "New"} Advance`}
        visible={showForm}
        hideModal={closeShowAdvancesForm}
      >
        <AdvancesForm
          data={record}
          cancelButton={closeShowAdvancesForm}
          getAdvanceData={setAdvanceData}
          advanceFor={advanceFor}
        />
      </CustomModal>
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.type} advance in the amount of ${showDeleteConfirmation?.record?.amount} ${showDeleteConfirmation?.record?.currencycode}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </View>
  );
};

export default AdvancesTable;

const styles = StyleSheet.create({});
