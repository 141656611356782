import { View } from "react-native";
import React from "react";
import { FormLabel } from "..";
import { Checkbox } from "react-native-paper";
import { formCheckBoxStyle } from "./formCheckBoxStyle";

const FormCheckBox = ({ label, name, onChange, value, required }) => {
  return (
    <View style={formCheckBoxStyle.container}>
      <Checkbox
        status={value ? "checked" : "unchecked"}
        onPress={() => {
          onChange(name, !value);
        }}
      />
      <FormLabel
        text={label}
        required={required}
        customStyle={{ marginTop: 4 }}
      />
    </View>
  );
};

export default FormCheckBox;
