import { View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { CustomButton, Loader, commonFormStyle } from "../../../components";
import { FormSelect } from "../../../components/FormControl";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
} from "../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import { CONTRACTS_TYPE_DSP_URL } from "../../../constants/GlobalUrls";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const SelectContractTypePopUp = ({ cancelButton, onSubmit }) => {
  let theme = useTheme();
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [contractTypeOptions, setContractTypeOptions] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getContractTypes();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const onChange = (name, value) => {
    setId(value);
  };

  const getContractTypes = async () => {
    const handleSuccessResponse = (res) => {
      setContractTypeOptions(res);
    };

    await api
      .get(CONTRACTS_TYPE_DSP_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
        setIsLoading(false);
      });
  };

  return (
    <View style={commonFormStyle(theme).modalFormContainer}>
      <Loader loading={isLoading} />
      <FormSelect
        required
        name={"contracttypeid"}
        label={"Select Contract Type"}
        options={contractTypeOptions}
        optionName="contracttypename"
        optionValue="id"
        onChange={onChange}
        value={id}
      />
      <View style={commonFormStyle(theme).actionBtnContainer}>
        <CustomButton
          disabled={!id}
          onPress={() => {
            onSubmit(id);
          }}
          text={BUTTON_TEXTS.SUBMIT}
          cutomButtonStyle={commonFormStyle(theme).submitBtn}
        />
        <CustomButton
          text={BUTTON_TEXTS.CANCEL}
          onPress={cancelButton}
          cutomButtonStyle={commonFormStyle(theme).backBtn}
        />
      </View>
    </View>
  );
};

export default SelectContractTypePopUp;
