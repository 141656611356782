import { Image, View } from "react-native";
import React, { useState } from "react";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { Searchbar, useTheme } from "react-native-paper";
import { config } from "./catalogue.config";
import { ACTION_BUTTON_TYPE } from "../../../constants/GlobalVariables";

const ReleaseTable = ({ data, selectActiveTab }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  let columns = [
    {
      key: "image_url",
      title: "Image",
      dataIndex: "image_url",
      width: 1,
      render: (image_url) => {
        return (
          <Image
            style={{
              width: 24,
              height: 24,
              borderRadius: 5,
            }}
            resizeMode="cover"
            source={{ uri: image_url }}
          />
        );
      },
    },
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
      width: 1,
    },
    {
      key: "artist_names",
      title: "Artist Name",
      dataIndex: "artist_names",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "release_title",
      title: "Release Title",
      dataIndex: "release_title",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "label_name",
      title: "Label",
      dataIndex: "label_name",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "upc",
      title: "UPC",
      dataIndex: "upc",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "release_date",
      title: "Release Date",
      dataIndex: "release_date",
      sort: true,
      filter: true,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sort: true,
      filter: true,
      width: 1,
    },
    {
      key: "COG",
      title: "COG",
      dataIndex: "COG",
      center: true,
      hideToolTip: true,
      width: 0.5,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            borderRadius={25}
            buttonType={ACTION_BUTTON_TYPE.VIEW_MORE}
            // handleOnPress={() => {
            //   openShowAdvancesForm(row);
            // }}
          />
        );
      },
    },
  ];
  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.RELEASE_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default ReleaseTable;
