import { StyleSheet, Text } from "react-native";
import React, { useState } from "react";
import { Chip, useTheme } from "react-native-paper";
import CustomModal from "../CustomModal/CustomModal";
import { ConfirmationWindow } from "..";

const CustomChip = ({
  onClose,
  label,
  value,
  removeKey,
  transparent = false,
  shouldConfirm = true,
}) => {
  let theme = useTheme();
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const closeRemoveConfirmationWindow = () => {
    setShowRemoveConfirmation(false);
  };
  const openRemoveConfirmationWindow = () => {
    if (shouldConfirm) {
      setShowRemoveConfirmation(true);
    } else {
      onClose(value, removeKey, label);
    }
  };
  return (
    <>
      <Chip
        style={
          transparent
            ? {
                backgroundColor: "transparent",
                borderColor: theme.colors.primary,
                borderWidth: 1,
              }
            : { backgroundColor: theme.colors.primary }
        }
        onClose={openRemoveConfirmationWindow}
      >
        {label}
      </Chip>
      <CustomModal
        visible={showRemoveConfirmation}
        hideModal={closeRemoveConfirmationWindow}
      >
        <ConfirmationWindow
          customMessage={
            <Text style={styles(theme).customMessageRegularText}>
              Are you sure you want to remove{" "}
              <Text style={styles(theme).customMessageBoldText}>{label}</Text>?
            </Text>
          }
          handleNoOnClick={closeRemoveConfirmationWindow}
          handleYesOnClick={() => {
            onClose(value, removeKey, label);
          }}
        />
      </CustomModal>
    </>
  );
};

export default CustomChip;

const styles = (theme) =>
  StyleSheet.create({
    customMessageRegularText: {
      width: 700,
      fontSize: 30,
      fontFamily: theme.customFonts.regular,
      textAlign: "center",
    },
    customMessageBoldText: {
      fontSize: 30,
      fontFamily: theme.customFonts.bold,
      textAlign: "center",
    },
  });
