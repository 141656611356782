import React from "react";
import { OverlappingDspDealProvider } from "./Reducer/OverlappingDspDealContextAPI";
import OverlappingDspDealComsumer from "./OverlappingDspDealComsumer";

const OverlappingDspDeals = () => {
  return (
    <OverlappingDspDealProvider>
      <OverlappingDspDealComsumer />
    </OverlappingDspDealProvider>
  );
};

export default OverlappingDspDeals;
