import React, { useContext, useEffect } from "react";
import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import CustomSidebarMenu from "../../../components/CommonSideBar/CustomSidebarMenu";
import adminNavigationString from "./adminNavigationString";
import AuthContextAPI from "../../../context/AuthContextAPI";

const Drawer = createDrawerNavigator();

const AdminAppDrawer = () => {
  const { userInformation, logoutUser } = useContext(AuthContextAPI);

  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (
        Object.keys(userInformation).length > 0 &&
        !("permissions" in userInformation)
      ) {
        logoutUser();
      }
    }
    return () => {
      isMoulded = false;
    };
  }, [userInformation]);

  return (
    <Drawer.Navigator
      // initialRouteName={adminNavigationString.ADVANCES.name}
      defaultStatus="open"
      screenOptions={{
        drawerType: isLargeScreen ? "permanent" : "back",
        overlayColor: "transparent",
        drawerStyle: { borderRightWidth: 0 },
      }}
      drawerContent={(props) => <CustomSidebarMenu {...props} />}
    >
      {Object.values(adminNavigationString).map((menuItem) => {
        if (
          ("permissions" in userInformation &&
            userInformation?.permissions.includes(menuItem.title)) ||
          menuItem.name === adminNavigationString.ADMIN_PORTAL.name
        ) {
          return (
            <Drawer.Screen
              key={menuItem.name}
              name={menuItem.name}
              component={menuItem.component}
              options={{
                inProgress: menuItem.inProgress,
                headerShown: isLargeScreen ? false : true,
                title: menuItem.title,
                drawerItemStyle: menuItem.drawerItemStyle,
                unmountOnBlur: true,
              }}
            />
          );
        }
      })}
    </Drawer.Navigator>
  );
};

export default AdminAppDrawer;
