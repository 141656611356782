import { StyleSheet } from "react-native";

export const loginStyle = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 80,
    marginBottom: 40,
    alignItems: "center",
  },
  titleText: {
    flex: 0.7,
  },
  logo: {
    height: 56,
    width: 56,
  },
  signInBtn: {
    marginTop: 40,
  },
  bottomButton: {
    marginTop: 20,
  },
  bottomLinkButton: {
    marginTop: 40,
  },
});
