import React, { useContext, useEffect, useState } from "react";
import { Image, Keyboard, Text, View } from "react-native";
import { withTheme } from "react-native-paper";
import { CommonLayoutWrapper, CustomButton } from "../../components";
import CustomTextInput from "../../components/CustomTextInput";
import MessageValidation from "../../components/MessageValidation";
import {
  BUTTON_TYPE,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  ENVIRONMENT,
  MESSAGE_TYPE,
} from "../../constants/GlobalVariables";
import AuthContextAPI from "../../context/AuthContextAPI";
import authNavigationStrings from "../../navigation/AuthStack/authNavigationStrings";
import { checkObjectIsEmpty } from "../../utils/checkObjectIsEmpty";
import Constants from "expo-constants";
import { customStyle } from "../../components/CustomText/customStyle";
import { loginStyle } from "./loginStyle";
import { config } from "./login.config";
import { commonStyle } from "../../components/CommonStyles/commonStyle";

const Login = ({ navigation, theme }) => {
  let initialState = {
    form: { username: "", password: "" },
    message: {
      show: false,
      type: "",
      message: "",
    },
  };
  const { dark } = theme;

  const {
    TITLE_TEXT,
    USERNAME_FPH,
    PASSWORD_FPH,
    SIGN_IN_BTN,
    SIGN_UP_BTN,
    FORGOT_PSWD_LINK,
  } = config;

  // auth context to handle login
  const { loginUser } = useContext(AuthContextAPI);

  const [formData, setFormData] = useState(initialState.form);
  const [message, setMessage] = useState(initialState.message);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      setFormData(initialState.form);
      setMessage(initialState.message);
    });
  }, [navigation]);

  const { username, password } = formData;

  const onChangeText = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    let isEmpty = checkObjectIsEmpty(formData);
    if (isEmpty) {
      let response = await loginUser(formData);
      if (response) {
        setMessage(response);
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <CommonLayoutWrapper marginTop={20}>
      <View style={commonStyle.fixWidthContainer}>
        <View style={loginStyle.titleContainer}>
          <View style={loginStyle.titleText}>
            <Text style={customStyle(theme).boldText28}>
              {TITLE_TEXT}
              {Constants.manifest.extra.environment === ENVIRONMENT.develop &&
                ENVIRONMENT.develop}
            </Text>
          </View>
          <Image
            source={
              dark
                ? require("../../../assets/login-wallet-Icon.png")
                : require("../../../assets/icon_light3.png")
            }
            style={loginStyle.logo}
          />
        </View>
        <View style={commonStyle.commonMarginBottonContainer}>
          <CustomTextInput
            name="username"
            placeholder={USERNAME_FPH}
            onChangeText={onChangeText}
            value={username}
          />
        </View>
        <CustomTextInput
          name="password"
          placeholder={PASSWORD_FPH}
          onChangeText={onChangeText}
          value={password}
          secureTextEntry
        />
        <View style={loginStyle.signInBtn}>
          <CustomButton
            text={SIGN_IN_BTN}
            buttontype={BUTTON_TYPE.PRIMARY}
            onPress={onSubmit}
          />
        </View>
        <View style={loginStyle.bottomButton}>
          <CustomButton
            text={SIGN_UP_BTN}
            buttontype={BUTTON_TYPE.SECONDARY}
            onPress={() =>
              navigation.navigate(authNavigationStrings.SIGN_UP.name)
            }
          />
        </View>
        <MessageValidation {...message} />
        <View style={loginStyle.bottomLinkButton}>
          <CustomButton
            text={FORGOT_PSWD_LINK}
            buttontype={BUTTON_TYPE.LINK}
            onPress={() =>
              navigation.navigate(authNavigationStrings.FORGOT_PASSWORD.name, {
                initial: false,
                screen: "login",
              })
            }
          />
        </View>
      </View>
    </CommonLayoutWrapper>
  );
};

export default withTheme(Login);
