import React, { useContext } from "react";
import { View, Text } from "react-native";
import { withTheme } from "react-native-paper";
import LandingPageContextAPI from "../../screens/Landing/LandingPageContextAPI";
import HorzontalLine from "../HorzontalLine/HorzontalLine";
import { config, dummyCardDetails } from "./landingPageCardInformation.config";
import { landingPageCardInformationStyle } from "./landingPageCardInformationStyle";

function LandingPageCardInformation({ theme }) {
  const { colors } = theme;
  //deconstruct  context
  const {
    data: { dashboardCardData },
  } = useContext(LandingPageContextAPI);

  return (
    <View style={{ flex: 3, margin: 15 }}>
      <View style={{ flex: 1, height: 40 }} />
      <HorzontalLine color={colors.black} thickness={0.5} />
      <View style={{ flex: 1, alignItems: "center", marginVertical: 15 }}>
        <Text style={landingPageCardInformationStyle(theme).titleText}>
          {config.CURRENT_BALANCE_TEXT}
        </Text>
        <Text style={landingPageCardInformationStyle(theme).amountText}>
          {dummyCardDetails.CURRENT_BALANCE_VALUE}
        </Text>
      </View>
      <HorzontalLine color={colors.black} thickness={0.5} />
      <View
        style={landingPageCardInformationStyle(theme).bottomDetailsContainer}
      >
        <View>
          <Text
            style={landingPageCardInformationStyle(theme).titleText}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {config.CARD_HOLDER_TEXT}
          </Text>
          <Text
            style={landingPageCardInformationStyle(theme).cardDetailsSubText}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {dashboardCardData?.name}
          </Text>
        </View>
        <View
          style={landingPageCardInformationStyle(theme).cardDetailLeftContainer}
        >
          <Text
            style={landingPageCardInformationStyle(theme).titleText}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {config.UPDATED_TEXT}
          </Text>
          <Text
            style={landingPageCardInformationStyle(theme).cardDetailsSubText}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {dummyCardDetails.UPDATED_VALUE}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default withTheme(LandingPageCardInformation);
