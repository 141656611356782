import React, { useContext } from 'react'
import PaymentContextAPI from './Reducer/PaymentsContextAPI';
import LandingPage from './LandingPage';
import PaymentDetailPage from './PaymentDetailPage';
import InvoicesDataContextAPI from '../InvoicesData/InvoicesDataContextAPI';
import InvoiceDetailPage from '../InvoicesData/InvoiceDetailPage';
import { Loader } from '../../../components';

const Payments = () => {

    let {
        state: { showLandingPage },
    } = useContext(PaymentContextAPI);

    let {
        state: { selectedRowData, loading },
    } = useContext(InvoicesDataContextAPI);

    return <>
        <Loader loading={loading} />
        {
            Object.keys(selectedRowData).length > 0 ?
                <InvoiceDetailPage />
                :
                showLandingPage
                    ? <LandingPage />
                    : <PaymentDetailPage />
        }</>;
}

export default Payments
