import { View } from "react-native";
import React, { useContext } from "react";
import {
  CustomButton,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import { config } from "./employeeAccounts.config";
import EmployeeAccountContextAPI from "./Reducer/EmployeeContextAPI";
import EmployeeAccountTable from "./EmployeeAccountTable";
import { useTheme } from "react-native-paper";
import {
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../constants/GlobalVariables";
import { LOADER, UPDATE_MULTIPLE_DATA } from "./Reducer/actions";
import { DOWNLOAD_EMPLOYEE_ACCOUNT_URL } from "../../../constants/GlobalUrls";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

let uuid = require("uuid");

const LandingPage = () => {
  let theme = useTheme();
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let {
    state: { isLoading, tableData },
    dispatch,
  } = useContext(EmployeeAccountContextAPI);

  const onClickCreateAccount = () => {
    dispatch({
      type: UPDATE_MULTIPLE_DATA,
      payload: { isLandingPage: false, selectedRow: {}, isEditMode: false },
    });
  };

  const onClickDownloadCSV = async () => {
    dispatch({ type: LOADER, payload: true });
    await api
      .get(DOWNLOAD_EMPLOYEE_ACCOUNT_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `employee_account_${uuid.v4()}.csv`);
        tempLink.click();
        dispatch({ type: LOADER, payload: false });
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const extraHeaderComponent = (
    <View style={commonStatisticButtonStyle(theme).statisticContainer}>
      <Statistics label={"Total"} value={tableData.length || 0} />
      <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
        <CustomButton
          text={config.CREATE_BTN_TEXT}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={onClickCreateAccount}
        />
        <CustomButton
          text={DOWNLOAD_CSV_TEXT}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={onClickDownloadCSV}
        />
      </View>
    </View>
  );
  return (
    <WebContentContainerWrapper
      title={config.TITLE}
      loading={isLoading}
      extraHeaderComponent={extraHeaderComponent}
    >
      <EmployeeAccountTable />
    </WebContentContainerWrapper>
  );
};

export default LandingPage;
