export const downloadFile = (id, url) => {
  if (
    window.confirm(
      `Are you sure you want to download the file with ID: ${id}, File: ${url
        .split("/")
        .pop()}?`
    )
  ) {
    window.open(url, "_blank");
  }
};

export const downloadPDF = (url) => {
  var link = document.createElement("a");
  if (typeof link.download === "string") {
    link.target = "_blank";
    link.href = url;
    link.setAttribute("download", true);

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(url, "_blank");
  }
};
