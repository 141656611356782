import React from "react";
import { EmployeeAccountProvider } from "./Reducer/EmployeeContextAPI";
import EmployeeAccountConsumer from "./EmployeeAccountConsumer";

const EmployeeAccounts = () => {
  return (
    <EmployeeAccountProvider>
      <EmployeeAccountConsumer />
    </EmployeeAccountProvider>
  );
};

export default EmployeeAccounts;
