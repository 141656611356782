import { StyleSheet } from "react-native";

export const breadCrumbStyle = (theme) =>
  StyleSheet.create({
    titleTextContainer: {
      marginVertical: 16,
      flexDirection: "row",
      alignItems: "baseline",
    },
    titleLink: {
      fontFamily: theme.customFonts.bold,
      fontSize: 30,
      color: theme.colors.text,
      // textDecorationLine: "underline",
    },
    titleText: {
      fontFamily: theme.customFonts.regular,
      fontSize: 30,
      color: theme.colors.text,
    },
  });
