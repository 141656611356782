import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { ActionsButtonIcons, CommonDataTable } from '../../../components';
import { ACTION_BUTTON_TYPE } from '../../../constants/GlobalVariables';
import { breadCrumbStyle } from '../../../components/BreadCrumb/breadCrumbStyle';
import { useTheme } from 'react-native-paper';
import InvoicesDataContextAPI from './InvoicesDataContextAPI';
import { config } from './invoice.config';
import PaymentContextAPI from '../Payments/Reducer/PaymentsContextAPI';
import { formatCurrency } from '../../../utils/formatCurrency';

const InvoicePaymentDetailsTable = ({ pageName }) => {
    let theme = useTheme();
    let {
        state: { invoicePaymentDetailTable },
    } = useContext(InvoicesDataContextAPI);

    let {
        getPaymentByID
    } = useContext(PaymentContextAPI);

    let columns = [
        {
            key: "paymentid",
            title: "ID",
            dataIndex: "paymentid",
            sort: true,
            search: true,
            width: 1.5,
            // bold: true,
        },
        {
            key: "clientname",
            title: "Client Name",
            dataIndex: "clientname",
            sort: true,
            search: true,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            filter: true,
        },
        {
            key: "date_requested",
            title: "Date Requested",
            dataIndex: "date_requested",
            sort: true,
        },
        {
            key: "datepaid",
            title: "Date Paid",
            dataIndex: "datepaid",
            sort: true,
        },
        {
            key: "amount",
            title: "Amount",
            dataIndex: "amount",
            sort: true,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "remainingamount",
            title: "Remaining Amt.",
            dataIndex: "remainingamount",
            sort: true,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            sort: true,
            filter: true,
        },
        {
            key: "paidvia",
            title: "Paid Via",
            dataIndex: "paidvia",
            sort: true,
            filter: true,
        },
        {
            key: "noofinvoices",
            title: "No. Of Invoices",
            dataIndex: "noofinvoices",
            sort: true,
            filter: true,
        },
        {
            key: "paymentstatus",
            title: "Payment Status",
            dataIndex: "paymentstatus",
            sort: true,
            filter: true,
        },
        {
            key: "approvedby",
            title: "Approved By",
            dataIndex: "approvedby",
            sort: true,
        },
    ];

    let invoiceColumns = [...columns, {
        key: "paymentid",
        title: "View",
        dataIndex: "paymentid",
        center: true,
        // hideToolTip: true,
        width: 1,
        customToolTip: () => {
            return "View payment detail";
        },
        render: (id) => {
            return (
                <ActionsButtonIcons
                    buttonType={ACTION_BUTTON_TYPE.FILE_SEARCH}
                    handleOnPress={() => {
                        getPaymentByID(id);
                    }}
                />
            );
        },
    }]

    return (
        <View style={{ marginTop: 36, gap: 15 }}>
            <Text style={breadCrumbStyle(theme).titleLink}>Payment Details {`(${invoicePaymentDetailTable.length})`}</Text>
            <CommonDataTable
                canFilterColumns
                columns={pageName === config.TITLE ? invoiceColumns : columns}
                data={invoicePaymentDetailTable}
                tableMaxHeight={700}
            />
        </View>
    );
}

export default InvoicePaymentDetailsTable;
