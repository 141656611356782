import { StyleSheet } from "react-native";

export const landingStyles = StyleSheet.create({
  pageContainer: { flex: 1 },
  headerContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    marginTop: 60,
    paddingBottom: 20,
  },
  headerTextContainer: {
    justifyContent: "flex-start",
    marginLeft: 12,
  },
  headerIconsContainer: {
    flexDirection: "row",
    flexGrow: 2,
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  icons: { justifyContent: "flex-start", height: 28, width: 28 },
  cardImageContainer: {
    position: "relative",
    marginBottom: 10,
  },
});
