export const addEditArrayOfObject = (arr, rowData, record) => {
  let tempData = [...arr];
  if (Object.keys(record).length > 0) {
    let foundIndex = tempData.findIndex((obj) => obj.key === record.key);
    tempData[foundIndex] = rowData;
  } else {
    tempData.push(rowData);
  }
  return tempData;
};
