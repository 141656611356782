import { BUTTON_TEXTS } from "../../../../constants/GlobalVariables";

export const config = (isEditMode) => {
  return {
    HEADER_TITLE: "Clients",
    HEADER_SUBTITLE: isEditMode ? " > Edit Client" : " > New Client",
    SUBMIT_BTN_TEXT: isEditMode ? BUTTON_TEXTS.SAVE : BUTTON_TEXTS.SUBMIT,
  };
};

export const clientTypeObj = {
  INDIVIDUAL: "individual",
  COMPANY: "company",
};

export const clientTypeOptions = [
  {
    title: "Individual",
    value: clientTypeObj.INDIVIDUAL,
  },
  {
    title: "Company",
    value: clientTypeObj.COMPANY,
  },
];
