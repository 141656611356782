import { StyleSheet, Text, View, FlatList } from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import NoData from "../NoData";
import { Checkbox, Searchbar, useTheme } from "react-native-paper";
import { customModalStyle } from "../CustomModal/customModalStyle";
import { TouchableOpacity } from "react-native-gesture-handler";
import { customStyle } from "../CustomText/customStyle";

const FilterColumnPopUp = ({ data, onClickApply }) => {
  let theme = useTheme();
  const [setselectedValues, setSetselectedValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    selectedColumnForFilter,
    columnsDetails,
    listOfValuesForSelection,
    filterCount,
  } = data;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (selectedColumnForFilter?.dataIndex in columnsDetails) {
        setSetselectedValues(
          columnsDetails[selectedColumnForFilter?.dataIndex]
        );
      } else {
        setSetselectedValues(listOfValuesForSelection);
      }
    }
    return () => {
      isMoulded = false;
    };
  }, []);

  const updateSelectedValues = (item) => {
    let temp = [...setselectedValues];
    if (setselectedValues.includes(item)) {
      temp = temp.filter((el) => el !== item);
    } else {
      temp.push(item);
    }
    setSetselectedValues(temp);
  };

  const getOptions = useCallback(() => {
    let filteredData = [...listOfValuesForSelection];
    if (searchTerm !== "") {
      filteredData = [...listOfValuesForSelection].filter((element) =>
        element.toString().toLocaleLowerCase().includes(searchTerm)
      );
    }
    return filteredData;
  }, [searchTerm]);

  const Separator = () => {
    return <View style={styles(theme).separatorContainer} />;
  };

  return (
    <View>
      <View style={styles(theme).headerContainer}>
        <Text style={customModalStyle(theme).title}>
          {selectedColumnForFilter
            ? selectedColumnForFilter?.title +
              " (" +
              setselectedValues.length +
              "-" +
              listOfValuesForSelection.length +
              ")"
            : ""}
        </Text>
      </View>
      <Searchbar
        placeholder={"Search..."}
        onChangeText={(query) => {
          setSearchTerm(query.toLocaleLowerCase());
        }}
        value={searchTerm}
      />
      <FlatList
        ListEmptyComponent={<NoData />}
        data={getOptions()}
        keyExtractor={(item, index) => index}
        ItemSeparatorComponent={Separator}
        renderItem={({ item }) => (
          <View style={styles(theme).renderMenuItemContainer}>
            <View>
              <Checkbox
                status={
                  setselectedValues.includes(item) ? "checked" : "unchecked"
                }
                onPress={() => {
                  updateSelectedValues(item);
                }}
              />
            </View>
            <View style={styles(theme).renderMenuItemTextLabelContainer}>
              <View style={styles(theme).itemLabelContainer}>
                <View>
                  <Text numberOfLines={1}>
                    {selectedColumnForFilter?.render
                      ? selectedColumnForFilter.render(item, item)
                      : item === null || item === ""
                      ? "(Blanks)"
                      : item}
                  </Text>
                </View>

                <View style={styles(theme).chipSelectedFilterCountContainer}>
                  <Text style={styles(theme).chipSelectedFilterCountLabel}>
                    {filterCount[item]}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        style={styles(theme).listContainer}
      />
      <View style={styles(theme).actionButtonContainer}>
        <TouchableOpacity
          style={styles(theme).selectClearBtnContainer}
          onPress={() => {
            setSetselectedValues(listOfValuesForSelection);
          }}
        >
          <Text style={customStyle(theme).regularText14}>Select All</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).selectClearBtnContainer}
          onPress={() => {
            setSetselectedValues([]);
          }}
        >
          <Text style={customStyle(theme).regularText14}>Clear All</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).appplyBtnContainer}
          onPress={() => {
            onClickApply(setselectedValues, selectedColumnForFilter);
          }}
        >
          <Text style={customStyle(theme).regularTextBlack14}>Apply</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default FilterColumnPopUp;

const styles = (theme) =>
  StyleSheet.create({
    appplyBtnContainer: {
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 25,
      backgroundColor: theme.colors.primary,
    },
    selectClearBtnContainer: {
      borderWidth: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 25,
      borderColor: theme.colors.primary,
    },
    renderMenuItemContainer: {
      flexDirection: "row",
      alignItem: "center",
      width: 500,
    },
    renderMenuItemTextLabelContainer: {
      justifyContent: "center",
      flex: 1,
      marginRight: 12,
    },
    actionButtonContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: 4,
    },
    listContainer: {
      height: 250,
      borderWidth: 1,
      marginVertical: 10,
      borderRadius: 5,
      borderColor: theme.colors.divider,
      width: 500,
    },
    headerContainer: { marginBottom: 5 },
    chipSelectedFilterCountContainer: {
      backgroundColor: theme.colors.primary,
      borderRadius: 25,
      paddingHorizontal: 5,
    },
    chipSelectedFilterCountLabel: { textAlign: 1 },
    itemLabelContainer: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
    separatorContainer: {
      height: 1,
      backgroundColor: theme.colors.divider,
      opacity: 0.3,
    },
  });
