import { StyleSheet } from "react-native";

export const styles = (theme, slide) =>
  StyleSheet.create({
    slide: {
      // paddingHorizontal: 20,
      // paddingBottom: 10,
      // paddingTop: 30,
      flexBasis: "100%",
      flex: 1,
      maxWidth: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.customCardColor,
      borderWidth: 0,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      borderRadius: 0,
      paddingBottom: slide ? 0 : 10,
      borderBottomEndRadius: slide ? 0 : 10,
      borderBottomStartRadius: slide ? 0 : 10,
    },
    slideText: {
      width: "100%",
      textAlign: "left",
      fontSize: 20,
    },
    header: { marginTop: 10 },
    tableContainer: { marginHorizontal: 15 },
  });

export default styles;
