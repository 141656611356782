import { StyleSheet, View, Text } from 'react-native'
import React, { useContext, useEffect } from 'react'
import { WebContentContainerWrapper } from '../../../components'
import PaymentContextAPI from './Reducer/PaymentsContextAPI';
import { RETURN_TO_LANDING_PAGE } from './Reducer/actions';
import { config } from './payment.config';
import { useTheme } from 'react-native-paper';
import { MAKE_THESE_STATUS_RED_COLOR } from '../InvoicesData/invoice.config';
import BoldText14 from '../../../components/CustomText/BoldText14';
import InvoiceDetailForPaymentTable from './InvoiceDetailForPaymentTable';
import { config as invoiceConfig } from "../InvoicesData/invoice.config";
import InvoicesDataContextAPI from '../InvoicesData/InvoicesDataContextAPI';
import { SELECTED_ROW } from '../InvoicesData/reducer/actions';
import { formatCurrency } from '../../../utils/formatCurrency';

const PaymentDetailPage = () => {
    let theme = useTheme();

    let {
        state: { selectedRowData, loading },
        pageName,
        getInvoiceDetailsTableData,
        dispatch: paymentDispatch,
    } = useContext(PaymentContextAPI);

    let { state: invoiceState, dispatch: invoiceDispatch } = useContext(InvoicesDataContextAPI);

    const gotToLandingPage = () => {
        paymentDispatch({ type: RETURN_TO_LANDING_PAGE });
    };


    const goToInvoiceDetailPage = () => {
        paymentDispatch({ type: SELECTED_ROW, payload: {} });
    };

    const goToInvoiceLandingPage = () => {
        paymentDispatch({ type: SELECTED_ROW, payload: {} });
        invoiceDispatch({ type: RETURN_TO_LANDING_PAGE });
    };

    useEffect(() => {
        let isMoulded = true
        if (isMoulded) {
            getInvoiceDetailsTableData()
        }
        return () => {
            isMoulded = false
        };
    }, []);

    const paymentBreadCrumbData = [
        {
            title: config.TITLE,
            onClickPress: gotToLandingPage,
        },
        {
            title: `Payment Details #${selectedRowData?.id}`,
            active: true,
        },
    ];

    const invoiceBreadCrumbData = [
        {
            title: invoiceConfig.TITLE,
            onClickPress: goToInvoiceLandingPage,
        },
        {
            title: `Invoice Details #${invoiceState?.selectedRowData?.id}`,
            onClickPress: goToInvoiceDetailPage,
        },
        {
            title: `Payment Details #${selectedRowData?.id}`,
            active: true,
        },
    ];



    const getLabelValue = (label, value = "") => {
        const displayValue = value ? value.toString().toUpperCase() : ""; // Handle null/undefined and convert to uppercase
        const color = MAKE_THESE_STATUS_RED_COLOR.includes(displayValue)
            ? theme.colors.error
            : theme.colors.text;
        return (
            <View style={styles(theme).labelValueContainer}>
                <BoldText14 text={`${label}: `} />
                <Text
                    style={{
                        color: color,
                    }}
                >
                    {value}
                </Text>
            </View>
        );
    };

    return (
        <WebContentContainerWrapper
            loading={loading}
            breadCrumbData={
                pageName === config.TITLE
                    ? paymentBreadCrumbData
                    : invoiceBreadCrumbData
            }
        >
            <View style={styles(theme).contentWrapper}>
                <View style={styles(theme).formContainer}>
                    <View style={{ flexDirection: "row", gap: 20 }}>
                        <View style={styles(theme).gapContainer}>
                            <View>
                                {getLabelValue("Client", selectedRowData?.client_name)}
                            </View>
                            <View>
                                {getLabelValue("Amount", formatCurrency(selectedRowData?.netamount))}
                            </View>
                            <View>
                                {getLabelValue("Currency", selectedRowData?.currency)}
                            </View>
                            <View>{getLabelValue("Status", selectedRowData?.status)}</View>
                        </View>
                        <View style={styles(theme).gapContainer}>
                            <View>
                                {getLabelValue("Date Requested", selectedRowData?.date_requested)}
                            </View>
                            <View>
                                {getLabelValue("Date Paid", selectedRowData?.remittance_date)}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <InvoiceDetailForPaymentTable pageName={pageName} />
        </WebContentContainerWrapper>
    );
}

export default PaymentDetailPage

const styles = (theme, pdfPresent = false) =>
    StyleSheet.create({
        formContainer: { width: 620 },
        gapContainer: { gap: 25, width: 500 },
        labelValueContainer: { flexDirection: "row" },
        dropdownMargin: { marginTop: -6 },
        marginTopContainer16: { marginTop: 16 },
        marginTopContainer20: { marginTop: 20, opacity: 0.3 },
        marginBottomContainer10: { marginBottom: 10 },
        dropdownTextInputContainer: {
            flexDirection: "row",
            gap: 10,
            marginTop: -21,
        },
        dropdownWithInputContainerWrapper: { width: 200, marginTop: 4 },
        inputWithDropdownContainerWrapper: { flex: 1 },
        pdf: {
            flex: 1,
            width: 200,
            height: 200,
        },
        contentWrapper: { flexDirection: "row" },
        pdfPreviewContainer: {
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            height: 265, //500
            width: 300, //175
            marginLeft: 30,
        },
        emptyPDFContainer: {
            justifyContent: "center",
            alignSelf: "center",
            flex: 1,
            borderWidth: 1,
            width: 170,
        },
        downloadBtn: {
            textDecorationLine: "underline",
            color: theme.colors.linkColor,
            fontFamily: theme.customFonts.bold,
            fontSize: 14,
            opacity: pdfPresent ? 1 : 0.5,
        },
    });
