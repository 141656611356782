import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import React, { useRef } from "react";
import { AntDesign } from "@expo/vector-icons";
import RBSheet from "react-native-raw-bottom-sheet";
import { withTheme } from "react-native-paper";
const screen = Dimensions.get("window");
const { height } = screen;

const FilterDropdown = ({
  theme,
  options,
  activeFilter = {},
  onChange,
  filterHeaderLabel = "Choose",
  placeholder = "",
  name = "",
  formField = false,
}) => {
  const modalRef = useRef(null);

  const onOpen = () => {
    modalRef.current?.open();
  };

  const onClose = () => {
    modalRef.current?.close();
  };

  const onItemSelection = (value) => {
    onChange(name, value);
    onClose();
  };

  return (
    <View style={styles(theme).filterContainer}>
      <View
        style={
          formField
            ? styles(theme).formFieldFilterWrapper
            : styles(theme).filterWrapper
        }
      >
        <TouchableOpacity
          style={
            formField
              ? styles(theme).formFieldFilterButton
              : styles(theme).filterButton
          }
          onPress={onOpen}
        >
          <View
            style={
              formField
                ? styles(theme).formFieldFilterButtonTextContainer
                : styles(theme).filterButtonTextContainer
            }
          >
            <Text
              style={
                formField
                  ? styles(theme, activeFilter.label).filterButtonTitleStyle
                  : styles(theme).titleStyle
              }
            >
              {activeFilter.label || placeholder}
            </Text>
          </View>
          <View style={styles(theme).filterButtonIconContainer}>
            <AntDesign
              name="caretdown"
              size={formField ? 14 : 8}
              color={
                activeFilter.label
                  ? theme.colors.text
                  : theme.colors.inputborderColor
              }
            />
          </View>
        </TouchableOpacity>
        <RBSheet
          ref={modalRef}
          height={height / 2}
          openDuration={250}
          closeOnDragDown
          customStyles={{
            container: { backgroundColor: theme.colors.card, borderRadius: 15 },
          }}
        >
          <FlatList
            showsVerticalScrollIndicator={false}
            keyExtractor={(item) => item.value}
            data={options}
            ListHeaderComponent={
              <View style={styles(theme).modalHeaderTitle}>
                <Text style={styles(theme).titleHeaderStyle}>
                  {filterHeaderLabel}
                </Text>
              </View>
            }
            renderItem={({ item, index, separators }) => (
              <TouchableOpacity
                style={{
                  backgroundColor:
                    activeFilter.value === item.value
                      ? theme.colors.primary
                      : theme.colors.card,
                }}
                key={item.key}
                onPress={() => {
                  onItemSelection(item);
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    marginHorizontal: 8,
                    borderBottomColor: theme.colors.divider,
                    borderBottomWidth:
                      activeFilter.value === item.value ||
                      options.length - 1 === index
                        ? 0
                        : 1,
                    paddingVertical: 8,
                  }}
                >
                  <Text style={styles(theme).dropdownItemTitle}>
                    {item.label}
                  </Text>
                </View>
              </TouchableOpacity>
            )}
          />
        </RBSheet>
      </View>
    </View>
  );
};

export default withTheme(FilterDropdown);

const styles = (theme, isPlaceholder) =>
  StyleSheet.create({
    filterContainer: { flex: 1, alignItems: "flex-end" },
    filterWrapper: { width: 125 },
    formFieldFilterWrapper: { width: "100%" },
    filterButton: {
      borderRadius: 50,
      borderWidth: 2,
      height: 25,
      borderColor: theme.colors.secondaryText,
      flexDirection: "row",
      alignItems: "center",
    },
    formFieldFilterButton: {
      height: theme.inputText.height,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: theme.colors.secondaryText,
      flexDirection: "row",
      alignItems: "center",
    },
    filterButtonTextContainer: { flex: 4, marginLeft: 8 },
    formFieldFilterButtonTextContainer: { marginLeft: 15 },
    filterButtonIconContainer: {
      flex: 1,
      alignItems: "flex-end",
      marginRight: 8,
    },
    titleStyle: {
      textAlign: "center",
      color: theme.colors.secondaryText,
      fontFamily: theme.customFonts.medium,
      fontSize: 12,
    },
    filterButtonTitleStyle: {
      fontSize: 16,
      fontFamily: theme.customFonts.medium,
      color: isPlaceholder ? theme.colors.text : theme.colors.inputborderColor,
    },
    titleHeaderStyle: {
      textAlign: "center",
      color: theme.colors.secondaryText,
      fontFamily: theme.customFonts.semiBold,
      fontSize: 20,
    },
    modalBodyContainer: {
      backgroundColor: theme.colors.card,
      borderRadius: 15,
    },
    modalHeaderTitle: { alignItems: "center", padding: 10 },
    dropdownItem: {
      height: 32,
      justifyContent: "center",
      paddingHorizontal: 0,
    },
    dropdownItemTitle: {
      textAlign: "center",
      color: theme.colors.secondaryText,
      fontFamily: theme.customFonts.medium,
      fontSize: 14,
    },
    itemDivider: {
      backgroundColor: theme.colors.secondaryText,
    },
  });
