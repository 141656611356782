import React from "react";
import {
  ContactSupport,
  ForgotPassword,
  Login,
  NewPassword,
  SignUp,
} from "../../screens";
import { createStackNavigator } from "@react-navigation/stack";
import authNavigationStrings from "./authNavigationStrings";
import { FontAwesome } from "@expo/vector-icons";

const Stack = createStackNavigator();

export default function AuthStack({ theme }) {
  const { customFonts, colors } = theme;
  return (
    <Stack.Navigator
      screenOptions={{
        headerTitleAlign: "center",
        headerTitleStyle: { fontFamily: customFonts.semiBold, fontSize: 20 },
        headerBackTitleVisible: false,
        headerShadowVisible: false,
        headerStyle: { backgroundColor: colors.background },
        headerBackImage: () => {
          return (
            <FontAwesome
              name="chevron-left"
              size={18}
              color={theme.colors.text}
              style={{ padding: 10 }}
            />
          );
        },
      }}
    >
      <Stack.Screen
        name={authNavigationStrings.LOGIN.name}
        component={Login}
        options={{
          headerShown: false,
          title: authNavigationStrings.LOGIN.title,
        }}
      />
      <Stack.Screen
        name={authNavigationStrings.FORGOT_PASSWORD.name}
        component={ForgotPassword}
        options={{ title: authNavigationStrings.FORGOT_PASSWORD.title }}
      />
      <Stack.Screen
        name={authNavigationStrings.PASSWORD_RESET.name}
        component={NewPassword}
        options={{ title: authNavigationStrings.PASSWORD_RESET.title }}
      />
      <Stack.Screen
        name={authNavigationStrings.SIGN_UP.name}
        component={SignUp}
        options={{ title: authNavigationStrings.SIGN_UP.title }}
      />
      <Stack.Screen
        name={authNavigationStrings.CONTACT_SUPPORT.name}
        component={ContactSupport}
        options={{ title: authNavigationStrings.CONTACT_SUPPORT.title }}
      />
    </Stack.Navigator>
  );
}
