import React from "react";
import { Text, View } from "react-native";
import { Button, withTheme } from "react-native-paper";
import CustomSideBarMenuIcon from "./CustomSideBarMenuIcon";
import { customSideBarMenuStyle } from "./customSideBarMenuStyle";

const CustomSidebarMenu = (props) => {
  let {
    descriptors,
    state: { index },
    theme,
  } = props;

  let desciptorsArr = Object.values(descriptors);

  let { colors } = theme;

  return (
    <View style={customSideBarMenuStyle(theme).container}>
      {desciptorsArr.map(({ route, navigation, options }, j) => {
        return (
          options?.drawerItemStyle?.height !== 0 &&
          options?.headerStyle?.height !== 0 && (
            <Button
              key={route.key}
              onPress={() => {
                navigation.navigate(route.name);
              }}
              uppercase={false}
              style={customSideBarMenuStyle(theme).drawerItemBtn}
              mode={index === j ? "contained" : "text"}
            >
              <View style={customSideBarMenuStyle(theme).buttonTextContainer}>
                <View style={{ width: 30 }}>
                  <CustomSideBarMenuIcon
                    screenName={route.name}
                    color={
                      options?.inProgress ? colors.borderColor : colors.infoText
                    }
                  />
                </View>
                <Text
                  style={
                    customSideBarMenuStyle(
                      theme,
                      index === j,
                      options?.inProgress || false
                    ).buttonText
                  }
                >
                  {options.title}
                </Text>
              </View>
            </Button>
          )
        );
      })}
    </View>
  );
};

export default withTheme(CustomSidebarMenu);
