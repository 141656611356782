import React from "react";
import { View, Text } from "react-native";
import { withTheme } from "react-native-paper";
import { formLabelCustomStyle, formLabelStyle } from "./formLabelStyle";

const FormLabel = ({
  text,
  theme,
  customStyle = {},
  required = false,
  bold = false,
  isValidated = false,
}) => {
  return (
    <View style={{ ...formLabelCustomStyle.container, ...customStyle }}>
      <Text
        style={
          isValidated
            ? formLabelStyle(theme).labelTextError
            : formLabelStyle(theme, bold).labelText
        }
      >
        {text}
      </Text>
      {!!required && <Text style={formLabelStyle(theme).labelRequired}>*</Text>}
    </View>
  );
};

export default withTheme(FormLabel);
