import React from "react";
import { withTheme } from "react-native-paper";
import { WebContentContainerWrapper } from "../../../components";

const AdminPortal = ({ theme }) => {
  // const {
  //   userInformation: { username },
  // } = useContext(AuthContextAPI);
  return (
    <WebContentContainerWrapper title={`Welcome!`}></WebContentContainerWrapper>
  );
};

export default withTheme(AdminPortal);
