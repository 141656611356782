import React, { useContext, useEffect } from "react";
import AppStack from "./AppStack/AppStack";
import AuthStack from "./AuthStack/AuthStack";
import AuthContextAPI from "../context/AuthContextAPI";
import * as Linking from "expo-linking";
import { NavigationContainer } from "@react-navigation/native";
import { getNavigationTheme } from "../themes/getTheme";
import { Loader } from "../components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

const PERSISTENCE_KEY = "NAVIGATION_STATE";

const prefix = Linking.createURL("/");

const MainNav = () => {
  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        password_reset: "password_reset/confirm",
      },
    },
  };

  const [initialState, setInitialState] = React.useState();
  const [isReady, setIsReady] = React.useState(false);

  const {
    user,
    userInformation: { darkmode },
  } = useContext(AuthContextAPI);

  const handleDeepLink = (event) => {
    let data = Linking.parse(event.url);
    setInitialState(data);
  };

  const getInitialURL = async () => {
    const initialURL = await Linking.getInitialURL();
    if (initialURL) setInitialState(Linking.parse(initialURL));
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      Linking.addEventListener("url", handleDeepLink);
      if (initialState) {
        getInitialURL();
      }
    }
    return () => {
      isMoulded = false;
      Linking.removeEventListener("url");
    };
  }, []);

  useEffect(() => {
    const restoreState = async () => {
      try {
        if (Platform.OS === "web") {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <NavigationContainer
      initialState={initialState}
      onStateChange={(state) =>
        AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
      }
      theme={getNavigationTheme(darkmode)}
      fallback={<Loader loading={true} />}
      linking={linking}
    >
      {user ? (
        <AppStack theme={getNavigationTheme(darkmode)} />
      ) : (
        <AuthStack theme={getNavigationTheme(darkmode)} />
      )}
    </NavigationContainer>
  );
};

export default MainNav;
