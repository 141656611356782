import {
  GO_TO_LANDING_PAGE,
  LOADER,
  REFRESH_TABLE,
  UPDATE_MULTIPLE_DATA,
  UPDATE_TABLE_DATA,
} from "./actions";

export const initialState = {
  isLoading: true,
  tableData: [],
  isLandingPage: true,
  selectedRow: {},
  refreshTable: false,
  isEditMode: false,
  userRoleOptions: [],
  permissionsOptions: [],
};

export const employeeAccountReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, isLoading: action.payload };
    case UPDATE_TABLE_DATA:
      return { ...state, tableData: action.payload };
    case GO_TO_LANDING_PAGE:
      return {
        ...state,
        isLandingPage: true,
        selectedRow: {},
        refreshTable: !state.refreshTable,
      };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    case REFRESH_TABLE:
      return { ...state, refreshTable: !state.refreshTable };
    default:
      return state;
  }
};
