import { StyleSheet } from "react-native";

export const customStyle = (theme) =>
  StyleSheet.create({
    headerText: {
      color: theme.colors.text,
      fontSize: 20,
      fontFamily: theme.customFonts.semiBold,
    },
    headerText20: {
      color: theme.colors.text,
      fontSize: 20,
      fontFamily: theme.customFonts.regular,
    },
    informationText: {
      color: theme.colors.infoText,
      fontSize: 14,
      fontFamily: theme.customFonts.regular,
    },
    regularText14: {
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: theme.customFonts.regular,
    },
    regularTextBlack14: {
      color: theme.colors.black,
      fontSize: 14,
      fontFamily: theme.customFonts.regular,
    },
    secondaryHeaderText: {
      color: theme.colors.text,
      fontSize: 16,
      fontFamily: theme.customFonts.medium,
    },
    statisticText: {
      color: theme.colors.text,
      fontSize: 18,
      fontFamily: theme.customFonts.bold,
    },
    chartLabelText: {
      color: theme.colors.text,
      fontSize: 12,
      fontFamily: theme.customFonts.regular,
    },
    heatMapValueText: {
      color: theme.colors.cardPrimaryText,
      fontSize: 12,
      fontFamily: theme.customFonts.regular,
    },
    contrastHeaderText: {
      color: theme.colors.contrastText,
      fontSize: 20,
      fontFamily: theme.customFonts.semiBold,
    },
    secondaryHeaderTextSemiBold: {
      color: theme.colors.text,
      fontSize: 16,
      fontFamily: theme.customFonts.semiBold,
    },
    statisticTextSemiBold: {
      color: theme.colors.text,
      fontSize: 24,
      fontFamily: theme.customFonts.semiBold,
    },
    boldText14: {
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: theme.customFonts.bold,
    },
    primaryColorText: {
      color: theme.colors.cardPrimaryText,
      fontSize: 14,
      fontFamily: theme.customFonts.bold,
    },
    boldText32: {
      color: theme.colors.text,
      fontSize: 32,
      fontFamily: theme.customFonts.bold,
    },
    boldText16: {
      color: theme.colors.infoText,
      fontSize: 16,
      fontFamily: theme.customFonts.bold,
    },
    boldText28: {
      color: theme.colors.text,
      fontSize: 28,
      fontFamily: theme.customFonts.bold,
    },
    textSemiBold14Black: {
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: theme.customFonts.regular,
    },
    textRegular14Blue: {
      color: theme.colors.editBtnIcon,
      fontSize: 14,
      fontFamily: theme.customFonts.semiBold,
    },
  });
