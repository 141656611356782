import React from 'react'
import Payments from './Payments'
import { PaymentProvider } from './Reducer/PaymentsContextAPI'
import { InvoiceDataProvider } from '../InvoicesData/InvoicesDataContextAPI'
import { config } from './payment.config'

const PaymentsConsumer = () => {
    return (
        <PaymentProvider>
            <InvoiceDataProvider pageName={config.TITLE}>
                <Payments />
            </InvoiceDataProvider>
        </PaymentProvider>
    )
}

export default PaymentsConsumer