import { DEAL_TYPES } from "../ContractManager/ContractManagerDealForm/ContractManagerDealForm.config";

export const config = {
  TITLE: "Overlapping DSP Deals",
  LABEL_SEARCH_PLACEHOLDER: "Search by Label ID/Label Name",
  RELEASE_SEARCH_PLACEHOLDER: "Search by ID/Label Name/Artist Name/Release Title/UPC",
  TRACK_ON_UPC_SEARCH_PLACEHOLDER: "Search by Label Name/Artist Name/Release Title/UPC/Track Title/ISRC",
};

export const TABS = {
    LABEL: "labelTableData",
    RELEASE: "releaseTableData",
    TRACKS_ON_UPC: "trackOnUpcTableData"
  }
  
  export const TABLENAMES = {
    labelTableData: "Label",
    releaseTableData: "Release",
    trackOnUpcTableData: "Track On UPC"
  }
  
  export const DEAL_TYPE_BASED_ON_TAB = {
    labelTableData: DEAL_TYPES.LABEL,
    releaseTableData: DEAL_TYPES.RELEASE,
    trackOnUpcTableData: DEAL_TYPES.TRACK_ON_UPC
  }