import { View, ImageBackground } from "react-native";
import React, { useEffect, useState } from "react";
import CommonCardHeaders from "../CommonCardHeaders/CommonCardHeaders";
import CustomVictoryPiechart from "../charts/CustomVictoryPiechart";
import CustomVictoryBarChart from "../charts/CustomVictoryBarChart";
import { withTheme, Text, Badge, Divider } from "react-native-paper";
import { customStyle } from "../CustomText/customStyle";
import GlobalHeatMapContent from "./GlobalHeatMapContent";
import Loader from "../Loader/Loader";
import {
  AGE_WISE_RECORD_URL,
  COUNTRY_RECORDS_URL,
  GENDER_WISE_RECORDS_URL,
} from "../../constants/GlobalUrls";
import useAxios from "../../hooks/useAxios";
import axios from "axios";
import { DEFAULT_ERROR_MSG } from "../../constants/GlobalVariables";
import { imageUrl } from "../../utils/imageUrl";

const Audience = ({ data, activeTab, itemIndex, theme }) => {
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    countryData: [],
    formatAgeWiseData: [],
    genderWiseData: {},
    ageWishData: [],
  });
  const getChartData = async () => {
    if (data?.id) {
      setLoading(true);
      let endpoints = [
        COUNTRY_RECORDS_URL,
        GENDER_WISE_RECORDS_URL,
        AGE_WISE_RECORD_URL,
      ];
      let formData = new FormData();
      formData.append("store_id", data?.id);
      await Promise.all(
        endpoints.map((endpoint) =>
          api.post(endpoint, formData, {
            cancelToken: cancelTokenSource.token,
          })
        )
      )
        .then(
          axios.spread(
            (
              { data: countryDataRes },
              { data: genderWiseDataRes },
              { data: ageWishDataRes }
            ) => {
              let resFormatAgeWiseData = Object.entries(genderWiseDataRes).map(
                ([key, val] = entry) => {
                  return { x: key, y: val };
                }
              );
              let responseData = {
                countryData: countryDataRes,
                formatAgeWiseData: resFormatAgeWiseData,
                genderWiseData: genderWiseDataRes,
                ageWishData: ageWishDataRes,
              };
              setChartData(responseData);
            }
          )
        )
        .catch((err) => {
          if (err?.status === 500) {
            alert(DEFAULT_ERROR_MSG);
          }
        });
      setLoading(false);
    }
  };

  const { countryData, formatAgeWiseData, ageWishData, genderWiseData } =
    chartData;
  useEffect(() => {
    let isMoulded = true;

    if (isMoulded) {
      if (activeTab === itemIndex) {
        getChartData();
      }
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [activeTab, itemIndex, data]);

  return (
    <View
      style={{
        backgroundColor: theme.colors.customCardColor,
        borderTopStartRadius: 10,
        borderTopEndRadius: 10,
      }}
    >
      <Loader
        loading={loading}
        backgroundColor={theme.colors.customCardColor}
      />
      <View style={{ marginTop: 10 }}>
        <CommonCardHeaders
          size={18}
          title={"Global Heatmap"}
          subtitle={"Audience by country"}
          customIcon={imageUrl(data?.logopath)}
          customIconUri
        />
      </View>
      <View style={{ paddingHorizontal: 15 }}>
        <ImageBackground
          source={require("../../../assets/images/map.png")}
          resizeMode="cover"
          style={{ width: "100%", height: 180 }}
          imageStyle={{ opacity: theme.dark ? 1 : 0.1 }}
        >
          <GlobalHeatMapContent data={countryData} theme={theme} />
        </ImageBackground>
      </View>
      <Divider style={{ height: 1, marginTop: 15, marginHorizontal: 15 }} />
      <View style={{ flex: 2, flexDirection: "row" }}>
        <View
          style={{
            flex: 1,
            borderRightWidth: 1,
            borderColor: theme.colors.divider,
          }}
        >
          <CommonCardHeaders
            size={18}
            title={"Gender"}
            subtitle={"Male/Female"}
            showIcon={false}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 15,
            }}
          >
            <View style={{ marginRight: 15 }}>
              <CustomVictoryPiechart data={formatAgeWiseData} theme={theme} />
            </View>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    marginRight: 5,
                    alignSelf: "flex-start",
                    marginTop: 5,
                  }}
                >
                  <Badge
                    size={8}
                    style={{
                      backgroundColor: theme.colors.cardPrimaryText,
                    }}
                  />
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Text style={customStyle(theme).chartLabelText}>Male</Text>
                  <Text style={customStyle(theme).chartLabelText}>
                    {genderWiseData?.male_percentage || 0}%
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    marginRight: 5,
                    alignSelf: "flex-start",
                    marginTop: 5,
                  }}
                >
                  <Badge
                    size={8}
                    style={{
                      backgroundColor: theme.colors.secondary,
                    }}
                  />
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Text style={customStyle(theme).chartLabelText}>Female</Text>
                  <Text style={customStyle(theme).chartLabelText}>
                    {genderWiseData?.female_percentage || 0}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ flex: 1, marginBottom: 10 }}>
          <CommonCardHeaders
            size={18}
            title={"Age"}
            subtitle={"Listeners by age"}
            showIcon={false}
          />
          <View style={{ marginLeft: 5 }}>
            <CustomVictoryBarChart
              data={ageWishData}
              x="agegroupid__agegroup"
              y="percentage"
            />
          </View>
        </View>
      </View>
      <Divider style={{ height: 1, marginHorizontal: 15 }} />
    </View>
  );
};

export default withTheme(Audience);
