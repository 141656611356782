import { StyleSheet, View } from "react-native";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  Loader,
  WebContentContainerWrapper,
  commonFormStyle,
} from "../../../components";
import {
  ACTIVATE_ACCOUNT_BTN,
  ADMIN_ROLE_VALUE,
  ADMIN_VALUE,
  CHANGE_PASSWORD_BTN,
  DEACTIVATE_ACCOUNT_BTN,
  accountTypeOptions,
  config,
} from "./employeeAccounts.config";
import EmployeeAccountContextAPI from "./Reducer/EmployeeContextAPI";
import {
  GO_TO_LANDING_PAGE,
  LOADER,
  UPDATE_MULTIPLE_DATA,
} from "./Reducer/actions";
import {
  FormControlInput,
  FormNestedDropdown,
  FormSelect,
  GeneratePassword,
} from "../../../components/FormControl";
import FormRadioGroup from "../../../components/FormControl/FormRadio/FormRadioGroup";
import FormRadioButton from "../../../components/FormControl/FormRadio/FormRadioButton";
import MessageValidation from "../../../components/MessageValidation";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INVALID_EMAIL_MSG,
  INVALID_PHONE_NUMBER_MSG,
  MESSAGE_TYPE,
} from "../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import {
  ACCOUNT_URL,
  GET_USER_ROLES_URL,
  GET_USER_ROLE_PERMISSIONS_URL,
  UPDATE_EMPLOYEE_PASSWORD,
} from "../../../constants/GlobalUrls";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { checkObjectIsEmpty } from "../../../utils/checkObjectIsEmpty";
import { checkEmailValidation } from "../../../utils/checkEmailValidation";
import { checkPhoneNumberValidation } from "../../../utils/checkPhoneNumberValidation";
import { confirmationStyle } from "../../../components/ConfirmationWindow/ConfirmationWindow";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const EmployeeAccountForm = () => {
  let theme = useTheme();
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let {
    state: {
      selectedRow,
      isLoading,
      isEditMode,
      userRoleOptions,
      permissionsOptions,
    },
    dispatch,
  } = useContext(EmployeeAccountContextAPI);

  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [showPopUp, setShowPopUp] = useState({
    show: false,
    title: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    accounttype: "",
    gender: "",
    subscribed: false,
    darkmode: false,
    password: "",
    staff: true,
    userrole: "",
    permissions: [],
    password: "",
  });

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (isEditMode) {
        let obj = {
          ...formData,
          ...selectedRow,
        };
        setFormData(obj);
      }
      getUserRoles();
    }
    return () => {
      isMoulded = false;
    };
  }, []);

  const getUserRoles = async () => {
    if (userRoleOptions.length === 0 && permissionsOptions.length === 0) {
      dispatch({ type: LOADER, payload: true });
      let endpoints = [GET_USER_ROLES_URL, GET_USER_ROLE_PERMISSIONS_URL];
      await axios
        .all(
          endpoints.map((endpoint) =>
            api.get(endpoint, {
              cancelToken: cancelTokenSource.token,
            })
          )
        )
        .then(
          axios.spread(
            ({ data: userRoleOptions }, { data: permissionsOptions }) => {
              dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: { userRoleOptions, permissionsOptions },
              });
            }
          )
        )
        .catch((error) => {
          checkNetworkReachable();
          if (error?.response?.status === 401) {
            logoutUser();
          } else if (!axios.isCancel(error) && error?.response) {
            alert(DEFAULT_ERROR_MSG);
          }
        });
      dispatch({ type: LOADER, payload: false });
    }
  };

  const closeShowNewAccountForm = () => {
    dispatch({ type: GO_TO_LANDING_PAGE, payload: {} });
  };

  const breadCrumbData = [
    {
      title: config.TITLE,
      onClickPress: closeShowNewAccountForm,
    },
    {
      title: `${
        Object.keys(selectedRow).length === 0 ? "New" : "Edit"
      } Account`,
      active: true,
    },
  ];

  const {
    username,
    email,
    phone,
    accounttype,
    gender,
    userrole,
    permissions,
    password,
    subscribed,
    darkmode,
    staff,
  } = formData;

  const getAllPermissions = () =>
    Object.values(permissionsOptions)
      .flat()
      .map((el) => el.permission_id);

  const getRoleBasedPermissions = (value) =>
    permissionsOptions[value].map((el) => el.permission_id);

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value, selectedName) => {
    if (message.show) {
      resetMessageObj();
    }
    if (name === "accounttype") {
      let updateValues = {};
      if (value === ADMIN_VALUE) {
        updateValues = {
          [name]: value,
          userrole: ADMIN_ROLE_VALUE,
          permissions: getAllPermissions(),
        };
      } else {
        updateValues = { [name]: value, userrole: "", permissions: [] };
      }
      setFormData({ ...formData, ...updateValues });
    } else if (name === "userrole") {
      let updateValues = {};
      updateValues = {
        [name]: value,
        permissions: getRoleBasedPermissions(value),
      };
      setFormData({ ...formData, ...updateValues });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getRolesOptions = useCallback(() => {
    let filteredOptions = [...userRoleOptions];
    if (accounttype) {
      if (accounttype !== ADMIN_VALUE) {
        filteredOptions = filteredOptions.filter(
          (el) => el.name !== ADMIN_ROLE_VALUE
        );
        return filteredOptions;
      }
    }
    return filteredOptions;
  }, [accounttype, userRoleOptions]);

  const handleErrorResponse = (error) => {
    checkNetworkReachable();
    if (error?.response?.status === 401) {
      logoutUser();
    } else if (!axios.isCancel(error) && error?.response) {
      if (error?.response?.data?.msg) {
        alert(error?.response?.data?.msg);
      } else {
        alert(DEFAULT_ERROR_MSG);
      }
    }
    dispatch({ type: LOADER, payload: false });
  };

  const handleSuccessResponse = (res) => {
    if (res?.msg) {
      alert(res.msg);
    }
    closeShowNewAccountForm();
  };

  const onSubmit = async () => {
    let validateData = {
      username,
      email,
      gender,
      accounttype,
      userrole,
      permissions,
      password,
    };
    let validateEditData = {
      username,
      email,
      gender,
      accounttype,
      userrole,
      permissions,
    };

    let isEmpty = isEditMode
      ? checkObjectIsEmpty(validateEditData)
      : checkObjectIsEmpty(validateData);

    if (isEmpty) {
      // Check if all the required fields are not empty.

      if (!checkEmailValidation(email)) {
        // Email validation.
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_EMAIL_MSG,
        });
        setFormData({
          ...formData,
          email: "",
        });
      } else if (phone && !checkPhoneNumberValidation(phone)) {
        // Phone number validation.
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_PHONE_NUMBER_MSG,
        });
        setFormData({
          ...formData,
          phone: "",
        });
      } else {
        dispatch({ type: LOADER, payload: true });

        if (isEditMode) {
          let payload = {
            username,
            email,
            phone,
            accounttype,
            gender,
            password,
            userrole,
            permissions,
            darkmode,
            subscribed,
            staff,
          };

          await api
            .put(ACCOUNT_URL, payload, {
              cancelToken: cancelTokenSource.token,
            })
            .then((res) => {
              handleSuccessResponse(res.data);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        } else {
          await api
            .post(
              ACCOUNT_URL,
              {
                username,
                email,
                phone,
                accounttype,
                gender,
                password,
                userrole,
                permissions,
                password,
                subscribed,
                darkmode,
                staff,
              },
              {
                cancelToken: cancelTokenSource.token,
              }
            )
            .then((res) => {
              handleSuccessResponse(res.data);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        }
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  const changeAccountStatus = async () => {
    closeShowUp();
    dispatch({ type: LOADER, payload: true });
    let payload = {
      ...selectedRow,
      active: !selectedRow?.active,
    };

    await api
      .put(ACCOUNT_URL, payload, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const updatePassword = async () => {
    closeShowUp();
    dispatch({ type: LOADER, payload: true });
    await api
      .put(
        `${UPDATE_EMPLOYEE_PASSWORD}${selectedRow?.id}/`,
        { password },
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const closeShowUp = () => {
    setShowPopUp({
      ...showPopUp,
      show: false,
    });
  };

  return (
    <WebContentContainerWrapper
      breadCrumbData={breadCrumbData}
      loading={isLoading}
    >
      <View style={commonFormStyle(theme).formContainer}>
        <>
          <FormControlInput
            required
            label="Username"
            name={"username"}
            value={username}
            onChange={onChange}
            isValidated={message.show && !username}
            disabled={isEditMode}
          />
          <FormControlInput
            required
            label="Email"
            name={"email"}
            value={email}
            onChange={onChange}
            isValidated={message.show && !email}
            disabled={isEditMode}
          />
          <FormControlInput
            label="Phone Number"
            name={"phone"}
            value={phone}
            onChange={onChange}
          />
          <FormRadioGroup
            required
            label={"Gender"}
            value={gender}
            name={"gender"}
            onChange={onChange}
            isValidated={message.show && !gender}
          >
            <FormRadioButton label={"Male"} value={"male"} />
            <FormRadioButton label={"Female"} value={"female"} />
          </FormRadioGroup>
          <FormSelect
            required
            label={"Select Account Type"}
            options={accountTypeOptions}
            onChange={onChange}
            value={accounttype}
            name={"accounttype"}
            isValidated={message.show && !accounttype}
          />
          <FormSelect
            required
            sortValues={false}
            optionName="name"
            optionValue="name"
            label={"Select Role"}
            options={getRolesOptions()}
            onChange={onChange}
            value={userrole}
            name={"userrole"}
            isValidated={message.show && !userrole}
            disabled={!accounttype || accounttype === ADMIN_VALUE}
          />
          <FormNestedDropdown
            required
            name={"permissions"}
            label={"Select Permissions"}
            options={permissionsOptions}
            value={permissions}
            onChange={onChange}
            isValidated={message.show && permissions.length === 0}
            disabled={!userrole}
          />
          {!isEditMode && (
            <GeneratePassword
              required
              label={"Password"}
              name={"password"}
              value={password}
              onChange={onChange}
            />
          )}
          <MessageValidation {...message} />
          <>
            {isEditMode && (
              <View style={commonFormStyle(theme).actionBtnContainer}>
                <CustomButton
                  onPress={() =>
                    setShowPopUp({
                      show: true,
                      title: selectedRow?.active
                        ? DEACTIVATE_ACCOUNT_BTN
                        : ACTIVATE_ACCOUNT_BTN,
                    })
                  }
                  text={
                    selectedRow?.active
                      ? DEACTIVATE_ACCOUNT_BTN
                      : ACTIVATE_ACCOUNT_BTN
                  }
                  cutomButtonStyle={commonFormStyle(theme).submitBtn}
                />
                <CustomButton
                  text={CHANGE_PASSWORD_BTN}
                  onPress={() =>
                    setShowPopUp({
                      show: true,
                      title: CHANGE_PASSWORD_BTN,
                    })
                  }
                  cutomButtonStyle={commonFormStyle(theme).submitBtn}
                />
              </View>
            )}
            <View
              style={
                isEditMode
                  ? commonFormStyle(theme).actionBtnContainerMarginTop20
                  : commonFormStyle(theme).actionBtnContainer
              }
            >
              <CustomButton
                onPress={onSubmit}
                text={BUTTON_TEXTS.SUBMIT}
                cutomButtonStyle={commonFormStyle(theme).submitBtn}
              />
              <CustomButton
                text={BUTTON_TEXTS.CANCEL}
                onPress={closeShowNewAccountForm}
                cutomButtonStyle={commonFormStyle(theme).backBtn}
              />
            </View>
          </>
        </>
        <CustomModal
          title={showPopUp.title}
          visible={showPopUp.show}
          hideModal={closeShowUp}
        >
          {showPopUp.title === CHANGE_PASSWORD_BTN && (
            <View style={confirmationStyle(theme).container}>
              <View style={styles.passswordGeneratorPopUpContainer}>
                <GeneratePassword
                  required
                  label={"Password"}
                  name={"password"}
                  value={password}
                  onChange={onChange}
                />
              </View>

              <View style={styles.passswordGeneratorPopUpButtonContainer}>
                <CustomButton
                  onPress={updatePassword}
                  text={"Update Password"}
                  cutomButtonStyle={commonFormStyle(theme).yesBtn}
                />
                <CustomButton
                  text={BUTTON_TEXTS.CANCEL}
                  onPress={closeShowUp}
                  cutomButtonStyle={commonFormStyle(theme).backBtn}
                />
              </View>
            </View>
          )}
          {(showPopUp.title === ACTIVATE_ACCOUNT_BTN ||
            showPopUp.title === DEACTIVATE_ACCOUNT_BTN) && (
            <ConfirmationWindow
              message={`Are you sure you want to ${
                selectedRow?.active ? "deactivate" : "activate"
              } this account with username ${selectedRow?.username}?`}
              handleNoOnClick={closeShowUp}
              handleYesOnClick={changeAccountStatus}
            />
          )}
        </CustomModal>
      </View>
    </WebContentContainerWrapper>
  );
};

export default EmployeeAccountForm;

const styles = StyleSheet.create({
  passswordGeneratorPopUpContainer: { width: "80%" },
  passswordGeneratorPopUpButtonContainer: {
    width: "80%",
    flexDirection: "row",
    marginTop: 40,
  },
});
