import { StyleSheet, View, Text } from "react-native";
import axios from "axios";
import { useTheme } from "react-native-paper";
import AdjustmentContextAPI from "./AdjustmentContextAPI";
import { dealFormForLabelStyle } from "../ContractManager/ContractManagerDealForm/dealFormForLabelStyle";
import {
  ConfirmationWindow,
  CustomButton,
  CustomModal,
} from "../../../components";
import { useContext, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { LOADER, UPDATE_MULTIPLE_DATA } from "./Reducer/actions";
import { COMPARE_MATRIX_URL } from "../../../constants/GlobalUrls";
import SelectedFilterContainer from "../Reports/SelectedFilterContainer";
import { config } from "./adjustment.config";
import { showAllSelectFilterStyles } from "../Reports/ShowAllSelectFilters";
import SecondaryHeaderTextSemiBold from "../../../components/CustomText/SecondaryHeaderTextSemiBold";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const CompareAdjustmentsFromFilters = () => {
  let theme = useTheme();
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  let {
    state: { client_id, reporting_year, client_name, service_name, service_id },
    dispatch,
  } = useContext(AdjustmentContextAPI);

  const getCompareMatrix = async () => {
    dispatch({ type: LOADER, payload: true });

    const handleSuccessResponse = (res) => {
      let tempData = [
        {
          reportingPeriod: " ",
          EUR: [],
          USD: [],
          GBP: [],
        },
        ...res?.data,
      ];
      dispatch({
        type: UPDATE_MULTIPLE_DATA,
        payload: {
          compareMatrixData: tempData,
          total_diff: res?.total_diff,
          loading: false,
        },
      });
    };

    await api
      .post(
        COMPARE_MATRIX_URL,
        {
          reporting_year: reporting_year,
          client_id: client_id[0],
          service_id: service_id[0]
        },
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const onPressGenerateReportBtn = async () => {
    closeShowWarningMessage();
    getCompareMatrix();
  };

  const closeShowWarningMessage = () => {
    setShowWarningMessage(false);
  };

  return (
    <View style={styles.btnContainer}>
      <CustomButton
        disabled={
          !(client_id.length > 0 && reporting_year && service_id.length > 0)
        }
        text={"Compare"}
        onPress={() => {
          setShowWarningMessage(true);
        }}
      />
      <CustomModal
        visible={showWarningMessage}
        hideModal={closeShowWarningMessage}
      >
        <ConfirmationWindow
          customMessage={
            <>
              <Text style={dealFormForLabelStyle(theme).messageContainer}>
                Are you certain about proceeding with the comparison generation
                using the specified filters?
              </Text>
              <View style={styles.containerForFilter}>
                <View style={showAllSelectFilterStyles(theme).container}>
                  <SecondaryHeaderTextSemiBold text={"Applied Filters"} />
                  <View
                    style={showAllSelectFilterStyles(theme).filterTextContainer}
                  >
                    <SelectedFilterContainer
                      title={config.CLIENTS_TITLE}
                      value={client_name && client_name[0]}
                    />
                    <SelectedFilterContainer
                      title={config.YEAR_TITLE}
                      value={reporting_year}
                    />
                    <SelectedFilterContainer
                      title={config.SERVICE_TITLE}
                      value={service_name && service_name[0]}
                    />
                  </View>
                </View>
              </View>
            </>
          }
          handleNoOnClick={closeShowWarningMessage}
          handleYesOnClick={onPressGenerateReportBtn}
        />
      </CustomModal>
    </View>
  );
};

export default CompareAdjustmentsFromFilters;

const styles = StyleSheet.create({
  btnContainer: { marginTop: 15 },
  containerForFilter: {
    width: 700,
    marginTop: 20,
  },
});
