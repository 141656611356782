import React from "react";
import ReportsPage from "./ReportsPage";
import { ReportProvider } from "./ReportsContextAPI";

const Reports = () => {
  return (
    <ReportProvider>
      <ReportsPage />
    </ReportProvider>
  );
};

export default Reports;
