import { LOADER, RESET_FILTERS, RETURN_TO_LANDING_PAGE, SELECTED_ROW, UPDATE_MULTIPLE_DATA } from "./actions";

export const initialState = {
    loading: false,
    tableData: [],
    showLandingPage: true,
    selectedRowData: {},
    paymentInvoiceDetailTable: [],
    existingInvoiceList: [],

    clientOptions: [],
    yearOptions: [],
    currencyOptions: [],

    client_name: [],
    currency_name: [],

    client_id: [],
    reporting_year: "",
    currency_id: [],
}

export const paymentDataReducer = (state, action) => {
    switch (action.type) {
        case LOADER:
            return { ...state, loading: action.payload };
        case UPDATE_MULTIPLE_DATA:
            return { ...state, ...action.payload };
        case RETURN_TO_LANDING_PAGE:
            return { ...state, selectedRowData: {}, showLandingPage: true };
        case SELECTED_ROW:
            return {
                ...state,
                selectedRowData: action.payload,
                showLandingPage: false,
            };
        case RESET_FILTERS:
            return {
                ...state,
                client_name: [],
                year_name: [],
                currency_name: [],
                client_id: [],
                reporting_year: "",
                currency_id: [],
                tableData: [],
            };
        default:
            return state;
    }
};
