import { TABLE_ACTION } from "../screens/WebDashboardPages/ContractManager/ContractManagerDealForm/ContractManagerDealForm.config";

const findAllValuesFromKey = (obj, keyToFind) => {
  return (
    Object.entries(obj).reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(value)
          : typeof value === "object" && value
          ? acc.concat(findAllValuesFromKey(value, keyToFind))
          : acc,
      []
    ) || []
  );
};

export const findAllByKey = (arr, keyToFind) => {
  let temp = [...arr].filter((item) => item.action !== TABLE_ACTION.delete);
  return findAllValuesFromKey(temp, keyToFind);
};
