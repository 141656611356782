import React, { useState } from "react";
import CreateNewClient from "./CreateNewClient/CreateNewClient";
import ClientManagerListingPage from "./ListingPage/ClientManagerListingPage";

const ClientManager = () => {
  const [showListingPage, setShowListingPage] = useState(true);
  const [recordData, setRecordData] = useState({});

  const hideShowListinPage = () => {
    setShowListingPage(!showListingPage);
  };

  const getRecordData = (record) => {
    setRecordData(record);
    hideShowListinPage();
  };

  const checkObjectHasEmptyKeys = () => {
    return Object.keys(recordData).length !== 0;
  };

  return showListingPage ? (
    <ClientManagerListingPage getRecordData={getRecordData} />
  ) : (
    <CreateNewClient
      isEditMode={checkObjectHasEmptyKeys()}
      hideShowListinPage={hideShowListinPage}
      recordData={recordData}
    />
  );
};

export default ClientManager;
