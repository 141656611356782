import { TABS } from "../overlappingDspDeal.config";
import { CHANGE_SHOW_PAGE, LOADER, CHANGE_ACTIVE_TAB, UPDATE_MULTIPLE_DATA } from "./actions";

export const initialState = {
  refreshTable: true,
  isLoading: true,

  labelTableData: [],
  releaseTableData: [],
  trackOnUpcTableData: [],

  activeTab: TABS.LABEL,
  landing: true,
  contractForm: false,
  dealForm: false,
};

export const overlappingDspDealReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, isLoading: action.payload };
    case CHANGE_SHOW_PAGE:
      return {
        ...state,
        landing: false,
        contractForm: false,
        dealForm: false,
        [action.payload]: true,
      };
    case CHANGE_ACTIVE_TAB:
        return { ...state, activeTab: action.payload };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
