import React from "react";
import { Text, View } from "react-native";
import { withTheme } from "react-native-paper";
import { statisticsStyle } from "./statisticsStyle";

const Statistics = ({ label, value, theme, color }) => {
  return (
    <View>
      <Text style={statisticsStyle(theme).label}>{label}</Text>
      <Text style={statisticsStyle(theme, color).value}>{value}</Text>
    </View>
  );
};

export default withTheme(Statistics);
