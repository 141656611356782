import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { withTheme } from "react-native-paper";
import AuthContextAPI from "../../context/AuthContextAPI";
import useAxios from "../../hooks/useAxios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  BUTTON_TYPE,
  LOGIN_AUTH_ACCESS_TOKEN,
  USER_INFORMATION,
} from "../../constants/GlobalVariables";
import axios from "axios";
import { USER_DETIALS } from "../../constants/GlobalUrls";
import { getData } from "../../utils/getItemFromStorage";
import nonAdminNavigationStrings from "../../navigation/AppStack/NonAdminAppStack/nonAdminNavigationStrings";
import { CustomButton } from "../../components";

const Home = ({ navigation, theme }) => {
  const { colors } = theme;
  // auth context to handle logout
  // const auth = useContext(AuthContext);
  const { logoutUser } = useContext(AuthContextAPI);

  // api hook
  let api = useAxios();

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!(userInfo && Object.keys(userInfo).length > 0)) {
      getUserDetails();
    } else {
      getStorage();
    }
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const getStorage = async () => {
    let userDetails = await getData(LOGIN_AUTH_ACCESS_TOKEN);
    if (userDetails) {
      setUserInfo(JSON.parse(AsyncStorage.getItem(USER_INFORMATION)));
    }
  };

  const getUserDetails = async () => {
    const handleSuccessResponse = (res) => {
      setUserInfo(res[0]);
      AsyncStorage.setItem(USER_INFORMATION, JSON.stringify(res[0]));
    };

    await api
      .get(USER_DETIALS, { cancelToken: cancelTokenSource.token })
      .then((res) => {
        handleSuccessResponse(res.data);
      });
  };
  return (
    <View style={styles.container}>
      <Text style={{ color: colors.text }}>Welcome, {userInfo?.username}!</Text>
      <View style={styles.bottomButton}>
        <CustomButton
          text="Change Password"
          onPress={() =>
            navigation.navigate(nonAdminNavigationStrings.CHANGE_PASSWORD.name)
          }
        />
      </View>
      <View style={styles.bottomButton}>
        <CustomButton
          text="Victory Chart Examples"
          onPress={() =>
            navigation.navigate(nonAdminNavigationStrings.VICTORY.name)
          }
        />
      </View>
      <View style={styles.bottomButton}>
        <CustomButton
          text="Sign Out"
          buttontype={BUTTON_TYPE.SECONDARY}
          onPress={logoutUser}
        />
      </View>
    </View>
  );
};

export default withTheme(Home);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  bottomButton: {
    marginTop: 16,
  },
});
