import { Text } from "react-native";
import React from "react";
import { withTheme } from "react-native-paper";
import { customStyle } from "./customStyle";

const SecondaryHeaderText = ({ text, theme }) => {
  return <Text style={customStyle(theme).secondaryHeaderText}>{text}</Text>;
};

export default withTheme(SecondaryHeaderText);
