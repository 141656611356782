import { View } from "react-native";
import React, { useState } from "react";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { updateSelectedRow } from "../Accounts/AccountEditPage/updateSelectedRow";
import { Checkbox, Searchbar, useTheme } from "react-native-paper";
import { config } from "./missingDspDeals.config";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";

const TrackOnUpcTable = ({ data, selectActiveTab, onChangeSeletedRow }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  const onPressUpdateSelectedRow = (id) => {
    let finalData = updateSelectedRow(data, id, "release_track_upc_id");
    onChangeSeletedRow(finalData);
  };

  let columns = [
    {
      key: "existing",
      title: "",
      dataIndex: "existing",
      width: 1,
      hideToolTip: true,
      render: (existing, record) => {
        return (
          <Checkbox
            status={existing ? "checked" : "unchecked"}
            onPress={() => {
              onPressUpdateSelectedRow(record?.release_track_upc_id);
            }}
          />
        );
      },
    },
    {
      key: "release_track_upc_id",
      title: "ID",
      dataIndex: "release_track_upc_id",
      sort: true,
      search: true,
    },
    {
      key: "label_name",
      title: "Label Name",
      dataIndex: "label_name",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "artist_name",
      title: "Artist Name",
      dataIndex: "artist_name",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "release_title",
      title: "Release Title",
      dataIndex: "release_title",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "release_upc",
      title: "UPC",
      dataIndex: "release_upc",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "track_title",
      title: "Track Title",
      dataIndex: "track_title",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "track_isrc",
      title: "ISRC",
      dataIndex: "track_isrc",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "start_reporting_period",
      title: "MIN From",
      dataIndex: "start_reporting_period",
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "end_reporting_period",
      title: "MAX To",
      dataIndex: "end_reporting_period",
      sort: true,
      search: true,
      filter: true,
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.TRACKS_ON_UPC_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default TrackOnUpcTable;
