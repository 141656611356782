import React, { useContext, useEffect, useState } from "react";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import { withTheme } from "react-native-paper";
import { CommonLayoutWrapper, CustomButton } from "../../components";
import authNavigationStrings from "../../navigation/AuthStack/authNavigationStrings";
import CustomTextInput from "../../components/CustomTextInput";
import {
  BUTTON_TYPE,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INFORMATION_TEXT,
  INVALID_EMAIL_MSG,
  MESSAGE_TYPE,
} from "../../constants/GlobalVariables";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import { numbersOptions, typeOptions } from "./signUp.config";
import MessageValidation from "../../components/MessageValidation";
import AuthContextAPI from "../../context/AuthContextAPI";
import { checkObjectIsEmpty } from "../../utils/checkObjectIsEmpty";
import { isValidateEmail } from "../../utils/isValidateEmail";

const SignUp = ({ navigation, theme }) => {
  let initialState = {
    form: {
      email: "",
      phone: "",
      type: "",
      details: "",
      songs_count: "",
      videos_count: "",
    },
    message: {
      show: false,
      type: "",
      message: "",
    },
  };
  const { colors } = theme;
  const { signUp } = useContext(AuthContextAPI);

  const [formData, setFormData] = useState(initialState.form);
  const [message, setMessage] = useState(initialState.message);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      setFormData(initialState.form);
      setMessage(initialState.message);
    });
  }, [navigation]);

  const onChangeText = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    let data = {
      ...formData,
      type: formData.type.value,
      details: formData.details.value,
      songs_count: formData.songs_count.value,
      videos_count: formData.videos_count.value,
    };
    Keyboard.dismiss();
    let isEmpty = checkObjectIsEmpty(data);
    if (isEmpty) {
      if (!isValidateEmail(data.email)) {
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_EMAIL_MSG,
        });
      } else {
        let response = await signUp(data);
        if (response) {
          setMessage(response);
          setFormData(initialState.form);
        }
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <CommonLayoutWrapper marginTop={20}>
      <View style={styles.titleContainer}>
        <Text style={{ color: colors.infoText }}>
          {INFORMATION_TEXT.signUp}
        </Text>
      </View>
      <View style={styles.inputFieldGap}>
        <CustomTextInput
          name="email"
          value={formData.email}
          onChangeText={onChangeText}
          placeholder="Email"
        />
        <CustomTextInput
          value={formData.phone}
          onChangeText={onChangeText}
          name="phone"
          placeholder="Phone number"
        />
        <FilterDropdown
          formField
          options={typeOptions}
          activeFilter={formData.type}
          onChange={onChangeText}
          name="type"
          placeholder="Where are you from?"
          filterHeaderLabel="Where are you from?"
        />

        <FilterDropdown
          formField
          options={typeOptions}
          activeFilter={formData.details}
          onChange={onChangeText}
          name="details"
          placeholder="What’s the size of your catalog?"
          filterHeaderLabel="What’s the size of your catalog?"
        />

        <FilterDropdown
          formField
          options={numbersOptions}
          activeFilter={formData.songs_count}
          onChange={onChangeText}
          name="songs_count"
          placeholder="Who are you?"
          filterHeaderLabel="Who are you?"
        />

        <FilterDropdown
          formField
          options={numbersOptions}
          activeFilter={formData.videos_count}
          onChange={onChangeText}
          name="videos_count"
          placeholder="What do you want to do?"
          filterHeaderLabel="What do you want to do?"
        />
      </View>

      <View style={styles.bottomButton}>
        <CustomButton
          text="Sign Up"
          buttontype={BUTTON_TYPE.PRIMARY}
          onPress={onSubmit}
        />
      </View>
      <MessageValidation {...message} />
      <View style={styles.bottomLinkButton}>
        <CustomButton
          text="Need Help?"
          buttontype={BUTTON_TYPE.LINK}
          onPress={() =>
            navigation.navigate(authNavigationStrings.CONTACT_SUPPORT.name)
          }
        />
      </View>
    </CommonLayoutWrapper>
  );
};

export default withTheme(SignUp);

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: 40,
  },
  bottomButton: {
    marginTop: 30,
  },
  bottomLinkButton: {
    marginTop: 64,
  },
  inputFieldGap: {
    gap: 10,
  },
});
