import React, { useContext } from "react";
import InvoicesDataContextAPI from "./InvoicesDataContextAPI";
import LandingPage from "./LandingPage";
import InvoiceDetailPage from "./InvoiceDetailPage";
import PaymentContextAPI from "../Payments/Reducer/PaymentsContextAPI";
import { Loader } from "../../../components";
import PaymentDetailPage from "../Payments/PaymentDetailPage";

const InvoicesDataConsumer = () => {
  let {
    state: { showLandingPage },
  } = useContext(InvoicesDataContextAPI);

  let {
    state: { selectedRowData, loading },
  } = useContext(PaymentContextAPI);

  return <>
    <Loader loading={loading} />
    {Object.keys(selectedRowData).length > 0 ?
      <PaymentDetailPage />
      : showLandingPage
        ? <LandingPage />
        : <InvoiceDetailPage />}</>;
};

export default InvoicesDataConsumer;
