import { View, StyleSheet } from "react-native";
import React from "react";
import HeaderWithFilter from "./HeaderWithFilter";
import TrendsTVStatsCard from "./TrendsTVStatsCard";
import TopCountriesTable from "./TopCountriesTable";
import ChannelScroll from "../ChannelsScroll/ChannelScroll";
import { chartData } from "../TrendsStats/trends.data";
import SecondaryHeaderTextSemiBold from "../CustomText/SecondaryHeaderTextSemiBold";
import InformationText from "../CustomText/InformationText";
import BoldText14 from "../CustomText/BoldText14";
import { AntDesign } from "@expo/vector-icons";
import PrimaryColorText from "../CustomText/PrimaryColorText";
import { withTheme } from "react-native-paper";
import Carousel from "../Carousel/Carousel";
import { commonStyle } from "../CommonStyles/commonStyle";

const TrendsTV = ({ theme }) => {
  const TVColumns = [
    {
      key: "track",
      title: "Track",
      dataIndex: "track",
      width: 3,
      render: (track) => <SecondaryHeaderTextSemiBold text={track} />,
    },
    {
      key: "channel",
      title: "Channel",
      dataIndex: "channel",
      width: 2,
      render: (channel) => <InformationText text={channel} />,
    },
    {
      key: "plays",
      title: "Plays",
      dataIndex: "plays",
      width: 1.5,
      render: (plays) => <BoldText14 text={plays} />,
    },
    {
      key: "change",
      title: "Change",
      dataIndex: "change",
      width: 1.5,
      render: (change) => (
        <View style={styles.changeColumn}>
          <AntDesign
            name="caretup"
            size={8}
            color={theme.colors.actionIconActive}
            style={styles.icon}
          />
          <PrimaryColorText text={`${change}%`} />
        </View>
      ),
    },
  ];

  const statsChartsTableData = [
    {
      title: "IDJTV - Last Week",
      subtitle: "IDJTV is a Music TV Station",
      headerImage: require("../../../assets/images/TVChannels/IDJTV.jpg"),
      chartData: chartData,
      columns: TVColumns,
      tableData: [
        {
          id: 1,
          track: "Mame Mi",
          channel: "IDJTV",
          plays: 1203,
          change: 30,
        },
        {
          id: 2,
          track: "Vudu",
          channel: "IDJTV",
          plays: 845,
          change: 10,
        },
        {
          id: 3,
          track: "Leden",
          channel: "IDJTV",
          plays: 332,
          change: 5,
        },
        {
          id: 4,
          track: "Magija",
          channel: "IDJTV",
          plays: 315,
          change: 12,
        },
      ],
    },
    {
      title: "IDJTV - Last Week",
      subtitle: "IDJTV is a Music TV Station",
      headerImage: require("../../../assets/images/TVChannels/IDJTV.jpg"),
      chartData: chartData,
      columns: TVColumns,
      tableData: [
        {
          id: 1,
          track: "Mame Mi",
          channel: "IDJTV",
          plays: 1203,
          change: 30,
        },
        {
          id: 2,
          track: "Vudu",
          channel: "IDJTV",
          plays: 845,
          change: 10,
        },
        {
          id: 3,
          track: "Leden",
          channel: "IDJTV",
          plays: 332,
          change: 5,
        },
        {
          id: 4,
          track: "Magija",
          channel: "IDJTV",
          plays: 315,
          change: 12,
        },
      ],
    },
  ];
  return (
    <View>
      <HeaderWithFilter />
      <View style={commonStyle.commonMarginContainer}>
        <ChannelScroll />
      </View>
      <View style={commonStyle.commonMarginTopContainer}>
        <Carousel items={statsChartsTableData} style="chartTable" />
      </View>
      <TrendsTVStatsCard />
      <TopCountriesTable />
    </View>
  );
};

export default withTheme(TrendsTV);

const styles = StyleSheet.create({
  icon: { height: 8, width: 8, marginRight: 5 },
  changeColumn: {
    flexDirection: "row",
    alignItems: "center",
  },
});
