import { StyleSheet } from "react-native";

export const customSideBarMenuStyle = (
  theme,
  activeBtn = false,
  inProgress = false
) =>
  StyleSheet.create({
    container: { marginHorizontal: 24, marginTop: 32 },
    drawerItemBtn: {
      alignItems: "flex-start",
      borderRadius: 25,
      marginBottom: 16,
    },
    buttonTextContainer: { flexDirection: "row", alignItems: "center" },
    buttonText: {
      color: inProgress? theme.colors.borderColor: activeBtn ? theme.colors.drawerActiveText : theme.colors.text,
      marginLeft: 16,
      fontFamily: theme.customFonts.medium,
      fontSize: 16,
    },
  });
