import { createContext, useContext, useEffect, useReducer } from "react";
import { adjustmentReducer, initialState } from "./Reducer/adjustmentsReducer";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { UPDATE_MULTIPLE_DATA } from "./Reducer/actions";
import { GET_CLIENTS_FILTER_URL, GET_SERVICES_URL, GET_YEARS_FILTER_URL } from "../../../constants/GlobalUrls";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const AdjustmentContextAPI = createContext();

export default AdjustmentContextAPI;

export const AdjustmentProvider = ({ children }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [state, dispatch] = useReducer(adjustmentReducer, initialState);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getAllOptions();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const getAllOptions = async () => {
    let endpoints = [
      { url: GET_CLIENTS_FILTER_URL, method: "post" },
      { url: GET_YEARS_FILTER_URL, method: "get" },
      { url: GET_SERVICES_URL, method: "get" },
    ];
    await axios
      .all(
        endpoints.map((endpoint) => {
          if (endpoint?.method === "get") {
            return api.get(endpoint?.url, {
              cancelToken: cancelTokenSource.token,
            });
          } else {
            return api.post(endpoint?.url, {
              cancelToken: cancelTokenSource.token,
            });
          }
        })
      )
      .then(
        axios.spread(({ data: clientOptions }, { data: yearOptions }, { data: serviceOptions }) => {
          dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: {
              clientOptions,
              yearOptions,
              serviceOptions,
              loading: false,
            },
          });
        })
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  let contextData = {
    state,
    dispatch,
    initialState,
  };
  return (
    <AdjustmentContextAPI.Provider value={contextData}>
      {children}
    </AdjustmentContextAPI.Provider>
  );
};
