import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { useTheme } from "react-native-paper";

const SelectedFilterContainer = ({ title, value = "", custom = false }) => {
  let theme = useTheme();
  return (
    <View style={styles(theme).container}>
      <View>
        <Text style={styles(theme).titleText}>{title}:</Text>
      </View>
      <View>
        {custom ? value : <Text style={value? styles(theme).valueText: {}}>{value || '-'}</Text>}
      </View>
    </View>
  );
};

export default SelectedFilterContainer;

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      gap: 5,
      justifyContent: "center",
      alignItems: "flex-end",
    },
    titleText: {
      color: theme.colors.datatableTitleText,
      fontFamily: theme.customFonts.semiBold,
    },
    valueText: {
      fontSize: 12,
      color: theme.colors.editBtnIcon,
      borderWidth: 1,
      borderColor: theme.colors.editBtnIcon,
      borderRadius: 5,
      paddingHorizontal: 5,
    },
  });
