import { createContext, useContext, useEffect, useReducer } from "react";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { initialState, invoiceDataReducer } from "./reducer/invoiceDataReducer";
import {
  GET_ALL_REPORT_VERSIONS_URL,
  GET_CLIENT_FOR_INVOICES,
  GET_INVOICE_PAYMENT_DETAILS,
  GET_INVOICE_TYPES_URL,
  GET_SERVICES_URL,
  INVOICES_URL,
} from "../../../constants/GlobalUrls";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { LOADER, SELECTED_ROW, UPDATE_MULTIPLE_DATA } from "./reducer/actions";
import { config } from "./invoice.config";

const InvoicesDataContextAPI = createContext();

export default InvoicesDataContextAPI;

export const InvoiceDataProvider = ({ children, pageName = config.TITLE }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [state, dispatch] = useReducer(invoiceDataReducer, initialState);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && pageName === config.TITLE) {
      getAllOptions();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && pageName === config.TITLE) {
      if (!state?.showLandingPage) {
        if (state?.selectedRowData?.id) {
          getInvoiceTypeOptions();
        }
      } else {
        if (state?.tableData.length > 0) {
          getData();
        }
      }
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [state?.showLandingPage]);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && pageName !== config.TITLE) {
      if (state?.selectedRowData?.id) {
        getInvoiceTypeOptions();
      }
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [state?.selectedRowData]);

  const getData = async () => {
    dispatch({ type: LOADER, payload: true });

    const handleSuccessResponse = (res) => {
      dispatch({
        type: UPDATE_MULTIPLE_DATA,
        payload: { tableData: res, loading: false },
      });
    };

    await api
      .post(
        INVOICES_URL,
        {
          report_version_id: state?.report_version_id[0],
          client_id: state?.client_id[0],
          service_id: state?.service_id[0]
        },
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const getAllOptions = async () => {
    dispatch({ type: LOADER, payload: true });
    let endpoints = [
      { url: GET_CLIENT_FOR_INVOICES, method: "get" },
      { url: GET_ALL_REPORT_VERSIONS_URL, method: "get" },
      { url: GET_SERVICES_URL, method: "get" },
    ];
    await axios
      .all(
        endpoints.map((endpoint) => {
          if (endpoint.method === "get") {
            return api.get(endpoint.url, {
              cancelToken: cancelTokenSource.token,
            });
          } else {
            return api.post(endpoint.url, {
              cancelToken: cancelTokenSource.token,
            });
          }
        })
      )
      .then(
        axios.spread(
          ({ data: clientOptions }, { data: reportVersionOptions }, { data: serviceOptions }) => {
            dispatch({
              type: UPDATE_MULTIPLE_DATA,
              payload: {
                clientOptions,
                reportVersionOptions,
                serviceOptions,
                loading: false,
              },
            });
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    dispatch({ type: LOADER, payload: false });
  };


  const getInvoiceTypeOptions = async () => {

    dispatch({ type: LOADER, payload: true });

    if (state?.invoiceTypeOptions.length === 0) {

      let invoiceEndpoints = [
        { url: GET_INVOICE_TYPES_URL, method: "get" },
      ];

      await axios
        .all(
          invoiceEndpoints.map((endpoint) => {
            if (endpoint.method === "get") {
              return api.get(endpoint.url, {
                cancelToken: cancelTokenSource.token,
              });
            } else {
              return api.post(endpoint.url, {
                cancelToken: cancelTokenSource.token,
              });
            }
          })
        )
        .then(
          axios.spread(({ data: invoiceTypeOptions }) => {
            dispatch({
              type: UPDATE_MULTIPLE_DATA,
              payload: {
                invoiceTypeOptions,
              },
            });
          })
        )
        .catch((error) => {
          checkNetworkReachable();
          if (error?.response?.status === 401) {
            logoutUser();
          } else if (!axios.isCancel(error) && error?.response) {
            alert(DEFAULT_ERROR_MSG);
          }
        });

    }
    await getPaymentDetailsTableData();

    dispatch({ type: LOADER, payload: false });
  };

  const getPaymentDetailsTableData = async () => {
    let PAYMENT_URL = `${GET_INVOICE_PAYMENT_DETAILS}${state?.selectedRowData?.id}`

    let endpoints = [
      { url: PAYMENT_URL, method: "post" }
    ];

    await axios
      .all(
        endpoints.map((endpoint) => {
          if (endpoint.method === "get") {
            return api.get(endpoint.url, {
              cancelToken: cancelTokenSource.token,
            });
          } else {
            return api.post(endpoint.url, {
              cancelToken: cancelTokenSource.token,
            });
          }
        })
      )
      .then(
        axios.spread(({ data: invoicePaymentDetailTable }) => {
          dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: {
              invoicePaymentDetailTable,
              loading: false,
            },
          });
        })
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

  }

  const getInvoiceByID = async (id) => {
    dispatch({ type: LOADER, payload: true });
    const handleSuccessResponse = (res) => {
      let data = res.length > 0 ? res[0] : {}
      dispatch({ type: SELECTED_ROW, payload: data })
    };

    await api
      .get(`${INVOICES_URL}${id}`, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  }

  let contextData = {
    state,
    dispatch,
    initialState,
    getData,
    getInvoiceTypeOptions,
    pageName,
    getInvoiceByID,
  };

  return (
    <InvoicesDataContextAPI.Provider value={contextData}>
      {children}
    </InvoicesDataContextAPI.Provider>
  );
};
