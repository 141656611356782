import { StyleSheet } from "react-native";

export const commonstyle = (theme) =>
  StyleSheet.create({
    searchTableWrapper: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });
