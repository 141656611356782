import React from "react";
import { Card, withTheme } from "react-native-paper";
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { ACTION_BUTTON_TYPE } from "../../constants/GlobalVariables";
import { customCardsStyle } from "./customCardsStyle";
import { TouchableOpacity } from "react-native";

const TitleIconCards = ({ theme, title, icon, leaveMarginRight, onPress }) => {
  const getIcons = () => {
    if (icon === ACTION_BUTTON_TYPE.REVENUE) {
      return (
        <FontAwesome5
          name={icon}
          size={24}
          color={theme.colors.infoText}
          style={{ height: 24, width: 24 }}
        />
      );
    } else if (icon === ACTION_BUTTON_TYPE.STATEMENT) {
      return (
        <MaterialIcons name={icon} size={24} color={theme.colors.infoText} />
      );
    }
  };
  return (
    <TouchableOpacity
      onPress={onPress && onPress}
      style={customCardsStyle(theme, leaveMarginRight).buttonWrapper}
    >
      <Card
        elevation={0}
        theme={theme}
        style={customCardsStyle(theme, leaveMarginRight).container}
      >
        <Card.Content
          style={{
            height: 48,
            justifyContent: "center",
            paddingHorizontal: 0,
            alignItems: "center",
          }}
        >
          <Card.Title
            titleStyle={customCardsStyle(theme).iconTitleCard}
            title={title}
            left={(props) => getIcons()}
            leftStyle={{ marginRight: 0 }}
          />
        </Card.Content>
      </Card>
    </TouchableOpacity>
  );
};

export default withTheme(TitleIconCards);
