import { StyleSheet, View } from "react-native";
import React from "react";
import { RadioButton } from "react-native-paper";
import FormLabel from "../FormLabel/FormLabel";

const FormRadioButton = ({ label, value }) => {
  return (
    <View style={styles.container}>
      <RadioButton value={value} />
      <FormLabel text={label} customStyle={{ marginTop: 4 }} />
    </View>
  );
};

export default FormRadioButton;

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
});
