import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import { Searchbar, useTheme } from "react-native-paper";
import { config } from "./catalogue.config";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { ACTION_BUTTON_TYPE } from "../../../constants/GlobalVariables";

const TrackTable = ({ data, selectActiveTab }) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
      width: 1,
    },
    {
      key: "tracknumber",
      title: "Track No",
      dataIndex: "tracknumber",
      sort: true,
      filter: true,
      width: 1.5,
    },
    {
      key: "artist_names",
      title: "Artist Name",
      dataIndex: "artist_names",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "release_title",
      title: "Release Title",
      dataIndex: "release_title",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "track_title",
      title: "Track Title",
      dataIndex: "track_title",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "label_name",
      title: "Label",
      dataIndex: "label_name",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "release_upc",
      title: "UPC",
      dataIndex: "release_upc",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "isrc",
      title: "ISRC",
      dataIndex: "isrc",
      sort: true,
      filter: true,
      search: true,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sort: true,
      filter: true,
      width: 1,
    },
    {
      key: "COG",
      title: "COG",
      dataIndex: "COG",
      center: true,
      hideToolTip: true,
      width: 0.5,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            borderRadius={25}
            buttonType={ACTION_BUTTON_TYPE.VIEW_MORE}
            // handleOnPress={() => {
            //   openShowAdvancesForm(row);
            // }}
          />
        );
      },
    },
  ];
  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.TRACK_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View>{selectActiveTab}</View>
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default TrackTable;

const styles = StyleSheet.create({});
