import { StyleSheet } from "react-native";

export const commonBadgePaginationStyle = (theme, active, show) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "center",
      marginVertical: show ? 5 : 0,
      borderBottomLeftRadius: show ? 0 : 10,
      borderBottomRightRadius: show ? 0 : 10,
      marginLeft: 5,
    },
    badgeColor: {
      backgroundColor: active
        ? theme.colors.actionIconActive
        : theme.colors.secondaryText,
      marginRight: 5,
    },
  });
