import { TABS } from "../adjustment.config";
import {
  CHANGE_ACTIVE_TAB,
  LOADER,
  RESET_FILTERS,
  UPDATE_MULTIPLE_DATA,
} from "./actions";

export const initialState = {
  loading: true,

  compareMatrixData: [],
  compareTableData: [],
  total_diff: {
    EUR: 0,
    GBP: 0,
    USD: 0,
  },

  clientOptions: [],
  serviceOptions: [],
  yearOptions: [],

  client_name: [],
  service_name: [],

  client_id: [],
  service_id: [],
  reporting_year: "",

  activeTab: TABS.MATRIX_VIEW,
};

export const adjustmentReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, loading: action.payload };
    case CHANGE_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    case RESET_FILTERS:
      return {
        ...state,
        client_name: [],
        service_name: [],
        client_id: [],
        service_id: [],
        reporting_year: "",
        compareMatrixData: [],
      };
    default:
      return state;
  }
};
