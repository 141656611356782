import React, { useContext, useEffect, useState } from "react";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import CustomTextInput from "../components/CustomTextInput";
import { withTheme } from "react-native-paper";
import {
  BUTTON_TYPE,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INFORMATION_TEXT,
  INVALID_EMAIL_MSG,
  MESSAGE_TYPE,
} from "../constants/GlobalVariables";
import { CommonLayoutWrapper, CustomButton } from "../components";
import MessageValidation from "../components/MessageValidation";
import { checkObjectIsEmpty } from "../utils/checkObjectIsEmpty";
import AuthContextAPI from "../context/AuthContextAPI";
import { isValidateEmail } from "../utils/isValidateEmail";

const ContactSupport = ({ navigation, theme }) => {
  let initialState = {
    form: {
      email: "",
      issue: "",
      message: "",
    },
    message: {
      show: false,
      type: "",
      message: "",
    },
  };
  const { colors } = theme;
  const { contactSupport } = useContext(AuthContextAPI);

  const [formData, setFormData] = useState(initialState.form);
  const [message, setMessage] = useState(initialState.message);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      setFormData(initialState.form);
      setMessage(initialState.message);
    });
  }, [navigation]);

  const onChangeText = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    let isEmpty = checkObjectIsEmpty(formData);
    if (isEmpty) {
      if (!isValidateEmail(formData.email)) {
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_EMAIL_MSG,
        });
      } else {
        let response = await contactSupport(formData);
        if (response) {
          setMessage(response);
          setFormData(initialState.form);
        }
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <CommonLayoutWrapper marginTop={20}>
      <View style={styles.titleContainer}>
        <Text style={{ color: colors.infoText }}>
          {INFORMATION_TEXT.contactSupport}
        </Text>
      </View>
      <View style={styles.inputFieldGap}>
        <CustomTextInput
          name="email"
          placeholder="Email"
          value={formData.email}
          onChangeText={onChangeText}
        />
        <CustomTextInput
          name="issue"
          placeholder="Your issue"
          value={formData.issue}
          onChangeText={onChangeText}
        />
        <CustomTextInput
          name="message"
          placeholder="Ticket text here"
          multiline={true}
          value={formData.message}
          onChangeText={onChangeText}
        />
      </View>

      <View style={styles.bottomButton}>
        <CustomButton
          text="Send"
          buttontype={BUTTON_TYPE.PRIMARY}
          onPress={onSubmit}
        />
      </View>
      <MessageValidation {...message} />
    </CommonLayoutWrapper>
  );
};

export default withTheme(ContactSupport);

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: 40,
  },
  bottomButton: {
    marginTop: 30,
  },
  inputFieldGap: {
    gap: 10,
  },
});
