import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Card, withTheme } from "react-native-paper";
import CommonCardHeaders from "../../CommonCardHeaders/CommonCardHeaders";
import CommonDataTable from "../../CommonDataTable/CommonDataTable";
import { styles } from "./styles";

export const Tableslide = ({ theme, ...props }) => {
  const { data, slide = true } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let isMoulded = true;

    if (isMoulded) {
      setTableData(data.data);
    }
    return () => {
      isMoulded = false;
    };
  }, [data]);

  return (
    <Card elevation={0} style={styles(theme, slide).slide}>
      <View style={styles(theme, slide).header}>
        <CommonCardHeaders title={data.title} subtitle={data.subtitle} />
      </View>
      <View style={styles(theme, slide).tableContainer}>
        <CommonDataTable
          columns={data.columns}
          data={tableData}
          mobileView
          showPagination={false}
          showHeader={data?.showHeader || false}
        />
      </View>
    </Card>
  );
};

export default withTheme(Tableslide);
