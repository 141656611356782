import { StyleSheet } from "react-native";

export const commonStyle = StyleSheet.create({
  commonMarginContainer: { marginVertical: 10 },
  commonMarginTopContainer: { marginTop: 10 },
  commonMarginBottonContainer: { marginBottom: 10 },
  commonMarginLeftContent: {
    marginLeft: 10,
  },
  tableColumnImageTextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  TableImageContainer: { marginVertical: 15 },
  TableTextContainer: { marginLeft: 10 },
  lastComponentMarginBottom: {
    marginBottom: 20,
  },
});
