import React, { useState } from "react";
import { Platform, Pressable, TextInput, View } from "react-native";
import { withTheme } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTogglePasswordVisibility } from "../utils/useTogglePasswordVisibility";

const CustomTextInput = ({
  name,
  placeholder,
  secureTextEntry = false,
  customStyle = {},
  multiline = false,
  onChangeText,
  theme,
  // isDatePicker = false,
  // onDatePickerClick,
  value = "",
  disabled = false,
  isValidated = false,
}) => {
  const { inputText, customFonts, colors } = theme;
  const { passwordVisibility, rightIcon, handlePasswordVisibility } =
    useTogglePasswordVisibility();
  const [isFocused, setIsFocused] = useState("");
  const handleInputFocus = (textinput) => {
    setIsFocused(textinput);
  };
  const handleInputBlur = (textinput) => {
    setIsFocused(textinput);
  };
  return (
    <View
      style={{
        // flex: 1,
        alignItems: "center",
        justifyContent: "center",
        ...customStyle,
      }}
    >
      <View
        style={{
          width: "100%",
          borderRadius: 10,
          flexDirection: "row",
          borderWidth: 1,
          height: multiline ? 192 : inputText.height,
          backgroundColor: disabled && colors.secondaryBackground,
          paddingLeft: inputText.paddingHorizontal,
          paddingRight: secureTextEntry ? 0 : 15,
          borderColor: isValidated
            ? colors.error
            : isFocused === name
            ? colors.primary
            : colors.inputborderColor,
        }}
      >
        <TextInput
          disabled={disabled}
          style={{
            width: "90%",
            fontSize: 16,
            fontFamily:
              Platform.OS === "web" ? customFonts.regular : customFonts.medium,
            color: colors.text,
            justifyContent: "flex-start",
            textAlignVertical: multiline ? "top" : undefined,
            paddingVertical: multiline ? 15 : 0,
            outline: 0,
            borderColor: "transparent",
          }}
          underlineColorAndroid={colors.backgroundColor}
          value={`${value}`}
          name={name}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry && passwordVisibility}
          placeholderTextColor={inputText.placeholderTextColor}
          multiline={multiline}
          onChangeText={(val) => {
            onChangeText(name, val);
          }}
          onFocus={() => {
            handleInputFocus(name);
          }}
          onBlur={() => {
            handleInputBlur("");
          }}
        />
        {secureTextEntry && (
          <View style={{ alignSelf: "center" }}>
            <Pressable onPress={handlePasswordVisibility}>
              <MaterialCommunityIcons
                name={rightIcon}
                size={22}
                color={colors.inputborderColor}
              />
            </Pressable>
          </View>
        )}
      </View>
    </View>
  );
};

export default withTheme(CustomTextInput);
