import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { FILTER_CHART_TRACK_DATA_URL } from "../../constants/GlobalUrls";
import { DEFAULT_ERROR_MSG } from "../../constants/GlobalVariables";

import useAxios from "../../hooks/useAxios";

const TrendsContextAPI = createContext();

export default TrendsContextAPI;

export const TrendsProvider = ({ children }) => {
  // api hook
  let api = useAxios();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  // Endpoints
  let FILTER_CHART_TRACK_DATA_REQ = api.post(FILTER_CHART_TRACK_DATA_URL, {
    cancelToken: cancelTokenSource.token,
  });

  // Endpoints Array
  let chartsEndpoints = [FILTER_CHART_TRACK_DATA_REQ];

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    audienceData: [],
  });
  const [chartData, setChartData] = useState({});
  const [filterChartDropdown, setFilterChartDropdown] = useState([]);
  const getChartsData = async () => {
    setLoading(true);

    await Promise.all(chartsEndpoints)
      .then(([{ data: filterDropDownData }]) => {
        // setChartData({ audienceData: audienceData });
      })
      .catch((err) => {
        if (err?.status === 500) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setLoading(false);
  };

  const getFilterData = async () => {
    setLoading(true);

    await api
      .post(FILTER_CHART_TRACK_DATA_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setFilterChartDropdown(response.data);
      })
      .catch((err) => {
        if (err?.status === 500) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    getFilterData();
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  let contextData = {
    loading,
    data,
    setData,
    filterChartDropdown,
  };
  return (
    <TrendsContextAPI.Provider value={contextData}>
      {children}
    </TrendsContextAPI.Provider>
  );
};
