import { View } from "react-native";
import React, { useContext } from "react";
import ReportVersionTable from "./ReportVersionTable";
import {
  CustomButton,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import ReportVersionContextAPI from "./ReportVersionConsumer";
import {
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import { config } from "./reportVersion.config";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { DOWNLOAD_REPORT_VERISON_URL } from "../../../constants/GlobalUrls";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { LOADER } from "./reducer/actions";
let uuid = require("uuid");

const ReportVersionLandingPage = () => {
  let theme = useTheme();
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let {
    state: { loading, tableData },
    dispatch,
  } = useContext(ReportVersionContextAPI);

  const onClickDownloadCSV = async () => {
    dispatch({ type: LOADER, payload: true });
    await api
      .get(DOWNLOAD_REPORT_VERISON_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `report_version_${uuid.v4()}.csv`);
        tempLink.click();
        dispatch({ type: LOADER, payload: false });
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          dispatch({ type: LOADER, payload: false });
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  return (
    <WebContentContainerWrapper title={config.TITLE} loading={loading}>
      <View style={commonStatisticButtonStyle(theme).statisticContainer}>
        <Statistics label={"Total"} value={tableData.length || 0} />

        <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
          <CustomButton
            text={DOWNLOAD_CSV_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={onClickDownloadCSV}
          />
        </View>
      </View>
      <ReportVersionTable />
    </WebContentContainerWrapper>
  );
};

export default ReportVersionLandingPage;
