import dayjs from "dayjs";
import React from "react";
import { Platform } from "react-native";
import { withTheme } from "react-native-paper";
import { DatePickerInput, registerTranslation } from "react-native-paper-dates";
import { dateConfig, locale } from "../../constants/GlobalVariables";
import CustomTextInput from "../CustomTextInput";

const CustomDatePickerInput = ({
  customStyle = {},
  name,
  value,
  onChange,
  theme,
  disabled,
  isValidated = false,
}) => {
  const { inputText, customFonts } = theme;

  registerTranslation(locale, { ...dateConfig });
  return (
    <DatePickerInput
      disabled={disabled}
      style={{
        ...customStyle,
        height: inputText.height,
        width: "100%",
      }}
      theme={{
        fonts: {
          regular: {
            fontFamily:
              Platform.OS === "web" ? customFonts.regular : customFonts.medium,
            fontSize: 16,
          },
        },
      }}
      locale={locale}
      // label="Birthdate"
      value={value}
      onChange={(d) => onChange(name, d)}
      inputMode="start"
      mode="outlined"
      withDateFormatInLabel={false}
      label="DD/MM/YYYY"
      render={() => (
        <CustomTextInput
          isValidated={isValidated}
          value={value ? dayjs(value).format("DD/MM/YYYY") : value}
          isDatePicker
          onChangeText={(d) => onChange(name, d)}
          // customStyle={{ paddingHorizontal: 0 }}
        />
      )}
      outlineColor="#FFF"
      // other react native TextInput props
    />
  );
};

export default withTheme(CustomDatePickerInput);
