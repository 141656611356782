import { StyleSheet, Text, View } from "react-native";
import React, { useContext } from "react";
import SingleSelectFilter from "./SingleSelectFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import { CustomButton } from "../../../../components";
import { config } from "../report.config";
import ReportsContextAPI from "../ReportsContextAPI";
import { RESET_FILTERS, UPDATE_MULTIPLE_DATA } from "../Reducer/actions";
import { BUTTON_TYPE } from "../../../../constants/GlobalVariables";
import { Fontisto } from "@expo/vector-icons";
import { useTheme } from "react-native-paper";
import { customStyle } from "../../../../components/CustomText/customStyle";
import GenerateReportFromFilters from "./GenerateReportFromFilters";

const AllFilters = () => {
  let theme = useTheme();
  let {
    state: {
      startMonthOptions,
      endMonthOptions,
      clientOptions,
      contractOptions,
      labelOptions,
      releaseOptions,
      trackOptions,
      start_month,
      end_month,
      client_ids,
      contract_ids,
      label_ids,
      release_ids,
      track_ids,
    },
    dispatch,
  } = useContext(ReportsContextAPI);

  const onChangeMonth = (name, value) => {
    let payload = {
      client_ids: [],
      contract_ids: [],
      label_ids: [],
      release_ids: [],
      track_ids: [],
      client_names: [],
      contract_names: [],
      label_names: [],
      release_names: [],
      track_names: [],
      [name]: value,
    };
    if (name === config.START_MONTH_KEY) {
      payload = { ...payload, end_month: "" };
    }

    dispatch({ type: UPDATE_MULTIPLE_DATA, payload: payload });
  };

  const onChangeMultiSelectFilter = (value, updatedValue) => {
    let temp = value;

    if (config.CLIENT_KEY in temp) {
      temp = {
        ...temp,
        contract_ids: [],
        label_ids: [],
        release_ids: [],
        track_ids: [],
        contract_names: [],
        label_names: [],
        release_names: [],
        track_names: [],
      };
    } else if (config.CONTRACT_KEY in temp) {
      temp = {
        ...temp,
        label_ids: [],
        release_ids: [],
        track_ids: [],
        label_names: [],
        release_names: [],
        track_names: [],
      };
    } else if (config.LABEL_KEY in temp) {
      temp = {
        ...temp,
        release_ids: [],
        track_ids: [],
        release_names: [],
        track_names: [],
      };
    } else if (config.RELEASE_KEY in temp) {
      temp = {
        ...temp,
        track_ids: [],
        track_names: [],
      };
    }
    dispatch({
      type: UPDATE_MULTIPLE_DATA,
      payload: temp,
    });
  };

  const onClearAllFilter = () => {
    dispatch({
      type: RESET_FILTERS,
      payload: {},
    });
  };

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={customStyle(theme).headerText20}>
          Make your selection to generate a custom report
        </Text>
        <CustomButton
          buttontype={BUTTON_TYPE.SECONDARY}
          onPress={onClearAllFilter}
          text={
            <View style={styles.clearBtnTextContainer}>
              <Fontisto name="eraser" size={14} color={theme.colors.black} />
              <Text>Reset Filters</Text>
            </View>
          }
        />
      </View>
      <View style={styles.filtersContainer}>
        <View style={styles.filtersContainer}>
          <SingleSelectFilter
            placeholdertext={config.START_MONTH}
            name={config.START_MONTH_KEY}
            value={start_month}
            options={startMonthOptions}
            onChange={onChangeMonth}
          />
          <SingleSelectFilter
            disabled={start_month === ""}
            placeholdertext={config.END_MONTH}
            name={config.END_MONTH_KEY}
            value={end_month}
            options={endMonthOptions}
            onChange={onChangeMonth}
          />
        </View>
        <MultiSelectFilter
          disabled={!(start_month && end_month)}
          optionName="client_name"
          optionValue="client_id"
          multipleSelect={false}
          name={config.CLIENT_KEY}
          title={config.CLIENT_NAME}
          placeholdertext={config.CLIENTS_TITLE}
          value={client_ids}
          options={clientOptions}
          onChange={onChangeMultiSelectFilter}
        />
        <MultiSelectFilter
          optionName="contract_name"
          optionValue="contract_id"
          disabled={!(start_month && end_month)}
          multipleSelect={false}
          name={config.CONTRACT_KEY}
          title={config.CONTRACT_NAME}
          placeholdertext={config.CONTRACT_TITLE}
          value={contract_ids}
          options={contractOptions}
          onChange={onChangeMultiSelectFilter}
        />
      </View>
      <View style={styles.filtersContainer}>
        <MultiSelectFilter
          disabled={!(start_month && end_month)}
          optionName="label_name"
          optionValue="label_id"
          multipleSelect={false}
          name={config.LABEL_KEY}
          title={config.LABEL_NAME}
          placeholdertext={config.LABELS_TITLE}
          value={label_ids}
          options={labelOptions}
          onChange={onChangeMultiSelectFilter}
        />
        <MultiSelectFilter
          disabled={!(start_month && end_month)}
          optionName="release_title"
          optionValue="release_id"
          multipleSelect={false}
          name={config.RELEASE_KEY}
          title={config.RELEASE_NAME}
          placeholdertext={config.RELEASE_TITLE}
          value={release_ids}
          options={releaseOptions}
          onChange={onChangeMultiSelectFilter}
        />
        <MultiSelectFilter
          disabled={!(start_month && end_month)}
          optionName="track_title"
          optionValue="track_id"
          multipleSelect={false}
          name={config.TRACK_KEY}
          title={config.TRACK_NAME}
          placeholdertext={config.TRACKS_TITLE}
          value={track_ids}
          options={trackOptions}
          onChange={onChangeMultiSelectFilter}
        />
      </View>
      <GenerateReportFromFilters />
    </>
  );
};

export default AllFilters;

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: "row",
    gap: 10,
    flex: 1,
    justifyContent: "space-between",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearBtnTextContainer: { flexDirection: "row", gap: 5, alignItems: "center" },
});
