import { View } from "react-native";
import React, { useContext, useState } from "react";
import { AntDesign, Entypo, FontAwesome, Ionicons } from "@expo/vector-icons";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
} from "../../../constants/GlobalVariables";
import {
  ActionsButtonIcons,
  CommonDataTable,
  ConfirmationWindow,
  CustomModal,
  commonStatisticButtonStyle,
} from "../../../components";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import { PERMISSIONS, config } from "./employeeAccounts.config";
import { Searchbar, useTheme } from "react-native-paper";
import EmployeeAccountContextAPI from "./Reducer/EmployeeContextAPI";
import { LOADER, REFRESH_TABLE, UPDATE_MULTIPLE_DATA } from "./Reducer/actions";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { ACCOUNT_URL } from "../../../constants/GlobalUrls";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const EmployeeAccountTable = () => {
  let theme = useTheme();
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let {
    state: { tableData },
    dispatch,
  } = useContext(EmployeeAccountContextAPI);

  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });

  const onChangeSearch = (query) => setSearchQuery(query);

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ show: false, record: {} });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  const handleDelete = async () => {
    dispatch({ type: LOADER, payload: true });
    closeDeleteConfirmationWindow();

    let id = showDeleteConfirmation?.record?.id;
    let url = `${ACCOUNT_URL}${id}/`;

    const triggerTableRefresh = () => {
      dispatch({ type: REFRESH_TABLE, payload: false });
    };

    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      triggerTableRefresh();
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        if (error?.response?.data?.msg) {
          alert(error?.response?.data?.msg);
        } else {
          alert(DEFAULT_ERROR_MSG);
        }
      }
    };

    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch({ type: LOADER, payload: false });
      });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 0.2,
      sort: true,
      search: true,
    },
    {
      key: "username",
      title: "Username",
      dataIndex: "username",
      sort: true,
      search: true,
      width: 0.4,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      sort: true,
      search: true,
      width: 1,
    },
    {
      key: "accounttype",
      title: "Account Type",
      dataIndex: "accounttype",
      sort: true,
      search: true,
      width: 0.4,
      filter: true,
    },
    {
      key: "userrole",
      title: "Role",
      dataIndex: "userrole",
      sort: true,
      width: 0.4,
      filter: true,
    },
    {
      key: "active",
      title: "Active",
      dataIndex: "active",
      width: 0.3,
      center: true,
      filter: true,
      customToolTip: (active) => {
        return active ? "Active" : "Inactive";
      },
      render: (active) => {
        return (
          <FontAwesome
            name="circle"
            size={14}
            color={active ? theme.colors.primary : theme.colors.infoText}
          />
        );
      },
    },
    {
      key: "Account Admin",
      title: "Accounts",
      dataIndex: "Account Admin",
      width: 0.35,
      center: true,
      filter: true,
      customToolTip: (permission) => {
        return `User has ${permission} permissions`;
      },
      render: (permission) => {
        if (permission === PERMISSIONS.ALL) {
          return (
            <AntDesign name="check" size={16} color={theme.colors.primary} />
          );
        } else if (permission === PERMISSIONS.NONE) {
          return <Entypo name="cross" size={16} color={theme.colors.error} />;
        } else {
          return (
            <Ionicons
              name="information-circle-outline"
              size={16}
              color={theme.colors.text}
            />
          );
        }
      },
    },
    {
      key: "Contract Admin",
      title: "Contracts",
      dataIndex: "Contract Admin",
      width: 0.35,
      center: true,
      filter: true,
      customToolTip: (permission) => {
        return `User has ${permission} permissions`;
      },
      render: (permission) => {
        if (permission === PERMISSIONS.ALL) {
          return (
            <AntDesign name="check" size={16} color={theme.colors.primary} />
          );
        } else if (permission === PERMISSIONS.NONE) {
          return <Entypo name="cross" size={16} color={theme.colors.error} />;
        } else {
          return (
            <Ionicons
              name="information-circle-outline"
              size={16}
              color={theme.colors.text}
            />
          );
        }
      },
    },
    {
      key: "Finance Admin",
      title: "Finance",
      dataIndex: "Finance Admin",
      width: 0.35,
      center: true,
      filter: true,
      customToolTip: (permission) => {
        return `User has ${permission} permissions`;
      },
      render: (permission) => {
        if (permission === PERMISSIONS.ALL) {
          return (
            <AntDesign name="check" size={16} color={theme.colors.primary} />
          );
        } else if (permission === PERMISSIONS.NONE) {
          return <Entypo name="cross" size={16} color={theme.colors.error} />;
        } else {
          return (
            <Ionicons
              name="information-circle-outline"
              size={16}
              color={theme.colors.text}
            />
          );
        }
      },
    },
    {
      key: "Catalogue Admin",
      title: "Catalogue",
      dataIndex: "Catalogue Admin",
      width: 0.35,
      center: true,
      filter: true,
      customToolTip: (permission) => {
        return `User has ${permission} permissions`;
      },
      render: (permission) => {
        if (permission === PERMISSIONS.ALL) {
          return (
            <AntDesign name="check" size={16} color={theme.colors.primary} />
          );
        } else if (permission === PERMISSIONS.NONE) {
          return <Entypo name="cross" size={16} color={theme.colors.error} />;
        } else {
          return (
            <Ionicons
              name="information-circle-outline"
              size={16}
              color={theme.colors.text}
            />
          );
        }
      },
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "key",
      width: 0.2,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: {
                  isLandingPage: false,
                  selectedRow: row,
                  isEditMode: true,
                },
              });
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.DELETE,
      title: "Delete",
      dataIndex: "id",
      width: 0.2,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete this account with username ${showDeleteConfirmation?.record?.username}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </View>
  );
};

export default EmployeeAccountTable;
