import { View } from "react-native";
import React, { useState, useEffect } from "react";
import CommonAvatar from "../CommonAvatar/CommonAvatar";
import SecondaryHeaderText from "../CustomText/SecondaryHeaderText";
import InformationText from "../CustomText/InformationText";
import StatisticText from "../CustomText/StatisticText";
import Carousel from "../Carousel/Carousel";
import { topChartStyle } from "./topChartStyle";

let columns = [
  {
    key: "stores",
    title: "icon",
    dataIndex: "stores",
    width: 2,
    render: (store, record) => {
      return (
        <View style={topChartStyle.imageTextContainer}>
          <View style={topChartStyle.imageContainer}>
            <CommonAvatar
              src={require("../../../assets/images/sample_picture.png")}
            />
          </View>
          <View style={topChartStyle.textContainer}>
            <SecondaryHeaderText text={store} />
            <InformationText text={record.date} />
          </View>
        </View>
      );
    },
    // bold: true,
  },
  {
    key: "amount",
    title: "amount",
    dataIndex: "amount",
    width: 1,
    left: true,
    render: (amount) => {
      return <StatisticText text={amount} />;
    },
  },
];

const data = [
  {
    title: "Top Stores",
    subtitle: "Last Month",
    columns: columns,
    data: [
      {
        id: 1,
        stores: "Spotify",
        date: "September 2021",
        amount: "$2,084,00",
      },
      {
        id: 2,
        stores: "Spotify",
        date: "September 2021",
        amount: "$2,084,00",
      },
      {
        id: 3,
        stores: "Spotify",
        date: "September 2021",
        amount: "$784,00",
      },
      {
        id: 4,
        stores: "Spotify",
        date: "September 2021",
        amount: "$84,00",
      },
      {
        id: 5,
        stores: "Spotify",
        date: "September 2021",
      },
      {
        id: 6,
        stores: "Spotify",
        date: "September 2021",
      },
    ],
  },
  {
    title: "Top Releases",
    subtitle: "Last Month",
    columns: columns,
    data: [
      {
        id: 1,
        stores: "Spotify",
        date: "September 2021",
        amount: "$2,084,00",
      },
      {
        id: 2,
        stores: "Spotify",
        date: "September 2021",
        amount: "$2,084,00",
      },
      {
        id: 3,
        stores: "Spotify",
        date: "September 2021",
        amount: "$784,00",
      },
      {
        id: 4,
        stores: "Spotify",
        date: "September 2021",
        amount: "$84,00",
      },
      {
        id: 5,
        stores: "Spotify",
        date: "September 2021",
      },
    ],
  },
  {
    title: "Top Countries",
    subtitle: "Last Month",
    columns: columns,
    data: [
      {
        id: 1,
        stores: "Spotify",
        date: "September 2021",
        amount: "$2,084,00",
      },
      {
        id: 2,
        stores: "Spotify",
        date: "September 2021",
        amount: "$2,084,00",
      },
      {
        id: 3,
        stores: "Spotify",
        date: "September 2021",
        amount: "$784,00",
      },
      {
        id: 4,
        stores: "Spotify",
        date: "September 2021",
        amount: "$84,00",
      },
      {
        id: 5,
        stores: "Spotify",
        date: "September 2021",
      },
    ],
  },
];

const TopCards = ({ customData }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let isMoulded = true;

    if (isMoulded) {
      if (customData) {
        let finalData = customData.map((obj) => {
          return { ...obj, columns: columns };
        });
        setTableData(finalData);
      } else {
        setTableData(data);
      }
    }
    return () => {
      isMoulded = false;
    };
  }, [data]);

  return <Carousel style="slide" items={tableData} />;
};

export default TopCards;
