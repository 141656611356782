import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
} from "react-native";
import React, { useCallback, useState } from "react";
import { FORM_REQUIRED_FIELD_MSG } from "../../../constants/GlobalVariables";
import FormLabel from "../FormLabel/FormLabel";
import {
  formSelectCustomStyle,
  formSelectStyle,
} from "../FormSelect/formSelectStyle";
import { Checkbox, List, Menu, useTheme } from "react-native-paper";
import MessageValidation from "../../MessageValidation";
import { MaterialIcons } from "@expo/vector-icons";

const FormNestedDropdown = ({
  name,
  label,
  required = false,
  options = {},
  onChange = () => {},
  disabled = false,
  value = [],
  optionName = "permission_name",
  optionValue = "permission_id",
  placeholdertext = "",
  isValidated = false,
  validationMessage = FORM_REQUIRED_FIELD_MSG,
  showHelpText = true,
}) => {
  let theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [widthOfTheDropdown, setWidthOfTheDropdown] = useState(0);

  const openMenu = () => setVisible(true);

  const closeMenu = () => {
    setVisible(false);
    if (searchTerm) {
      setSearchTerm("");
    }
  };

  const onItemSelection = (option) => {
    let tempValue = [...value];
    if (value.includes(option[optionValue])) {
      tempValue = tempValue.filter((el) => el !== option[optionValue]);
    } else {
      tempValue.push(option[optionValue]);
    }
    onChange(name, tempValue, option);
  };

  const getValue = useCallback(() => {
    let label = "Permission(s)";
    let total = 0;
    Object.values(options).map((el) => (total = total + el.length));
    return `${label} - ${value.length}/${total}`;
  }, [value, options]);

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setWidthOfTheDropdown(width);
  };
  return (
    <View style={formSelectCustomStyle.container}>
      {!!label && (
        <FormLabel text={label} required={required} isValidated={isValidated} />
      )}
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        style={formSelectStyle(theme, disabled).customDropdownContainer}
        anchor={
          <TouchableHighlight
            onLayout={onLayout}
            underlayColor={theme.colors.selectedTableBackgroundColor}
            children={
              <View
                style={
                  formSelectStyle(theme, disabled).customDropdownBtnContainer
                }
              >
                <Text
                  style={formSelectStyle(theme, disabled).customDropdownBtnText}
                >
                  {value ? (
                    getValue()
                  ) : (
                    <Text
                      style={formSelectStyle(theme, true).customDropdownBtnText}
                    >
                      {placeholdertext}
                    </Text>
                  )}
                </Text>
                {!disabled && (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color={theme.colors.text}
                  />
                )}
              </View>
            }
            disabled={disabled}
            uppercase={false}
            onPress={openMenu}
            style={
              formSelectStyle(theme, disabled, isValidated).customDropdownBtn
            }
          />
        }
      >
        <ScrollView contentContainerStyle={styles.dropDownScrollContainer}>
          <List.Section
            style={{
              width: widthOfTheDropdown,
            }}
          >
            {Object.entries(options).map(([key, valueArr]) => {
              let total = 0;
              valueArr.map((el) => {
                if (value.includes(el[optionValue])) {
                  total = total + 1;
                }
              });

              return (
                <List.Accordion
                  expanded
                  right={() => <></>}
                  titleStyle={{
                    color: theme.colors.secondaryText,
                    fontFamily: theme.customFonts.semiBold,
                  }}
                  key={key}
                  title={`${key} - ${total}/${valueArr.length}`}
                >
                  {valueArr.map((item) => {
                    return (
                      <List.Item
                        style={styles.childListContainer}
                        left={(props) => (
                          <Checkbox
                            status={
                              value.includes(item[optionValue])
                                ? "checked"
                                : "unchecked"
                            }
                            onPress={() => {
                              onItemSelection(item);
                            }}
                          />
                        )}
                        key={item[optionValue]}
                        title={item[optionName]}
                      />
                    );
                  })}
                </List.Accordion>
              );
            })}
          </List.Section>
        </ScrollView>
      </Menu>
      {showHelpText && (
        <MessageValidation
          fieldValidation={isValidated}
          message={validationMessage}
        />
      )}
    </View>
  );
};

export default FormNestedDropdown;

const styles = StyleSheet.create({
  dropDownScrollContainer: { height: 250 },
  childListContainer: { marginLeft: 10 },
});
