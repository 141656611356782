import {
  LOADER,
  UPDATE_MULTIPLE_DATA,
  UPDATE_TABLE_DATA,
  VERSION_LOADING,
} from "./actions";

export const initialState = {
  loading: false,
  tableData: [{}],
  versionLoading: false,
};

export const reportVersionReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, loading: action.payload };
    case VERSION_LOADING:
      return { ...state, versionLoading: action.payload };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    case UPDATE_TABLE_DATA:
      return { ...state, tableData: action.payload };
    default:
      return state;
  }
};
