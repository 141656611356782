import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import React, { useCallback, useContext, useState } from "react";
import {
  CustomButton,
  CustomModal,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import {
  TABLENAMES,
  TABS,
  config,
  configContract,
} from "./missingDspDeals.config";
import {
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import {
  DOWNLOAD_MISSING_DSP_DEALS_URL,
  GET_DSP_CONTRACTS_URL,
} from "../../../constants/GlobalUrls";
import SelectContractTable from "./SelectContractTable";
import FormRadioGroup from "../../../components/FormControl/FormRadio/FormRadioGroup";
import { accountEditTableStyle } from "../Accounts/AccountEditPage/AccountEditTables";
import FormRadioButton from "../../../components/FormControl/FormRadio/FormRadioButton";
import LabelTable from "./LabelTable";
import ReleaseTable from "./ReleaseTable";
import TrackOnUpcTable from "./TrackOnUpcTable";
import CustomChip from "../../../components/CustomChip/CustomChip";
import { updateSelectedRow } from "../Accounts/AccountEditPage/updateSelectedRow";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

// import adminNavigationString from "../../../navigation/AppStack/AdminAppDrawer/adminNavigationString";
const screen = Dimensions.get("window");
const { width } = screen;

let uuid = require("uuid");

const MissingDspDeals = ({
  activeTab,
  onTabChange,
  getRecordSelectedData,
  allTableData,
  isLoading,
  setIsLoading,
  getContractSelectedContract,
  onClickCreateContractBtn,
}) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let theme = useTheme();

  const [showSelectContractWindow, setShowSelectContractWindow] = useState({
    show: false,
    data: {},
    contractTableData: [],
  });
  const [tableLoader, setTableLoader] = useState(false);
  const { labelTableData, releaseTableData, trackOnUpcTableData } =
    allTableData;

  const closeShowSelectContractWindow = () => {
    setShowSelectContractWindow({
      show: false,
      data: {},
      contractTableData: [],
    });
  };

  const onClickDownloadCSV = async () => {
    setIsLoading(true);
    await api
      .get(DOWNLOAD_MISSING_DSP_DEALS_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `missing_dsp_deals_${uuid.v4()}.csv`);
        tempLink.click();
        setIsLoading(false);
      })
      .catch((error) => {
        checkNetworkReachable();
        setIsLoading(false);
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const openSelectContractWindow = async (record) => {
    setTableLoader(true);
    setShowSelectContractWindow({
      ...showSelectContractWindow,
      show: true,
    });
    const handleSuccessResponse = (res) => {
      setShowSelectContractWindow({
        show: true,
        data: record,
        contractTableData: res,
      });
    };
    await api
      .get(GET_DSP_CONTRACTS_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setTableLoader(false);
  };

  const onPressCloseSelectedChip = (id, removeKey) => {
    let finalData = updateSelectedRow(allTableData[activeTab], id, removeKey);
    getRecordSelectedData(finalData);
  };

  const getTotalSelected = useCallback(() => {
    return allTableData[activeTab].filter((x, i) => {
      return x.existing;
    }).length;
  }, [JSON.stringify(allTableData[activeTab])]);

  let selectActiveTab = (
    <FormRadioGroup
      inlineLabel
      label={"Table:"}
      value={activeTab}
      onChange={(name, value) => {
        onTabChange(value);
      }}
    >
      <View style={accountEditTableStyle.radioButtonWrapper}>
        <FormRadioButton
          label={`${TABLENAMES[TABS.LABEL]}`}
          value={TABS.LABEL}
        />
        <FormRadioButton
          label={`${TABLENAMES[TABS.RELEASE]}`}
          value={TABS.RELEASE}
        />
        <FormRadioButton
          label={`${TABLENAMES[TABS.TRACKS_ON_UPC]}`}
          value={TABS.TRACKS_ON_UPC}
        />
      </View>
    </FormRadioGroup>
  );

  let extraHeaderComponent = (
    <>
      <View style={commonStatisticButtonStyle(theme).statisticContainer}>
        <Statistics
          label={TABLENAMES.labelTableData}
          value={labelTableData.length || 0}
        />
        <Statistics
          label={TABLENAMES.releaseTableData}
          value={releaseTableData.length || 0}
        />
        <Statistics
          label={TABLENAMES.trackOnUpcTableData}
          value={trackOnUpcTableData.length || 0}
        />
        <Statistics label={"Selected Rows"} value={getTotalSelected() || 0} />
        <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
          <CustomButton
            disabled={getTotalSelected() === 0}
            text={config.CREATE_NEW_CONTRACT_BTN_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={onClickCreateContractBtn}
          />
          <CustomButton
            disabled={getTotalSelected() === 0}
            text={config.ADD_TO_EXISTING_CONTRACT_BTN_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={openSelectContractWindow}
          />
          <CustomButton
            text={DOWNLOAD_CSV_TEXT}
            cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
            textStyle={commonStatisButtonCustomStyle(theme).text}
            onPress={onClickDownloadCSV}
          />
        </View>
      </View>
      {getTotalSelected() > 0 && (
        <ScrollView
          style={styles(theme).chipWrapper}
          contentContainerStyle={styles(theme).chipContainer}
        >
          {activeTab === TABS.LABEL
            ? allTableData[activeTab].map((element, index) => {
                if (element?.existing) {
                  return (
                    <CustomChip
                      key={`${index}${element?.name}`}
                      label={`${element?.id} • ${element?.labelname}`}
                      value={element?.id}
                      removeKey={"id"}
                      onClose={onPressCloseSelectedChip}
                    />
                  );
                }
              })
            : activeTab === TABS.RELEASE
            ? allTableData[activeTab].map((element, index) => {
                if (element?.existing) {
                  return (
                    <CustomChip
                      key={`${index}${element?.name}`}
                      label={`${element?.release_id} • ${element?.release_title} • ${element?.release_upc}`}
                      value={element?.release_id}
                      removeKey={"release_id"}
                      onClose={onPressCloseSelectedChip}
                    />
                  );
                }
              })
            : allTableData[activeTab].map((element, index) => {
                if (element?.existing) {
                  return (
                    <CustomChip
                      key={`${index}${element?.name}`}
                      label={`${element?.release_track_upc_id} • ${element?.track_title} • ${element?.release_upc}${element?.track_isrc}`}
                      value={element?.release_track_upc_id}
                      removeKey={"release_track_upc_id"}
                      onClose={onPressCloseSelectedChip}
                    />
                  );
                }
              })}
        </ScrollView>
      )}
    </>
  );

  return (
    <WebContentContainerWrapper
      title={config.Title}
      loading={isLoading}
      extraHeaderComponent={extraHeaderComponent}
    >
      {activeTab === TABS.LABEL ? (
        <LabelTable
          data={labelTableData}
          selectActiveTab={selectActiveTab}
          onChangeSeletedRow={getRecordSelectedData}
        />
      ) : (
        <></>
      )}
      {activeTab === TABS.RELEASE ? (
        <ReleaseTable
          data={releaseTableData}
          selectActiveTab={selectActiveTab}
          onChangeSeletedRow={getRecordSelectedData}
        />
      ) : (
        <></>
      )}
      {activeTab === TABS.TRACKS_ON_UPC ? (
        <TrackOnUpcTable
          data={trackOnUpcTableData}
          selectActiveTab={selectActiveTab}
          onChangeSeletedRow={getRecordSelectedData}
        />
      ) : (
        <></>
      )}
      <CustomModal
        title={`${configContract.Title} (${showSelectContractWindow.contractTableData.length})`}
        visible={showSelectContractWindow.show}
        hideModal={closeShowSelectContractWindow}
      >
        <View style={styles(theme).modelContainer}>
          <SelectContractTable
            isLoading={tableLoader}
            data={showSelectContractWindow.contractTableData}
            getContractSelectedContract={getContractSelectedContract}
          />
        </View>
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default MissingDspDeals;

const styles = (theme) =>
  StyleSheet.create({
    modelContainer: { width: width - 150 },
    chipContainer: {
      flexDirection: "row",
      gap: 4,
      flexWrap: "wrap",
      maxHeight: 50,
    },
    chipWrapper: {
      marginTop: 16,
      padding: 8,
      borderColor: theme.colors.divider,
      borderWidth: 1,
      borderRadius: 10,
    },
  });
