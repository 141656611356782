import {
  StyleSheet,
  View,
  Text,
  ImageBackground,
  Pressable,
} from "react-native";
import React from "react";
import { withTheme } from "react-native-paper";
import { LinearGradient } from "expo-linear-gradient";
import { FontAwesome } from "@expo/vector-icons";

const TopMenu = ({
  title,
  theme,
  menuOptions,
  onMenuClick,
  activeMenu,
  singleOption = false,
  handleBackBtnClick = null,
}) => {
  const maskImage = () => {
    if (title === "Trends") {
      if (theme.dark) {
        return require("../../../assets/images/menuActiveDarkNew.png");
      } else {
        return require("../../../assets/images/menuActiveLightNew.png");
      }
    } else {
      if (theme.dark) {
        return require("../../../assets/images/menuActivePaymentDark.png");
      } else {
        return require("../../../assets/images/menuActivePaymentLight.png");
      }
    }
  };
  return (
    <LinearGradient
      // Linear Gradient
      colors={["#80efaf", "#7af6fa", "#5fd1fb", "#80efae", "#b3ec56"]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      style={styles(theme).container}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 20,
          marginTop: 60,
          marginBottom: 40,
        }}
      >
        <Pressable onPress={handleBackBtnClick} style={{ padding: 10 }}>
          <View>
            {!!handleBackBtnClick && (
              <FontAwesome
                name="chevron-left"
                size={18}
                color={theme.colors.black}
                style={{ width: 18, height: 18 }}
              />
            )}
          </View>
        </Pressable>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginRight: 18,
            padding: 10,
          }}
        >
          <Text style={styles(theme).headerText}>{title}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: !!singleOption ? "flex-start" : "space-around",
          // height: 71,
          marginHorizontal: 16,
        }}
      >
        {menuOptions.map((option) =>
          option.key === activeMenu ? (
            <ImageBackground
              key={option.key}
              source={maskImage()}
              style={{
                height: 42,
                width: title === "Trends" ? 150 : 190,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={styles(theme, option.key === activeMenu).menuText}>
                {option.title}
              </Text>
            </ImageBackground>
          ) : (
            <View
              key={option.key}
              style={{
                height: 42,
                width: title === "Trends" ? 150 : 190,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                onPress={() => {
                  onMenuClick(option.key);
                }}
                style={styles(theme).menuText}
              >
                {option.title}
              </Text>
            </View>
          )
        )}
      </View>
    </LinearGradient>
  );
};

export default withTheme(TopMenu);

const styles = (theme, active = false) =>
  StyleSheet.create({
    container: {
      backgroundColor: "red",
      width: "100%",
    },
    headerText: {
      color: theme.colors.black,
      fontSize: 20,
      fontFamily: theme.customFonts.semiBold,
    },
    headerContainer: {
      justifyContent: "center",
      alignSelf: "center",
      alignItems: "center",
    },
    menuText: {
      color: theme.dark
        ? active
          ? theme.colors.text
          : theme.colors.contrastText
        : theme.colors.text,
      fontSize: 14,
      fontFamily: theme.customFonts.semiBold,
    },
  });
