import React, { useContext, useEffect, useState } from "react";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import CustomTextInput from "../components/CustomTextInput";
import MessageValidation from "../components/MessageValidation";
import {
  BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INFORMATION_TEXT,
  MESSAGE_TYPE,
  NEW_PASSWORD_DONT_MATCH_MSG,
} from "../constants/GlobalVariables";
import { withTheme } from "react-native-paper";
import { checkObjectIsEmpty } from "../utils/checkObjectIsEmpty";
import AuthContextAPI from "../context/AuthContextAPI";
import axios from "axios";
import authNavigationStrings from "../navigation/AuthStack/authNavigationStrings";
import { CommonLayoutWrapper, CustomButton } from "../components";
import { commonStyle } from "../components/CommonStyles/commonStyle";

const NewPassword = ({ theme, navigation, route }) => {
  const { colors } = theme;

  const cancelTokenSource = axios.CancelToken.source();

  const { newPasswordTokenValidate, newPasswordConfirm } =
    useContext(AuthContextAPI);

  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
    showForm: true,
  });

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      checkTokenValidation();
    }
    return () => {
      isActive = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const { password, password_confirmation } = formData;

  const getToken = () => {
    return { token: route?.params?.token };
  };

  let checkTokenValidation = async () => {
    let token = getToken();
    await newPasswordTokenValidate(token)
      .then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
        } else {
          setMessage({
            showForm: false,
            show: true,
            type: MESSAGE_TYPE.error,
            message: "This link has been expired!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setMessage({
            showForm: false,
            show: true,
            type: MESSAGE_TYPE.error,
            message: error?.response?.statusText,
          });
        }
        setMessage({
          showForm: false,
          show: true,
          type: MESSAGE_TYPE.error,
          message: DEFAULT_ERROR_MSG,
        });
      });
    setIsLoading(false);
  };

  const onChangeText = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    let isEmpty = checkObjectIsEmpty(formData);
    if (isEmpty) {
      const handleSuccessResponse = (res) => {
        setMessage({
          showForm: false,
          show: true,
          type: MESSAGE_TYPE.success,
          message:
            "Your password has been successfully updated. Please open the mobile application and log in with your new password.",
        });
        setIsLoading(false);
      };

      const handleErrorResponse = (err) => {
        setIsLoading(false);
        setMessage({
          showForm: false,
          show: true,
          type: MESSAGE_TYPE.error,
          message: DEFAULT_ERROR_MSG,
        });
      };
      if (formData.password !== formData.password_confirmation) {
        setMessage({
          ...message,
          show: true,
          type: MESSAGE_TYPE.error,
          message: NEW_PASSWORD_DONT_MATCH_MSG,
        });
      } else {
        setIsLoading(true);
        let response = await newPasswordConfirm(
          { ...formData, ...getToken() },
          {
            cancelToken: cancelTokenSource.token,
          }
        )
          .then((res) => {
            handleSuccessResponse(res.data);
          })
          .catch((error) => {
            handleErrorResponse(error.response);
          });
        if (response) {
          setMessage(response);
        }
      }
    } else {
      setMessage({
        ...message,
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <CommonLayoutWrapper marginTop={10} loading={isLoading}>
      {message.showForm && (
        <>
          <View style={styles.titleContainer}>
            <Text style={{ color: colors.infoText }}>
              {INFORMATION_TEXT.newPassword}
            </Text>
          </View>
          <View style={commonStyle.commonMarginBottonContainer}>
            <CustomTextInput
              secureTextEntry
              name="password"
              placeholder="New Password"
              customStyle={styles.inputField}
              onChangeText={onChangeText}
              value={password}
            />
          </View>
          <CustomTextInput
            secureTextEntry
            name="password_confirmation"
            placeholder="Confirm New Password"
            onChangeText={onChangeText}
            value={password_confirmation}
            customStyle={styles.inputField}
          />
          <View style={styles.bottomButton}>
            <CustomButton
              text="Save New Password"
              buttontype={BUTTON_TYPE.PRIMARY}
              onPress={onSubmit}
            />
          </View>
        </>
      )}
      <MessageValidation {...message} />
      {message.showForm && (
        <View style={styles.bottomLinkButton}>
          <CustomButton
            text="Having Trouble?"
            buttontype={BUTTON_TYPE.LINK}
            onPress={() =>
              navigation.navigate(authNavigationStrings.CONTACT_SUPPORT.name)
            }
          />
        </View>
      )}
    </CommonLayoutWrapper>
  );
};

export default withTheme(NewPassword);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  titleContainer: {
    marginBottom: 40,
  },
  bottomButton: {
    marginTop: 30,
  },
  bottomLinkButton: {
    marginTop: 64,
  },
  inputField: {
    marginBottom: 10,
  },
});
