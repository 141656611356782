import { TABS } from "../catalogue.config";
import { CHANGE_ACTIVE_TAB, LOADER, UPDATE_MULTIPLE_DATA } from "./actions";

export const initialState = {
  isLoading: true,
  releaseTableData: [],
  trackTableData: [],
  available_upcs: 0,
  available_isrcs: 0,
  activeTab: TABS.RELEASES,
};

export const catalogueReducer = (state, action) => {
  switch (action.type) {
    case LOADER:
      return { ...state, isLoading: action.payload };
    case CHANGE_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case UPDATE_MULTIPLE_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
