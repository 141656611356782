import React from "react";
import { View } from "react-native";
import CustomTextInput from "../../CustomTextInput";
import MessageValidation from "../../MessageValidation";
import FormLabel from "../FormLabel/FormLabel";
import { formControlInputStyle } from "./formControlInputStyle";
import { FORM_REQUIRED_FIELD_MSG } from "../../../constants/GlobalVariables";

const FormControlInput = ({
  label,
  name,
  onChange = () => {},
  value,
  isDatePicker = false,
  onDatePickerClick,
  disabled = false,
  placeholder,
  required = false,
  isValidated = false,
  validationMessage = FORM_REQUIRED_FIELD_MSG,
  secureTextEntry = false,
  showHelpText = true,
}) => {
  return (
    <View style={formControlInputStyle.container}>
      <FormLabel text={label} required={required} isValidated={isValidated} />
      <CustomTextInput
        secureTextEntry={secureTextEntry}
        isDatePicker={isDatePicker}
        onDatePickerClick={onDatePickerClick}
        name={name}
        value={value || ""}
        disabled={disabled}
        placeholder={placeholder}
        // customStyle={styles.userNameInput}
        onChangeText={onChange}
        isValidated={isValidated}
      />
      {showHelpText && (
        <MessageValidation
          fieldValidation={isValidated}
          message={validationMessage}
        />
      )}
    </View>
  );
};

export default FormControlInput;
