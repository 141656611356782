import { ScrollView, StyleSheet, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  WebContentContainerWrapper,
  commonFormStyle,
} from "../../../../components";
import { pageConfig } from "../Accounts.config";
import { config } from "../AccountsListingPage/AccountsListingPage.config";
import { config as tablePageConfig } from "../AccountEditPage/account.config";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
} from "../../../../constants/GlobalVariables";
import { Divider, useTheme } from "react-native-paper";
import AccountEditTables from "./AccountEditTables";
import SelectedChipContainer from "./SelectedChipContainer";
import CustomChip from "../../../../components/CustomChip/CustomChip";
import { updateSelectedRow } from "./updateSelectedRow";
import { UPDATE_USER_ACCOUNT_DATA } from "../../../../constants/GlobalUrls";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const AccountEditPage = ({ recordData, changeShowPage }) => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  let theme = useTheme();
  let accountType = recordData?.accounttype;
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationWindow, setShowConfirmationWindow] = useState(false);
  const [clientsListing, setClientsListing] = useState([]);
  const [secondTableListing, setSecondTableListing] = useState([]);
  const [firstTabActive, setFirstTabActive] = useState(true);
  const [totalClientsSelected, setTotalClientsSelected] = useState(0);
  const [totalSecondTableSelected, setTotalSecondTableSelected] = useState(0);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      setTotalClientsSelected(
        clientsListing.filter((x, i) => {
          return x.existing;
        }).length
      );
    }
    return () => {
      isMoulded = false;
    };
  }, [clientsListing]);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      setTotalSecondTableSelected(
        secondTableListing.filter((x, i) => {
          return x.existing;
        }).length
      );
    }
    return () => {
      isMoulded = false;
    };
  }, [secondTableListing]);

  const gotToLandingPage = () => {
    changeShowPage(pageConfig.pages.LANDING);
  };
  const breadCrumbData = [
    {
      title: config.Title,
      onClickPress: gotToLandingPage,
    },
    {
      title: `${recordData?.username}`,
      active: true,
    },
  ];

  const openConfirmationWindow = () => {
    setShowConfirmationWindow(true);
  };

  const closeConfirmationWindow = () => {
    setShowConfirmationWindow(false);
  };

  const onPressCloseSelectedChipFirst = (id) => {
    let finalData = updateSelectedRow(clientsListing, id, "client_id");
    setClientsListing(finalData);
  };

  const onPressCloseSelectedChipSecond = (id, removeKey) => {
    let finalData = updateSelectedRow(secondTableListing, id, removeKey);
    setSecondTableListing(finalData);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      gotToLandingPage();
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        if (error?.response?.data?.msg) {
          alert(error?.response?.data?.msg);
        } else {
          alert(DEFAULT_ERROR_MSG);
        }
      }
    };

    let payload = {
      clients: [],
      artists: [],
      labels: [],
    };

    payload.clients = clientsListing
      .filter(({ existing }) => existing)
      .map(({ client_id }) => client_id);

    if (accountType === tablePageConfig.ARTIST_ACCOUNT_TYPE) {
      payload.artists = secondTableListing
        .filter(({ existing }) => existing)
        .map(({ artist_id }) => artist_id);
    } else {
      payload.labels = secondTableListing
        .filter(({ existing }) => existing)
        .map(({ label_id }) => label_id);
    }

    let url = `${UPDATE_USER_ACCOUNT_DATA}${recordData.id}/`;
    await api
      .post(url, payload, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
    setIsLoading(false);
  };

  let actionButtons = (
    <View style={commonFormStyle(theme).actionBtnHeaderContainer}>
      <CustomButton
        onPress={onSubmit}
        text={BUTTON_TEXTS.SAVE}
        cutomButtonStyle={commonFormStyle(theme).submitBtn}
      />
      <CustomButton
        text={BUTTON_TEXTS.CANCEL}
        onPress={openConfirmationWindow}
        cutomButtonStyle={commonFormStyle(theme).backBtn}
      />
    </View>
  );

  let extraHeaderComponent = (
    <View style={styles(theme).selectedRowsChipContainer}>
      <SelectedChipContainer
        activeTab={firstTabActive}
        label={`${tablePageConfig.CLIENT_TITLE} (${clientsListing.length} - ${totalClientsSelected})`}
      >
        <ScrollView
          style={styles(theme).chipWrapper}
          contentContainerStyle={styles(theme).chipContainer}
        >
          {clientsListing.map((element, index) => {
            if (element?.existing) {
              return (
                <CustomChip
                  key={`${index}${element?.name}`}
                  label={`${element?.client_id} • ${element?.name}`}
                  value={element?.client_id}
                  onClose={onPressCloseSelectedChipFirst}
                />
              );
            }
          })}
        </ScrollView>
      </SelectedChipContainer>
      <Divider style={styles(theme).dividerContainer} />
      {accountType === tablePageConfig.ARTIST_ACCOUNT_TYPE ? (
        <SelectedChipContainer
          activeTab={!firstTabActive}
          label={`${tablePageConfig.ARTIST_TITLE} (${secondTableListing.length} - ${totalSecondTableSelected})`}
        >
          <ScrollView
            style={styles(theme).chipWrapper}
            contentContainerStyle={styles(theme).chipContainer}
          >
            {secondTableListing.map((element, index) => {
              if (element?.existing) {
                return (
                  <CustomChip
                    key={`${index}${element?.artist_id}`}
                    label={`${element?.artist_id} • ${element?.artist_name}`}
                    value={element?.artist_id}
                    removeKey={"artist_id"}
                    onClose={onPressCloseSelectedChipSecond}
                  />
                );
              }
            })}
          </ScrollView>
        </SelectedChipContainer>
      ) : (
        <SelectedChipContainer
          activeTab={!firstTabActive}
          label={`${tablePageConfig.LABEL_TITLE} (${secondTableListing.length} - ${totalSecondTableSelected})`}
        >
          <ScrollView
            style={styles(theme).chipWrapper}
            contentContainerStyle={styles(theme).chipContainer}
          >
            {secondTableListing.map((element, index) => {
              if (element?.existing) {
                return (
                  <CustomChip
                    key={`${index}${element?.label_id}`}
                    label={`${element?.label_id} • ${element?.label_name}`}
                    value={element?.label_id}
                    removeKey={"label_id"}
                    onClose={onPressCloseSelectedChipSecond}
                  />
                );
              }
            })}
          </ScrollView>
        </SelectedChipContainer>
      )}
    </View>
  );

  return (
    <WebContentContainerWrapper
      loading={isLoading}
      breadCrumbData={breadCrumbData}
      actionButtons={actionButtons}
      extraHeaderComponent={extraHeaderComponent}
    >
      <AccountEditTables
        accountType={accountType}
        userAccountId={recordData?.id}
        clientsListing={clientsListing}
        setClientsListing={setClientsListing}
        secondTableListing={secondTableListing}
        setSecondTableListing={setSecondTableListing}
        firstTabActive={firstTabActive}
        setFirstTabActive={setFirstTabActive}
        setIsLoading={setIsLoading}
      />
      <CustomModal
        visible={showConfirmationWindow}
        hideModal={closeConfirmationWindow}
      >
        <ConfirmationWindow
          message={`Are you sure you want to Cancel without saving the changes?`}
          handleNoOnClick={closeConfirmationWindow}
          handleYesOnClick={gotToLandingPage}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default AccountEditPage;

const styles = (theme) =>
  StyleSheet.create({
    selectedRowsChipContainer: { flexDirection: "row", flex: 1 },
    dividerContainer: { width: 2, height: "100%", marginHorizontal: 10 },
    chipContainer: {
      flexDirection: "row",
      gap: 4,
      flexWrap: "wrap",
    },
    chipWrapper: {
      marginTop: 16,
      padding: 8,
      borderColor: theme.colors.divider,
      borderWidth: 1,
      borderRadius: 10,
      height: 50,
    },
  });
