import React from "react";
import { RoyaltiesProvider } from "./Reducer/RoyaltiesContextAPI";
import Royalties from "./Royalties";

const RoyaltiesConsumer = () => {
  return (
    <RoyaltiesProvider>
      <Royalties />
    </RoyaltiesProvider>
  );
};

export default RoyaltiesConsumer;
