import { StyleSheet } from "react-native";

export const commonStatisticButtonStyle = (theme, searchBarWidth = 450) =>
  StyleSheet.create({
    statisticContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchBar: {
      height: 36,
      width: searchBarWidth,
      marginTop: 32,
      marginBottom: 16,
    },
    searchBarWithoutMarginTop: {
      height: 36,
      width: searchBarWidth,
      marginBottom: 8,
    },
    searchBarInput: { fontSize: 16 },
  });

export const commonStatisButtonCustomStyle = (theme) => {
  return {
    buttonContainer: { flexDirection: "row" },
    downloadBtn: { marginLeft: 20 },
    text: { fontFamily: theme.customFonts.bold, fontSize: 16 },
  };
};
