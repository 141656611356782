import { StyleSheet } from "react-native";

export const commonAvatarStyle = (size, circleShape) =>
  StyleSheet.create({
    container: {
      borderRadius: circleShape ? size / 2 : 10,
      width: size,
      height: size,
      overflow: "hidden",
    },
  });
