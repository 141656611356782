import AsyncStorage from "@react-native-async-storage/async-storage";

export const getData = async (storageKey) => {
  try {
    const value = await AsyncStorage.getItem(storageKey);
    if (value !== null) {
      return value;
      // value previously stored
    }
  } catch (e) {
    // error reading value
  }
};
