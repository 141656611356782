import { StyleSheet, View } from "react-native";
import React, { useContext, useEffect } from "react";
import { config } from "./account.config";
import ClientsTable from "./ClientsTable";
import ArtistTable from "./ArtistTable";
import LabelTable from "./LabelTable";
import FormRadioGroup from "../../../../components/FormControl/FormRadio/FormRadioGroup";
import FormRadioButton from "../../../../components/FormControl/FormRadio/FormRadioButton";
import {
  GET_ALL_ARTIST_URL,
  GET_ALL_CLIENT_URL,
  GET_ALL_LABELS_URL,
} from "../../../../constants/GlobalUrls";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const AccountEditTables = ({
  accountType,
  userAccountId,
  clientsListing,
  setClientsListing,
  secondTableListing,
  setSecondTableListing,
  firstTabActive,
  setFirstTabActive,
  setIsLoading,
}) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getTableData();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const getTableData = async () => {
    setIsLoading(true);
    let SECONDTAB_URL =
      accountType === config.ARTIST_ACCOUNT_TYPE
        ? GET_ALL_ARTIST_URL
        : GET_ALL_LABELS_URL;
    let endpoints = [
      `${GET_ALL_CLIENT_URL}${userAccountId}/`,
      `${SECONDTAB_URL}${userAccountId}/`,
    ];
    await axios
      .all(
        endpoints.map((endpoint) =>
          api.get(endpoint, {
            cancelToken: cancelTokenSource.token,
          })
        )
      )
      .then(
        axios.spread(
          ({ data: clientListingData }, { data: secondTabListingData }) => {
            setClientsListing(clientListingData);
            setSecondTableListing(secondTabListingData);
            setIsLoading(false);
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
        setIsLoading(false);
      });
  };

  const onTabChange = () => {
    setFirstTabActive(!firstTabActive);
  };

  let selectActiveTab = (
    <FormRadioGroup
      inlineLabel
      label={"Table:"}
      value={firstTabActive}
      onChange={onTabChange}
    >
      <View style={accountEditTableStyle.radioButtonWrapper}>
        <FormRadioButton label={config.CLIENT_TABLE_TITLE} value={true} />
        <FormRadioButton
          label={
            accountType === config.ARTIST_ACCOUNT_TYPE
              ? config.ARTIST_TABLE_TITLE
              : config.LABEL_TABLE_TITLE
          }
          value={false}
        />
      </View>
    </FormRadioGroup>
  );

  return (
    <View>
      <View>
        {firstTabActive ? (
          <ClientsTable
            data={clientsListing}
            selectActiveTab={selectActiveTab}
            updateTable={setClientsListing}
          />
        ) : accountType === config.ARTIST_ACCOUNT_TYPE ? (
          <ArtistTable
            data={secondTableListing}
            selectActiveTab={selectActiveTab}
            updateTable={setSecondTableListing}
          />
        ) : (
          <LabelTable
            data={secondTableListing}
            selectActiveTab={selectActiveTab}
            updateTable={setSecondTableListing}
          />
        )}
      </View>
    </View>
  );
};

export default AccountEditTables;

export const accountEditTableStyle = StyleSheet.create({
  radioButtonWrapper: { flexDirection: "row" },
});
