export default {
  HOME: {
    name: "home",
    title: "Home",
  },
  TRENDS: {
    name: "trends",
    title: "Trends",
  },
  PAYMENTS: {
    name: "payments",
    title: "Payments",
  },
  PRODUCTS: {
    name: "products",
    title: "Products",
  },
  SETTINGS: {
    name: "settings",
    title: "Settings",
  },
  CHANGE_PASSWORD: {
    name: "change-password",
    title: "Change Password",
  },
  VICTORY: {
    name: "victory",
    title: "Victory Charts",
  },
};
