export const config = {
  TITLE: "Employee Accounts",
  CREATE_BTN_TEXT: "Create New Account",
  SEARCH_PLACEHOLDER: "Search by  Username/Email/Account Type",
};

export const PERMISSIONS = {
  ALL: "all",
  SOME: "some",
  NONE: "no",
};

export const ADMIN_VALUE = "Admin";
export const EMPLOYEE_VALUE = "Employee";
export const ADMIN_ROLE_VALUE = "Admin";
export const ACTIVATE_ACCOUNT_BTN = "Activate Account";
export const DEACTIVATE_ACCOUNT_BTN = "Deactivate Account";
export const CHANGE_PASSWORD_BTN = "Change Password";

export const accountTypeOptions = [
  {
    title: ADMIN_VALUE,
    value: ADMIN_VALUE,
  },
  {
    title: EMPLOYEE_VALUE,
    value: EMPLOYEE_VALUE,
  },
];
