export const config = {
  Title: "User Accounts",
  STATISITICS_ARR: [{ label: "Total", value: "total" }],
  SEARCH_PLACEHOLDER: "Search by ID/Username/Email/Account Type",
  CREATE_BTN_TEXT: "Create New Account",
  CREATE_NEW_ACCOUNT_FORM_TITLE: "New Account",
};

export const ARTIST_VALUE = "Artist";
export const LABEL_VALUE = "Label";

export const accountTypeOptions = [
  {
    title: ARTIST_VALUE,
    value: ARTIST_VALUE,
  },
  {
    title: LABEL_VALUE,
    value: LABEL_VALUE,
  },
];

export const TABLE_IDS = {
  CLIENT_TABLE_ID: "Clients",
  ARTIST_TABLE_ID: "Artists",
  LABEL_TABLE_ID: "Labels",
};
