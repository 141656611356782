import { View, Text } from "react-native";
import React from "react";
import { withTheme } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import InformationText from "../CustomText/InformationText";
import { commonHeaderStyle } from "./commonHeaderStyle";
import CommonAvatar from "../CommonAvatar/CommonAvatar";

const CommonCardHeaders = ({
  theme,
  title,
  subtitle,
  customIcon,
  showIcon = true,
  src = null,
  size = 20,
  uri = null,
  customIconUri = false,
}) => {
  return (
    <View style={commonHeaderStyle(theme).container}>
      <View style={commonHeaderStyle(theme).titleIconContainer}>
        <View style={commonHeaderStyle(theme).titleSubtitleImage}>
          {!!src && (
            <View style={commonHeaderStyle(theme).imageContainer}>
              <CommonAvatar src={src} uri={uri} />
            </View>
          )}
          <View>
            <Text style={commonHeaderStyle(theme, size).titleText}>
              {title}
            </Text>
            {!!subtitle && (
              <View>
                <InformationText text={subtitle} />
              </View>
            )}
          </View>
        </View>
        {!!showIcon &&
          (!!customIcon ? (
            <CommonAvatar src={customIcon} uri={customIconUri} />
          ) : (
            <Ionicons
              name="ios-information-circle-outline"
              size={24}
              color={theme.colors.infoText}
              style={commonHeaderStyle(theme).icon}
            />
          ))}
      </View>
    </View>
  );
};

export default withTheme(CommonCardHeaders);
