import { View, StyleSheet } from "react-native";
import React, { useState, useEffect } from "react";
import Tableslide from "../../Carousel/Slide/Tableslide";
import SecondaryHeaderText from "../../CustomText/SecondaryHeaderText";
import InformationText from "../../CustomText/InformationText";
import CommonAvatar from "../../CommonAvatar/CommonAvatar";
import { AntDesign } from "@expo/vector-icons";
import { withTheme } from "react-native-paper";
import { commonStyle } from "../../CommonStyles/commonStyle";

const TopChartedTracks = ({ theme }) => {
  let columns = [
    {
      key: "chartname",
      title: "Track / Released",
      dataIndex: "chartname",
      width: 2,
      render: (store, record) => {
        return (
          <View style={commonStyle.tableColumnImageTextContainer}>
            <View style={commonStyle.TableImageContainer}>
              <CommonAvatar
                src={require("../../../../assets/images/sample_picture.png")}
              />
            </View>

            <View style={commonStyle.TableTextContainer}>
              <SecondaryHeaderText text={store} />
              <InformationText text={record.date} />
            </View>
          </View>
        );
      },
      // bold: true,
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
      center: true,
      width: 0.7,
      render: () => {
        return (
          <View>
            <CommonAvatar
              src={require("../../../../assets/images/057-india.png")}
              size={24}
              circleShape
            />
          </View>
        );
      },
    },
    {
      key: "rank",
      title: "Rank",
      dataIndex: "rank",
      center: true,
      width: 0.7,
      render: (rank) => {
        let positive = rank < 4;
        return (
          <View style={styles.descriptionContainer}>
            <View style={styles.iconContainer}>
              <AntDesign
                name={positive ? "caretup" : "caretdown"}
                size={16}
                color={
                  positive
                    ? theme.colors.actionIconActive
                    : theme.colors.downArrow
                }
                style={styles.icon}
              />
            </View>
            <SecondaryHeaderText text={`${rank}`} />
          </View>
        );
      },
    },
    {
      key: "peak",
      title: "Peak",
      dataIndex: "peak",
      center: true,
      width: 0.5,
      render: (peak) => {
        return (
          <View>
            <SecondaryHeaderText text={`${peak}`} />
          </View>
        );
      },
    },
  ];
  const data = {
    title: "Top Charted Tracks",
    columns: columns,
    showHeader: true,
    data: [
      {
        id: 1,
        chartname: "Chart 1",
        date: "18/06/2020",
        country: "India",
        rank: 3,
        peak: 1,
      },
      {
        id: 2,
        chartname: "Chart 1",
        date: "18/06/2020",
        country: "India",
        rank: 3,
        peak: 1,
      },
      {
        id: 2,
        chartname: "Chart 1",
        date: "18/06/2020",
        country: "India",
        rank: 3,
        peak: 1,
      },
      {
        id: 2,
        chartname: "Chart 1",
        date: "18/06/2020",
        country: "India",
        rank: 3,
        peak: 1,
      },
    ],
  };
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let isMoulded = true;

    if (isMoulded) {
      setTableData(data);
    }
    return () => {
      isMoulded = false;
    };
  }, []);
  return (
    <View>
      <Tableslide data={tableData} slide={false} />
    </View>
  );
};

export default withTheme(TopChartedTracks);

const styles = StyleSheet.create({
  iconContainer: {
    marginRight: 8,
    justifyContent: "flex-end",
  },
  icon: { height: 16, width: 16 },
  descriptionContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
