import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
} from "react-native-paper";
import {
  customDarkTheme,
  customFonts,
  customLightTheme,
  darkThemeCustomComponent,
  lightThemeCustomComponent,
} from "./customTheme";
import {
  DarkTheme as DarkThemeNavigation,
  DefaultTheme as DefaultThemeNavigation,
} from "@react-navigation/native";

export const getPaperTheme = (isDark = false) => {
  if (isDark) {
    return {
      ...PaperDarkTheme,
      ...darkThemeCustomComponent,
      dark: isDark,
      mode: "adaptive",
      colors: {
        ...PaperDarkTheme.colors,
        ...customDarkTheme,
      },
      customFonts: customFonts,
      roundness: 10,
    };
  } else {
    return {
      ...PaperDefaultTheme,
      ...lightThemeCustomComponent,
      dark: isDark,
      mode: "adaptive",
      colors: {
        ...PaperDefaultTheme.colors,
        ...customLightTheme,
      },
      customFonts: customFonts,
      roundness: 10,
    };
  }
};

export const getNavigationTheme = (isDark = false) => {
  if (isDark) {
    return {
      ...DarkThemeNavigation,
      dark: isDark,
      colors: {
        ...DarkThemeNavigation.colors,
        ...customDarkTheme,
      },
      customFonts: customFonts,
    };
  } else {
    return {
      ...DefaultThemeNavigation,
      dark: isDark,
      colors: {
        ...DefaultThemeNavigation.colors,
        ...customLightTheme,
      },
      customFonts: customFonts,
    };
  }
};
