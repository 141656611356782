import { createContext, useContext, useEffect, useReducer } from "react";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import {
  initialState,
  reportVersionReducer,
} from "./reducer/reportVersionReducer";
import { GET_ALL_REPORT_VERSIONS_URL } from "../../../constants/GlobalUrls";
import { LOADER, UPDATE_TABLE_DATA } from "./reducer/actions";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";

const ReportVersionContextAPI = createContext();

export default ReportVersionContextAPI;

export const ReportVersionProvider = ({ children }) => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const [state, dispatch] = useReducer(reportVersionReducer, initialState);

  const getTableData = async () => {
    dispatch({ type: LOADER, payload: true });

    await api
      .get(GET_ALL_REPORT_VERSIONS_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        dispatch({ type: UPDATE_TABLE_DATA, payload: response.data });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    dispatch({ type: LOADER, payload: false });
  };

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getTableData();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  let contextData = {
    state,
    dispatch,
    initialState,
  };

  return (
    <ReportVersionContextAPI.Provider value={contextData}>
      {children}
    </ReportVersionContextAPI.Provider>
  );
};
