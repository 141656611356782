import { createContext, useContext, useEffect, useReducer } from "react";
import {
  initialState,
  overlappingDspDealReducer,
} from "./overlappingDspDealReducer";
import { LOADER, UPDATE_MULTIPLE_DATA } from "./actions";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import {
  GET_OVERLAPPING_LABELS_URL,
  GET_OVERLAPPING_RELEASES_URL,
  GET_OVERLAPPING_RELEASE_TRACKS_URL,
} from "../../../../constants/GlobalUrls";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const OverlappingDspDealContextAPI = createContext();

export default OverlappingDspDealContextAPI;

export const OverlappingDspDealProvider = ({ children }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [state, dispatch] = useReducer(overlappingDspDealReducer, initialState);

  let { refreshTable, landing } = state;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && landing) {
      getTableData();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [refreshTable, landing]);

  const getTableData = async () => {
    dispatch({ type: LOADER, payload: true });
    let endpoints = [
      GET_OVERLAPPING_LABELS_URL,
      GET_OVERLAPPING_RELEASES_URL,
      GET_OVERLAPPING_RELEASE_TRACKS_URL,
    ];

    await Promise.all(
      endpoints.map((endpoint) =>
        api.get(endpoint, {
          cancelToken: cancelTokenSource.token,
        })
      )
    )
      .then(
        axios.spread(
          (
            { data: labelTableData },
            { data: releaseTableData },
            { data: trackOnUpcTableData }
          ) => {
            dispatch({
              type: UPDATE_MULTIPLE_DATA,
              payload: {
                labelTableData,
                releaseTableData,
                trackOnUpcTableData,
                isLoading: false,
              },
            });
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        dispatch({ type: LOADER, payload: false });
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  let contextData = {
    state,
    dispatch,
    initialState,
  };
  return (
    <OverlappingDspDealContextAPI.Provider value={contextData}>
      {children}
    </OverlappingDspDealContextAPI.Provider>
  );
};
