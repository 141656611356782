import React, { useContext, useEffect, useState } from "react";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import { withTheme } from "react-native-paper";
import { CommonLayoutWrapper, CustomButton } from "../components";
import CustomTextInput from "../components/CustomTextInput";
import MessageValidation from "../components/MessageValidation";
import {
  BUTTON_TYPE,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INFORMATION_TEXT,
  INVALID_EMAIL_MSG,
  MESSAGE_TYPE,
} from "../constants/GlobalVariables";
import AuthContextAPI from "../context/AuthContextAPI";
import authNavigationStrings from "../navigation/AuthStack/authNavigationStrings";
import { checkObjectIsEmpty } from "../utils/checkObjectIsEmpty";
import { isValidateEmail } from "../utils/isValidateEmail";

const ForgotPassword = ({ navigation, theme }) => {
  let initialState = {
    form: { email: "" },
    message: {
      show: false,
      type: "",
      message: "",
    },
  };
  const { colors } = theme;
  const { forgotPassword } = useContext(AuthContextAPI);

  const [formData, setFormData] = useState(initialState.form);
  const [message, setMessage] = useState(initialState.message);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      setFormData(initialState.form);
      setMessage(initialState.message);
    });
  }, [navigation]);

  const { email } = formData;

  const onChangeText = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    let isEmpty = checkObjectIsEmpty(formData);
    if (isEmpty) {
      if (!isValidateEmail(formData.email)) {
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_EMAIL_MSG,
        });
      } else {
        let response = await forgotPassword(formData);
        if (response) {
          setFormData(initialState.form);
          setMessage(response);
        }
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <CommonLayoutWrapper marginTop={20}>
      <View style={styles.titleContainer}>
        <Text style={{ color: colors.infoText }}>
          {INFORMATION_TEXT.forgotPassword}
        </Text>
      </View>
      <CustomTextInput
        name="email"
        placeholder="Email"
        customStyle={styles.inputField}
        onChangeText={onChangeText}
        value={email}
      />
      <View style={styles.bottomButton}>
        <CustomButton
          text="Reset Password"
          buttontype={BUTTON_TYPE.PRIMARY}
          onPress={onSubmit}
        />
      </View>
      <MessageValidation {...message} />
      <View style={styles.bottomLinkButton}>
        <CustomButton
          text="Having Trouble?"
          buttontype={BUTTON_TYPE.LINK}
          onPress={() =>
            navigation.navigate(authNavigationStrings.CONTACT_SUPPORT.name)
          }
        />
      </View>
    </CommonLayoutWrapper>
  );
};

export default withTheme(ForgotPassword);

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: 40,
  },
  bottomButton: {
    marginTop: 30,
  },
  bottomLinkButton: {
    marginTop: 64,
  },
  inputField: {
    marginBottom: 10,
  },
});
