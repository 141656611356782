import dayjs from "dayjs";
import { View } from "react-native";
import React, { useContext } from "react";
import CommonAvatar from "../../components/CommonAvatar/CommonAvatar";
import HeaderText from "../../components/CustomText/HeaderText";
import InformationText from "../../components/CustomText/InformationText";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import { landingStyles } from "./landingStyles";
import { withTheme } from "react-native-paper";
import AuthContextAPI from "../../context/AuthContextAPI";
import nonAdminNavigationStrings from "../../navigation/AppStack/NonAdminAppStack/nonAdminNavigationStrings";
import { imageUrl } from "../../utils/imageUrl";

const LandingPageHeader = ({ navigation, theme }) => {
  const {
    userInformation: { username, profilepicturepath },
  } = useContext(AuthContextAPI);
  const profileImageUrl = imageUrl(profilepicturepath);
  const todayDate = dayjs(new Date()).format("ddd, MMM D YYYY");

  return (
    <View style={landingStyles.headerContainer}>
      <View>
        <CommonAvatar uri src={profileImageUrl} />
      </View>
      <View style={landingStyles.headerTextContainer}>
        <HeaderText text={`Welcome, ${username}`} />
        <InformationText text={todayDate} />
      </View>
      <View style={landingStyles.headerIconsContainer}>
        <Ionicons
          name="notifications-circle"
          size={28}
          color={theme.colors.actionIconActive}
          style={landingStyles.icons}
        />
        <AntDesign
          name="setting"
          size={28}
          color={theme.colors.secondaryText}
          style={landingStyles.icons}
          onPress={() =>
            navigation.navigate(nonAdminNavigationStrings.SETTINGS.name)
          }
        />
      </View>
    </View>
  );
};

export default withTheme(LandingPageHeader);
